import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {

  msg = '';
  title = '';

  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) { }

  ngOnInit() { }

  onClose() {
    this.dialogRef.close(false);
  }

  yes() {
    this.dialogRef.close(true);
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse,HttpRequest} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Config } from 'codelyzer';

@Injectable()
export class ParksService {
    isNewParkAdded = new BehaviorSubject(false);
    constructor(private http: HttpClient) { }
    public getParksListDefaults(data): Observable<HttpResponse<any>> {
        let params = new HttpParams().set('cityId', data.cityId);
        console.log('Params to get parks:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        // const options = {observe: 'response', params: params, headers: headers};
        const url = environment.facilityUrl + '/parks/parksbycity';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public getParks(data): Observable<HttpResponse<any>> {
        let params = new HttpParams().set('cityId', data.cityId);
        console.log('Params to get parks:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        // const options = {observe: 'response', params: params, headers: headers};
        const url = environment.facilityUrl + '/parks/parksbycity';
        return this.http.get<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public getFormats(parkId): Observable<HttpResponse<any>> {
        let params = new HttpParams().set('parkId',parkId);
        // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        // const options = {observe: 'response', params: params, headers: headers};
        const url = environment.facilityUrl + '/parks/gameFormats';
        return this.http.get<any>(url, { observe: 'response',params: params, });
    }
    public updateDefaults(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('parkId', data.parkId);
        params = params.append('repId', data.repId);
        console.log('Params to update default park:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/admin/defaults';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public deletePark(data): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('cityId', data.cityId);
        params = params.append('parkId', data.parkId);
        console.log('Params to delete park:', params);
        let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
        const url = environment.apiUrl + '/parks';
        return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
    }
    public addPark(data,selectedFormatList,isKioskAvailable, data1): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('Game_Formats', selectedFormatList.toString());
        params = params.append('Kiosk_Status',isKioskAvailable?'Active':'Inactive');
        params = params.append('parkName', data.parkName);
        params = params.append('street', data.street);
        params = params.append('city', data.city);
        params = params.append('state', data.state);
        params = params.append('zipCode', data.zipCode);
        params = params.append('website', data.website);
        params = params.append('cityId', data1.cityId);
        params = params.append('Agreement', data1.Agreement);
        params = params.append('DonationFlag', data1.donation);
        params = params.append('Location_Id', data.location);
        console.log('Params to add park:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json'); 
        const url= environment.facilityUrl + '/park'       
        // const url='https://hrav6vv6q0.execute-api.us-east-1.amazonaws.com/dev/park';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public addFormat(name, hours,price): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('Format_Name', name);
        params = params.append('Hours', hours);
        params = params.append('Amount_Per_Hour', price);
        console.log('Params to add park:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json'); 
        const url= environment.facilityUrl + '/parks/gameFormats'     
        // const url='https://hrav6vv6q0.execute-api.us-east-1.amazonaws.com/dev/park';
        return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
    public editPark(data,selectedFormatList,isKioskAvailable, data1): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('Game_Formats', selectedFormatList.toString());
        params = params.append('Kiosk_Status',isKioskAvailable?'Active':'Inactive');
        params = params.append('parkName', data.parkName);
        params = params.append('parkId', data.parkId);
        params = params.append('street', data.street);
        params = params.append('city', data.city);
        params = params.append('state', data.state);
        params = params.append('zipCode', data.zipCode);
        params = params.append('website', data.website);
        params = params.append('cityId', data1.cityId);
        params = params.append('Agreement', data1.Agreement);
        params = params.append('DonationFlag', data1.donation);
        params = params.append('Location_Id', data.location);
        console.log('Params to edit park:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');   
        const url= environment.facilityUrl + '/park'            
        // const url='https://hrav6vv6q0.execute-api.us-east-1.amazonaws.com/dev/park';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }

    public uploadfile(url, file): Observable<HttpResponse<any>>{ 
        let headers = new HttpHeaders().set('Content-Type', 'application/pdf');
        const formData: FormData = new FormData();
        formData.append('pdf', file, file.name);
        return this.http.put<any>(url,formData,{headers:headers})
     }

    public addParkAgreement(parkId,agreement): Observable<HttpResponse<any>> {
        let params = new HttpParams();
        params = params.append('parkId', parkId);
        params = params.append('Park_Agreement', agreement);
        console.log('Params to edit park:', params);
        let headers = new HttpHeaders().set('Content-Type', 'application/json');  
        const url= environment.facilityUrl + '/park'             
        //const url='https://hrav6vv6q0.execute-api.us-east-1.amazonaws.com/dev/park';
        return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
    }
}

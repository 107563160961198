import { CheckStatusComponent } from './public/check-status/check-status.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import {BrowserModule} from "@angular/platform-browser";
import {EventEmitter, NgModule, Output} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
// import {HttpModule} from "@angular/http";
import { AppComponent } from "./app.component";
import { UserRegistrationService } from "./service/user-registration.service";
import { UserParametersService } from "./service/user-parameters.service";
import { UserLoginService } from "./service/user-login.service";
import { CognitoUtil } from "./service/cognito.service";
import { NotificationsService } from "./service/notifications.service"
import { routing } from "./app.routes";
import { HomeComponent, HomeLandingComponent } from "./public/home.component";
import { AwsUtil } from "./service/aws.service";
import { AdminsComponent } from "./secure/admins/admins.component";
import { GroundsComponent } from "./secure/grounds/grounds.component";
import { AdminComponent } from "./admin.component";
import { SlotsComponent } from "./secure/slots/slots.component";
import { ParksComponent } from "./secure/parks/parks.component";
import { ParkDefaultsComponent } from "./secure/parkDefaults/parkDefaults.component";
import { LoginComponent } from "./public/auth/login/login.component";
import { RegisterComponent } from "./public/auth/register/registration.component";
import { ForgotPassword2Component, ForgotPasswordStep1Component } from "./public/auth/forgot/forgotPassword.component";
import { LogoutComponent, ConfirmRegistrationComponent } from "./public/auth/confirm/confirmRegistration.component";
import { ResendCodeComponent } from "./public/auth/resend/resendCode.component";
import { NewPasswordComponent } from "./public/auth/newpassword/newpassword.component";
import { MFAComponent } from './public/auth/mfa/mfa.component';
import { ChartModule } from 'primeng/chart';
// import { CalendarComponent } from './calendar/calendar.component';
// import { DropdownModule } from 'angular-custom-dropdown';

import { FullCalendarModule } from 'ng-fullcalendar';
import { SlotsCalendarComponent } from './secure/slots/slots-calendar/slots-calendar.component';
import { SlotsService } from './service/slots.service';
import { TopNavComponent } from './secure/top-nav/top-nav.component';
import { SideBarComponent } from './secure/side-bar/side-bar.component';
import { ParksService } from './service/parks.service';
import { UserIdleModule } from 'angular-user-idle';
import { ConfirmationService, MenuItem } from 'primeng/api';
import {CalendarModule, ConfirmDialogModule, DropdownModule, MessagesModule, ProgressSpinnerModule, MenuModule, InputSwitchModule, SidebarModule} from 'primeng/primeng';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DialogModule} from 'primeng/dialog';
import {AdminsService} from './service/admins.service';
import {HttpClientModule} from '@angular/common/http';
import {UserFormService} from './service/user-form.service';
import { GroundsService } from "./service/grounds.service";
// import {ConfirmationService} from 'primeng/api';
import { AutoCompleteModule, CheckboxModule } from 'primeng/primeng';
import { CityRequestsComponent } from './secure/city-requests/city-requests.component';
import { CityRequestsService } from "./service/city-requests.service";
// import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AddCityComponent } from './public/add-city/add-city.component';
import { CitiesService } from "./service/cities.service";
// import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { FormsModule }   from '@angular/forms';
// import { CalendarComponent } from './calendar/calendar.component';
import { PopoverModule } from "ng2-popover";
import { GrowlModule } from 'primeng/growl';
import { MessageService } from 'primeng/components/common/messageservice';
import { UnauthorizedComponent } from './secure/unauthorized/unauthorized.component';
import { ErrorComponent } from './secure/error/error.component';
import { LandingComponent } from './public/landing/landing.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { PricingComponent } from './public/pricing/pricing.component';
import {StepsModule} from 'primeng/steps'
import { RecaptchaModule } from 'ng-recaptcha';
import {TabViewModule} from 'primeng/tabview';
import { CustomSlotComponent } from './public/custom-slot/custom-slot.component';
import {GMapModule} from 'primeng/gmap';
import { ReservationHistoryComponent } from './secure/reservation-history/reservation-history.component';
import { DashboardComponent } from './public/dashboard/dashboard.component';
import { UsersService } from './service/users.service';
import {NgxPaginationModule} from 'ngx-pagination';
import { AccountPreferencesComponent } from './secure/account-preferences/account-preferences.component';
import {HomeService} from './service/home.service';
import { SportsComponent } from './public/sports/sports.component';
import { SportsDetailComponent } from './public/sports-detail/sports-detail.component';
import { SigninComponent } from './public/main-auth/signin/signin.component';
import { ForgotPasswordComponent, ForgotPasswordStep2Component } from './public/main-auth/forgot-password/forgot-password.component';
import { ReSendComponent } from './public/main-auth/re-send/re-send.component';
import { SignupComponent } from './public/main-auth/signup/signup.component';
import { HomeLogoutComponent,ConfirmSignupComponent } from './public/main-auth/confirm-signup/confirm-signup.component';
import { BookingComponent } from './public/booking/booking.component';
import { SlotsParksComponent } from './public/slots-parks/slots-parks.component';
import { AdsenseModule } from 'ng2-adsense';
import { SearchPipe } from './filters/search.pipe';
// import { ToastrModule } from 'ngx-toastr';

// import {MenuItem} from 'primeng/api';

// import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';

import { FacebookModule } from 'ngx-facebook';
import {InlineEditorModule} from '@qontu/ngx-inline-editor';
import { FeedbackComponent } from './public/feedback/feedback.component';
import { HomePipe } from './filters/home.pipe';
import { DragulaModule } from 'ng2-dragula';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { GroupbyPipe } from './filters/groupby.pipe';
import { RefereeBookingComponent } from './public/referee-booking/referee-booking.component';
import { ReportIssueComponent } from './public/report-issue/report-issue.component';
import { SupportRequestsComponent } from './public/support-requests/support-requests.component';
import { SupportPipe } from './filters/support.pipe';
import { UniquePipe } from './filters/unique.pipe';
import { ServicesComponent } from './secure/services/services.component';
import { SportRequestsComponent } from './secure/sport-requests/sport-requests.component';
import { RefereeRequestsComponent } from './secure/referee-requests/referee-requests.component';
import { ExportExcelService } from './service/export-excel.service';
import { PartnersComponent } from './secure/partners/partners.component';
import { SlotDetailsComponent } from './public/slot-details/slot-details.component';
import { SlotPasswordRequestsComponent } from './secure/slot-password-requests/slot-password-requests.component';
import { EditSlotRequestsComponent } from './secure/edit-slot-requests/edit-slot-requests.component';
import { BillingComponent } from './secure/billing/billing.component';
import { SubscriptionsComponent } from './secure/subscriptions/subscriptions.component';
import { CustomSlotRequestsComponent } from './secure/custom-slot-requests/custom-slot-requests.component';
import { CustomSlotsComponent } from './public/custom-slots/custom-slots.component';
import { CityAdminsComponent } from './secure/city-admins/city-admins.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { SummaryComponent } from './secure/summary/summary.component';

import { SocialLoginModule, AuthServiceConfig } from 'angular5-social-login';
import { FacebookLoginProvider } from 'angular5-social-login';
import { AboutComponent } from './public/about/about.component';
import { WhyComponent } from './public/why/why.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InfoslotClaimReqsComponent } from './secure/infoslot-claim-reqs/infoslot-claim-reqs.component';
import { ReusableAlertComponent } from './secure/reusable-alert/reusable-alert.component';
import { NotificationsComponent } from './secure/notifications/notifications.component';
import { OnboardSlotsComponent } from './secure/onboard-slots/onboard-slots.component';
import { AdminProfileComponent } from './secure/admin-profile/admin-profile.component';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { SlotReservationSuccessComponent } from './public/slot-reservation-success/slot-reservation-success.component';
import { ServiceRequestComponent } from './public/service-request/service-request.component';
import { ServiceRequestSuccessComponent } from './public/service-request-success/service-request-success.component';
import { ServiceSignupsComponent } from './secure/service-signups/service-signups.component';
import { ServiceSubscriptionsComponent } from './secure/service-subscriptions/service-subscriptions.component';
import { SurveyComponent } from './public/survey/survey.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ServiceDetailsComponent } from './public/service-details/service-details.component';
import { LocationPreferenceComponent } from './secure/location-preference/location-preference.component';
import { LocationRequestComponent } from './secure/location-request/location-request.component';
import { AddNewMembershipDialogComponent } from './public/custom-slots/add-new-membership-dialog/add-new-membership-dialog.component';
import { UpgrageToPremiumDialogComponent } from './public/premium-dialog/premium-dialog.component'
import { MembershipService } from './service/membership.service';
import { MembershipRequestsComponent } from './secure/membership-requests/membership-requests.component';
import { MatButtonModule, MatCardModule, MatCheckboxModule, MatChipsModule, MatDialogModule, MatSelectModule, MatSlideToggleModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import { MatPaginatorModule, MatSortModule } from '@angular/material';
import { ViewMembershipDetailsComponent } from './secure/membership-requests/view-membership-details/view-membership-details.component';
import { MembershipPreferencesComponent } from './secure/account-preferences/membership-preferences/membership-preferences.component';
import { ConfirmationDialogComponent } from './secure/confirmation-dialog/confirmation-dialog.component';
import { BidForServiceDialogComponent } from './public/slot-details/bid-for-service-dialog/bid-for-service-dialog';
  
export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig([
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('2511395452227511')
        }
    ]);
    return config;
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 'auto'
  };

@NgModule({
    declarations: [
        NewPasswordComponent,
        LoginComponent,
        LogoutComponent,
        ConfirmRegistrationComponent,
        ResendCodeComponent,
        ForgotPasswordStep1Component,
        ForgotPassword2Component,
        RegisterComponent,
        MFAComponent,
        ParkDefaultsComponent,
        HomeLandingComponent,
        HomeComponent,
        UnauthorizedComponent,
        ErrorComponent,
        ParksComponent,
        AdminsComponent,
        GroundsComponent,
        AdminComponent,
        SlotsComponent,
        AppComponent,
        // CalendarComponent,
        SlotsCalendarComponent,
        TopNavComponent,
        SideBarComponent,
        AddCityComponent,
        CityRequestsComponent,
        LandingComponent,
        PricingComponent,
        CheckStatusComponent,
        CustomSlotComponent,
        ReservationHistoryComponent,
        DashboardComponent,
        AccountPreferencesComponent,
        SportsComponent,
        SportsDetailComponent,
        SigninComponent,
        ForgotPasswordComponent,
        ForgotPasswordStep2Component,
        ReSendComponent,
        SignupComponent,
        ConfirmSignupComponent,
        HomeLogoutComponent,
        BookingComponent,
        SlotsParksComponent,
        SearchPipe,
        FeedbackComponent,
        HomePipe,
        GroupbyPipe,
        RefereeBookingComponent,
        ReportIssueComponent,
        SupportRequestsComponent,
        SupportPipe,
        UniquePipe,
        ServicesComponent,
        SportRequestsComponent,
        RefereeRequestsComponent,
        PartnersComponent,
        SlotDetailsComponent,
        SlotPasswordRequestsComponent,
        EditSlotRequestsComponent,
        BillingComponent,
        SubscriptionsComponent,
        CustomSlotRequestsComponent,
        CustomSlotsComponent,
        CityAdminsComponent,
        SummaryComponent,
        AboutComponent,
        WhyComponent,
        InfoslotClaimReqsComponent,
        ReusableAlertComponent,
        NotificationsComponent,
        OnboardSlotsComponent,
        AdminProfileComponent,
        WelcomeComponent,
        SlotReservationSuccessComponent,
        ServiceRequestComponent,
        ServiceRequestSuccessComponent,
        ServiceSignupsComponent,
        ServiceSubscriptionsComponent,
        SurveyComponent,
        ServiceDetailsComponent,
        LocationPreferenceComponent,
        LocationRequestComponent,
        AddNewMembershipDialogComponent,
        UpgrageToPremiumDialogComponent,
        MembershipRequestsComponent,
        ViewMembershipDetailsComponent,
        MembershipPreferencesComponent,
        ConfirmationDialogComponent,
        BidForServiceDialogComponent
        ],
    imports: [
        MatSelectModule,
        MatChipsModule,
        MatCardModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatIconModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSortModule,
        BrowserModule,
        MatPaginatorModule,
        InputSwitchModule,
        HttpClientModule,
        MatTableModule,
        MatDialogModule,
        // HttpModule,
        FormsModule,
        ReactiveFormsModule,
        // NgbModule.forRoot(),
        FormsModule,
        DragulaModule,
        routing,
        FullCalendarModule,
        BrowserAnimationsModule,
        CalendarModule,
        DropdownModule,
        DialogModule,
        UserIdleModule.forRoot({ idle: 600, timeout: 3, ping: 1 }),
        ChartModule,
        RadioButtonModule,
        ReactiveFormsModule,
        CheckboxModule,
        // HttpModule,
        routing,
        FullCalendarModule,
        MessagesModule,
        GrowlModule,
        AutoCompleteModule,
        ConfirmDialogModule,
        BrowserAnimationsModule,
        PopoverModule,
        StepsModule,
        MenuModule,
        NgbModule.forRoot(),
        RecaptchaModule.forRoot(),
        // ToastrModule.forRoot(),
        TabViewModule,
        GMapModule,
        NgxPaginationModule,
        InlineEditorModule,
        SwiperModule,
        MatTabsModule,
        BrowserAnimationsModule,
        AdsenseModule.forRoot({
        adClient: 'ca-pub-7640562161899788',
        adSlot: 7259870550,
        adtest:"on"
        }),
        FacebookModule.forRoot(),
        AngularMultiSelectModule,
        SocialLoginModule,
        PdfViewerModule,
        SidebarModule
    ],
    providers: [
        CognitoUtil,
        AwsUtil,
        SlotsService,
        UserRegistrationService,
        MembershipService,
        UserLoginService,
        ConfirmationService,
        UserParametersService,
        AdminsService,
        ParksService,
        UserFormService,
        MessageService,
        GroundsService,
        UserParametersService,
        CitiesService,
        CityRequestsService,
        UsersService,
        HomeService,
        NotificationsService,
        ExportExcelService,
        MatPaginatorModule,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        }
    ],
    entryComponents: [
        AddNewMembershipDialogComponent,
        UpgrageToPremiumDialogComponent,
        BidForServiceDialogComponent,
        ViewMembershipDetailsComponent,
        ConfirmationDialogComponent
    ],
    exports: [
        AddNewMembershipDialogComponent,
        UpgrageToPremiumDialogComponent,
        BidForServiceDialogComponent,
        ViewMembershipDetailsComponent,
        ConfirmationDialogComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

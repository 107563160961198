import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { MembershipService } from '../../service/membership.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/components/common/messageservice';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../../environments/environment';
import {HomeService} from '../../service/home.service';
declare let paypal: any;

@Component({
  selector: 'premium-membership-dialog',
  templateUrl: './premium-dialog.component.html',
  styleUrls: ['./premium-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UpgrageToPremiumDialogComponent implements OnInit {

  loadingBar: boolean = false;
  selectedProfilePicture: any = '';
  selectedReceipt: any = '';
  selectedReceiptName: any = '';
  cricclub_id: any = '';
  team_name: any = '';
  email: any = '';
  mobile_number: any = '';
  msgs = [];
  first_name: any = '';
  last_name: any = '';
  street: any = '';
  city: any = '';
  state: any = '';
  zip_code: any = '';
  price: number = 50;
  paypal_client_id: string;
  paypal_secret_key: string;
  paypalToken: any;
  addScript: boolean = false;
  serviceFeePercent: number = 5;
  paypalFeePercent: number = 5;
  paypalFee: number;
  isPayed: boolean = false;
  isUploadedReceipt: boolean = false;
  isPayOrUploaded: boolean = false;
  paypal_payment_amount: any = null;
  serviceFee: number;
  total_amount: any;
  payment_info: any;

  hasStreet = true;
  hasCity = true;
  hasState = true;
  hasZip_code = true;
  hasCricclub_id = true;
  hasMobile_number = true;
  hasEmail = true;
  teamList:any = [];
  teamName= "";
  expense_amount: number;
  expense_comment: string;
  isExpenseOpen: boolean= false;
  hasExpense: any = true;
  termsAccepted: boolean = false;

  selectedLeague = null;
  leagues = [
    {
      label: 'Little Elm League',
      value: 'LITTLE_ELM_LEAGUE'
    }
  ]

  membershipSetup: any = {}

  constructor(
    public membershipService: MembershipService,
    private dialogRef: MatDialogRef<UpgrageToPremiumDialogComponent>,
    public homeService: HomeService,
    private messageService: MessageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.resetValues();
    this.first_name = localStorage.getItem('firstName');
    this.last_name = localStorage.getItem('lastName');
    this.mobile_number = localStorage.getItem('phoneNumber');
    console.log(localStorage.getItem('email'));
    if (localStorage.getItem('email') !== null && localStorage.getItem('email') !== 'null') {
      this.email = localStorage.getItem('email');
      this.hasEmail = true;
    }
  }

  resetValues() {
    this.first_name = '';
    this.last_name = '';
    this.email = '';
    this.mobile_number = '';
  }


  checkMandatoryFields() {
    if(this.first_name && this.last_name && this.email && this.mobile_number && this.selectedReceipt && this.termsAccepted){
      this.createMembership();
    } else {
      this.snackBar.open('Please fill all the required fields!', 'Okay', {
        duration: 4000,
        panelClass: ['EROR']
      })
    }
  }

  onUploadReceipt(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedReceipt = event.target.result;
      };
    }
    this.selectedReceiptName = event.target.files[0].name;
    this.isUploadedReceipt = this.selectedReceiptName ? true : false;
  }


  createMembership() {
    const premiumMembershipPayload = {
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      phone_number: this.mobile_number,
      receipt: this.selectedReceipt ? this.selectedReceipt : '',
      user_id: localStorage.getItem("id"),
      cricclub_id: this.cricclub_id
    }
    this.loadingBar = true;
    this.membershipService.upgradeToPremium(premiumMembershipPayload).subscribe((resp: any) => {
      console.log(resp);
      if (resp.status == 200) {
        this.messageService.add({
          severity: 'success',
          summary: 'Premium Membership Request has been submitted!',
          detail: 'We will notify you via email once it is processed'
        });
        this.dialogRef.close(true);
        this.loadingBar = false;
      }
    }, error => {
      this.loadingBar = false;
      this.snackBar.open("Error while submitting the form. Please make sure the details are filled in the proper format.", 'Okay', {
        duration: 4000,
        panelClass: ['EROR']
      })
    })
  }
 
}
import { Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UsersService } from '../../service/users.service';
import { UserLoginService } from './../../service/user-login.service';
import {HomeService} from '../../service/home.service';
import { environment } from './../../../environments/environment';
import { ParksService } from '../../service/parks.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
declare let paypal: any;
declare let paypal_auth:any;
declare let $;
import {MessageService} from 'primeng/components/common/messageservice';
@Component({
  selector: 'app-service-request',
  templateUrl: './service-request.component.html',
  styleUrls: ['./service-request.component.css'],
  styles: [`
    .star {
      position: relative;
      display: inline-block;
      font-size: 5rem;
      color: #d3d3d3;
    }
    .full {
      color: #FDCC0D;
    }
    .half {
      position: absolute;
      display: inline-block;
      overflow: hidden;
      color: #FDCC0D;
    }
  `]
  
})
export class ServiceRequestComponent implements OnInit {
  somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
  user = 'false';
  serviceRequestId: string = null;
  loadingBar: boolean = false;
  serviceReqsBids: any[] = [];
  previewVideos: any[] = [];
  previewImages: any[] = [];
  attachedVideos: any[] = [];
  attachedImages: any[] = [];
  selectedImageIndex: number = 0;
  moment: any = moment;
  paypal_client_id: string;
  paypal_secret_key: string;
  addScript: boolean = false;
  logoUploaded: boolean = false;
  addUploaded: boolean = false;
  mediaUploaded: boolean = false;

  
  paypalToken: any;
  total_amount: any;
  paypal_price: any;
  accepted_bid: any;
  serviceFeePercent: number = 5;
  serviceFee: number;
  bidAmount: number = 5;
  userId: any;
  selectedBidDetails:any;
  previewImage = {
    src: null,
    width: 0,
    height: 0,
    x:40,
    y:60,
    opacity: 1,
    contrast: 100,
    file: null as File | null,
  };
  attachedImage = {
    src: null,
    fileSize:"",
    file: null as File | null,
    width: 0,
    height: 0,
    x:40,
    y:60,
  };
  previewVideo :any
  videoUrl: any;
  attachedVideo={
    src: null,
    fileSize:"",
    file: null as File | null,
  }
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public usersService: UsersService,
    public homeService: HomeService,
    private userService: UserLoginService,
    private parksservice: ParksService,
    private messageService: MessageService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {
      this.route.params.subscribe(params => {
      this.serviceRequestId = params.serviceRequestId
    });

    this.user = localStorage.getItem('user');
    if (this.user !== 'true') {
      this.router.navigate(['/home/signin']);
    }
  }

  ngOnInit() {
    this.userId = localStorage.getItem("id");
    this.getServiceRequestBidDetails();
  }

   getPaypalDetails() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      console.log('get paypal res', res);
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
        this.setPayPal(this.paypal_client_id, this.total_amount);
      }
    })
  }

  setPayPal(token, price) {
   this.paypalToken=token;
   console.log('setPayPal token', token)
   setTimeout(function(){
      this.paypal_price = price;
     console.log('paypal_price', price)
   }.bind(this), 0);

   if (!this.addScript) {
     console.log('before addscript')
     this.addPaypalScript().then(() => {
     console.log('after addscript')
       setTimeout(function(){
          paypal.Buttons({
    onInit: function(data, actions)  {
      console.log('paypal oninit');
      console.log(data);
      console.log(actions);
    },
       createOrder: (data, actions) => {
         console.log('paypal createOrder')
         return actions.order.create({
           purchase_units: [{
           amount: {
           value: this.paypal_price
           }
           }],
           application_context: {
           shipping_preference: "NO_SHIPPING"
           },
         });
       },
       onApprove: (data, actions) => {
         this.loadingBar = true;
         this.checkout = false;
         return actions.order.capture().then((payment) => {
          this.paymentDone = true;
           this.payPalReceipt = payment;
           if ( payment.status === 'COMPLETED') {
           console.log("payment success::", payment)
           //this.onFormSubmit();
           this.paymentSuccess = true;
           this.acceptBidRequest();
           } else {
            this.paymentSuccess = false;
               this.onPayment();
               alert('Some error occured:' + payment.state);
           }
         })
       }, onError: function (err) {
            console.log('paypal onError', err)
        }, onCancel: function (data) {
           console.log('paypal onCancel', data)
        }
          }).render('#paypal-checkout-btn');
       }.bind(this), 0);
     })
   }
  }

  addPaypalScript() {
    // this.addScript = true;
    if ($('[title="srPaypal"]').length == 0) {
      return new Promise((resolve, reject) => {
       let scripttagElement = document.createElement('script');
       scripttagElement.setAttribute('title', "srPaypal");
       scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'capture';
       scripttagElement.onload = resolve;
       document.body.appendChild(scripttagElement);
      })
    } else {
      return new Promise(function(resolve, reject) {
        resolve('Script loaded previously');
      });
    }
  }

  toggleMenu() {
    console.log('top menu clicked');
  }

  updateUrl(event) {
    console.log('failed url:', this.somevalue);
    this.somevalue = 'https://content.fieldsmanager.com/System.png';
    console.log('new url:', this.somevalue);
  }

  logout() {
    this.user = 'false';
    if(localStorage.upgradeView==='true'){
      this.router.navigate(['/home']);
      localStorage.clear();
    }
    else{
      this.router.navigate(['/home']);
      localStorage.clear();
    }
  }

  getServiceRequestBidDetails(){
    this.loadingBar=true;
    this.homeService.serviceRequestBidDetails(this.serviceRequestId, this.userId).subscribe((res: any) => {
      this.loadingBar=false;
      this.serviceReqsBids = res;
      console.log(this.serviceReqsBids)
    })
  }

  myDashboard(){
    this.router.navigate(['/home/dashboard/']);
  }

  goHome(){
    this.router.navigate(['/home']);
  }

  openPaymentModal(bid){
    this.accepted_bid = bid;
    
    this.bidAmount = parseFloat(bid.Bid_Amount)
    if(this.bidAmount==0){
      this.acceptBidRequest()

    }
    else{
    this.serviceFee = (parseFloat(bid.Bid_Amount) * (this.serviceFeePercent / 100));
    this.total_amount = parseFloat(bid.Bid_Amount) + this.serviceFee;
    $('#paypal-checkout-btn').html('');
    $("#paymentModal").modal("show");
    this.getPaypalDetails();
  }
}

  acceptBidRequest() {
    this.loadingBar=true;
    this.accepted_bid.user_email = localStorage.getItem("email");
    this.homeService.accepStreamtServiceRequestBid(this.accepted_bid).subscribe((res: any) => {
      this.loadingBar=false;
     console.log(res)
    })
    this.homeService.acceptServiceRequestBid(this.accepted_bid).subscribe((res: any) => {
    this.loadingBar=false;
      this.messageService.add({
        severity: 'success',
        summary: 'success',
        detail: 'Successfully assigned service provider'
      });

     
      setTimeout(() => {
        $("#imageUploadModal").modal('show');
      }, 1500);
    })
  }
  acceptRejectRequest(bid) {
    this.loadingBar=true;
   
    this.homeService.rejectServiceRequestBid(bid).subscribe((res: any) => {
    this.loadingBar=false;
      this.messageService.add({
        severity: 'success',
        summary: 'success',
        detail: 'Successfully Rejected Bid'
      });
      setTimeout(() => {
       this.router.navigate(['/home/dashboard']);
      }, 1500);
    })
  }

  providerDetails(bid) {
    this.selectedBidDetails=bid
    console.log(this.selectedBidDetails)
    $("#userdetailsModal").modal('show');
  }

  
  async onSubmitLogo() {
    $("#imageUploadModal").modal('hide');
    this.loadingBar = true;
    const uploadPromises = [];
  
    for (let i = 0; i < this.previewImages.length; i++) {
      const image = this.previewImages[i];
      const data = {
        slotId: this.serviceReqsBids[0].Slot_Id,
        userId: this.serviceReqsBids[0].ServiceProvider_Id,
        width: Math.round(image.width * 1920 / 550),
        height: Math.round(image.height * 1080 / 308.125),
        x: Math.round(image.x * 1920 / 550),
        y: Math.round(image.y * 1080 / 308.125),
      };
  
    

      try {
        await this.homeService.uploadLogo(data, image.file);
        console.log('Upload completed for logo:');
      } catch (error) {
        console.error('Upload failed for logo:');
        console.error(error);
      }
    }
  
    
      
      this.previewVideo = '';
      this.logoUploaded = true;
      $("#imageUploadModal").modal('show');
      this.loadingBar = false;
    
  }


onBrowseAdd(){
  
  const fileInput = document.createElement('input');
  fileInput.setAttribute('type', 'file');
  fileInput.setAttribute('accept', 'image/*,video/*');
  fileInput.addEventListener('change', this.handleFileUploadAdd.bind(this));
  fileInput.click();
}
handleFileUploadAdd(event: any): void {
  const file = event.target.files[0];
  const fileType = file.type;
  const fileSize =  file.size / (1024 * 1024)

  if (fileType.includes('image')) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const image = new Image();
      image.src = reader.result as string;
      image.onload = () => {
        this.previewImage = {
          src: reader.result,
          width: 100,
          height: 100,
          x: 0,
          y: 0,
          opacity: 1,
          contrast: 100,
          file: file
        };
        this.previewImages.push(this.previewImage);
        let index = this.previewImages.length-1
        this.selectedImageIndex=index;
      };
     
    };
     
  } else if (fileType.includes('video')) {
      this.previewVideo = {
          src: URL.createObjectURL(file),
          file: file,
          videoUrl:this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file))
      };
      this.previewVideos.push(this.previewVideo);
      this.videoUrl = this.sanitizer.bypassSecurityTrustUrl(this.previewVideo.src);
  }
  else {

    alert('File size must be less than 10 MB for images and less than 600 MB for videos.');
  }
}


async  onSubmitAdd() {
  $("#imageUploadModal").modal('hide');
  this.loadingBar = true;

  for (let i = 0; i < this.attachedVideos.length; i++) {
    const video = this.attachedVideos[i];
    const data = {
      slotId: this.serviceReqsBids[0].Slot_Id,
      userId: this.serviceReqsBids[0].ServiceProvider_Id,
    };

    try {
      await this.homeService.uploadAd(data, video.file);
      console.log('Upload completed for video:', video);
    } catch (error) {
      console.error('Upload failed for video:', video);
      console.error(error);
    }
  }

  this.previewImage = {
    src: null,
    width: 0,
    height: 0,
    x: 40,
    y: 60,
    opacity: 1,
    contrast: 100,
    file: null as File | null,
  };
  this.previewVideo = '';
  this.addUploaded = true;
  $("#imageUploadModal").modal('show');
  this.loadingBar = false;
}


onBack(){
 



  this.previewImage = {
    src: null,
    width: 0,
    height: 0,
    x:40,
    y:60,
    opacity: 1,
    contrast: 100,
    file: null as File | null,
  };
  this.previewVideo=''
}

onClose(){
  $("#imageUploadModal").modal('hide');
  this.router.navigate(['/home/serviceRequests/' + this.serviceRequestId + '/success']);
}

onScaledWidthChange(width){
this.previewImages[this.selectedImageIndex].width=width*550/1920
}
onScaledHeightChange(height){
  this.previewImages[this.selectedImageIndex].height=height*308.125/1080
}
onScaledXChange(x){
  this.previewImages[this.selectedImageIndex].x=x*550/1920
}
onScaledYChange(y){
  this.previewImages[this.selectedImageIndex].y=y*308.125/1080
}
onAttachImage(){
  this.attachedImages=this.previewImages
  this.attachedImage.src=this.previewImage.src
  this.attachedImage.file=this.previewImage.file
  this.attachedImage.width=this.previewImage.width
  this.attachedImage.height=this.previewImage.height
  this.attachedImage.x=this.previewImage.x
  this.attachedImage.y=this.previewImage.y

  
 
  
  if (this.previewImage && this.previewImage.file) {
  const fileSizeKB = this.previewImage.file.size / 1024;
  const fileSizeFormatted = fileSizeKB >= 1024 ? (fileSizeKB / 1024).toFixed(2) + ' MB' : fileSizeKB.toFixed(2) + ' KB';
  this.attachedImage.fileSize=fileSizeFormatted
  let index = this.attachedImages.length-1
  this.attachedImages[index].fileSizeFormatted=fileSizeFormatted
  }

  this.previewImage = {
    src: null,
    width: 0,
    height: 0,
    x:40,
    y:60,
    opacity: 1,
    contrast: 100,
    file: null as File | null,
  };
 
console.log(this.attachedImages)
}
onAttachVideo(){

 
  this.attachedVideos=this.previewVideos
  this.attachedVideo.src=this.previewVideo.src
  this.attachedVideo.file=this.previewVideo.file
  
  
  if (this.previewVideo && this.previewVideo.file) {
    const fileSizeKB = this.previewVideo.file.size / 1024;
    const fileSizeFormatted = fileSizeKB >= 1024 ? (fileSizeKB / 1024).toFixed(2) + ' MB' : fileSizeKB.toFixed(2) + ' KB';
    this.attachedVideo.fileSize=fileSizeFormatted
    let index = this.attachedVideos.length-1
    this.attachedVideos[index].fileSizeFormatted=fileSizeFormatted
    }
    this.previewVideo=''
}

onCloseAttachImage(i){
  this.previewImages.splice(i, 1);
}

onCloseAttachVideo(i){
  this.attachedVideos.splice(i, 1);
}
onUploadMedia(){
  console.log('vid ',this.attachedVideos)
  console.log('img ',this.attachedImages)

  this.mediaUploaded=true
  if(this.attachedVideos.length>0&&this.previewImages.length>0){
    this.onSubmitAddAndLogo()
    console.log('add and logo')
  }
  if(this.attachedVideos.length>0&&this.previewImages.length==0){

    this.onSubmitAdd()
    console.log('add only')
  }
  if(this.previewImages.length>0&&this.attachedVideos.length==0)
  {
    this.onSubmitLogo()
    console.log('logo only')
  }

}

async onSubmitAddAndLogo(){
$("#imageUploadModal").modal('hide');
this.loadingBar = true;

const uploadPromises = [];
for (let i = 0; i < this.attachedVideos.length; i++) {
  const video = this.attachedVideos[i];
  const data = {
    slotId: this.serviceReqsBids[0].Slot_Id,
    userId: this.serviceReqsBids[0].ServiceProvider_Id,
   
  };

 
  try {
    await this.homeService.uploadAd(data, video.file);
    console.log('Upload completed for video:', video);
  } catch (error) {
    console.error('Upload failed for video:', video);
    console.error(error);
  }
}
  
           this.previewImage = {
        src: null,
        width: 0,
        height: 0,
        x:40,
        y:60,
        opacity: 1,
        contrast: 100,
        file: null as File | null,
      };
      this.previewVideo=''
      this.addUploaded=true
      $("#imageUploadModal").modal('show');
      this.loadingBar = false;
      if(this.previewImages.length>0)
            {
              this.onSubmitLogo()
            }
 


}


onSelectImage(i){
 this.selectedImageIndex=i
}


}

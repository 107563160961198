import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment';
import { MembershipService } from '../../../service/membership.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/components/common/messageservice';
import { MatSnackBar } from '@angular/material';
import { environment } from '../../../../environments/environment';
import {HomeService} from '../../../service/home.service';
declare let paypal: any;

@Component({
  selector: 'app-add-new-membership-dialog',
  templateUrl: './add-new-membership-dialog.component.html',
  styleUrls: ['./add-new-membership-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AddNewMembershipDialogComponent implements OnInit {

  loadingBar: boolean = false;
  selectedProfilePicture: any = '';
  selectedReceipt: any = '';
  selectedReceiptName: any = '';
  cricclub_id: any = '';
  team_name: any = '';
  email: any = '';
  mobile_number: any = '';
  msgs = [];
  first_name: any = '';
  last_name: any = '';
  street: any = '';
  city: any = '';
  state: any = '';
  zip_code: any = '';
  price: number = 50;
  paypal_client_id: string;
  paypal_secret_key: string;
  paypalToken: any;
  addScript: boolean = false;
  serviceFeePercent: number = 5;
  paypalFeePercent: number = 5;
  paypalFee: number;
  isPayed: boolean = false;
  isUploadedReceipt: boolean = false;
  isPayOrUploaded: boolean = false;
  paypal_payment_amount: any = null;
  serviceFee: number;
  total_amount: any;
  payment_info: any;

  hasStreet = true;
  hasCity = true;
  hasState = true;
  hasZip_code = true;
  hasCricclub_id = true;
  hasMobile_number = true;
  hasEmail = true;
  teamList:any = [];
  teamName= "";
  expense_amount: number;
  expense_comment: string;
  isExpenseOpen: boolean= false;
  hasExpense: any = true;

  selectedLeague = null;
  leagues = [
    {
      label: 'Little Elm League',
      value: 'LITTLE_ELM_LEAGUE'
    }
  ]

  membershipSetup: any = {}

  constructor(
    public membershipService: MembershipService,
    private dialogRef: MatDialogRef<AddNewMembershipDialogComponent>,
    public homeService: HomeService,
    private messageService: MessageService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getMembershipPreferences();
    this.resetValues();
    this.getTeamList();
    this.first_name = localStorage.getItem('firstName');
    this.last_name = localStorage.getItem('lastName');
    this.mobile_number = localStorage.getItem('phoneNumber');
    this.street = localStorage.getItem('street');
    this.city = localStorage.getItem('city');
    this.state = localStorage.getItem('state');
    this.zip_code = localStorage.getItem('zipcode');
    console.log(localStorage.getItem('email'));
    if (localStorage.getItem('email') !== null && localStorage.getItem('email') !== 'null') {
      this.email = localStorage.getItem('email');
      this.hasEmail = true;
    }
    this.cityChange();
  }

  getMembershipPreferences() {
    this.membershipService.getMembershipPreferences(environment.littleElmCityId).subscribe(resp => {
      this.membershipSetup = JSON.parse(JSON.stringify(resp));
      console.log(this.membershipSetup);
    })
  }
  getTeamList(){
    this.membershipService.getTeamList().subscribe((resp) => {
      this.teamList = resp;
    });
  }

  onTeamChange(event: any) {
    this.team_name = event.value ? event.value.team_name : null; 
  }

  resetValues() {
    this.first_name = '';
    this.last_name = '';
    this.street = '';
    this.city = '';
    this.state = '';
    this.zip_code = '';
    this.cricclub_id = '';
    this.email = '';
    this.mobile_number = '';
    this.team_name = '';
    this.teamName = '';
  }

  onSelectFile(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedProfilePicture = event.target.result;
      };
    }
  }

  checkMandatoryFields() {
    if(this.expense_amount && !this.expense_comment){
      this.messageService.add({
        severity: 'warn',
        summary: 'Please include a comment regarding the expense amount.',
      })
      this.hasExpense = false;
    }else if(!this.expense_amount && !this.expense_comment){
      this.hasExpense = true;
    }

    if (this.membershipSetup.street) {
      this.hasStreet = this.street != '';
    }
    if (this.membershipSetup.city) {
      this.hasCity = this.city != '';
    }
    if (this.membershipSetup.state) {
      this.hasState = this.state != '';
    }
    if (this.membershipSetup.cricclub_id) {
      this.hasCricclub_id = this.cricclub_id != '';
    }
    if (this.membershipSetup.phone_number) {
      this.hasMobile_number = this.mobile_number != '';
    }
    if (this.membershipSetup.zip_code) {
      this.hasZip_code = this.zip_code != '';
    }
    if (this.membershipSetup.email) {
      this.hasEmail = this.email != '';
    }
    if (this.isUploadedReceipt){
      this.isPayOrUploaded = true;
    }

    if (this.isPayOrUploaded == false){
      this.messageService.add({
        severity: 'warn',
        summary: 'To proceed, kindly submit the receipt.',
      });
    }else if (this.first_name != '' &&
      this.last_name != '' &&
      this.hasStreet &&
      this.hasCity &&
      this.hasState &&
      this.hasZip_code &&
      this.hasCricclub_id &&
      this.hasEmail &&
      this.hasMobile_number &&
      this.teamName &&
      this.hasExpense) {
      this.createMembership();
    }  else {
      this.snackBar.open('Please fill all the required fields!', 'Okay', {
        duration: 4000,
        panelClass: ['EROR']
      })
    }
  }

  onUploadReceipt(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.selectedReceipt = event.target.result;
      };
    }
    this.selectedReceiptName = event.target.files[0].name;
    this.isUploadedReceipt = this.selectedReceiptName ? true : false;
  }

  createMembership() {
    const newMembershipPayload = {
      first_name: this.first_name,
      last_name: this.last_name,
      street: this.street,
      city: this.city,
      state: this.state,
      zip_code: this.zip_code,
      email: this.email,
      phone_number: this.mobile_number,
      profile_pic: this.selectedProfilePicture ? this.selectedProfilePicture : '',
      receipt: this.selectedReceipt ? this.selectedReceipt : '',
      user_id: localStorage.getItem("id"),
      cricclub_id: this.cricclub_id,
      team_name: this.team_name,
      additional_expense_amount: this.expense_amount,
      additional_expense_comment: this.expense_comment
    }
    this.loadingBar = true;
    this.membershipService.createNewMembership(newMembershipPayload).subscribe((resp: any) => {
      console.log(resp);
      if (resp.body.message === 'Success') {
        this.messageService.add({
          severity: 'success',
          summary: 'New Membership Request has been submitted!',
          detail: 'We will notify you via email once it is processed'
        });
        this.dialogRef.close(true);
        this.loadingBar = false;
      }
    }, error => {
      this.loadingBar = false;
      this.snackBar.open("Error while submitting the form. Please make sure the details are filled in the proper format.", 'Okay', {
        duration: 4000,
        panelClass: ['EROR']
      })
    })
  }
  updatePaymentInfo(){
    const paymentInfoPayload = {
      paypal_amount: this.paypal_payment_amount,
      payment_info : this.payment_info,
      user_id: localStorage.getItem("id")
    }
    this.membershipService.createNewMembership(paymentInfoPayload).subscribe((resp: any) => {
      console.info("Response",resp)
    })
  }
  openPaymentModal(price){
    
    this.price = parseFloat(price)
    this.serviceFee = parseFloat((parseFloat(price) * (this.serviceFeePercent / 100)).toFixed(2));
    this.paypalFee = parseFloat((parseFloat(price) * (this.paypalFeePercent / 100)).toFixed(2));
    if(this.expense_amount){
      this.total_amount = parseFloat(price) + this.serviceFee + this.paypalFee + this.expense_amount;
    }else{
      this.total_amount = parseFloat(price) + this.serviceFee + this.paypalFee;
    }
    $('#paypal-checkout-btn').html('');
    (<any>$("#membershipPaymentModal")).modal('show');
    this.paypalNavigate();

}
  paypalNavigate() {
    this.homeService.getPaypalKey().subscribe((res: any)=> {
      if (res.msg === "success") {
        this.paypal_client_id = res.client_id;
        this.paypal_secret_key = res.secret_key;
        this.setPayPal(this.paypal_client_id, this.total_amount);
      }
    })
  }

  setPayPal(token, price) {
    this.paypalToken=token;
    setTimeout(function(){
       this.paypal_price = price;
    }.bind(this), 0);
 
    if (!this.addScript) {
      this.addPaypalScript().then(() => {
        setTimeout(function(){
           paypal.Buttons({
     onInit: function(data, actions)  {
 
     },
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
            amount: {
            value: this.paypal_price
            }
            }],
            application_context: {
            shipping_preference: "NO_SHIPPING"
            },
          });
        },
        onApprove: (data, actions) => {
          this.loadingBar = true;
          this.checkout = false;
          return actions.order.capture().then((payment) => {
           this.paymentDone = true;
            this.payPalReceipt = payment;
            if ( payment.status === 'COMPLETED') {
            this.paymentSuccess = true;
            this.isPayed = true;
            this.paypal_payment_amount = price;
            this.payment_info = payment;
            this.updatePaymentInfo();
            this.messageService.add({
              severity: 'success',
              summary: 'The payment has been successfully processed; kindly proceed to submit the form to complete the membership process.',
            });
            (<any>$("#membershipPaymentModal")).modal('hide');
            } else {
             this.paymentSuccess = false;
             this.isPayed = false;
                alert('Some error occured:' + payment.state);
            }
            this.loadingBar = false;
          })
        }, onError: (err) => {
            this.isPayed = false;
         }, onCancel: (data) => {
            this.isPayed = false;
         }
           }).render('#paypal-checkout-btn');
        }.bind(this), 0);
      })
    }
   }
  
   addPaypalScript() {
    // this.addScript = true;
    if ($('[title="srPaypal"]').length == 0) {
      return new Promise((resolve, reject) => {
       let scripttagElement = document.createElement('script');
       scripttagElement.setAttribute('title', "srPaypal");
       
       scripttagElement.src = 'https://www.paypal.com/sdk/js?client-id='+this.paypal_client_id+'&currency=USD&commit=true&intent='+'capture';
       scripttagElement.onload = resolve;
       document.body.appendChild(scripttagElement);
      })
    } else {
      return new Promise(function(resolve, reject) {
        resolve('Script loaded previously');
      });
    }
  }

  cityChange() {
    this.price = (this.city.toLowerCase() === 'little elm') ? 30 : 50;
  }

  // paypalNavigate() {
  //   window.open(`https://paypal.me/LECricket/${this.price}?country.x=US&locale.x=en_US`);
  // }

}
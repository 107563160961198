import { Component, OnInit } from '@angular/core';
import { CognitoUtil } from '../../service/cognito.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  // isActive = true;
  id = 0;
  constructor(public cognitoUtil: CognitoUtil,
    private router: Router) {
    // this.cognitoUtil.refresh();
  }

  isActive: string= '/admin';
  
  super_admin:boolean;
  partner:boolean;
  // constructor() { }

  ngOnInit() {
    console.log(this.isActive )
    console.log('side bar component..');
    this.isActive = this.router.url;
    console.log(this.isActive )
   
    if(localStorage.superAdmin==='true' && localStorage.partner!=='true'){
      this.super_admin=true;
      this.partner=false;
      this.isActive = this.router.url;
      this.isActive = '/admin/cityRequests';
    }
    else if(localStorage.partner==='true' && localStorage.superAdmin!=='true'){
      this.partner=true;
      this.super_admin=false;
      this.isActive = this.router.url;
      this.isActive = '/admin/partners';
    }
    else{
      console.log("admintest")
      this.super_admin=false;
      this.partner=false;
      this.isActive = this.router.url;
      // this.isActive = '/admin';
    }
  }

 
  addActive() {
    this.isActive = this.router.url;
    
    var menuBox = document.getElementById('menu-box');
     var pagew=document.getElementById('page-wrapper');
     if(pagew.offsetWidth<420){
      if (menuBox.style.display === "block" || menuBox.style.display === "") {
        menuBox.style.display = "none"; 
        pagew.style.paddingLeft="0px"
        } else {
        menuBox.style.display = "block";
        pagew.style.paddingLeft="11%";  
        }
     }
  }
}

import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';

import * as moment from 'moment';
import { jsonpCallbackContext } from '@angular/common/http/src/module';

@Component({
  selector: 'app-onboard-slots',
  templateUrl: './onboard-slots.component.html',
  styleUrls: ['./onboard-slots.component.css']
})
export class OnboardSlotsComponent implements OnInit {
  showDtls: boolean = false;
  onboardSlotRequests = [];
  loadingBar: boolean = false;
  moment: any = moment;
  id: any;
  constructor(private homeService : HomeService,
    private messageService: MessageService) {
    console.log("In Onboard Slot Requests Component")
   }

  ngOnInit() {
    this.getOnboardSlotRequests();
  }
  getOnboardSlotRequests() {
    this.loadingBar = true;
    let cityid = localStorage.getItem("cityId");
    this.homeService.getOnboardSlotsForAdmin(cityid).subscribe((response) => {
      this.loadingBar = false
      console.log(response);
      this.onboardSlotRequests = response.body;
    })
  }

  more_dtls(id){
   
    if(this.id==id){
        this.id=null;
    }
    else{
       this.id=id;
    }
  }

  onAcceptOrDeclineRequest(slotData,status){
    console.log(slotData);
    let data = {
      "SlotRequest_Id" : slotData.SlotRequest_Id,
      "Ground_Id": slotData.Ground_Id,
      "Park_Id": slotData.Park_Id,
      "Reservation_Id": slotData.Reservation_Id,
      "Slot_Id": slotData.Slot_Id,
      "Status": status
    }
    this.loadingBar = true;
    this.homeService.acceptOrDeclineOnboardSlotRequest(data).subscribe((response) => {
      this.loadingBar = false;
      console.log(response);
      if(response.status == 200){
        // let messagebody = JSON.stringify(response.body)
        // console.log(messagebody)
        this.messageService.add({ severity: 'success', summary: 'User Report Slot Requests', detail: response.body.message });
        this.getOnboardSlotRequests();
      }
    })
  }

  onAcceptOrDeclineStreamingRequest(slotData, streamingStatus){
    if(streamingStatus == 'Rejected') {
      slotData.Streaming_Url = null;
    }
    let data = {
      "SlotRequest_Id" : slotData.SlotRequest_Id,
      "Slot_Id": slotData.Slot_Id,
      "Streaming_Url": slotData.Streaming_Url,
      "Streaming_Status": streamingStatus
    }
    this.loadingBar = true;
    this.homeService.acceptOrDeclineStreamingRequest(data).subscribe((response) => {
      this.loadingBar = false;
      console.log(response);
      if(response.status == 200){
        this.messageService.add({ severity: 'success', summary: 'User Report Slot Requests', detail: response.body.message });
        this.getOnboardSlotRequests();
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()

export class MembershipService {

  constructor(
    public http: HttpClient
  ) { }

  createNewMembership(newMembershipPayload: any) {
    const url = environment.newapiUrl + '/membership';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(url, newMembershipPayload, { observe: 'response', headers: headers });
  }
  upgradeToPremium(premiumMembershipPayload: any) {
    const url = environment.newapiUrl + '/premiumUser';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(url, premiumMembershipPayload, { observe: 'response', headers: headers });
  }

  getMemberships(getMembershipsPayload) {
    const url = environment.newapiUrl + '/membership';
    return this.http.get(url, { params: getMembershipsPayload });
  }

  verifyMembership(verifiedMembershipPayload) {
    const url = environment.newapiUrl + '/membership';
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(url, verifiedMembershipPayload, { observe: 'response', headers: headers });
  }

  getMembershipPreferences(city_id) {
    const url = environment.newapiUrl + `/membership/preference?city_id=${city_id}`;
    return this.http.get(url);
  }
  getTeamList() {
    const url = environment.newapiUrl + `/teams`;
    return this.http.get(url);
  }

  saveMembershipPreferences(city_id, membershipSetupConfig) {
    const url = environment.newapiUrl + `/membership/preference?city_id=${city_id}`;
    return this.http.post(url, membershipSetupConfig);
  }

  getMembershipsCSV(status) {
    const url = environment.newapiUrl + `/membership/csv?status=${status}`;
    return this.http.get<any>(url);
  }

  getEnableSignupMembershipStatus(user_id) {
    const url = environment.newapiUrl + `/membership/status?user_id=${user_id}`;
    return this.http.get<any>(url);
  }

}

import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from "@angular/forms";
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ActivatedRoute, Router } from '@angular/router';
declare let $;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {

  feedbackForm: FormGroup;
  fbckFormSubmitted: boolean = false;
  feedback: boolean = false;
  userId: any;
  resvId:any;
  id:any;
  name:any;
  currentRate=0

  refId: any;
  user = 'false';
  fb_service_types: any[] = [];
  loadingBar: boolean = false;
  success: boolean = false;

  serviceRequestId:any;
  reviewerName:any;
  serviceName:any;
  userName:any;
  reviewerId:any;
  slotDate:any;
  slotTime:any;
  sport:any;
  
  constructor(
    private formbuilder: FormBuilder,
    private homeService: HomeService,
    private messageService:MessageService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.route.params.subscribe((params)=> {
     
      this.serviceRequestId = params.serviceRequestId;
      this.reviewerName=params.reviewerName;
      this.serviceName=params.serviceName;
      this.userName=params.userName;
      this.reviewerId=params.reviewerId;
      this.slotDate=params.slotDate;
      this.slotTime=params.slotTime;
      this.sport=params.sport;

      
    })
  }
 // https://www.dev-fm.bizdevworks.com/home/survey?serviceRequestId=8092016288&userName=mano%20test&service=Live%20Streaming&serviceProvider=Vishnu%20Nair

  ngOnInit() {
  	this.feedbackForm = this.formbuilder.group({    
      'suggestion': new FormControl(''),     
    });
    this.userId = localStorage.getItem('id');
  }


   onFeedback() {
   
   
     this.fbckFormSubmitted = true;
     if (this.feedbackForm.valid) {
      this.loadingBar=true;
      this.fbckFormSubmitted = false;
     
      this.homeService.saveSurveyFeedback(this.feedbackForm.value, this.currentRate,this.serviceRequestId,this.reviewerId)
        .subscribe((res: any)=> {
          $('#succesModal').modal({backdrop: 'static', keyboard: false},'show')
          
          console.log('fdbc res', res);
          this.loadingBar=false;
          this.currentRate=0
          this.success=true
          this.feedbackForm.reset();
          this.messageService.add({ severity: 'success', summary: 'Feedback', detail: 'Feedback Submitted Successfully' });
         // this.router.navigate(['/home']);
        });
      
    }
  }

  onClose(){
    this.router.navigate(['/home']);
  }

}

import { Component, OnInit } from '@angular/core';
import { SlotsService } from '../../service/slots.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import {HomeService} from '../../service/home.service';
declare let $;
import {MessageService} from 'primeng/components/common/messageservice';







@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {
  loadingBar = true;
  currentService: any;
  service_id: any;
  moment: any = moment;
  userId:any;
  user = 'false';
  currentSRRequest:any;
  readOnlyRequest:boolean=false;
  Bid_Amount:any;
  Channel_Id:string;
  Channel_Url:string;
  Stream_Key:string;
  Comments:any;
  preferredPrice:any;
  serviceComments:any[] = [];
  serviceRequesterRating:any[] = [];
  serviceKey : string;
  support_requests: boolean = false;



  







    
  
  
  
    constructor (
      public homeService:HomeService, 
      private slotService: SlotsService, 
      public router:Router,
      private route: ActivatedRoute,
      private messageService:MessageService
     ) { 
    this.route.params.subscribe((params)=> {
      if (params.service_id !== undefined) {
        this.service_id = params.service_id;
      }
    })
  }

  ngOnInit() {
    this.user = localStorage.getItem('user');
    this.userId = localStorage.getItem('id');
    this.getServiceDetails(this.userId)
  }

  getServiceDetails(userId) {
    this.loadingBar=true;
    this.slotService.serviceDetails(this.service_id,userId)
      .subscribe((slot: any)=> {

        console.log('slot details res', slot[0]);
        this.currentService= slot[0];
        this.loadingBar=false;
        console.log(this.currentService)
      });
  }

  serviceBidModalOpen(serviceRequest){
    if (this.userId == "false" || this.userId == null) {
      console.log("user not logged in");
      this.serviceKey = this.service_id;
      $("#signupmodal").modal("show");
    } else {

              if(!serviceRequest.ServiceSignupFlag){
                $("#bookingAlertModal").modal("show");

              }else{

              

              if(serviceRequest.Slot_Assigned_Time != null && serviceRequest.Slot_Assigned_to_ProviderId == this.userId && serviceRequest.Cancel_Flag!=1){
                this.router.navigate(['/home/serviceRequests/' + serviceRequest.Service_Request_Id + '/success']);
              } else if(serviceRequest.Slot_Assigned_to_ProviderId != this.userId && serviceRequest.Slot_Assigned_to_ProviderId != null && serviceRequest.Cancel_Flag!=1) {
              }
              else if(serviceRequest.Cancel_Flag==1){

              }
              else {
                this.currentSRRequest = serviceRequest;
                console.log(this.currentSRRequest)
                this.readOnlyRequest = serviceRequest.ServiceProvider_Id;
                this.Comments = serviceRequest.Comments || '';
                let pp = parseFloat(serviceRequest.Preferred_Price || 0);
                this.preferredPrice = pp > 0 ? pp : null;
                if(this.readOnlyRequest) {
                  this.Bid_Amount = serviceRequest.Bid_Amount;
                  this.Channel_Id = serviceRequest.Channel_ID;
                  this.Channel_Url = serviceRequest.Channel_Stream_Url;
                  this.Stream_Key = serviceRequest.Stream_Key;
                } else {
                  this.Bid_Amount = this.preferredPrice;
                  this.Channel_Id = "";
                  this.Channel_Url = "";
                  this.Stream_Key = "";
                }
                this.addnewCommentfieldforSerivce(serviceRequest.Service_Id,serviceRequest.User_Id)
                $("#pickSRRequestmodal").modal("show");
                
              }
            }
   }
  }
  addnewCommentfieldforSerivce(serviceId,User_Id) {
    this.loadingBar = true;
    this.homeService.getServiceCommentsAndRanting(serviceId,User_Id).subscribe((res: any)=> {
      
      
        this.serviceComments=res.body.commentResults;
        this.serviceRequesterRating=res.body.ratingResults;
        this.loadingBar = false;
      
      
    });
  }

  bidServiceRequest(){
    console.log(this.Channel_Id)
    console.log(this.Channel_Url)

    let data = {
      ...this.currentSRRequest,
      Bid_Amount: this.Bid_Amount,
      Comments: this.Comments,
      Channel_Url:this.Channel_Url,
      Channel_Id:this.Channel_Id,
      Stream_Key:this.Stream_Key,
      Service_Provider_Id: this.userId
    }
    console.log(data);
    this.loadingBar = true;
    $("#pickSRRequestmodal").modal("hide");
    this.homeService.bidSrvcRequest(data)
    .subscribe((res: any)=> {
      let body = JSON.parse(res.body);
      this.loadingBar = false;
      this.Channel_Url=''
      this.Channel_Id=''
      this.Stream_Key=''
      if(body.httpStatus == 400) {
        this.messageService.add({
          severity: 'error',
          summary: 'Bid request',
          detail: body.message
        });
      } else {
        this.loadingBar = false;
        this.getServiceDetails(this.userId)
        this.messageService.add({
          severity: 'success',
          summary: 'Bid request',
          detail: body.message
        });
      }
    });
  }

  gotoSignUpUser(user) {
    $("#signupmodal").modal("hide");
    this.router.navigate(["/home/signup/user"]);
  }
  gotoLogin() {
    $("#signupmodal").modal("hide");
   
    
      this.router.navigate(["/home/signin"], {
        queryParams: {
          serviceDetail: "true",
          serviceKey: this.serviceKey
        },
      });

    
   
  }
  gotoserviceSignup(){
    localStorage.setItem("upgradeView", "true");
    this.router.navigate(["/home/dashboard"], {
      queryParams: { services: "myServices" },
    });
  }


}

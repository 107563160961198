export const environment = {
    production: false,
    stage: 'dev',
    region: 'us-east-1',
    identityPoolId: 'us-east-1:d8b1eaab-81c6-4357-9a36-5f5328b051fe',
    userPoolId: 'us-east-1_o7H60bzFd',
    clientId: '3run3nquj30pkpaf1djg6acu8n',
    apiUrl: 'https://q3qapxnbvf.execute-api.us-east-1.amazonaws.com/dev',
    newapiUrl: 'https://hrav6vv6q0.execute-api.us-east-1.amazonaws.com/dev',
    slotUrl: 'https://36i25t2jw3.execute-api.us-east-1.amazonaws.com/dev',
    facilityUrl: "https://2xzxn3vvd5.execute-api.us-east-1.amazonaws.com/dev",
    streamUrl:"https://api.dev.scp.fieldsmanager.io",
    subscriptionsUrl: "https://zqyoa8ovo2.execute-api.us-east-1.amazonaws.com/dev",
    usersessionUrl:"https://api.dev.fieldsmanager.io",
    logoStage: 'dev',
    URL: '.dev-fm.bizdevworks.com',
    webSocketUrl : "wss://iw1fu04kkl.execute-api.us-east-1.amazonaws.com/dev",
    paypalURL:'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    paypaldirectURL:'https://www.paypal.com/sdk/js?client-id=AULoidK10R6oUXYfcL6JVF6Oy18Iv9bF_P0zCATeRLP87p6r2vVcO51HaTLbjnsChD3xXy8zrpV9tySa&currency=USD&commit=true&intent=',
    facebookAuthURL:'https://fm-dev.auth.us-east-1.amazoncognito.com/authorize?client_id=3run3nquj30pkpaf1djg6acu8n&response_type=token',
    googleAuthURL:'https://fm-dev.auth.us-east-1.amazoncognito.com/authorize?client_id=3run3nquj30pkpaf1djg6acu8n&response_type=token',
    amazonAuthURL:'https://fm-dev.auth.us-east-1.amazoncognito.com/authorize?client_id=3run3nquj30pkpaf1djg6acu8n&response_type=token',
    logout: 'https://fm-dev.auth.us-east-1.amazoncognito.com/logout?&client_id=3run3nquj30pkpaf1djg6acu8n&logout_uri=',
    cityId: '166',
    littleElmCityId: '166',
    APIKEY:'AIzaSyAdoxz7kj1-lIQWENmaYtdpd91BkUChP4o',   
};

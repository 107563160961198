import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatTableModule } from '@angular/material';
import { MembershipService } from '../../service/membership.service';
import { ViewMembershipDetailsComponent } from './view-membership-details/view-membership-details.component';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-membership-requests',
  templateUrl: './membership-requests.component.html',
  styleUrls: ['./membership-requests.component.css']
})
export class MembershipRequestsComponent implements OnInit {

  loadingBar: boolean = false;
  memberships: any[] = [];
  page_size: number = 10;
  page_no: number = 1;
  totalPages: number = 1;
  selectedStatus = "all";
  filterTeam: any = "";

  statuses = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Pending',
      value: 'P'
    },
    {
      label: 'Approved',
      value: 'Y'
    },
    {
      label: 'Rejected',
      value: 'N'
    },
  ]

  windowWidth: number = window.innerWidth;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
    console.log(this.windowWidth);
  }

  constructor(
    private membershipService: MembershipService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getMemberships();
  }

  getMemberships() {
    const getMembershipRequestsPayload = {
      page_size: this.page_size,
      page_no: this.page_no,
      status: this.selectedStatus,
      team_name: this.filterTeam.toLowerCase()
    }
    this.membershipService.getMemberships(getMembershipRequestsPayload).subscribe((resp: any) => {
      this.memberships = JSON.parse(JSON.stringify(resp.membershipRecord));
      this.totalPages = resp.totalPages;
      this.loadingBar = false;
    }, error => {
      this.loadingBar = false;
    })
  }

  selectedProfile(membership) {
    const config = new MatDialogConfig();
    if (this.windowWidth > 1200) {
      config.width = '50%';
      config.height = '80%';
    }
    if (this.windowWidth > 500 && this.windowWidth < 768) {
      config.width = '95%';
      config.height = '60%';
    }

    if (this.windowWidth <= 500) {
      config.width = '95%';
      config.height = '40%';
    }
    config.data = membership;
    const dialogRef = this.dialog.open(ViewMembershipDetailsComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      this.getMemberships();
    });
  }

  filterTeamName() {
    this.page_no = 1;
    this.getMemberships();
  }

  previousPage() {
    this.loadingBar = true;
    this.page_no = this.page_no - 1;
    this.getMemberships();
  }

  nextPage() {
    this.loadingBar = true;
    this.page_no = this.page_no + 1;
    this.getMemberships();
  }

  changeStatus() {
    this.loadingBar = true;
    this.page_no = 1;
    this.getMemberships();
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  exportAsExcelFile(excelFileName: string): void {
    this.membershipService.getMembershipsCSV(this.selectedStatus).subscribe((response) => {
      console.log(response);
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(response)));
      const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
var popupTools = require('popup-tools');
import * as jwt_decode from 'jwt-decode';
import { UserLoginService } from '../../service/user-login.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  loadingBar: boolean = false;
  errorMessage: string;
  user = 'false';
  superAdmin='false';
  cityAdmin='false';
  urlType: string;
  cityform: FormGroup;
  searchPlace;

  constructor(
    public router: Router,
    public userService: UserLoginService,
    private formbuilder: FormBuilder
  ) { }

  ngOnInit() {

    this.user = localStorage.getItem('user');
    this.cityAdmin=localStorage.getItem('cityAdmin');
    this.superAdmin=localStorage.getItem('superAdmin');

    this.cityform = this.formbuilder.group({
      'city': new FormControl('', Validators.required),
    }); 
  }

  loginWithAuthProvider(authType) {
    var URL, domainName = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
    if (authType === 'facebook') {
    URL = environment.facebookAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook';
    } else if (authType === 'google') {
    URL = environment.googleAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google';
    } else if (authType === 'amazon') {
    URL = environment.amazonAuthURL+'&redirect_uri=' + domainName + '&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon';
    }
    popupTools.popup(URL, 'My Popup', { width: 400, height: 450 }, ((err) => {
      console.log('window closed::');
      // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
      if (localStorage.authToken) {
      this.cognitoCallback(null, localStorage.authToken);
      }
    }))
  }

  userDashboard() {
    // localStorage.setItem("upgradeView", "true");
    this.router.navigate(["/home/dashboard"]);
  }

  cognitoCallback(message: string, result: any) {
    if (message != null) { // error
        this.loadingBar = false;
        this.errorMessage = message;
        console.log('result: ' + this.errorMessage);
        if (this.errorMessage === 'User is not confirmed.') {
            console.log('redirecting');
            this.router.navigate(['/home/accountActivation']);
        } else if (this.errorMessage === 'User needs to set password.') {
            console.log('redirecting to set new password');
            this.router.navigate(['/home/newPassword']);
        }
    } else { // success
        let tokenInfo;
        if(result.idToken==undefined){
            tokenInfo = this.getDecodedAccessToken(result);
            console.log('this is the id token ###################', tokenInfo);
            sessionStorage.setItem('authorizationToken', result);
        }
        else{
            tokenInfo = this.getDecodedAccessToken(result.getIdToken().getJwtToken());
        }
        let params = sessionStorage.getItem('authorizationToken');
        console.log('This is the token info', tokenInfo);
        if ((tokenInfo['cognito:username'] === 'systemadmin') || (tokenInfo['cognito:username'] === 'rohit') || (tokenInfo['cognito:groups'] && tokenInfo['cognito:groups'].length > 0 && tokenInfo['cognito:groups'][0] == 'SystemAdmin')){
            console.log('this is the console for system admin');
            localStorage.setItem('superAdmin', 'true');
            this.superAdmin=localStorage.superAdmin;
            // this.loadingBar = false;
            //this.router.navigate(['/admin/cityRequests']);
            this.router.navigate(['/home']);
        } else if(tokenInfo['cognito:username'].includes('Facebook')){
            console.log("facebook Data::",tokenInfo);
            localStorage.setItem('user', 'true');
            this.user=localStorage.user;
            this.userService.getUserDetails(params).subscribe(data => {
                console.log('this is the output from get user details:', data);
                localStorage.setItem('firstName', data[0]['First_Name']);
                localStorage.setItem('lastName', data[0]['Last_Name']);
                localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                localStorage.setItem('email', data[0]['Email']);
                localStorage.setItem('id', data[0]['User_Id']);
                localStorage.setItem('street', data[0]['Street']);
                localStorage.setItem('city', data[0]['City']);
                localStorage.setItem('state', data[0]['State']);
                localStorage.setItem('zipcode', data[0]['Zip_Code']);
                localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));
            });
            if ( this.urlType === 'custom') {
             this.router.navigate(['/home'])
            } else {
              this.router.navigate(['/home']);
            }
        } else if(tokenInfo['cognito:username'].includes('Google')){
            console.log("google Data::",tokenInfo);
            localStorage.setItem('user', 'true');
            this.user=localStorage.user;
            this.userService.getUserDetails(params).subscribe(data => {
                console.log('this is the output from get user details:', data);
                localStorage.setItem('firstName', data[0]['First_Name']);
                localStorage.setItem('lastName', data[0]['Last_Name']);
                localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                localStorage.setItem('email', data[0]['Email']);
                localStorage.setItem('id', data[0]['User_Id']);
                localStorage.setItem('street', data[0]['Street']);
                localStorage.setItem('city', data[0]['City']);
                localStorage.setItem('state', data[0]['State']);
                localStorage.setItem('zipcode', data[0]['Zip_Code']);
                localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));
            });
            if ( this.urlType === 'custom') {
             this.router.navigate(['/home'])
            } else {
              this.router.navigate(['/home']);
            }
        } else if(tokenInfo['cognito:username'].includes('LoginWithAmazon')){
            console.log("amazon Data::",tokenInfo);
            console.log("amazon Data::",tokenInfo['name']);
            localStorage.setItem('user', 'true');
            this.user=localStorage.user;
            this.userService.getUserDetails(params).subscribe(data => {
                console.log('this is the output from get user details:', data);
                localStorage.setItem('firstName', data[0]['First_Name']);
                localStorage.setItem('lastName', data[0]['Last_Name']);
                localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                localStorage.setItem('email', data[0]['Email']);
                localStorage.setItem('id', data[0]['User_Id']);
                localStorage.setItem('street', data[0]['Street']);
                localStorage.setItem('city', data[0]['City']);
                localStorage.setItem('state', data[0]['State']);
                localStorage.setItem('zipcode', data[0]['Zip_Code']);
                localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));
            });
            if ( this.urlType === 'custom') {
             this.router.navigate(['/home'])
            } else {
              this.router.navigate(['/home']);
            }
        } else if (tokenInfo['cognito:groups'][0].includes('User')) {
            console.log('************ User is logged in ***********');
            localStorage.setItem('user', 'true');
            this.user=localStorage.user;
            this.userService.getUserDetails(params).subscribe(data => {
                console.log('this is the output from get user details:', data);
                localStorage.setItem('firstName', data[0]['First_Name']);
                localStorage.setItem('lastName', data[0]['Last_Name']);
                localStorage.setItem('residentOf', data[0]['Resident_Of'] === null ? '' : data[0]['Resident_Of']);
                localStorage.setItem('teamName', data[0]['Team_Name'] === null ? '' : data[0]['Team_Name']);
                localStorage.setItem('phoneNumber', data[0]['Phone_Number']===null ? '':data[0]['Phone_Number']);
                localStorage.setItem('email', data[0]['Email']);
                localStorage.setItem('id', data[0]['User_Id']);
                sessionStorage.setItem('cityId', data[0]['City_Id']);
                localStorage.setItem('street', data[0]['Street']);
                localStorage.setItem('city', data[0]['City']);
                localStorage.setItem('state', data[0]['State']);
                localStorage.setItem('zipcode', data[0]['Zip_Code']);
                localStorage.setItem('sportsPreferences',JSON.stringify(data[0]['Sport_Names']));
            });
            if ( this.urlType === 'custom') {
             this.router.navigate(['home/'])
            } else {
              this.router.navigate(['/home']);
            }
        } else {
            console.log("cognito:group",tokenInfo['cognito:groups'][0].split('_')[1])
            localStorage.setItem('cognitoGroup',tokenInfo['cognito:groups'][0].split('_')[1])
            localStorage.setItem('cityAdmin', 'true');
            this.cityAdmin=localStorage.cityAdmin;
            sessionStorage.setItem('cityId', tokenInfo['cognito:groups'][0].split('_')[0]);
            this.userService.getAdminDetails(params).subscribe(data => {
                console.log('this is the output from get admin details:', data);
                localStorage.setItem('repId', data[0]['Rep_Id']);
                localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
                localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
                if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
                    // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                    // this.loadingBar = false;
                    this.router.navigate(['/parkDefaults']);
                } else {
                    localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
                    localStorage.setItem('defaultParkName', data[0]['Park_Name']);
                    // sessionStorage.setItem('repId', data[0]['Rep_Id']);
                    this.router.navigate(['/home']);
                }
            });
        }
    }
  }

  getDecodedAccessToken(token: string): any {
    console.log('inside the function');
    try {
        console.log('token info in function: ', jwt_decode(token));
        return jwt_decode(token);
    } catch (Error) {
        return null;
    }
  }

  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }
  gotoSupportReqs() {
    this.router.navigate(['/home/support-requests']);
  }

  logout () {
    this.user = 'false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { UserLoginService } from "../../service/user-login.service";
import {
  Callback,
  CognitoUtil,
  LoggedInCallback,
} from "../../service/cognito.service";
import { Router } from "@angular/router";
import { SlotsService } from "../../service/slots.service";
import { Ground } from "../../models/ground";
import { Slot } from "../../models/slot";
import { Schedule } from "../../models/schedule";
import { Sport } from "../../models/sport";
import { DropdownModule } from "primeng/primeng";
import { EventEmitter, Output } from "@angular/core";
import { ChartModule } from "primeng/chart";
import { SlotsCalendarComponent } from "./slots-calendar/slots-calendar.component";
import { CognitoRefreshToken } from "amazon-cognito-identity-js";
import { Message } from "primeng/components/common/api";
import { MessageService } from "primeng/components/common/messageservice";
import * as moment from "moment";
import { Moment } from "moment";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { ParksService } from "../../service/parks.service";
import { UsersService } from "../../service/users.service";
import { MenuItem } from "primeng/api";
import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";

import { environment } from "../../../environments/environment";

import { AuthService, FacebookLoginProvider } from "angular5-social-login";
import { Observable } from "rxjs";

var popupTools = require("popup-tools");

declare let $;

export class Stuff {
  public accessToken: string;
  public idToken: string;
}

@Component({
  selector: "app-slots",
  templateUrl: "./slots.html",
  styleUrls: ["./slots.component.css"],
})
export class SlotsComponent implements LoggedInCallback, OnInit {
  @ViewChild(SlotsCalendarComponent) private child: SlotsCalendarComponent;
  @Output() valueChange = new EventEmitter<any>();
  public stuff: Stuff = new Stuff();
  createSlot = new Slot();
  createSchedule = new Schedule();
  public allGrounds = [];
  public allSports = [];
  unauthorized = false;
  error = false;
  weekdayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  // selectedDate = new Date();
  selectedDate: any;
  currentDay: string;
  currentWeek: string;
  checkValidation: boolean = false;
  checkscheValidation: boolean = false;
  minDate: Moment;
  maxDate: Moment;
  msgs: any;
  public Sports = [];
  options = {
    responsive: false,
    maintainAspectRatio: false,
    legend: { position: "bottom", align: "left" },
  };
  // days = {'Mon': true, 'Tue': true, 'Wed': true, 'Thu': true, 'Fri': true, 'Sat': true };
  scheduleDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  openWeekDays = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];
  data: any;
  canceledCount = 0;
  availableCount = 0;
  reservedCount = 0;
  infoSlotCount = 0;
  pendingCount = 0;
  requestedCount = 0;
  selectedGround: any;
  loadingBar = false;
  selectedSport: any;
  selectedPark: any;
  selectedCity: any;
  slotsCount: any;
  refreshToken: any;
  newSlot: any;
  showScheduleCustomDates: boolean = false;
  showSlotCustomDates: any = true;
  weekDays = {};
  // openBeforeCount: any;
  // closeBeforeCount: any;
  slotOpenCategory: any;
  // scheduleCategory: any;
  selectedGroundInstance: any;
  selectedGroundInstanceName: any;
  close_option = "park";
  closeData: any;
  dataIn: any;
  pie_count: any;
  paypal: any;
  showScheduleWeekDays: any = false;
  isWeekdays: boolean = false;
  addScheduleSubmit: boolean = false;
  addSlotForm: FormGroup;
  addSlotcheduleForm: FormGroup;
  partner: any;
  parkNames = [];
  adminParkId: any;
  adminParkName: any;
  minimumDate: Moment;
  fileToUpload: File;
  s3_response_msg: string;
  preSignedUrl: string;
  uploadedBucket: string;
  uploadedKey: string;
  s3_res_err: boolean = false;
  isFile: boolean = false;
  isInformationSingleSlot: boolean = false;
  isInformationScheduleSlot: boolean = false;
  presignedURLAvailable: boolean = false;
  users = [];
  userID: any;
  newSlotID: any;
  userName: string = "";
  allUsers = [];
  selectedUser: any;
  selectedUserData: any = null;
  isUserSelected: boolean = false;
  isAssignButtonClicked: boolean = false;
  assignToNewUser: boolean = false;
  userAlreadyExisted: boolean = true;
  activeIndex: number = 0;
  items: MenuItem[];
  slotDetailsTab: boolean = true;
  usersOnSearch:any[] = [];

  singleSlotVisible1: boolean = true;
  singleSlotVisible2: boolean = false;
  singleSlotVisible3: boolean = false;
  singleSlotVisible4: boolean = false;

  slotScheduleVisible1: boolean = true;
  slotScheduleVisible2: boolean = false;
  slotScheduleVisible3: boolean = false;
  slotScheduleVisible4: boolean = false;

  constructor(
    public router: Router,
    public userService: UserLoginService,
    public cognitoUtil: CognitoUtil,
    private formbuilder: FormBuilder,
    public slotsservice: SlotsService,
    private messageService: MessageService,
    public parksService: ParksService,
    private socialAuthService: AuthService,
    private usersService: UsersService
  ) {
    this.userService.isAuthenticated(this);
    console.log("in SlotsComponent");
    // sessionStorage.setItem('parkName', 'Bakersfield');
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    this.addSlotForm = this.formbuilder.group({
      groundId: new FormControl("", Validators.required),
      sportId: new FormControl("", Validators.required),
      date: new FormControl("", Validators.required),
      startTime: new FormControl("", Validators.required),
      endTime: new FormControl("", Validators.required),
      slotCategory: new FormControl("", Validators.required),
      openDate: new FormControl("", Validators.required),
      closeDate: new FormControl("", Validators.required),
      autoFIFO: new FormControl("", Validators.required),
      paidSlot: new FormControl("", Validators.required),
      slotPrice: new FormControl("", Validators.required),
      isPrivateSlot: new FormControl("", Validators.required),
      payByCash: new FormControl(""),
      password: new FormControl("", Validators.required),
      cnfpass: new FormControl(""),
      team: new FormControl("", Validators.required),
      resident: new FormControl("", Validators.required),
      informationSlot: new FormControl("", Validators.required),
      comments: new FormControl("", Validators.required),
      bookingURL: new FormControl("", [
        Validators.required,
        Validators.pattern(reg),
      ]),
      nonReserveSlot: new FormControl("", Validators.required),
      publishInFb: new FormControl("", Validators.required),
      isSpecial: new FormControl("", Validators.required),
      userId: new FormControl("", Validators.required),
      fcfsSlot: new FormControl("", Validators.required),
      serviceFee: new FormControl(""),
      leagueName: new FormControl(""),
    });

    this.addSlotcheduleForm = this.formbuilder.group({
      groundId: new FormControl("", Validators.required),
      sportId: new FormControl("", Validators.required),
      date: new FormControl("", Validators.required),
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
      startTime: new FormControl("", Validators.required),
      endTime: new FormControl("", Validators.required),
      scheduleCategory: new FormControl("", Validators.required),
      autoFIFO: new FormControl("", Validators.required),
      paidSlot: new FormControl("", Validators.required),
      slotPrice: new FormControl("", Validators.required),
      openBefore: new FormControl("", Validators.required),
      closeBefore: new FormControl("", Validators.required),
      day: new FormControl("", Validators.required),
      isPrivateSlot: new FormControl("", Validators.required),
      password: new FormControl("", Validators.required),
      cnfpass: new FormControl(""),
      payByCash: new FormControl(""),
      team: new FormControl("", Validators.required),
      resident: new FormControl("", Validators.required),
      informationSlot: new FormControl("", Validators.required),
      comments: new FormControl("", Validators.required),
      bookingURL: new FormControl("", [
        Validators.required,
        Validators.pattern(reg),
      ]),
      nonReserveSlot: new FormControl("", Validators.required),
      publishInFb: new FormControl("", Validators.required),
      fcfsSlot: new FormControl("", Validators.required),
      serviceFee: new FormControl(""),
      leagueName: new FormControl(""),
    });
  }
 

  ngOnInit() {
    this.socialAuthService.authState.subscribe((res: any) => {
      if (res != null) {
        this.socialAuthService.signOut();
      }
    });

    this.createSlot.informationSlot = false;
    this.addSlotForm.get("slotCategory").enable();
    this.createSchedule.informationSlot = false;
    this.addSlotcheduleForm.get("scheduleCategory").enable();
    this.createSlot.nonReserveSlot = false;
    this.createSchedule.nonReserveSlot = false;
    this.createSlot.publishInFb = false;
    this.createSchedule.publishInFb = false;
    if (localStorage.getItem("planName") == "Basic") {
      setTimeout(
        function () {
          $("#home .switch__toggle").prop("disabled", true);
          $("#profile .switch__toggle").prop("disabled", true);
          $("#home .info_slot").prop("disabled", false);
          $("#profile .info_schedle").prop("disabled", false);
          this.createSlot.autoFIFO = true;
          this.createSlot.team = false;
          this.createSlot.resident = false;
          this.createSlot.isSpecial = false;
          this.createSchedule.autoFIFO = true;
          this.createSchedule.team = false;
          this.createSchedule.resident = false;
        }.bind(this),
        1000
      );
    }
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.selectedCity = sessionStorage.getItem("cityName");
    this.createSlot.repName = "park";
    this.createSlot.autoFIFO = false;
    this.createSlot.paidSlot = false;
    this.createSlot.payByCash = false;
    this.createSlot.slotCategory = "custom";
    this.createSlot.team = true;
    this.createSlot.isSpecial = false;
    this.createSlot.resident = true;
    this.createSchedule.scheduleCategory = "default";
    this.createSchedule.autoFIFO = false;
    this.createSchedule.paidSlot = false;
    this.createSchedule.payByCash = false;
    this.createSchedule.team = true;
    this.createSchedule.resident = true;
    this.pieCount();
    this.weekDays = {
      Mon: false,
      Tue: false,
      Wed: false,
      Thu: false,
      Fri: false,
      Sat: false,
      Sun: false,
    };
    this.selectedPark = localStorage.getItem("defaultParkName");
    console.log("selected Park is:", this.selectedPark);
    this.slotsservice.sendData(this.selectedPark);
    this.paypal = localStorage.paypal;
    this.getPartnerDetail();
    this.getParks();
    this.restrictDays();
    this.items = [
      {
        label: "Slot Details",
        command: (event: any) => {
          this.activeIndex = 0;
        },
      },
      {
        label: "Date & Time",
        command: (event: any) => {
          this.activeIndex = 1;
        },
      },
      {
        label: "Other details",
        command: (event: any) => {
          this.activeIndex = 2;
        },
      },
    ];
  }

  onUploadFile() {
    $("#fileUpload").click();
  }

  handleFileInput(files: FileList) {
    this.isFile = true;
    this.loadingBar = true;
    this.fileToUpload = files.item(0);
    console.log("filetoupload", this.fileToUpload);
    let rep_id = localStorage.getItem("repId");
    this.slotsservice.getPreSignedUrl(rep_id).subscribe((res: any) => {
      this.loadingBar = false;
      console.log("get presigned url res", res);
      this.preSignedUrl = res.signed_url;
      this.presignedURLAvailable = true;
      this.uploadedBucket = res.Bucket;
      this.uploadedKey = res.Key;
    });
  }

  uploadToPreSignedUrl() {
    this.loadingBar = true;
    $("#addSlot").modal("hide");
    let ext = this.uploadedKey.split(".")[1];
    this.slotsservice
      .uploadToPreSignedUrl(this.preSignedUrl, this.fileToUpload, ext)
      .subscribe(
        (res: any) => {
          this.loadingBar = false;
          let data = {
            key: this.uploadedKey,
            bucket: this.uploadedBucket,
          };
          this.saveS3Url(data);
          console.log("pre-signed url res", res);
        },
        (err: any) => {
          this.loadingBar = false;
          this.s3_res_err = true;
          this.s3_response_msg =
            "Unable to perform this operation. Please contact administrator";
          $("#s3ResModal").modal("show");
        }
      );
  }

  saveS3Url(s3File) {
    this.loadingBar = true;
    console.log("S3 File", s3File);
    let params = {
      rep_id: localStorage.getItem("repId"),
      key: s3File.key,
      bucket: s3File.bucket,
    };
    this.slotsservice.saveS3Url(params).subscribe((res: any) => {
      console.log("tarun api res", res);
      if (JSON.parse(res.body).httpStatus == 200) {
        // if (res.body.httpStatus == 200) {
        this.loadingBar = false;
        this.s3_res_err = false;
        this.s3_response_msg =
          "Your request was submitted. The slots will be created soon";
        $("#s3ResModal").modal("show");
      } else {
        this.slotsservice.delS3File(this.uploadedKey).subscribe((res: any) => {
          console.log("delete file res", res);
          this.loadingBar = false;
          this.s3_res_err = true;
          this.s3_response_msg =
            "Provided Excel data is not in an accepted format. Please click on sample file.";
          $("#s3ResModal").modal("show");
        });
      }
    });
  }

  onInformationSlot(slot) {
    console.log("@@@@@@@@@@@", slot);
    if (slot == true) {
      this.isInformationSingleSlot = true;
      this.createSlot.slotCategory = "always";
      this.addSlotForm.get("slotCategory").disable();
      // this.addSlotForm.patchValue({slotCategory : 'always'})
      this.createSlot.team = false;
      this.createSlot.resident = false;
      this.createSlot.autoFIFO = false;
      this.createSlot.nonReserveSlot = false;
      this.createSlot.fcfsSlot = false;
      // $("#home .switch__toggle").prop('disabled', true);
      // $("#home .info_slot").prop('disabled', false);
    } else if (slot == false) {
      this.addSlotForm.get("slotCategory").enable();
      this.isInformationSingleSlot = false;
      if (this.createSlot.slotCategory == "custom") {
        this.showSlotCustomDates = true;
      } else {
        this.showSlotCustomDates = false;
      }
      this.createSlot.team = true;
      this.createSlot.resident = true;
      // $("#home .switch__toggle").prop('disabled', false);
      // $("#home .info_slot").prop('disabled', false);
      if (localStorage.getItem("planName") == "Basic") {
        this.createSlot.autoFIFO = true;
        this.createSlot.resident = false;
        this.createSlot.team = false;
        // $("#home .switch__toggle").prop('disabled', true);
        // $("#home .info_slot").prop('disabled', false);
      }
    }
  }

  onNonReserveSlot(slot) {
    console.log("Non Resv", slot);
    if (slot) {
      this.createSlot.team = false;
      this.createSlot.resident = false;
      this.createSlot.autoFIFO = true;
      this.createSlot.informationSlot = false;
      this.createSlot.fcfsSlot = false;
      // $("#home .switch__toggle").prop('disabled', true);
      // $("#home .nonresv_slot").prop('disabled', false);
      // $("#home .auto_fifo").prop('disabled', false);
    } else if (!slot) {
      this.createSlot.team = true;
      this.createSlot.resident = true;
      this.createSlot.autoFIFO = false;
      // $("#home .switch__toggle").prop('disabled', false);
    }
  }

  onisSpecialChange(flag) {
    if (!flag) {
      this.selectedUserData=null;
    }
  }

  onNonReserveSchedule(slot) {
    console.log("Non Resv", slot);
    if (slot) {
      this.createSchedule.team = false;
      this.createSchedule.resident = false;
      this.createSchedule.autoFIFO = true;
      this.createSchedule.informationSlot = false;
      this.createSchedule.fcfsSlot = false;
      // $("#home .switch__toggle").prop('disabled', true);
      // $("#home .nonresv_schedule").prop('disabled', false);
      // $("#home .auto_fifo").prop('disabled', false);
    } else if (!slot) {
      this.createSchedule.team = true;
      this.createSchedule.resident = true;
      this.createSchedule.autoFIFO = false;
      // $("#home .switch__toggle").prop('disabled', false);
    }
  }

  onFcfsSlot(slot) {
    if (slot) {
      this.createSlot.team = false;
      this.createSlot.resident = false;
      // this.createSlot.autoFIFO=true;
      this.createSlot.informationSlot = false;
      this.createSlot.nonReserveSlot = false;
      this.addSlotForm.get("slotCategory").disable();
    } else if (!slot) {
      this.createSlot.team = true;
      this.createSlot.resident = true;
      // this.createSlot.autoFIFO=false;
      this.addSlotForm.get("slotCategory").enable();
      this.createSlot.informationSlot = false;
      this.createSlot.nonReserveSlot = false;
      // $("#home .switch__toggle").prop('disabled', false);
    }
  }

  onInformationSchedule(schedule) {
    if (schedule) {
      this.createSchedule.fcfsSlot = false;
    }
    this.updateCreateSchedule(schedule)
  }
  onFcfsSchedule(schedule) {
    if (schedule == true) {
      this.createSchedule.informationSlot = false;
    } 
    this.updateCreateSchedule(schedule)
  }
  updateCreateSchedule(schedule){
    if (schedule == true) {
      this.isInformationScheduleSlot = true;
      this.createSchedule.scheduleCategory = "always";
      this.addSlotcheduleForm.get("scheduleCategory").disable();
      this.createSchedule.team = false;
      this.createSchedule.resident = false;
      this.createSchedule.autoFIFO = false;
      this.createSchedule.nonReserveSlot = false;
    } else if (schedule == false) {
      this.addSlotcheduleForm.get("scheduleCategory").enable();
      if (this.createSchedule.scheduleCategory == "custom") {
        this.showScheduleCustomDates = true;
      } else {
        this.showScheduleCustomDates = false;
      }
      this.isInformationScheduleSlot = false;
      this.createSchedule.team = true;
      this.createSchedule.resident = true;
      if (localStorage.getItem("planName") == "Basic") {
        this.createSchedule.autoFIFO = true;
        this.createSchedule.resident = false;
        this.createSchedule.team = false;
      }
    }
  }
  restrictDays() {
    this.minimumDate = moment()["_d"];
  }

  getParks() {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    this.parkNames = [];
    const params = {
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
    };

    this.parksService.getParksListDefaults(params).subscribe(
      (response) => {
        console.log("The response is", response);
        if (response.status === 200) {
          if (response.body.length === 0) {
            localStorage.setItem("defaultParkId", "temp");
            this.router.navigate(["/admin/parks"]);
            this.loadingBar = false;
          } else {
            console.log("Get Parks data is: ", response.body);
            if(!response.body.errorMessage){
            response.body.forEach((e) => {
              const temp = {};
              temp["value"] = e.Park_Id;
              temp["label"] = e.Park_Name;
              this.parkNames.push(temp);
            });}
            this.loadingBar = false;
            console.log("parksList data is: ", this.parkNames);

            if (localStorage.getItem("defaultParkId")) {
              this.adminParkId = localStorage.getItem("defaultParkId");
              this.getGrounds();
            } else {
              this.adminParkId = this.parkNames[0].value;
              localStorage.setItem("defaultParkId", this.parkNames[0].value);
              this.getGrounds();
            }
          }
        }
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }

  onParkSelected() {
    this.allGrounds = [];
    console.log("on park select method", this.adminParkId);
    this.parkNames.forEach((e) => {
      if (e.value === this.adminParkId) {
        this.adminParkName = e.label;
      }
    });
    console.log("on park select method name:", this.adminParkName);
    localStorage.setItem("defaultParkName", this.adminParkName);
    localStorage.setItem("defaultParkId", this.adminParkId);
    this.selectedPark = this.adminParkName;
    this.getGrounds();
    this.slotsservice.sendData(this.adminParkName);
  }

  onCalendarClick(event) {
    this.singleSlotVisible1=true;
    this.slotScheduleVisible1=true;
    this.singleSlotVisible2=false;
    this.singleSlotVisible3=false;
    this.singleSlotVisible4=false;
    this.slotScheduleVisible1=true;
    this.slotScheduleVisible2=false;
    this.slotScheduleVisible3=false;
    this.slotScheduleVisible4=false;
    // this.addSlotForm.reset();
    // this.addSlotcheduleForm.reset();
    this.createSlot = new Slot();
    this.createSchedule = new Schedule();
    setTimeout(
      function () {
        this.createSlot.team = true;
        this.createSlot.resident = true;
        this.createSchedule.team = true;
        this.createSchedule.resident = true;
        this.createSchedule.scheduleCategory = "default";
        this.createSlot.slotCategory = "custom";
      }.bind(this),
      1000
    );
    // this.createSlot.team = true;
    // this.createSlot.resident = true;
    // this.createSchedule.team = true;
    // this.createSchedule.resident = true;
    this.isInformationSingleSlot = false;
    this.isInformationScheduleSlot = false;
    this.createSlot.informationSlot = false;
    this.showScheduleCustomDates = false;
    this.addSlotForm.get("slotCategory").enable();
    this.createSchedule.scheduleCategory = "default";
    this.addSlotcheduleForm.get("scheduleCategory").enable();
    this.createSchedule.informationSlot = false;
    $("#addSlot").modal("show");
    this.createSlot.sportId = null;
    this.createSchedule.sportId = null;
    this.createSlot.groundId = this.selectedGround;
    this.createSchedule.groundId = this.selectedGround;
    this.prodSports(this.createSlot.groundId);
    setTimeout(
      function () {
        if (this.allSports.length == 1) {
          this.createSlot.sportId = this.allSports[0].value;
          this.createSchedule.sportId = this.allSports[0].value;
        } else {
          this.createSlot.sportId = this.selectedSport;
          this.createSchedule.sportId = this.selectedSport;
        }
      }.bind(this),
      1000
    );
    if (event) {
      this.createSlot.date = event;
    }
  }

  getPartnerDetail() {
    let cityId = sessionStorage.getItem("cityId");
    this.slotsservice.getPartnerDetail(cityId).subscribe((response: any) => {
      if (response.msg == undefined) {
        this.partner = response[0];
      }
    });
  }

  getWeekStartDay(day) {
    console.log(day);
  }

  pieCount() {
    console.log(
      666,
      this.reservedCount,
      this.availableCount,
      this.pendingCount,
      this.canceledCount,
      this.requestedCount,
      this.infoSlotCount
    );
    this.data = {
      labels: [
        "Reserved",
        "Available",
        "Pending",
        "Canceled",
        "Requested",
        "Information",
      ],
      datasets: [
        {
          data: [
            this.reservedCount,
            this.availableCount,
            this.pendingCount,
            this.canceledCount,
            this.requestedCount,
            this.infoSlotCount,
          ],
          backgroundColor: [
            "#3f51b5",
            "#2da95b",
            "#f44336",
            "#a6a6a6",
            "#ff9800",
            "#00A9FF",
          ],
          hoverBackgroundColor: [
            "#162ab5",
            "#05a948",
            "#f4291c",
            "#828282",
            "#ff9800",
            "#00A9FF",
          ],
        },
      ],
    };
  }

  slotsCounter(data) {
    this.pie_count = data;
  }

  // selectCustomDates() {
  //     this.showcustomDates = true;
  //     this.slotOpenCategory = 'custom';
  // }
  // testEvent(test) {
  //     console.log('this is the test', test);
  // }
  closeOptions(params) {
    if (params === "park") {
      this.close_option = "park";
    } else if (params === "ground") {
      this.close_option = "ground";
    } else {
      this.close_option = "city";
    }
  }

  closePark(data) {
    this.dataIn = data;
    document.getElementById("remove-city").click();
    document.getElementById("addslot1").click();
  }

  closeConfirmPark() {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    let params;
    if (this.dataIn.repName === "park") {
      params = {
        type: this.dataIn.repName,
        Id: localStorage.getItem("defaultParkId"),
        date: moment(this.dataIn.date).format("YYYY-MM-DD"),
        authorizationToken: sessionStorage.getItem("authorizationToken"),
        cityId: sessionStorage.getItem("cityId"),
      };
      this.closeData = params;
    } else if (this.dataIn.repName === "ground") {
      params = {
        type: this.dataIn.repName,
        Id: this.dataIn.groundId.Ground_Id,
        date: moment(this.dataIn.date).format("YYYY-MM-DD"),
        authorizationToken: sessionStorage.getItem("authorizationToken"),
        cityId: sessionStorage.getItem("cityId"),
      };
      this.closeData = params;
    } else {
      params = {
        type: this.dataIn.repName,
        Id: sessionStorage.getItem("cityId"),
        date: moment(this.dataIn.date).format("YYYY-MM-DD"),
        authorizationToken: sessionStorage.getItem("authorizationToken"),
        cityId: sessionStorage.getItem("cityId"),
      };
      this.closeData = params;
    }
    console.log("Params to close park/ground/city:", this.closeData);
    console.log("Params are: ", params);
    this.slotsservice.cancelByDate(params).subscribe(
      (response) => {
        if (response.status === 200) {
          this.messageService.add({
            severity: "success",
            summary: "Slots Update",
            detail: "All unreserved slots have been cancelled successfully",
          });
          this.messageService.add({
            severity: "warn",
            summary: "Slots Update",
            detail: "Please process reserved slots manually",
          });
          this.child.reloadSlots();
          this.loadingBar = false;
        }
      },
      (err) => {
        this.errorHandle(err);
      }
    );
    this.createSlot.date = null;
    this.createSlot.groundId = null;
  }

  setScheduleOpenDates(params) {
    console.log("params" + params);
    if (params !== "custom") {
      if (params === "defalut") {
        this.showScheduleWeekDays = true;
        this.showScheduleCustomDates = false;
      } else if (params === "always") {
        this.showScheduleWeekDays = false;
        this.showScheduleCustomDates = false;
      }
    } else {
      this.showScheduleCustomDates = true;
      this.showScheduleWeekDays = false;
    }
    this.createSchedule.scheduleCategory = params;
    console.log(
      "This is the slot open category",
      this.createSchedule.scheduleCategory
    );
  }
  setSlotOpenDates(params) {
    if (params !== "custom") {
      this.showSlotCustomDates = false;
    } else {
      this.showSlotCustomDates = true;
    }
    this.createSlot.slotCategory = params;
    console.log("This is the slot open category", this.createSlot.slotCategory);
  }
  displayCounter(data) {
    console.log("counts data: ", data);
    this.slotsCount = data;
    this.reservedCount = data["reservedCount"];
    this.availableCount = data["availableCount"];
    this.pendingCount = data["pendingCount"];
    this.canceledCount = data["canceledCount"];
    this.requestedCount = data["requestedCount"];
    this.infoSlotCount = data["infoSlotCount"];
    this.pieCount();
  }
  errorIn(data) {
    console.log("this is the error in data:", data);
    this.error = data;
  }
  prodSports(data) {
    this.getSports(data.Ground_Id);
  }
  unauthorizedIn(data) {
    console.log("this is the unauthorized in data:", data);
    this.unauthorized = data;
  }
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      // this.router.navigate(['/home/login']);
      this.router.navigate(["/home/signin"]);
    } else {
      this.cognitoUtil.getAccessToken(new AccessTokenCallback(this));
      this.cognitoUtil.getIdToken(new IdTokenCallback(this));
    }
  }
  errorHandle(err) {
    if (err.status === 401) {
      this.loadingBar = false;
      this.unauthorized = true;
      // this.router.navigate(['/admin/unauthorized']);
    } else if (err.status === 400 || err.status === 404 || err.status === 500) {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    } else {
      this.loadingBar = false;
      this.error = true;
      // this.router.navigate(['/admin/error']);
    }
  }

  getGrounds() {
    this.loadingBar = true;
    console.log("cityId", sessionStorage.getItem("cityId"));
    this.cognitoUtil.refresh();
    const params = {
      parkId: localStorage.getItem("defaultParkId"),
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
    };
    this.slotsservice.getGrounds(params).subscribe(
      (response) => {
        console.log("The response is", response);
        if (response.status === 200) {
          if (response.body.length === 0) {
            this.messageService.add({
              severity: "success",
              summary: "Slots Update",
              detail: "No grounds exist",
            });
            this.loadingBar = false;
            console.log("In no sports section grounds route");
            this.router.navigate(["admin/grounds"]);
          } else {
            response.body.forEach((o) => {
              console.log(o);
              let obj = new Ground();
              const temp = {};
              temp["value"] = o;
              temp["label"] = o.Ground_Name;
              this.allGrounds.push(temp);
            });
            this.selectedGround = this.allGrounds[0].value;
            // this.createSlot.groundId=this.allGrounds[0].value;
            console.log("this is initial select ground", this.selectedGround);
            this.getSportsByGround(this.selectedGround);
            console.log("sportList data is: ", this.allGrounds);
            this.loadingBar = false;
          }
        }
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }
  generateOpenDates(params) {
    console.log("generateOpenDates is called", params);
    this.selectedDate = moment(params).format("dddd").toString();
    console.log("Current Day is", this.selectedDate);
    // this.currentWeek = moment().format('w');
    // if (this.weekdayList.indexOf(this.selectedDate) > -1) {
    this.minDate = moment(params).add(-1, "weeks").startOf("isoWeek")["_d"];
    this.maxDate = moment(params).add(-1, "weeks").endOf("isoWeek")["_d"];
    console.log(this.selectedDate, this.minDate, this.maxDate, " is a weekday");
    this.createSlot.openDate = this.minDate;
    this.createSlot.closeDate = this.maxDate;
    console.log(
      "openDate:",
      this.createSlot.openDate,
      "closeDate:",
      this.createSlot.closeDate
    );
    // } else {
    // this.minDate = (moment(params).add(-1, 'weeks').startOf('isoWeek'))['_d'];
    // this.maxDate = (moment(params).add(-1, 'weeks').endOf('isoWeek'))['_d'];
    // console.log(this.selectedDate, this.minDate, this.maxDate,  ' is not a weekday');
    // this.createSlot.openDate = this.minDate;
    // this.createSlot.closeDate = this.maxDate;
    // }
  }
  getSportsByGround(inputParams) {
    this.loadingBar = true;
    this.cognitoUtil.refresh();
    this.selectedGroundInstance = inputParams.Ground_Id;
    this.selectedGroundInstanceName = inputParams.Ground_Name;
    console.log("get sports for", inputParams);

    this.selectedSport = "";
    this.allSports = [];
    // if(inputParams.hasOwnProperty(''))
    const params = {
      groundId: inputParams.Ground_Id,
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
    };
    this.slotsservice.getSports(params).subscribe(
      (response) => {
        console.log("The response is", response);
        if (response.status === 200) {
          if (response.body.length === 0) {
            this.messageService.add({
              severity: "success",
              summary: "Slots Update",
              detail: "No sports exist",
            });
            this.messageService.add({
              severity: "info",
              summary: "Slots Update",
              detail: "No slots exist",
            });
            this.loadingBar = false;
            // console.log('In no sports section GS route');
            // this.router.navigate(['admin/grounds']);
          } else {
            response.body.forEach((o) => {
              console.log(o);
              let obj = new Sport();
              const temp = {};
              temp["value"] = o.Sport_Id;
              temp["label"] = o.Sport_Name;
              this.allSports.push(temp);
            });
            console.log("sportList data is: ", this.allSports.length);
            this.loadingBar = false;
            if (this.allSports.length == 1) {
              this.selectedSport = this.allSports[0].value;
            }
          }
        }
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }
  onSportChange(params) {
    console.log("on sport change", params);
  }

  addSlot(dataIn) {
    this.cognitoUtil.refresh();
    this.selectedUserData = this.selectedUserData || {};
    dataIn.userId = dataIn.userId || this.selectedUserData.User_Id;
    console.log("dataIn.userId", dataIn.userId);
    this.selectedUserData = null;
    console.log("save data", dataIn);
  
    const startTime = moment(dataIn.startTime).format("hh:mm A");
    const endTime = moment(dataIn.endTime).format("hh:mm A");
    const slot = startTime + " - " + endTime;
    const sDate = moment(dataIn.date).format("YYYY-MM-DD");
  
    this.setDefaultValues(dataIn);
  
    if (this.isFormInvalid(dataIn)) {
      this.checkValidation = true;
    } else {
      this.checkValidation = false;
      this.handleSlotCategory(dataIn, slot, sDate);
    }
  }
  
  setDefaultValues(dataIn) {
    const defaultValues = {
      autoFIFO: false,
      fcfsSlot: false,
      team: false,
      isSpecial: false,
      userId: null,
      resident: false,
      slotCategory: "always",
    };
  
    for (const key in defaultValues) {
      if (dataIn[key] === undefined || dataIn[key] === null) {
        dataIn[key] = defaultValues[key];
      }
    }
  }
  
  isFormInvalid(dataIn) {
    const formControls = this.addSlotForm.controls;
    const requiredFields = [
      "groundId",
      "sportId",
      "date",
      "startTime",
      "endTime",
      "slotCategory",
    ];
  
    if (dataIn.slotCategory === "custom") {
      requiredFields.push("openDate", "closeDate");
    }
  
    for (const field of requiredFields) {
      if (
        dataIn[field] === undefined ||
        formControls[field].status === "INVALID"
      ) {
        return true;
      }
    }
  
    if (dataIn.paidSlot === true && dataIn.slotPrice === undefined) {
      return true;
    }
  
    if (
      dataIn.isPrivateSlot === true &&
      (dataIn.password === undefined ||
        formControls["password"].value !== formControls["cnfpass"].value)
    ) {
      return true;
    }
  
    return false;
  }
  
  handleSlotCategory(dataIn, slot, sDate) {
    if (dataIn.informationSlot) {
      dataIn.paidSlot = "Information";
    } else if (dataIn.fcfsSlot) {
      dataIn.paidSlot = "First Come First Serve";
    } else {
      dataIn.paidSlot = dataIn.paidSlot ? "Paid" : "Free";
      dataIn.slotPrice = dataIn.paidSlot === "Free" ? "0" : dataIn.slotPrice;
      dataIn.serviceFee = dataIn.paidSlot === "Free" ? null : dataIn.serviceFee;
    }
  
    this.loadingBar = true;
    document.getElementById("addslot").click();
  
    let fb_access_token = null;
    if (this.createSlot.publishInFb) {
      this.signInWithFacebook()
        .then((userData) => {
          fb_access_token = userData.token;
          this.saveSlotData(dataIn, slot, sDate, fb_access_token);
        });
    } else {
      this.saveSlotData(dataIn, slot, sDate, fb_access_token);
    }
  }
  
  signInWithFacebook() {
    const socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    return this.socialAuthService.signIn(socialPlatformProvider);
  }
  
  saveSlotData(dataIn, slot, sDate, fb_access_token) {
    const params = {
      sportId: dataIn.sportId,
      groundId: dataIn.groundId.Ground_Id,
      repId: localStorage.getItem("repId"),
      slot: slot,
      date: sDate,
      parkId: localStorage.getItem("defaultParkId"),
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
      openDate: moment(dataIn.openDate).format("YYYY-MM-DD"),
      closeDate: moment(dataIn.closeDate).format("YYYY-MM-DD"),
      slotCategory: dataIn.slotCategory,
      autoFIFO: dataIn.autoFIFO,
      slotPrice: dataIn.slotPrice,
      paidSlot: dataIn.paidSlot,
      isPrivate: dataIn.isPrivateSlot === undefined ? "" : dataIn.isPrivateSlot,
      password: dataIn.password === undefined ? "" : dataIn.password,
      payByCash: dataIn.payByCash,
      Ask_Team_Name: dataIn.team,
      Ask_Resident_Of: dataIn.resident,
      Comments: dataIn.comments,
      BookingUrl: dataIn.bookingURL,
      Service_Fee: dataIn.serviceFee,
      nonResvSlot: dataIn.nonReserveSlot,
      publishInFb: dataIn.publishInFb,
      isSpecial: dataIn.isSpecial,
      userId: dataIn.userId,
      access_token: fb_access_token,
      leagueName: dataIn.leagueName,
    };
  
    console.log("save slot", params);
  
    this.slotsservice.addSlot(params).subscribe(
      (response) => {
        console.log("The response is", response);
        this.handleAddSlotResponse(response);
      },
      (err) => {
        this.createSlot = new Slot();
        this.createSlot.slotCategory = "custom";
        this.errorHandle(err);
      }
    );
  }
  
  handleAddSlotResponse(response) {
    if (response.status === 200) {
      if (response.body.msg === "overlap") {
        this.createSlot = new Slot();
        this.loadingBar = false;
        this.createSlot = new Slot();
        this.messageService.add({
          severity: "success",
          summary: "Slots Update",
          detail:
            "There is an overlap with another slot : \n" +
            response.body.overlapSlot[0].Slot,
        });
      } else if (response.body.msg === "success") {
        this.createSlot = new Slot();
        this.messageService.add({
          severity: "success",
          summary: "Slots Update",
          detail: "Slot added successfully",
        });
        this.createSlot = new Slot();
        if (
          !response.body.data.fcfsSlot &&
          !response.body.data.informationSlot &&
          !response.body.data.nonReserveSlot
        ) {
          this.userID = "";
          this.isAssignButtonClicked = false;
          // $('#slotAssignModal').modal('show')
        }
        this.newSlotID = response.body.data.insertId;
        console.log(response.body.data.insertId);
        this.child.reloadSlots();
        this.loadingBar = false;
      }
    }
    this.createSlot.slotCategory = "custom";
    this.showSlotCustomDates = true;
  }
  

  clearAddSlot() {
    this.singleSlotVisible1 = false;
    this.singleSlotVisible2 = false;
    this.singleSlotVisible3 = false;
    this.singleSlotVisible4 = false;

    $("#addSlot").modal("hide");
    this.createSlot = new Slot();
    this.createSlot.slotCategory = "custom";
  }
  clearAddSchedule() {
    this.slotScheduleVisible1 = false;
    this.slotScheduleVisible2 = false;
    this.slotScheduleVisible3 = false;
    this.slotScheduleVisible4 = false;
    $("#addSlot").modal("hide");
    this.createSchedule = new Schedule();
    this.createSlot.slotCategory = "custom";
  }
  addSchedule(dataIn) {
    var atLeastOneIsChecked = !(this.addSlotcheduleForm.controls['day'].status== 'INVALID')
    //$('input[name="weekDays"]:checked').length > 0;
    console.log(atLeastOneIsChecked);
    this.cognitoUtil.refresh();
    console.log("save data", dataIn);
    let startTime = moment(dataIn.startTime).format("hh:mm A");
    let endTime = moment(dataIn.endTime).format("hh:mm A");
    let slotStartTime = isNaN(parseInt(startTime))
      ? dataIn.startTime
      : startTime;
    let slotEndTime = isNaN(parseInt(endTime)) ? dataIn.endTime : endTime;
    let slot = `${slotStartTime} - ${slotEndTime}`;
    let sDate = moment(dataIn.startDate).format("YYYY-MM-DD");
    let eDate = moment(dataIn.endDate).format("YYYY-MM-DD");
    if (dataIn.informationSlot == true) {
      dataIn.scheduleCategory = "always";
    }
    if (dataIn.scheduleCategory !== "custom") {
      dataIn.openBefore = undefined;
      dataIn.closeBefore = undefined;
    }
    if (dataIn.autoFIFO === undefined || dataIn.autoFIFO === null) {
      dataIn.autoFIFO = false;
    }

    if (dataIn.team === undefined || dataIn.team === null) {
      dataIn.team = false;
    }

    if (dataIn.resident === undefined || dataIn.resident === null) {
      dataIn.resident = false;
    }

    if (
      dataIn.groundId === undefined ||
      this.addSlotcheduleForm.controls["groundId"].status === "INVALID" ||
      dataIn.sportId === undefined ||
      this.addSlotcheduleForm.controls["sportId"].status === "INVALID" ||
      dataIn.startDate === undefined ||
      this.addSlotcheduleForm.controls["startDate"].status === "INVALID" ||
      dataIn.endDate === undefined ||
      this.addSlotcheduleForm.controls["endDate"].status === "INVALID" ||
      dataIn.startTime === undefined ||
      this.addSlotcheduleForm.controls["startTime"].status === "INVALID" ||
      dataIn.endTime === undefined ||
      this.addSlotcheduleForm.controls["endTime"].status === "INVALID" ||
      this.addSlotcheduleForm.controls["day"].status === "INVALID" ||
      atLeastOneIsChecked == false ||
      (dataIn.scheduleCategory == "custom" &&
        (dataIn.openBefore == undefined ||
          dataIn.closeBefore == undefined ||
          this.addSlotcheduleForm.controls["openBefore"].status === "INVALID" ||
          this.addSlotcheduleForm.controls["closeBefore"].status ===
            "INVALID")) ||
      (dataIn.paidSlot == true &&
        (dataIn.slotPrice == undefined ||
          this.addSlotcheduleForm.controls["slotPrice"].status ===
            "INVALID")) ||
      (dataIn.isPrivateSlot == true &&
        (dataIn.password == undefined ||
          this.addSlotcheduleForm.controls["password"].status === "INVALID")) ||
      this.addSlotcheduleForm.controls["password"].value !==
        this.addSlotcheduleForm.controls["cnfpass"].value
    ) {
      this.checkscheValidation = true;
    } else {
      if (dataIn.informationSlot == true) {
        dataIn.paidSlot = "Information";
      } else if (dataIn.fcfsSlot == true) {
        dataIn.paidSlot = "First Come First Serve";
      } else {
        if (
          dataIn.paidSlot === false ||
          dataIn.paidSlot === undefined ||
          dataIn.paidSlot === null
        ) {
          dataIn.paidSlot = "Free";
          dataIn.slotPrice = "0";
          dataIn.serviceFee = null;
        } else {
          dataIn.paidSlot = "Paid";
        }
      }
      this.checkscheValidation = false;
      this.loadingBar = true;
      document.getElementById("addslot").click();
      let fb_access_token = null;
      if (this.createSchedule.publishInFb) {
        let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        this.socialAuthService
          .signIn(socialPlatformProvider)
          .then((userData) => {
            fb_access_token = userData.token;
            this.addScheduleInner(dataIn, slot, sDate, eDate, fb_access_token)
          });
      } else {
        this.addScheduleInner(dataIn, slot, sDate, eDate, fb_access_token)
      }
    }
  }

  addScheduleInner(dataIn, slot, sDate, eDate, fb_access_token){
    const params = {
      sportId: dataIn.sportId,
      groundId: dataIn.groundId,
      repId: localStorage.getItem("repId"),
      slot: slot,
      startDate: sDate,
      endDate: eDate,
      parkId: localStorage.getItem("defaultParkId"),
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
      days: JSON.stringify(this.weekDays),
      scheduleCategory: dataIn.scheduleCategory,
      startBefore: dataIn.openBefore,
      endBefore: dataIn.closeBefore,
      autoFIFO: dataIn.autoFIFO,
      schedulePrice: dataIn.slotPrice,
      scheduleType: dataIn.paidSlot,
      isPrivate:
        dataIn.isPrivateSlot === undefined ? "" : dataIn.isPrivateSlot,
      password: dataIn.password === undefined ? "" : dataIn.password,
      payByCash: dataIn.payByCash,
      Ask_Team_Name: dataIn.team,
      Ask_Resident_Of: dataIn.resident,
      Comments: dataIn.comments,
      BookingUrl: dataIn.bookingURL,
      Service_Fee: dataIn.serviceFee,
      nonResvSchedule: dataIn.nonReserveSlot,
      access_token: fb_access_token,
      leagueName: dataIn.leagueName,
    };
    this.slotsservice.addSchedule(params).subscribe(
      (response) => {
        console.log("The response is", response);
        if (response.status === 200) {
          this.createSchedule = new Schedule();
          this.addSlotcheduleForm.reset();
          this.weekDays = {
            Mon: false,
            Tue: false,
            Wed: false,
            Thu: false,
            Fri: false,
            Sat: false,
            Sun: false,
          };
          if (response.body.message === "overlap") {
            this.loadingBar = false;
            this.messageService.add({
              severity: "warn",
              summary: "Schedules Update",
              detail:
                "There is an overlap with a slot : \n" +
                response.body.info[0].Slot,
            });
          } else if (response.body.message === "success") {
            this.messageService.add({
              severity: "success",
              summary: "Schedules Update",
              detail: "Schedule added successfully",
            });
            this.loadingBar = false;
            this.child.reloadSlots();
          }
        }
        this.createSchedule.scheduleCategory = "default";
      },
      (err) => {
        this.createSchedule = new Schedule();
        this.errorHandle(err);
        this.createSchedule.scheduleCategory = "default";
      }
    );
  }

  onSelect(event) {
    console.log("calendar click event fired", event);
  }

  getSports(paramsIn) {
    this.loadingBar = true;
    this.selectedUserData =null;
    this.cognitoUtil.refresh();
    this.Sports = [];
    console.log("get sports by ground in add slot", paramsIn);
    const params = {
      groundId: paramsIn,
      cityId: sessionStorage.getItem("cityId"),
      authorizationToken: sessionStorage.getItem("authorizationToken"),
    };
    this.slotsservice.getSports(params).subscribe(
      (response) => {
        console.log("The response is", response);
        if (response.status === 200) {
          if (response.body.length === 0) {
            this.loadingBar = false;
            this.messageService.add({
              severity: "success",
              summary: "Slots Update",
              detail: "No sports exist",
            });
          } else {
            if(!response.body.errorMessage){
            response.body.forEach((o) => {
              console.log(o);
              let obj = new Sport();
              const temp = {};
              temp["value"] = o.Sport_Id;
              temp["label"] = o.Sport_Name;
              this.Sports.push(temp);
            });}
            this.loadingBar = false;
            console.log("sportList data is: ", this.Sports);
          }
        }
      },
      (err) => {
        this.errorHandle(err);
      }
    );
  }
  onDaySelection(day) {
    if (this.weekDays[day] === false) {
      this.weekDays[day] = true;
    } else {
      this.weekDays[day] = false;
    }
    this.isWeekdays = Object["values"](this.weekDays).includes(true);
  }
  onAssignToUser() {
    $("#assignUserModel").modal("show");
    this.getUsers();
  }
  getUsers() {
    this.loadingBar = true;
    this.usersService.getActiveUsers().subscribe((response) => {
      console.log(response);
      this.loadingBar = false;
      if (response.body.length > 0) {
        this.allUsers = response.body;
        response.body.forEach((user) => {
          const temp = {};
          temp["value"] = user.User_Id;
          temp["label"] = user.First_Name + " " + user.Last_Name;
          this.users.push(temp);
        });
      }
    });
  }
  onSelectUser() {
    let user_id = this.userID;
    this.selectedUser = this.allUsers.filter((user) => {
      return this.userID === user.User_Id;
    });
    if (this.selectedUser) {
      this.isUserSelected = true;
    }
    this.userName =
      this.selectedUser[0].First_Name + " " + this.selectedUser[0].Last_Name;
    console.log(this.selectedUser);
  }

  assignSlotToUser() {
    this.isAssignButtonClicked = true;
    if (this.userID == undefined || this.userID == null || this.userID == "") {
      this.isUserSelected = false;
      return;
    } else {
      this.isUserSelected = true;
    }
    let data = {
      Slot_Id: this.newSlotID,
      User_Id: this.userID,
      City_Id: sessionStorage.getItem("cityId"),
      First_Name: this.selectedUser[0].First_Name,
      Last_Name: this.selectedUser[0].Last_Name,
      User_Mobile: this.selectedUser[0].Phone_Number,
      Email: this.selectedUser[0].Email,
      AssignedBy: localStorage.getItem("repName"),
      Assignee_Email: localStorage.getItem("email"),
      Assignee_Mobile: localStorage.getItem("phoneNumber"),
    };
    this.loadingBar = true;
    this.slotsservice.assignSlotToUser(data).subscribe((response) => {
      console.log(response);
      this.loadingBar = false;
      if (response.status === 200) {
        if (response.body.message) {
          $("#assignUserModel").modal("hide");
          this.messageService.add({
            severity: "success",
            summary: "Slot allot update",
            detail: "slot allotted to " + this.userName + "",
          });
        }
      }
    });
  }
  isNewUser() {
    this.assignToNewUser = true;
    this.userAlreadyExisted = false;
  }

  isUserExisted() {
    this.assignToNewUser = false;
    this.userAlreadyExisted = true;
  }

  onSearch(char: string) {
    if (char.length > 2) {
     console.log(char)
      this.usersService.getUsersOnEmail(char)
      .subscribe((res: any)=> {
        let user_list_search = res.body;
        console.log('user_list_search', user_list_search);
        this.usersOnSearch = user_list_search;
      });
    }
    else{
      this.usersOnSearch = [];
    } 
  }
  onSelectUserByEMail(result: string) {
    this.selectedUserData = result;
    this.usersOnSearch = [];
  }
}

export class AccessTokenCallback implements Callback {
  constructor(public slots: SlotsComponent) {}

  callback() {}

  callbackWithParam(result) {
    this.slots.stuff.accessToken = result;
  }
}

export class IdTokenCallback implements Callback {
  constructor(public slots: SlotsComponent) {}

  callback() {}

  callbackWithParam(result) {
    this.slots.stuff.idToken = result;
  }
}

export class MyModel {
  value: Date;
}

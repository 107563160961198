import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UserLoginService } from './../../service/user-login.service';
import {HomeService} from '../../service/home.service';
import { environment } from './../../../environments/environment';
import * as moment from 'moment';
declare let paypal: any;
declare let paypal_auth:any;
declare let $;
@Component({
  selector: 'app-service-request-success',
  templateUrl: './service-request-success.component.html',
  styleUrls: ['./service-request-success.component.css']
})
export class ServiceRequestSuccessComponent implements OnInit {

  somevalue: string = 'https://content.fieldsmanager.com/' + environment.logoStage + '/' + sessionStorage.getItem('cityId') + '.png';
  user = 'false';
  serviceRequestId: string = null;
  loadingBar: boolean = false;
  serviceReqsBid: any;
  moment: any = moment;
  userId: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public homeService: HomeService
  ) {
      this.route.params.subscribe(params => {
      this.serviceRequestId = params.serviceRequestId
    });
    this.user = localStorage.getItem('user');
  }

  ngOnInit() {
    this.getServiceRequestBidAcceptedDetails();
    this.userId = localStorage.getItem("id");
  }

  updateUrl(event) {
    console.log('failed url:', this.somevalue);
    this.somevalue = 'https://content.fieldsmanager.com/System.png';
    console.log('new url:', this.somevalue);
  }

  logout() {
    this.user = 'false';
    if(localStorage.upgradeView==='true'){
      this.router.navigate(['/home']);
      localStorage.clear();
    }
    else{
      this.router.navigate(['/home']);
      localStorage.clear();
    }
  }

  getServiceRequestBidAcceptedDetails(){
    this.loadingBar = true;
    this.homeService.serviceRequestBidAcceptedDetails(this.serviceRequestId, this.userId).subscribe((res: any) => {
      this.loadingBar=false;
      this.serviceReqsBid = res[0];
    })
  }

  myDashboard(){
    this.router.navigate(['/home/dashboard/']);
  }

  goHome(){
    this.router.navigate(['/home']);
  }
}

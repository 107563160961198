import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
declare let $;
@Component({
  selector: 'app-location-request',
  templateUrl: './location-request.component.html',
  styleUrls: ['./location-request.component.css']
})
export class LocationRequestComponent implements OnInit {
  services: any[] = [];
  locationName: string = "";
  loadingBar = false;
  error:any;
  unauthorized:any;
  msgs:any;
  repId:any;
  checkValidation;
  constructor( private homeService: HomeService,) { }

  ngOnInit() {
   this.repId = localStorage.getItem('repId');
   this.getServices( this.repId)

  }
  
  getServices(repId) {
   
    this.homeService.getlocationsofparks(repId)
    .subscribe((res: any)=> {

      if(res.msg=='No services found!'){
        this.services = [];
      }
      else{
        this.services = res;
      }
      
    });
  }

  openCreateSrvModal() {
    // this.isFormSubmitted = false;
    // this.currentService = null;
    this.checkValidation=false;
    $("#createServiceModal").modal('show');
    // this.editService = false;
    this.locationName = '';
    // this.serviceType = "";
    // this.serviceSports = [];
  }

  onCreateLocation() {
    if(this.locationName.length===0){
      this.checkValidation=true;
    }
    else{   
   
      $("#createServiceModal").modal('hide');
       this.loadingBar=true;
      let data = {
        location_name: this.locationName,
        repId :this.repId 
       
      }
      this.homeService.requestLocation(data)
        .subscribe((res: any)=> {
         
            this.loadingBar=false;
        
          this.getServices(this.repId)
        });
    }
  }

  onDeleteLocation(location){
    this.loadingBar=true;
    let data = {
      locationId: location.Location_Id,
     
    }
    this.homeService.deleteLocation(data)
      .subscribe((res: any)=> {
       
        this.getServices(this.repId)
        this.loadingBar=false;
      });

  }


}

import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { HomeService } from '../../../service/home.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { addExtraRecipientCommon, removeEmailCommon } from '../../dashboard/dashboard.component';
import * as moment from 'moment';
import {MessageService} from 'primeng/components/common/messageservice';

@Component({
  selector: 'bid-for-service-dialog',
  templateUrl: './bid-for-service-dialog.html',
  styleUrls: ['./bid-for-service-dialog.css'],
  encapsulation: ViewEncapsulation.None
})
export class BidForServiceDialogComponent implements OnInit {
  currentSRRequest : any;
  recipient_form :FormGroup;
  recipient_emails: FormControl[] = [];
  serviceComments:any[] = [];
  servicesArr: any[] = [];
  loadingBar: boolean = false;
  refereedSports:any=[];
  service_provider_status:any;
  tab_type:any = 'referee';
  userId: any;
  refereeRequests: any;
  channelNames:any[] = [];
  moment: any = moment;
  Bid_Amount:any;
  Channel_Id:string;
  Channel_Url:string;
  Stream_Key:string;
  Secondary_Stream_Url:string;
  Secondary_Stream_Key:string;
  Comments:any;
  youtubeCheck: boolean = false;
  serviceRequesterRating:any[] = [];
  preferredPrice:any;
  readOnlyRequest:boolean=false;


    constructor(
        private dialogRef: MatDialogRef<BidForServiceDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formbuilder: FormBuilder,
        public homeService: HomeService,
        private messageService: MessageService,
      ) { }
    ngOnInit() {
      this.currentSRRequest = this.data //from slotdetail
      this.recipient_form = this.formbuilder.group({
        recipient_emails : new FormControl("", Validators.required)
      })
      this.userId = localStorage.getItem('id');
      this.getServices(); 
      this.addselectedchannelid()
      this.addnewCommentfieldforSerivce(this.currentSRRequest.Service_Id, this.currentSRRequest.User_Id);

      let pp = parseFloat(this.currentSRRequest.Preferred_Price || 0);
      this.preferredPrice = pp > 0 ? pp : null;
      this.readOnlyRequest = this.currentSRRequest.ServiceProvider_Id;
    }

    addExtraRecipient() {
      addExtraRecipientCommon(this.recipient_emails, this.recipient_form);
    }
    removeEmail(index: number) {
      removeEmailCommon(index, this.recipient_emails, this.recipient_form)
    }
    getServices() {
      this.homeService.getServices()
        .subscribe((res: any)=> {
          this.servicesArr = res.filter((service) => service['Service_Status'] == 'Active');
      });
    }

    addnewCommentfieldforSerivce(serviceId,User_Id) {
      this.loadingBar = true;
      this.homeService.getServiceCommentsAndRanting(serviceId,User_Id).subscribe((res: any)=> {
          this.serviceComments=res.body.commentResults;
          this.serviceRequesterRating=res.body.ratingResults;
          this.loadingBar = false;
      });
    }
    
    addselectedchannelid() {
          this.loadingBar = true;
          this.homeService.getchannels().subscribe((res: any)=> {
          this.channelNames=res.body;
          this.loadingBar = false;
      });
    }
    closeDialog() {
      this.dialogRef.close();
    }

    bidServiceRequest(){
      let extraReceipients = '';
      this.recipient_emails.forEach(receipients=>{
        extraReceipients += (extraReceipients.length === 0) ? receipients.value : ', ' + receipients.value
      })
      let recipient_mail = this.recipient_emails.filter((control) => control.value != "").map(control=>(control.value));
      let data = {
        ...this.currentSRRequest,
        Bid_Amount: this.Bid_Amount,
        Comments: this.Comments,
        Channel_Url:this.Channel_Url,
        YoutubeCheck:this.youtubeCheck,
        Channel_Id:this.Channel_Id,
        Stream_Key:this.Stream_Key,
        Secondary_Stream_Key:this.Secondary_Stream_Key,
        Secondary_Stream_Url:this.Secondary_Stream_Url,
        Service_Provider_Id: this.userId,
        Extra_Recipient: extraReceipients
      }
      this.loadingBar = true;
      this.homeService.bidSrvcRequest(data)
      .subscribe((res: any)=> {
        let body = JSON.parse(res.body);
        this.loadingBar = false;
        this.Channel_Url=''
        this.Channel_Id=''
        this.Stream_Key=''
        if(body.httpStatus == 400) {
          this.messageService.add({
            severity: 'error',
            summary: 'Bid request',
            detail: body.message
          });
        } else {
          this.loadingBar = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Bid request',
            detail: body.message
          });
        }
        this.closeDialog()
      });
    }
  
}
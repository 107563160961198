import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MembershipService } from '../../../service/membership.service';
import { MatSnackBar } from '@angular/material';



@Component({
  selector: 'app-view-membership-details',
  templateUrl: './view-membership-details.component.html',
  styleUrls: ['./view-membership-details.component.css']
})
export class ViewMembershipDetailsComponent implements OnInit {
  loadingBar: boolean = false;
  expirationDate: any = null;
  expiry_date: any = new Date(this.data.membership_exp_date);
  team_name: any = this.data.Team_Name;
  cricclub_id = this.data.cricclub_id;
  is_edit : boolean = false; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<ViewMembershipDetailsComponent>,
    public membershipService: MembershipService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() { }

  openReceipt(receiptUrl) {
    
    if(receiptUrl === null){
      this.snackBar.open('Receipt is not uploaded', 'Okay', {
        duration: 4000
      })
    }else{
      window.open(receiptUrl);
    }
  }

  openPlayerDetails(){
    window.open(this.data.cricclub_link);
  }

  updateValues(){
    console.info("Selected date",this.expiry_date)
    console.info("team_name",this.team_name)
    console.info("cricclub_id",this.cricclub_id)
    this.loadingBar= true;
    const requestpayload = {
      update_values: true,
      membership_id: this.data.membership_id,
      expiry_date: this.data.is_verified == 'Y'? this.expiry_date : null,
      team_name: this.team_name,
      cricclub_id: this.cricclub_id,
      user_id: this.data.user_id
    }
    this.membershipService.verifyMembership(requestpayload).subscribe(resp => {
      this.loadingBar= false;
      this.matDialogRef.close();
    })
    this.is_edit = false;
  }

  enableEdit(){
    this.is_edit = true;
  }

  membershipResponse(isVerified) {
    this.loadingBar= true;
    const requestpayload = {
      membership_id: this.data.membership_id,
      is_verified: isVerified,
      email: this.data.Email
    }
    this.membershipService.verifyMembership(requestpayload).subscribe(resp => {
      this.loadingBar= false;
      this.matDialogRef.close();
    })
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { City } from '../models/city';
import { User } from '../models/user';
import { Observable } from 'rxjs/Observable';
import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable()
export class HomeService {

  private dataSource = new BehaviorSubject('default message');
  currentMessage = this.dataSource.asObservable();

  constructor(private http: HttpClient) { }

  sendData(data: any) {
    this.dataSource.next(data)
  }

  sendBookingData(data: any) {
    this.dataSource.next(data)
  }

  public sports = [{ "Sport_Id": 1, "Sport_Name": "Soccer", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 2, "Sport_Name": "Baseball", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 3, "Sport_Name": "Cricket", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }, { "Sport_Id": 4, "Sport_Name": "Football", "Sport_Image": "http://placehold.it/160x200" }];

  public parks = [{ "Park_Id": 1, "Park_Name": "Marylebone park-london Baker Street1", "address": "4090 Barton Creek" }, { "Park_Id": 2, "Park_Name": "Marylebone park-london Baker Street2", "address": "4091 Barton Creek" }, { "Park_Id": 3, "Park_Name": "Marylebone park-london Baker Street3", "address": "2100 Barton Springs Rd" }, { "Park_Id": 4, "Park_Name": "Marylebone park-london Baker Street4", "address": "2131 William Barton Dr" }, { "Park_Id": 5, "Park_Name": "Marylebone park-london Baker Street5", "address": "4090 Barton Creek" }];

  getSports(): Observable<any> {
    let params = new HttpParams();
    const url = environment.apiUrl + '/sports/byactiveslots';
    return this.http.get(url, { params: params });
  }
  getlocations(): Observable<any> {
    let params = new HttpParams();
    const url = environment.newapiUrl + '/user/location';
    return this.http.get(url, { params: params });
  }
  getlocationsofparks(repId): Observable<any> {
    let params = new HttpParams();
    const url = environment.newapiUrl + '/user/fetchinglocation';
    params = params.append('RepId', repId);
    return this.http.get(url, { params: params });
  }
  getAdminRequestLocation(): Observable<any> {
    let params = new HttpParams();
    const url = environment.newapiUrl + '/user/fetchinglocation';
    return this.http.get(url, { params: params });
  }

  getPreferredLocations(userId): Observable<any> {
    let params = new HttpParams();
    params = params.append('User_Id', userId);
    const url = environment.newapiUrl + '/user/locationpreferences';
    return this.http.get(url, { params: params });
  }

  getparksData(s, d): Observable<any> {
    let params = new HttpParams()
    const url = 'https://maps.googleapis.com/maps/api/distancematrix/json?origins=' + s + '&destinations=' + d + '&key=AIzaSyClIpa7BlltcrEmMj2Enuaz0hbjC9aD0C4'
    console.log(url);
    return this.http.get(url, { params: params });
  }

  getVideoList(apiKey, channelId): Observable<any> {
    let params = new HttpParams()
    const url = 'https://www.googleapis.com/youtube/v3/search?key=' + apiKey + '&channelId=' + channelId + '&part=snippet,id&order=date&maxResults=8'
    console.log(url);
    return this.http.get(url, { params: params });
  }

  getParks(location): Observable<any> {
    console.log("get Parks", location);
    let params = new HttpParams();
    params = params.append('location', 'NW1 6XE');
    const options = { params: params };
    const url = environment.apiUrl + '/parks';
    return this.http.get(url, { params: params });
  }
  // acceptServiceRequest(data) {
  //   let headers = new HttpHeaders();
  //   headers.append('Content-Type', 'application/json');
  //   console.log(data)
  //   let params = new HttpParams();
  //   params = params.append('Service_Id', data.Service_Id);
  //   params = params.append('Sport_Id', data.Sport_Id);
  //   params = params.append('User_Id', data.User_Id);
  //   // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
  //   const url = environment.slotUrl+'/slots/serviceRequests/updateServcReq';
  //   return this.http.post<any>(url,'' ,{ params: params, headers: headers });
  // }
  public acceptServiceSignupRequest(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Service_Id', data.Service_Id);
    params = params.append('Sport_Id', data.Sport_Id);
    params = params.append('User_Id', data.User_Id);
    params = params.append('operation', "update");
    // console.log('Params to assign slot random:', params);
    // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    // let headers = new HttpHeaders();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.facilityUrl + '/services/signupRequests/updateServiceRequest';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  public acceptLocationRequest(Location_Id): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Location_Id', Location_Id);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.newapiUrl + '/user/updatinglocation';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  public deleteLocationRequest(Location_Id) {
    let params = new HttpParams();
    params = params.append('Location_Id', Location_Id);
    const url = environment.newapiUrl + '/user/location';
    return this.http.delete(url, { params: params });
  }

  public denyServiceSignupRequest(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Service_Id', data.Service_Id);
    params = params.append('Sport_Id', data.Sport_Id);
    params = params.append('User_Id', data.User_Id);
    params = params.append('operation', "delete");
    // console.log('Params to assign slot random:', params);
    // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    // let headers = new HttpHeaders();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.facilityUrl + '/services/signupRequests/updateServiceRequest';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }




  getParksData(from, to, place, sport, user_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('from_date', moment(from).format('YYYYMMDD'));
    params = params.append('to_date', moment(to).format('YYYYMMDD'));

    params = params.append('sport_id',sport);
    params = params.append('user_id',user_id);
    const url = environment.facilityUrl + '/parks/parksbysportandlocation';

    return this.http.get(url, { params: params });
  }

  getSlotsbyPark(sport, park, from_date, to_date, location, user_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('sport_id', sport.value);
    params = params.append('park_id', park);
    params = params.append('from_date', moment(from_date).format('YYYYMMDD'));
    params = params.append('to_date', moment(to_date).format('YYYYMMDD'));
    params = params.append('user_id', user_id);
    const url = environment.apiUrl + '/slots/allslotsbyfuture';
    return this.http.get(url, { params: params });
  }

  getParksByLocationDate(from_date, to_date, location, user_id): Observable<any> {
    console.log('API::', from_date, to_date, location);
    let params = new HttpParams();
    params = params.append('from_date', moment(from_date).format('YYYYMMDD'));
    params = params.append('to_date', moment(to_date).format('YYYYMMDD'));
    params = params.append('user_id', user_id);
    const url = environment.facilityUrl + '/parks/getallbylocation';
    return this.http.get(url, { params: params });
  }
  getSlotsByParkId(park_id, from_date, to_date, location, user_id): Observable<any> {
    let params = new HttpParams();
    params = params.append('park_id', park_id);
    params = params.append('from_date', moment(from_date).format('YYYYMMDD'));
    params = params.append('to_date', moment(to_date).format('YYYYMMDD'));
    params = params.append('user_id', user_id);
    const url = environment.apiUrl + '/slots/allslotsbyfuture';
    return this.http.get(url, { params: params });
  }

  getSuportReqs(user_id) {
    const url = environment.apiUrl + '/issues?user_id=' + user_id;
    return this.http.get(url);
  }

  createIssue(issue) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id', issue.user_id);
    params = params.append('reservation_id', issue.reservation_id);
    params = params.append('redmine_id', issue.redmine_id);
    params = params.append('subject', issue.subject);
    params = params.append('description', issue.description);
    params = params.append('project_id', issue.project_id);
    params = params.append('project_name', issue.project_name);
    params = params.append('priority_id', issue.priority_id);
    params = params.append('priority_name', issue.priority_name);
    params = params.append('status_id', issue.status_id);
    params = params.append('status_name', issue.status_name);
    params = params.append('author_id', issue.author_id);
    params = params.append('author_name', issue.author_name);
    params = params.append('created_on', issue.created_on);
    params = params.append('updated_on', issue.updated_on);
    const url = environment.apiUrl + '/issues';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getFutureReservations(dateNow, location, user_id) {
    let params = new HttpParams();
    params = params.append('current_date', dateNow);
    params = params.append('user_id', user_id);
    params = params.append('location', location);
    const url = environment.slotUrl + '/slots';
    return this.http.get(url, { params: params });
  }

  getFutureSessionRes(userId, type) {
    let params = new HttpParams();
    params = params.append('type', type);
    params = params.append('user_id', userId);
    const url = environment.slotUrl + '/reservations/user';
    //const url = 'https://nry8t2vqxj.execute-api.us-east-2.amazonaws.com/dev/users/getreservations';
    return this.http.get(url, { params: params });
  }

  getpastReservations(userId, type) {
    let params = new HttpParams();
    params = params.append('type', type);
    params = params.append('user_id', userId);
    const url = environment.slotUrl + '/reservations/user';
    return this.http.get(url, { params: params });
  }

  getallReservations(userId, type) {
    let params = new HttpParams();
    params = params.append('type', type);
    params = params.append('user_id', userId);
    const url = environment.slotUrl + '/reservations/user';
    return this.http.get(url, { params: params });
  }


  getTeamsList(user_id) {
    const url = environment.apiUrl + '/users/allteams';
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    return this.http.get(url, { params: params });
  }
  getTeamsListonSearch(user_id, query) {
    const url = environment.apiUrl + '/users/allteams';
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    params = params.append('type', "search");
    params = params.append('char', query);

    return this.http.get(url, { params: params });
  }
  createTeam(team) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Team_Name', team.Team_Name);
    params = params.append('Sport_Id', team.Sport_Id);
    params = params.append('Sport_Name', team.Sport_Name);
    params = params.append('Team_Max_Size', team.Team_Max_Size);
    params = params.append('Team_Size', team.Team_Size);
    params = params.append('Team_Created_By', team.Team_Created_By);
    const url = environment.newapiUrl + '/teams';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  addExtraRecepient(extraReceipients, slotId){
    
    const url = environment.slotUrl + '/slots/service/addRecipient';

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Extra_Recipient', extraReceipients);
    params = params.append('Slot_Id', slotId);

    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  onJoinTeam(team) {
    let headers = new HttpHeaders().set('Content-type', 'application/json');
    let params = new HttpParams();
    params = params.append('team_id', team.team_id);
    params = params.append('team_name', team.team_name);
    params = params.append('user_id', team.user_id);
    params = params.append('user_name', team.user_name);
    params = params.append('email', team.email);
    const url = environment.newapiUrl + '/teams/invitemember';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  onAcceptTeam(invitation_id, user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('invitation_id', invitation_id);
    params = params.append('user_id', user_id);
    const url = environment.newapiUrl + '/teams/jointeam';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getTeamMembers(team_id) {
    let params = new HttpParams();
    params = params.append('team_id', team_id);
    const url = environment.newapiUrl + '/teams/teammember';
    return this.http.get(url, { params: params });
  }

  deleteTeam(team_id) {
    let params = new HttpParams();
    params = params.append('team_id', team_id);
    const url = environment.newapiUrl + '/teams';
    return this.http.delete(url, { params: params });
  }

  updateTeam(team) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('team_id', team.team_id);
    params = params.append('team_name', team.team_name);
    params = params.append('tm_members', team.members.length !== 0 ? team.members : '[]');
    params = params.append('email', team.email);
    params = params.append('user_id', team.user_id);
    params = params.append('user_name', team.user_name)
    const url = environment.newapiUrl + '/teams';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  inviteTeam(team) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams();
    params = params.append('team_id', team.team_id);
    params = params.append('team_name', team.team_name);
    params = params.append('user_id', team.user_id);
    params = params.append('user_name', team.user_name);
    params = params.append('email', team.email);
    const url = environment.newapiUrl + '/teams/invitemember';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  joinTeamCnfm(invitation_id, user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json')
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    params = params.append('invitation_id', invitation_id);
    const url = environment.newapiUrl + '/teams/jointeam';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  refereeSignup(request) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', request.user_id);
    params = params.append('Service_Id', request.service_id);
    params = params.append('Sport_Ids', JSON.stringify(request.sports));
    const url = environment.newapiUrl + '/user/serviceProvider';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getRefereerequests() {
    const url = environment.apiUrl + '/referee/requests';
    return this.http.get(url);
  }

  getRefereeStatus(userId, serviceId) {
    const url = environment.newapiUrl + '/user/serviceProvider/status?User_Id=' + userId + '&Service_Id=' + serviceId;
    // const url = environment.apiUrl + '/referee?user_id='+userId;
    return this.http.get(url);
  }

  refereeUpdate(userId, refereeId, sportsIds) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id', userId);
    params = params.append('ref_id', refereeId);
    params = params.append('sport_ids', JSON.stringify(sportsIds));
    const url = environment.apiUrl + '/referee';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  updateRefereeReq(ref_sport_id, type) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ref_sport_id', ref_sport_id);
    params = params.append('type', type);
    const url = environment.apiUrl + '/referee/refstatus';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  requestReferee(user_id, resv_id, payment_status) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    params = params.append('reservation_id', resv_id);
    params = params.append('pay_status', payment_status);
    const url = environment.apiUrl + '/users/refereeslot';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  requestReferee_v2(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Slot_Id', data.slot_id);
    params = params.append('Service_Id', data.service_id);
    params = params.append('Sport_Id', data.sport_id);
    params = params.append('User_Id', data.user_id);
    params = params.append('Requested_Quals', JSON.stringify(data.Requested_Quals));
    params = params.append('Preferred_Price', data.preferred_price);
    params = params.append('Fixed_Pay', data.fixedPay);
    const url = environment.slotUrl + '/slots/serviceRequests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getMyRefereereqs(user_id, service_id) {
    // const url = environment.apiUrl + '/referee/requestslots?user_id='+user_id+'&ref_id='+ref_id+'';  
    const url = environment.slotUrl + '/slots/serviceRequests/byProvider?User_Id=' + user_id + '&Service_Id=' + service_id + '';
    return this.http.get(url);
  }
  getMyRefereereqsPast(user_id, service_id) {
    // const url = environment.apiUrl + '/referee/requestslots?user_id='+user_id+'&ref_id='+ref_id+'';  
    const url = environment.slotUrl + '/slots/serviceRequests/byProvider?User_Id=' + user_id + '&Service_Id=' + service_id + '&type=past';
    return this.http.get(url);
  }

  getMyServiceReqs(user_id, service_id, type) {
    const url = environment.slotUrl + '/slots/serviceRequests/byProvider?User_Id=' + user_id + '&Service_Id=' + service_id + '&myRequests=true' + '&type=' + type;
    return this.http.get(url);
  }

  pickSlot(user_id, resv_id, resv_ref_id, ref_id, city_id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    // params = params.append('user_id', user_id);
    params = params.append('reservation_id', resv_id);
    params = params.append('city_id', city_id);
    params = params.append('ref_slot_req_id', resv_ref_id);
    params = params.append('ref_id', ref_id);
    const url = environment.apiUrl + '/referee/pick/requests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  saveFeedback(fb, ref_id, resv_id) {
    console.log('fb', fb, ref_id, resv_id);
    let share_info = fb.regular_user ? "Yes" : "No";
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('title', fb.title);
    params = params.append('review', fb.description);
    params = params.append('overall_exp', fb.overall_experience);
    params = params.append('overall_satisfaction', fb.overall_satisfaction);
    params = params.append('timely_response', fb.timely_response);
    params = params.append('our_support', fb.our_support);
    params = params.append('fb_ser_id', fb.service); // 1
    params = params.append('share_info', share_info);
    params = params.append('anything_else', fb.suggestion);
    params = params.append('user_id', fb.userId);
    params = params.append('reservation_id', resv_id);
    params = params.append('ref_Id', ref_id);
    params = params.append('ref_slot_req_id', '');
    const url = environment.apiUrl + '/users/feedback';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getFbServices() {
    const url = environment.apiUrl + '/feedback/servicetypes';
    return this.http.get(url);
  }

  getServices() {
    const url = environment.facilityUrl + '/services';
    // const url = environment.apiUrl + '/services';  
    return this.http.get(url);
  }

  createService(service) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();

    params = params.append('Service_Name', service.service_name);
    params = params.append('Service_Type', service.serviceType);
    params = params.append('created_by', service.created_by);
    params = params.append('city_admin_id', service.city_id);
    params = params.append('Sport_Ids', JSON.stringify(service.serviceSports));
    const url = environment.facilityUrl + '/services';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  createLocation(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Location', data.location_name);
    params = params.append('Status', 'Active');
    const url = environment.newapiUrl + '/user/location';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  requestLocation(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Location', data.location_name);
    params = params.append('Status', 'Requested');
    params = params.append('RepId', data.repId);
    const url = environment.newapiUrl + '/user/location';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  deleteLocation(data) {
    let params = new HttpParams();
    params = params.append('Location_Id', data.locationId);
    const url = environment.newapiUrl + '/user/location';
    return this.http.delete(url, { params: params });
  }

  updateService(service) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();

    params = params.append('Service_Name', service.service_name);
    params = params.append('Service_Type', service.service_type);
    params = params.append('Service_Id', service.service_id);
    params = params.append('Sport_Ids', JSON.stringify(service.service_sports));
    const url = environment.facilityUrl + '/services';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  changeServiceStatus(service) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();

    params = params.append('Status_Type', service.type);
    params = params.append('Service_Id', service.service_id);
    const url = environment.facilityUrl + '/services';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getsportRequests() {
    const url = environment.apiUrl + '/sports/requests';
    return this.http.get(url);
  }

  changeSportReqStatus(request) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();

    params = params.append('sport_req_id', request.Sport_Req_Id);
    params = params.append('type', request.type);
    const url = environment.apiUrl + '/sports/status';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  requestSport(sport, user_id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    params = params.append('sport_name', sport.sport);
    params = params.append('sport_description', sport.description);
    const url = environment.apiUrl + '/users/sport';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  cancelSlot(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('slotId', data.slot_id);
    // params = params.append('city_id', data.city_id);
    params = params.append('cancellationReason', data.reason);
    params = params.append('userId', data.user_id);
    const url = environment.apiUrl + '/slots/cancelbyuser';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  checkUsername(username) {
    const url = environment.apiUrl + '/users/checkusername?username=' + username;
    return this.http.get(url);
  }

  checkEmail(email) {
    const url = environment.apiUrl + '/users/checkuseremail?email=' + email;
    return this.http.get(url);
  }

  checkMobileNumber(mobile_number) {
    const url = environment.apiUrl + '/users/checkusermobile?phone_number=' + mobile_number;
    return this.http.get(url);
  }

  public getUmpiringServiceRequests(data) {
    console.log('this is the data from get Sports by ground', data);
    let params = new HttpParams();
    let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    params = params.append('cityId', data.cityId);
    const options = { params: params, headers: headers };
    const url = environment.facilityUrl + '/services/getUmpiringServiceRequests?City_Id=' + data.cityId;
    return this.http.get(url);
  }

  getPaypalKey() {
    const url = environment.apiUrl + '/paypal/secret';
    return this.http.get(url);
  }
  getPaypalKeyDetails(City_Id) {
    const url = environment.apiUrl + '/paypal/secret?City_Id=' + City_Id;
    return this.http.get(url);
  }

  removePartner(fm_info) {
    let params = new HttpParams();
    params = params.append('partner_inv_id', fm_info.partner_inv_id);
    params = params.append('partner_id', fm_info.partner_id);
    params = params.append('email', fm_info.email);
    const url = environment.apiUrl + '/partners/admins/invitations';
    return this.http.delete(url, { params: params });
  }

  updateSlot(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('reservation_id', data.reservation_id);
    params = params.append('user_id', data.user_id);
    params = params.append('slot_id', data.slot_id);
    params = params.append('city_id', data.city_id);
    params = params.append('req_date', data.req_date);
    params = params.append('req_slot', data.req_slot);
    const url = environment.slotUrl + '/reservations/edit/requests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getPickedRefReqs(data) {
    let params = new HttpParams();
    let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    params = params.append('cityId', data.cityId);
    const options = { params: params, headers: headers };
    const url = environment.apiUrl + '/referee/pick/requests?city_id=' + data.cityId;
    return this.http.get(url);
  }
  getServiceSignupRequests(data) {
    let params = new HttpParams();
    let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    params = params.append('cityId', data.cityId);
    const options = { params: params, headers: headers };
    const url = environment.facilityUrl + '/services/signupRequests/getRequestedUser';
    return this.http.get(url);
  }

  assignReferee(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ref_ps_req_id', data.ref_ps_req_id);
    params = params.append('reservation_id', data.reservation_id);
    params = params.append('ref_id', data.ref_id);
    params = params.append('city_id', data.city_id);
    params = params.append('status', data.status);
    const url = environment.apiUrl + '/referee/pick/requests';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  saveInvoice(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Subscription_Bill_Id', data.Subscription_Bill_Id);
    params = params.append('Subscription_Id', data.Subscription_Id);
    params = params.append('Plan_Id', data.Plan_Id);
    params = params.append('Transaction_Id', data.Transaction_Id);
    params = params.append('Intent', data.Intent);
    params = params.append('Status', data.Status);
    params = params.append('Payer_Email', data.Payer_Email);
    params = params.append('Payer_Id', data.Payer_Id);
    params = params.append('Payer_Name', data.Payer_Name);
    params = params.append('Phone_Number', data.Phone_Number);
    params = params.append('Amount', data.Amount);
    params = params.append('Payee_Id', data.Payee_Id);
    params = params.append('Payee_Email', data.Payee_Email);
    params = params.append('Capture_Id', data.Capture_Id);
    params = params.append('Currency', data.Currency);
    params = params.append('Create_Time', data.Create_Time);
    params = params.append('Update_Time', data.Update_Time);
    const url = environment.apiUrl + '/admin/subscriptions/payments';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getInvoiceData(subscription_id, billId) {
    const url = environment.apiUrl + '/admin/subscriptions/payments/?Subscription_Id=' + subscription_id + '&Subscription_Bill_Id=' + billId;
    return this.http.get(url);
  }

  getSubscriptionBills(subscription_id) {
    const url = environment.apiUrl + '/admin/subscriptions/bills/?Subscription_Id=' + subscription_id;
    return this.http.get(url);
  }

  getSubscriptions() {
    const url = environment.apiUrl + '/admin/cityadmins/premium';
    return this.http.get(url);
  }

  upgradePlanDetails(data, subscription_id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Subscription_Id', subscription_id);
    params = params.append('Plan_Id', data.value);
    params = params.append('Parks_Limit', data.noofParks);
    params = params.append('Grounds_Per_Park', data.groundsperParks);
    params = params.append('Sports_Per_Ground', data.sportsperGround);
    const url = environment.apiUrl + '/admin/subscriptions/upgrade';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  updateBillPrice(bill_id, bill_price) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Subscription_Bill_Id', bill_id);
    params = params.append('Bill_Price', bill_price);
    const url = environment.apiUrl + '/admin/cityadmins/premium';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  subscrSixmthPlan(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Subscription_Id', data.Subscription_Id);
    params = params.append('Plan_Id', data.Plan_Id);
    params = params.append('Transaction_Id', data.Transaction_Id);
    params = params.append('Intent', data.Intent);
    params = params.append('Status', data.Status);
    params = params.append('Payer_Email', data.Payer_Email);
    params = params.append('Payer_Id', data.Payer_Id);
    params = params.append('Payer_Name', data.Payer_Name);
    params = params.append('Phone_Number', data.Phone_Number);
    params = params.append('Amount', data.Amount);
    params = params.append('Payee_Id', data.Payee_Id);
    params = params.append('Payee_Email', data.Payee_Email);
    params = params.append('Capture_Id', data.Capture_Id);
    params = params.append('Currency', data.Currency);
    params = params.append('Create_Time', data.Create_Time);
    params = params.append('Update_Time', data.Update_Time);
    const url = environment.apiUrl + '/admin/subscriptions/payments/advance';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  groundsList(park_id) {
    const url = environment.apiUrl + '/grounds/park?parkId=' + park_id;
    return this.http.get(url);
  }

  sportsList(park_id, ground_id) {
    const url = environment.apiUrl + '/sports/getbyparkandground?Park_Id=' + park_id + '&Ground_Id=' + ground_id;
    return this.http.get(url);
  }

  reqCustomSlot(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Park_Id', data.Park_Id);
    params = params.append('Ground_Id', data.Ground_Id);
    params = params.append('Sport_Id', data.Sport_Id);
    params = params.append('User_Id', data.User_Id);
    params = params.append('Date', data.Date);
    params = params.append('Slot', data.Slot);
    params = params.append('Custom_Price', data.Custom_Price);
    params = params.append('teamName', data.selectedTeams);
    params = params.append('Resident_Of', data.residentOf);


    // params = params.append('League_Name', data.leagueName);
    const url = environment.apiUrl + '/slots/customslots';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getAvilSlots(data, date, userId) {
    const url = environment.slotUrl + '/slots/slotsbyparksportandground?Park_Id=' + data.park + '&Sport_Id=' + data.sport + '&Ground_Id=' + data.ground + '&Date=' + date + '&user_id=' + userId;
    return this.http.get(url);
  }

  getNextavlSlot(data) {
    const url = environment.apiUrl + '/slots/getoneafterdate?Date=' + data.date + '&Sport_Id=' + data.sportId + '&Park_Id=' + data.parkId + '&Ground_Id=' + data.groundId + '&user_id=' + data.userId;
    return this.http.get(url);
  }

  getSlotsCountBySelectedPark(parkId) {
    const url = environment.apiUrl + '/slots/getcountbypark?Park_Id=' + parkId;
    return this.http.get(url);
  }

  getSlotsCountByAllParks(userId) {
    const url = environment.apiUrl + '/slots/getcountbyallparks?user_id=' + userId;
    return this.http.get(url);
  }

  getSlotsCountBySelectedSport(sportId) {
    const url = environment.apiUrl + '/slots/getcountbysport?Sport_Id=' + sportId;
    return this.http.get(url);
  }

  getCustomSlots(userId) {
    const url = environment.apiUrl + '/slots/customslots/getbyuser?User_Id=' + userId;
    return this.http.get(url);
  }

  updateCustomSlot(custom_slot_id) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Custom_Slot_Id', custom_slot_id);
    const url = environment.apiUrl + '/slots/customslots/reservepaidslots';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updatePhone(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('phoneNumber', data.phoneNumber);
    params = params.append('userId', data.userId);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = environment.apiUrl + '/users/changemobile';
    ;
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updateResidentOf(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Resident_Of', data.residentOf);
    params = params.append('User_Id', data.userId);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = environment.apiUrl + '/users/profile';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updateSportsPreferences(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Sports_Preferences', data.Sports_Preferences);
    params = params.append('User_Id', data.userId);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = environment.apiUrl + '/users/profile';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  public updatelocationPreferences(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Location_Id', data.location_Preferences);
    params = params.append('User_Id', data.userId);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = environment.apiUrl + '/users/profile';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public updateAddress(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Street', data.street);
    params = params.append('City', data.city);
    params = params.append('State', data.state);
    params = params.append('Zip_Code', data.zipcode);
    params = params.append('User_Id', data.userId);
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    const url = environment.apiUrl + '/users/profile';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  public filterReservations(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('from_date', data.from_date);
    params = params.append('to_date', data.to_date);
    params = params.append('city_id', data.city);
    params = params.append('park_id', data.park);
    params = params.append('ground_id', data.ground);
    params = params.append('sport_id', data.sport);
    params = params.append('status', data.status);
    params = params.append('user_id', data.user_id);
    params = params.append('sort_by', data.sortBy);
    params = params.append('location_preferences', data.location_preferences);
    let url = environment.slotUrl + "/slots?from_date=" + data.from_date + "&to_date=" + data.to_date + "&city_id=" + data.city + "&park_id=" + data.park + "&ground_id=" + data.ground + "&sport_id=" + data.sport + "&status=" + data.status + "&user_id=" + data.user_id + "&sort_by=" + data.sortBy + "&location=" + data.location + "&location_preferences=" + data.location_preferences;
    return this.http.get(url);

  }

  getSlotsCount(userId) {
    const url = environment.usersessionUrl + '/slots/slots/count?user_id=' + userId;
    return this.http.get(url);
  }

  saveBookingFlag(data) {
    let params = new HttpParams();
    params = params.append('userId', data.userId);
    params = params.append('phoneNumber', data.phoneNumber);
    params = params.append('slotId', data.slotId);
    params = params.append('slot', data.slot);
    params = params.append('repId', data.repId);
    params = params.append('repName', data.repName);
    params = params.append('slotFIFO', data.slotFIFO);
    params = params.append('slotStatus', data.slotStatus);
    params = params.append('reservationDate', data.reservationDate);
    params = params.append('cityId', data.cityId);
    params = params.append('groundId', data.groundId);
    params = params.append('sportId', data.sportId);
    params = params.append('createdAt', data.createdAt);
    params = params.append('firstName', data.firstName);
    params = params.append('lastName', data.lastName);
    params = params.append('email', data.email);
    params = params.append('teamName', data.teamName);
    params = params.append('residentOf', data.residentOf);
    params = params.append('stage', data.stage);
    params = params.append('authorizationId', data.authorizationId);
    params = params.append('paypalAmount', data.paypalAmount);
    params = params.append('orderId', data.orderId);
    params = params.append('cityDomain', sessionStorage.getItem('cityDomain'));
    params = params.append('Payment_Mode', data.paymentMode);
    params = params.append('External_Booking_Flag', data.External_Booking_Flag);
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.slotUrl + '/reservations';
    return this.http.post(url, '', { params: params, headers: headers });
  }

  claimResvReq(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Slot_Id', data.Slot_Id);
    params = params.append('First_Name', data.name);
    params = params.append('Last_Name', data.Last_Name);
    params = params.append('Email', data.email);
    params = params.append('Phone_Number', data.phone);
    params = params.append('User_Id', data.User_Id);
    params = params.append('Booking_Info', data.bookingInfo)
    const url = environment.usersessionUrl + '/facility/slots/claimRequests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getGroundsBasedonZipCode(zipcode, userId): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('zipcode', zipcode);
    params = params.append('User_Id', userId)
    const url = environment.facilityUrl + '/grounds/bylocation';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })

  }

  addGroundToPark(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Park_Id', data.Park_Id);
    params = params.append('User_Id', data.User_Id);
    params = params.append('City_Id', data.City_Id);
    params = params.append('Ground_Name', data.Ground_Name);
    params = params.append('Ground_Type', data.Ground_Type);
    params = params.append('sport', data.sport)
    const url = environment.facilityUrl + '/grounds/groundRequests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  addOnboardSlot(slot): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("City_Id", slot.City_Id);
    params = params.append("Park_Id", slot.Park_Id);
    params = params.append("Ground_Id", slot.Ground_Id);
    params = params.append("Sport_Id", slot.Sport_Id);
    params = params.append("Slot", slot.Slot);
    params = params.append("Date", slot.Date);
    params = params.append("User_Id", slot.User_Id);
    params = params.append("onBoardSlotComments", slot.onBoardSlotComments);
    params = params.append("RequestliveStreaming", slot.RequestliveStreaming);
    params = params.append("Requested_Quals", JSON.stringify(slot.Requested_Quals));
    params = params.append("Service_Ids", JSON.stringify(slot.Service_Ids));
    params = params.append('Preferred_Price', slot.preferred_price);
    params = params.append('Fixed_Pay', slot.fixedPay);
    params = params.append('selectedTeams', slot.selectedTeams);
    params = params.append('Extra_Recipient', slot.Extra_Recipient);
    params = params.append('Resident_Of', slot.residentOf);

    // params = params.append('League_Name', slot.leagueName);
    const url = environment.slotUrl + "/slots/onboard";
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers })
  }

  getOnboardSlots(userid): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("User_Id", userid);
    const url = environment.slotUrl + "/slots/onboard";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getOnboardSlotsForAdmin(cityid): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("City_Id", cityid);
    const url = environment.slotUrl + "/slots/onboard";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  acceptOrDeclineOnboardSlotRequest(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Status', data.Status);
    params = params.append('SlotRequest_Id', data.SlotRequest_Id);
    params = params.append('Ground_Id', data.Ground_Id);
    params = params.append('Park_Id', data.Park_Id);
    params = params.append('Reservation_Id', data.Reservation_Id);
    params = params.append('Slot_Id', data.Slot_Id)
    const url = environment.facilityUrl + '/slots/onboard/process';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });

  }

  acceptOrDeclineStreamingRequest(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Slot_Id', data.Slot_Id)
    params = params.append('SlotRequest_Id', data.SlotRequest_Id);
    params = params.append('Streaming_Url', data.Streaming_Url);
    params = params.append('Streaming_Status', data.Streaming_Status);
    const url = environment.facilityUrl + '/slots/onboard/process';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });

  }

  addParkRequest(data): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Park_Name', data.Park_Name);
    params = params.append('Street', data.Street);
    params = params.append('City', data.City);
    params = params.append('State', data.State);
    params = params.append('Zip_Code', data.Zip_Code);
    // params = params.append('City_Id',data.City_Id);
    params = params.append('City_Id', environment.cityId);
    params = params.append('User_Id', data.User_Id)
    const url = environment.facilityUrl + '/parks/parkRequests';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers })

  }

  getCitiesByLocation(zipcode): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('zipcode', zipcode)
    const url = environment.facilityUrl + "/cities/bylocation";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getGroundsByPark(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', data.User_Id);
    params = params.append('Park_Id', data.Park_Id)
    const url = environment.facilityUrl + "/grounds/bypark";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getTotalParks(userid): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', userid);
    const url = environment.facilityUrl + "/parks/get";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getParksBasedOnLocation(zipCode) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('zipcode', zipCode);
    const url = environment.facilityUrl + "/parks/bylocation";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getParksByCity(city) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('city', city);
    const url = environment.facilityUrl + "/parks/bylocation";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }
  getParksByLocation(locations) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Location_Preferences', locations);
    const url = environment.facilityUrl + "/parks/bylocationpreferences";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  bidSrvcRequest(data) {
    let youtube = data.YoutubeCheck == true ? '1' : '0'
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ServiceProvider_Id', data.Service_Provider_Id);
    params = params.append('Service_Request_Id', data.Service_Request_Id);
    params = params.append('Bid_Amount', data.Bid_Amount);
    params = params.append('Comments', data.Comments);

    params = params.append('Channel_ID', data.Channel_Id);
    params = params.append('Channel_Stream_Url', data.Channel_Url);
    params = params.append('Stream_Key', data.Stream_Key);
    params = params.append('Secondary_Stream_Key', data.Secondary_Stream_Key);
    params = params.append('Secondary_Stream_Url', data.Secondary_Stream_Url);
    params = params.append('Stream_Creation', youtube);
    params = params.append('Extra_Recipient', data.Extra_Recipient);




    const url = environment.slotUrl + '/slots/serviceRequests/bids';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  updateBidLiveStream(data) {
    let youtube = data.YoutubeCheck == true ? '1' : '0'
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ServiceProvider_Id', data.Service_Provider_Id);
    params = params.append('Service_Request_Id', data.Service_Request_Id);
    params = params.append('Slot_Id', data.Slot_Id);
    params = params.append('Channel_ID', data.Channel_Id);
    params = params.append('Channel_Stream_Url', data.Channel_Url);
    params = params.append('Stream_Key', data.Stream_Key);
    params = params.append('Stream_Creation', youtube);
    params = params.append('Secondary_Stream_Key', data.Secondary_Stream_Key);
    params = params.append('Secondary_Stream_Url', data.Secondary_Stream_Url);

    const url = environment.slotUrl + '/slots/serviceRequests/bids/edit';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  serviceRequestBidDetails(serviceRequestId, userId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Request_Id', serviceRequestId);
    params = params.append('User_Id', userId);
    const url = environment.slotUrl + '/slots/serviceRequests/bidDetails';
    return this.http.get(url, { params: params });
  }

  serviceRequestBidAcceptedDetails(serviceRequestId, userId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Request_Id', serviceRequestId);
    params = params.append('User_Id', userId);
    params = params.append('Accepted', 'true');
    const url = environment.slotUrl + '/slots/serviceRequests/bidDetails';
    return this.http.get(url, { params: params });
  }

  acceptServiceRequestBid(bid) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ServiceProvider_Id', bid.ServiceProvider_Id);
    params = params.append('Service_Request_Id', bid.Service_Request_Id);
    params = params.append('User_Id', bid.User_Id);
    params = params.append('Slot_Id', bid.Slot_Id);
    params = params.append('Channel_ID', bid.Channel_ID);
    params = params.append('Stream_Creation', bid.Stream_Creation);
    params = params.append('Stream_Key', bid.Stream_Key);
    params = params.append('Channel_Stream_Url', bid.Channel_Stream_Url);
    params = params.append('Service_Id', bid.Service_Id);
    const url = environment.slotUrl + '/slots/serviceRequests/bids/accept';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  rejectServiceRequestBid(bid) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ServiceProvider_Id', bid.ServiceProvider_Id);
    params = params.append('Service_Request_Id', bid.Service_Request_Id);
    const url = environment.slotUrl + '/slots/serviceRequests/bids/decline';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  accepStreamtServiceRequestBid(bid) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('ServiceProvider_Id', bid.ServiceProvider_Id);
    params = params.append('Service_Request_Id', bid.Service_Request_Id);
    params = params.append('User_Id', bid.User_Id);
    params = params.append('Slot_Id', bid.Slot_Id);
    params = params.append('Channel_ID', bid.Channel_ID);
    params = params.append('Stream_Creation', bid.Stream_Creation);
    params = params.append('Stream_Key', bid.Stream_Key);
    params = params.append('Channel_Stream_Url', bid.Channel_Stream_Url);
    params = params.append('Service_Id', bid.Service_Id);
    params = params.append('Secondary_Stream_Url', bid.Secondary_Stream_Url);
    params = params.append('Secondary_Stream_Key', bid.Secondary_Stream_Key);
    params = params.append('User_Email', bid.user_email);
    const url = environment.streamUrl + "/updateConfigFile";
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  getSportQuals(sportId, serviceId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Sport_Id', sportId);
    params = params.append('Service_Id', serviceId);
    const url = environment.slotUrl + "/slots/serviceSport/quals";
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }



  async uploadLogo(data, imageFile) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.streamUrl}/image/url?user_id=${data.userId}&slot_id=${data.slotId}&width=${data.width}&height=${data.height}&x=${data.x}&y=${data.y}`;

    const getResponse = await this.http.get<any>(url, { observe: 'response', headers: headers }).toPromise();
    const preSignedUrl = getResponse.body.signed_url;

    headers = new HttpHeaders().set('Content-Type', imageFile.type).set('Content-Disposition', 'attachment');
    const putResponse = await this.http.put<any>(preSignedUrl, imageFile, { headers: headers }).toPromise();

    return putResponse;
  }
  async uploadAd(data, videoFile) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${environment.streamUrl}/mp4/url?user_id=${data.userId}&slot_id=${data.slotId}`;

    const getResponse = await this.http.get<any>(url, { observe: 'response', headers: headers }).toPromise();
    const preSignedUrl = getResponse.body.signed_url;

    headers = new HttpHeaders().set('Content-Type', videoFile.type).set('Content-Disposition', 'attachment');
    const putResponse = await this.http.put<any>(preSignedUrl, videoFile, { headers: headers }).toPromise();

    return putResponse;
  }


  getServiceBySport(sport) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Sport_Id', sport);
    const url = environment.slotUrl + '/slots/service/getBySport';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getQualsBySportAndService(service, sport) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', service);
    params = params.append('Sport_Id', sport);
    const url = environment.slotUrl + '/slots/serviceSport/quals';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  serviceProviderSignup(sport, service, quals, location, user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Sport_Id', sport);
    params = params.append('Service_Id', service);
    params = params.append('Quals', JSON.stringify(quals));
    params = params.append('Location', location);
    params = params.append('User_Id', user_id);
    const url = environment.newapiUrl + '/user/serviceProvider';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  userProvidedServiceDetails(user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', user_id);
    const url = environment.newapiUrl + '/user/serviceProvider';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  removeuserProvidedServiceDetails(service_id, sport_id, userId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', userId);
    params = params.append('Service_Id', service_id);
    params = params.append('Sport_Id', sport_id);
    const url = environment.newapiUrl + '/user/serviceProvider';
    return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
  }

  getServiceSportQuals(serviceId, sportId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', serviceId);
    params = params.append('Sport_Id', sportId);
    const url = environment.slotUrl + '/slots/service/quals/getBySport';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  addServiceSportQuals(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', data.serviceId);
    params = params.append('Sport_Id', data.sportId);
    params = params.append('Quals', JSON.stringify(data.quals));
    const url = environment.slotUrl + "/slots/serviceSport/quals";
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  addServiceComments(data) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', data.serviceId);
    params = params.append('Comments', JSON.stringify(data.comments));
    const url = environment.slotUrl + "/slots/serviceRequests/serviceComments";
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  getServiceCommentsAndRanting(serviceId, User_Id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', serviceId);
    params = params.append('User_Id', User_Id);
    const url = environment.slotUrl + '/slots/serviceRequests/serviceComments';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }
  getchannels() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.streamUrl + '/channeldetails';
    return this.http.get<any>(url, { observe: 'response', headers: headers })
  }

  getServiceComments(serviceId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Id', serviceId);
    const url = environment.slotUrl + '/slots/serviceRequests/serviceComments';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  getPlansBySportAndService(service, sport, userId) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('service_id', service);
    params = params.append('sport_id', sport);
    params = params.append('User_Id', userId);
    const url = environment.slotUrl + '/slots/serviceSubscriptions/get';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  onPurchase(subscription_id, userId, totalPrice, noOfGames, logoCount, videoCount, serviceId, addonFlag) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('User_Id', userId);
    params = params.append('Subscription_Id', subscription_id);
    params = params.append('Subscription_Amount', totalPrice);
    params = params.append('Subscribed_Match_Count', noOfGames);
    params = params.append('Subscribed_Logo_Count', logoCount);
    params = params.append('Subscribed_Video_Count', videoCount);
    params = params.append('Service_Id', serviceId);
    params = params.append('Addon_Flag', addonFlag);




    const url = environment.slotUrl + '/slots/serviceSubscriptions/create';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }



  getUserSubscriptionDetails(user_id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id', user_id);
    const url = environment.slotUrl + '/slots/serviceSubscriptions/getByUser';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers })
  }

  removeSubscription(Subscriber_Id) {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Subscriber_Id', Subscriber_Id);
    const url = environment.slotUrl + '/slots/serviceSubscriptions/deleteByUser';
    return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
  }

  getSubscriptionRequests() {
    const url = environment.slotUrl + '/slots/serviceSubscriptions/getByAdmin';
    return this.http.get(url);
  }

  public denySubscriptionRequest(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Subscriber_Id', data.Subscriber_Id);
    params = params.append('operation', "delete");
    // console.log('Params to assign slot random:', params);
    // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    // let headers = new HttpHeaders();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.slotUrl + '/slots/serviceSubscriptions/updateSubscriptionRequest';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }
  public acceptSubscriptionRequest(data): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.append('Subscriber_Id', data.Subscriber_Id);
    params = params.append('operation', "update");
    // console.log('Params to assign slot random:', params);
    // let headers = new HttpHeaders().set('authorizationtoken', data.authorizationToken);
    // let headers = new HttpHeaders();
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.slotUrl + '/slots/serviceSubscriptions/updateSubscriptionRequest';
    return this.http.put<any>(url, '', { observe: 'response', params: params, headers: headers });
  }

  saveSurveyFeedback(fb, rating, serviceRequestId, reviewerId) {

    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Comments', fb.suggestion);
    params = params.append('Reviewer_Id', reviewerId);
    params = params.append('Service_Request_Id', serviceRequestId);
    params = params.append('Rating', rating);
    const url = environment.slotUrl + '/slots/serviceRequests/createFeedback';
    return this.http.post<any>(url, '', { observe: 'response', params: params, headers: headers });
  }


  DeleteServiceRequest(data) {
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('Service_Request_Id', data.cancelServiceReqId);
    // params = params.append('city_id', data.city_id);
    const url = environment.slotUrl + '/slots/serviceRequests/cancel';
    return this.http.delete<any>(url, { observe: 'response', params: params, headers: headers });
  }
  getFutureMyServiceRequests(user_id, type) {
    const url = environment.slotUrl + '/slots/serviceRequests/byProvider?User_Id=' + user_id + '&Service_Id=' + null + '&myRequests=true' + '&type=' + type;
    return this.http.get(url);
  }

  getUpcomingLive(){
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = environment.slotUrl + '/slots/getLive'
    return this.http.get<any>(url, { observe: 'response', headers: headers })
  }
}


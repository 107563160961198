
import {Component, OnInit} from '@angular/core';
import {TabMenuModule} from 'primeng/tabmenu';
import {ConfirmationService, MenuItem} from 'primeng/api';
import {FileUploadModule} from 'primeng/fileupload';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {UserRegistrationService} from '../../service/user-registration.service';
import {CognitoCallback} from '../../service/cognito.service';
import {Router} from '@angular/router';
import {UserLoginService} from '../../service/user-login.service';
import {CitiesService} from '../../service/cities.service';
import { MessageService } from 'primeng/components/common/messageservice';
import {Message} from 'primeng/components/common/api';
import { environment } from '../../../environments/environment';
declare let $;


export class NewPasswordUser {
    accessToken: string;
    username: string;
    existingPassword: string;
    newPassword: string;
    password: string;
}


@Component({
    selector: 'app-account-preferences',
    templateUrl: './account-preferences.component.html',
})
export class AccountPreferencesComponent implements CognitoCallback, OnInit {

    items: MenuItem[];
    activeItem;
    public selectedTab = 'System Preferences';
    newUser: NewPasswordUser;
    router: Router;
    files: any[] = [];
    imageResult;
    show_edit;
    sfee_edit;
    loadingBar = false;
    tax_value;
    city_tax;
    city_service_fee;
    error = false;
    unauthorized = false;
    errorMessage: string;
    public userFormData: FormGroup;
    public userLogoData: FormGroup;
    public paypalData: FormGroup;
    public checkValidation = false;
    public logoValidation = false;
    msgs: Message[] = [];
    userType;
    change_pwdblk;
    change_tax;
    logo_edit;
    paypal_edit;
    message:string;
    clientId;
    clientSecret;
    show_clientId:boolean=false;
    show_clientSecret:boolean=false;
    cityId = sessionStorage.getItem('cityId');
    environmentDetails = environment



    constructor(public userRegistration: UserRegistrationService,
                public userService: UserLoginService, router: Router,
                public citiesService: CitiesService,
                public messageService: MessageService) {
        this.router = router;
        this.userFormData = new FormGroup({
            'currentPassword': new FormControl('', Validators.required),
            'newPassword': new FormControl('', Validators.required),
            'password': new FormControl('', Validators.required),
            'tax': new FormControl('', Validators.required),
            'serviceFee': new FormControl('', Validators.required),
        });

        this.userLogoData = new FormGroup({
            'logo': new FormControl('', Validators.required),
        });

        this.paypalData = new FormGroup({
          'clientId': new FormControl('', Validators.required),
          'clientSecret': new FormControl('', Validators.required)
        })


    }

    ngOnInit() {
        this.newUser = new NewPasswordUser();
        this.getCityTax();
        this.items = [
            {
                label: 'System Preferences', icon: 'fa fa-cog', command: (click) => {
                    this.selectedTab = 'System Preferences';
                    console.log('Selected tab:', this.selectedTab);
                }
            },
            {
                label: 'User Preferences', icon: 'fa fa-user', command: (click) => {
                    this.selectedTab = 'User Preferences';
                    console.log('Selected tab:', this.selectedTab);
                    this.userLogoData.reset();
                }
            }
        ];
        this.activeItem = this.items[0];
        // console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
        // this.userService.isAuthenticated(this);
        console.log('user type::', localStorage.cognitoGroup)
        this.userType = localStorage.cognitoGroup;
        $('.side_nav li.active').removeClass('active');
        this.citiesService.currentMessage.subscribe(res => {
          console.log('paypalRes::', res);
          this.clientId = res['clientId'];
          this.clientSecret = res['clientSecret'];
        })
    }

    onPaypalconfigChange() {
        this.loadingBar = true;
    console.log('PayPal config Data::', this.paypalData.value);
    let params = {
        'clientId': this.paypalData.value.clientId,
        'secret': this.paypalData.value.clientSecret,
        'cityId': sessionStorage.getItem('cityId'),
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
    };
    console.log('change password params: ', params);
    this.citiesService.updatePayPal(params).subscribe(response => {
        this.loadingBar = false;

        if (response.status === 200) {
            this.messageService.add({
                severity: 'success',
                summary: 'PayPal credentials updated!',
                detail: 'Now you can process paid slots.'
            });
        }
    }, err => { this.errorHandle(err) });
    }

    paypalIdToggle(){
     if(this.show_clientId==true){
      this.show_clientId=false;   
     }   
     else{
       this.show_clientId=true;   
     }
    }

    paypalSecretToggle(){
     if(this.show_clientSecret==true){
      this.show_clientSecret=false;   
     }   
     else{
       this.show_clientSecret=true;   
     }
    }

    ngOnDestroy() {
      $('.side_nav li:first-child').addClass('active');
    }

    getActiveItem(Item) {
    }

    onPasswordChange() {
        if ((this.newUser.existingPassword !== undefined) && (this.newUser.newPassword !== undefined) && (this.newUser.password !== undefined) && (this.newUser.existingPassword !== null) && (this.newUser.newPassword !== null) && (this.newUser.password !== null)) {
            this.checkValidation = false;
            document.getElementById('confirm-submit').click();
        } else {
            this.checkValidation = true;
        }
        // this.resetForm();
    }

    confirmPasswordChange() {
        // console.log(this.newUser);
        let params = {
            'PreviousPassword': this.newUser.existingPassword,
            'ProposedPassword': this.newUser.password,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'AccessToken': sessionStorage.getItem('accessToken')
        };
        // password change call to cognito sdk
        console.log('change password params: ', params);
        // this.userRegistration.changePassword(this.newUser, this);
        this.userRegistration.changePassword(params).subscribe(response => {

            console.log('Change password response:', response);
            if (response.status === 200) {
                console.log('else 200 case');
                this.messageService.add({
                    severity: 'success',
                    summary: 'Password successfully updated!',
                    detail: 'Please use the new password to login next time.'
                });
            } else {
                console.log('not 200 case', response.status);
                if (response.body !== undefined && response.body !== null ) {
                this.errorMessage = response.body.message;
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Password Update Failed!',
                        detail: 'An unexpected error occured, Unable to submit request at this time, Please try again later.'
                    });
                }

            }
            this.change_pwdblk = false;
            this.loadingBar = false;
        }, err => { this.errorHandle(err) });


        this.userFormData.reset();
    }

    resetForm() {
        this.newUser.existingPassword = '';
        this.newUser.newPassword = '';
        this.newUser.password = '';
        this.tax_value = '';
    }

    changeListener($event): void {
        this.readThis($event.target);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) { // error
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
        } else { // success
            // move to the next step
            console.log('redirecting to logout');
            // sessionStorage.setItem('firstPassword', 'true');
            alert('Account is activated successfully, please login with new password to continue!');
            // this.router.navigate(['/admin/logout']);
        }
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            // sessionStorage.setItem('firstPassword', 'true');
            // if (localStorage.getItem('superAdmin') === 'true') {
            alert('Account is activated successfully, please login with new password to continue!');
            this.router.navigate(['/admin/logout']);
            // } else {this.router.navigate(['/admin/logout']); }
        }
    }

    readThis(inputValue: any): void {
        let file: File = inputValue.files[0];
        let myReader: FileReader = new FileReader();

        myReader.onloadend = (e) => {
            this.imageResult = myReader.result;
        };
        myReader.readAsDataURL(file);
    }

    submitUpload() {
        this.loadingBar = true;
        if (this.imageResult !== undefined && this.imageResult !== '') {
            console.log('converted base64 image::', this.imageResult);
            let cityId = sessionStorage.getItem('cityId');
            let image_base64 = this.imageResult.split('base64,')[1];
            console.log('upload image params:', cityId, image_base64);
            this.citiesService.uploadLogo(image_base64, cityId).subscribe(response => {
                console.log('response for upload logo: ', response);
                this.messageService.add({
                    severity: 'success',
                    summary: 'City logo successfully uploaded!',
                    detail: 'Please refresh the application to reflect updated logo.'
                });
                // alert('City logo successfully uploaded, Please refresh to reflect changes.');
                this.loadingBar = false;
            }, err => { this.errorHandle(err) });

            setTimeout(function () {
                this.loadingBar = false;
                this.logoValidation = false;
            }.bind(this), 10000);
            // document.getElementById('addslot').click();
            this.imageResult = '';
        } else {
            this.logoValidation = true;
            this.loadingBar = false;
            this.userLogoData.reset();
        }
    }

    getCityTax() {
        // let cityDomain = sessionStorage.getItem('cityDomain');
        var params = sessionStorage.getItem('cityId');
        this.citiesService.getCityDetails(params).subscribe(response => {

            console.log('Get city tax response:', response.body[0].Tax_Percentage);
            this.city_tax = response.body[0].Tax_Percentage;
            this.city_service_fee = response.body[0].Service_Fee;
        }, err => { this.errorHandle(err) });

    }

    onTaxEdit() {
        this.logo_edit=false;
        this.paypal_edit=false;
        this.sfee_edit = false;
        if(this.show_edit==true){
        this.show_edit = false;
        }
        else{
        this.show_edit = true;
        }
        this.getCityTax();
    }

    onSfeeEdit() {
        this.logo_edit=false;
        this.paypal_edit=false;
        this.show_edit = false;
        this.sfee_edit = !this.sfee_edit;
        this.getCityTax();
    }

    onLogoEdit() {
        // this.userFormData.reset();
        this.imageResult='';
        this.logoValidation = false;
        this.show_edit = false;
        this.paypal_edit=false;
        this.sfee_edit = false;
        if(this.logo_edit==true){
          this.logo_edit=false;
        }
        else{
          this.logo_edit=true;
        }
    }

    onPaypalEdit(){
        this.show_clientId=false;
        this.show_clientSecret=false;

        var params = sessionStorage.getItem('cityId');
        // var params=sessionStorage.getItem('accessToken').split('/biz417bend180qaztriptransflightresponse/')[0]
        this.citiesService.getCityDetails(params).subscribe(response => {
            console.log(response)
            this.clientId=response.body[0]['PayPal'],
            this.clientSecret=response.body[0]['PayPal_Secret']  
        })
        
       this.show_edit=false;
       this.logo_edit=false;
        this.sfee_edit = false;
      if(this.paypal_edit==true){
       this.paypal_edit=false;
      }
      else{
       this.paypal_edit=true;   
      }
    }

    activeTab() {
      this.show_edit = false;
      this.change_pwdblk=false;		
      this.sfee_edit = false;
      this.paypal_edit=false;		
      this.logo_edit=false;
    }

    editPassword(){
     if(this.change_pwdblk==true){
      this.change_pwdblk=false; 
     }
     else{
      this.change_pwdblk=true; 
     }

    }

    editTax(){
      this.change_tax=true;   
    }

    onSaveTax(tax) {
        this.tax_value = tax;
        if (this.tax_value !== undefined && this.tax_value !== null) {
            this.show_edit = false;
            document.getElementById('confirm-save').click();
            this.checkValidation = false;

        } else {
            this.checkValidation = true;
        }
    }

    onSaveSfee(city_service_fee) {
        this.loadingBar = true;
        //alert('City tax successfully updated!');
        const params = {
            'City_Id': sessionStorage.getItem('cityId'),
            'Service_Fee': city_service_fee
        }
        this.citiesService.updateServiceFee( params ).subscribe(response => {
            console.log('response for updating tax: ', response);
            this.getCityTax();
            this.messageService.add({
                severity: 'success',
                summary: 'Service fee successfully updated!'
            });
            this.loadingBar = false;
            setTimeout(() => {
                this.clearMessages();
            }, 5000);
        }, err => { this.errorHandle(err) });
    }

    confirmtaxCancel() {
        this.show_edit = true;
    }

    confirmtaxSave() {
        this.loadingBar = true;
        this.userFormData.reset();
        setTimeout(function () {
        $('#tax_val').val('');
        }.bind(this), 0);
        console.log('data::', this.tax_value);
        const params = {
            'tax': this.tax_value,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        this.citiesService.updateTax( params ).subscribe(response => {
            console.log('response for updating tax: ', response);
            this.getCityTax();
            this.messageService.add({
                severity: 'success',
                summary: 'City tax successfully updated!',
                detail: 'Please refresh the application to reflect updated tax.'
            });
            this.loadingBar = false;
            //alert('City tax successfully updated!');
            setTimeout(() => {
            this.clearMessages();
            }, 5000);
        }, err => { this.errorHandle(err) });
    }

    oncancel() {
        this.show_edit = false;
        document.getElementById('cancel-save').click();
    }

    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        } else {
            this.loadingBar = false;
            this.error = true;
            // this.router.navigate(['/admin/error']);
        }
    }

    clearMessages() {
    this.msgs = [];
    }

}
import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';



@Component({
  selector: 'app-service-signups',
  templateUrl: './service-signups.component.html',
  styleUrls: ['./service-signups.component.css']
})
export class ServiceSignupsComponent implements OnInit {
  provider1=[];
  picked_ref_reqs:any=[];
  slotcitySearch:string="";
  slotparkSearch:string="";
  slotgroundSearch:string="";
  slotsportSearch:string="";
  slotstatusSearch:string="";
  loadingBar: boolean = false;
  isPagination: boolean = true;
  error:any;
  unauthorized:any;
  selectedReferee: any = "";
  city_id: any;
  request:any;
  type:any;
  declineReason:any;
  slotPrice:any;

  constructor(
    public homeservice: HomeService,
    private messageService: MessageService
    
  ) { }


  ngOnInit() {
    this.getServiceSignupRequests();
  }

  getServiceSignupRequests(){
    this.loadingBar=true;
      const params = {
        'cityId': sessionStorage.getItem('cityId'),
        'authorizationToken': sessionStorage.getItem('authorizationToken')
    };
      this.homeservice.getServiceSignupRequests(params).subscribe((res: any) => {
        this.loadingBar=false;
        console.log("service request")
        this.provider1=res;
        console.log(this.provider1)
      })
      console.log("hello")
      console.log(this.picked_ref_reqs)
   }

   onAcceptReq(request,type){
    console.log("button click")
    this.request = request;
    this.type = type;
      if(type === 'rejected'){
        this.homeservice.denyServiceSignupRequest(request).subscribe((res: any) => {
          this.loadingBar=false; 
          this.messageService.add({ severity: 'success', summary: 'Denied', detail: res.body.message });
          console.log(res.msg)
          // console.log(res)
          this.getServiceSignupRequests();
        })
      }
      else{
        this.homeservice.acceptServiceSignupRequest(request).subscribe((res: any) => {
          this.loadingBar=false; 
          this.messageService.add({ severity: 'success', summary:  'Approved', detail: res.body.message });
          console.log(res.body.message)
          // console.log(res)
          this.getServiceSignupRequests()
        })
      }
    }
  

}

import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import { MessageService } from 'primeng/components/common/messageservice';



@Component({
  selector: 'app-service-subscriptions',
  templateUrl: './service-subscriptions.component.html',
})
export class ServiceSubscriptionsComponent implements OnInit {
  subscriptionRequests=[];
  loadingBar: boolean = false;
  request:any;
  type:any;
  error:any;
  unauthorized:any;



  constructor(
    public homeservice: HomeService,
    private messageService: MessageService
  ) { 
    
  }

  ngOnInit() {
    this.getSubscriptionRequests();
  }

  getSubscriptionRequests(){
    this.loadingBar=true;
      this.homeservice.getSubscriptionRequests().subscribe((res: any) => {
        this.loadingBar=false;
        this.subscriptionRequests=res;
        console.log(this.subscriptionRequests)
      })

      
   }

   onAcceptReq(request,type){
    console.log("button click")
    this.request = request;
    this.type = type;
      if(type === 'rejected'){
        this.homeservice.denySubscriptionRequest(request).subscribe((res: any) => {
          this.loadingBar=false; 
          this.messageService.add({ severity: 'success', summary: 'Denied', detail: res.body.message });
          console.log(res.msg)
          // console.log(res)
          this.getSubscriptionRequests();
        })
      }
      else{
        this.homeservice.acceptSubscriptionRequest(request).subscribe((res: any) => {
          this.loadingBar=false; 
          this.messageService.add({ severity: 'success', summary:  'Approved', detail: res.body.message });
          console.log(res.body.message)
          // console.log(res)
          this.getSubscriptionRequests()
        })
      }
    }


}

import { environment } from "./../../../environments/environment";
import { Component, HostListener, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import {SidebarModule} from 'primeng/sidebar';
import { HomeService } from "../../service/home.service";
import { Sport } from "../../models/sport";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
declare let $;
declare var google;
import { CitiesService } from './../../service/cities.service';
import { UserLoginService } from '../../service/user-login.service';
import { SlotsService } from '../../service/slots.service';

var popupTools = require('popup-tools');
import * as jwt_decode from 'jwt-decode';
import { AdsenseModule } from 'ng2-adsense';
import { } from '@types/googlemaps';
import * as moment from 'moment-timezone';
import { Moment } from 'moment';
import { DragulaService } from 'ng2-dragula/ng2-dragula';
import {
  SwiperConfigInterface
} from 'ngx-swiper-wrapper';
import { MessageService } from 'primeng/components/common/messageservice';
import {SafeResourceUrl,DomSanitizer} from '@angular/platform-browser'
declare var google;
var AWS = require('aws-sdk');
import { ParksService } from '../../service/parks.service';
import { addExtraRecipientCommon, removeEmailCommon } from "../dashboard/dashboard.component";
import { MatDialog, MatDialogConfig } from "@angular/material";
import { AddNewMembershipDialogComponent } from "../custom-slots/add-new-membership-dialog/add-new-membership-dialog.component";
import { UpgrageToPremiumDialogComponent } from "../premium-dialog/premium-dialog.component";
import { MembershipService } from "../../service/membership.service";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.css"],
})
export class LandingComponent implements OnInit {
  safeUrl;
  public moment: any = moment;
  public sportsList = [];
  public sportsListPreferred = [];
  public parksList = [];
  public resParksList = [];
  recipient_form :FormGroup;
  // recipient_emails : AbstractControl[] = [];
  recipient_emails: FormControl[] = [];
  // recipient_emails = ["test1@nomail.com", "test2@normalize.com"]
  loadingBar = false;
  slot_id: any;
  sportsSuggestions: any;
  cityform: FormGroup;
  distanceData: any;
  distanceArray = [];
  searchPlace;
  searchPlace1;
  nearestParksData;
  citiesInfo = [];
  loadParks = true;
  show_allSports = false;
  sports_details = false;
  feedback = false;
  reportIssue = false;
  sport;
  user = "false";
  superAdmin = "false";
  cityAdmin = "false";
  errorMessage: string;
  email: string;
  urlType: string;
  loading_spinner = false;
  no_data = false;
  park_slotsDetails = false;
  updateLocation: boolean = false;
  asc: boolean = true;
  current_location: any;
  futureInitCities: any[] = [];
  futureInitGrounds: any[] = [];
  futureInitSports: any[] = [];
  futureInitReservations: any[] = [];
  futureReservations: any[] = [];
  futureSessionRes: any[] = [];
  myServiceReqRes: any
  pastReservations = [];
  selectedPark: any;
  slotChangeForm: FormGroup;
  parkName: any;
  userId: any;
  userName: any;
  groundName: any;
  sportName: any;
  selectedDate;
  time1: any;
  time2: any;
  meridian = true;
  inviteFormSubmitted: boolean = false;
  inviteForm: FormGroup;
  feedbackForm: FormGroup;
  fbckFormSubmitted: boolean = false;
  refereeRequests: any[] = [];
  parkSearch: string = "";
  groundSearch: string = "";
  sportSearch: string = "";
  slotdatelocation: string = "date";
  slotparkSearch: string = "all";
  slotgroundSearch: string = "all";
  slotsportSearch: string = "all";
  pastparkSearch: string = "";
  pastgroundSearch: string = "";
  pastsportSearch: string = "";
  rparkSearch: string = "";
  rgroundSearch: string = "";
  rsportSearch: string = "";
  rstatusSearch: string = "Pending";
  // statusSearch:string="Pending";
  citySearch: string = "";
  slotstatusSearch: string = "";
  slotcitySearch: string = "all";
  slotLocationSearch: any[] = [];
  paststatusSearch: string = "Pending";
  pastcitySearch: string = "";
  rcitySearch: string = "";
  fromDateSearch: any;
  toDateSearch: any;
  draggableItems = [
    // "onboard_slots_grounds",
    "my_reservations",
    "book_slots_now",
    // "allotef_for_me",
    // "referee_requests",
    "my_teams",
  ];
  support_requests: boolean = false;
  public config: SwiperConfigInterface = {
    slidesPerView: "auto",
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };
  // public sportsList = [];
  public locationList = [];
  sportpref:any[]=[];
  locationpref:any[]=[];
  current_sports_preferences:any;
  current_location_preferences:any;
  refereedSports: any[];
  service_provider_status: any;
  ref_id: any = null;
  resv_id: any = null;
  cancelSlotId: any;
  cityId: any;
  reason: string = "";
  feedback_url: any;
  report_url: any;
  searchSport: any;
  searchPark: any;
  isDelSlot: boolean = false;
  edit_slot_id: any;
  old_slot: any;
  customSlotForm: FormGroup;
  groundsList: any[] = [];
  sportsListDD: any[] = [];
  custSlotFormsbmt: boolean = false;
  slot_duration: any;
  toal_price: any;
  resv_slots: any[] = [];
  unresv_slots: any[] = [];
  slotStatus: string = "all";
  listview: boolean = true;
  gridview: boolean = false;
  availableSlots: any;
  bookedSlots: any;
  informationSlots: any;
  publishedSlots: any;

  perPageGrid: number;
  perPageTable: number;
  currentPageItem: number;
  teamCurrentPageItem: number;
  no_slots_msg: string;
  date_range: any[] = [];
  next_slots: any[] = [];

  minDate: any;
  cityDdChanged: boolean = false;
  LocationDdChanged: boolean = false;
  parkDdChanged: boolean = false;
  groundDdChanged: boolean = false;
  sportDdChanged: boolean = false;

  myResvGrid: boolean = true;
  myServGrid: boolean = true;
  myResvcurrentPage: number;
  myServicevcurrentPage:number
  pastResvcurrentPage: number;
  showPastResv: boolean = false;

  teamsGrid: boolean = false;
  teams: any[] = [];
  selectedTeam: any;
  teamForm: FormGroup;
  teamFormSubmitted: boolean = false;
  invitedTeam: any;
  inviteErrRes: boolean = false;
  inviteErrResMsg: any = "";
  inviteErrClass: string;
  editTeamForm: FormGroup;
  infoSlotReqForm: FormGroup;
  teamMembers: any[] = [];
  teamcardsmembers: any[] = [];
  removed_members: any[] = [];
  invite_option: boolean = false;
  teamsCurrentPage: number;
  informationSlot: any;
  infoReqFormSubmit: boolean = false;
  locationPreferrenceSet: boolean = false;
  sportPreferrenceSet: boolean = false;

  additionalInfo: string;
  allGrounds = [];
  allParks = [];
  allSport = [];
  zipcode: string;
  GroundTyes = [];
  addSlotForm: FormGroup;
  addGroundForm: FormGroup;
  groundsData = [];
  groundId: number;
  parkId: number;
  sportId: number;
  slotdate: any;
  starttime: any;
  endtime: any;
  minimumDate: Moment;
  selectedGroundPark: string;
  selectedGroundParkId: any;
  selectedGroundCity: string;
  selectedGroundCityId: any;
  selectedGroundName: string;
  selectedGroundSport: string;
  selectedGroundSportId: any;
  onBoardSlotComments: any;
  residentOf: any;
  RequestliveStreaming: any;
  onBoardSlots: any[] = [];
  timeError: boolean = false;
  addGroundSport: any;
  selectedSportArray = [];
  isGroundsAvailable: boolean = false;
  isSportsAvailable: boolean = false;
  checkValidation: boolean = false;
  isZipcodeNotEntered: boolean = false;
  parkIdtoAddGround: number;
  cityIdtoAddGround: number;
  groundType: any;
  listviewSlots: boolean = false;
  gridviewSlots: boolean = true;
  onboardcurrentpage: number;
  parkIdtoAddSlot: number;
  parkform: FormGroup;
  addParkDialog: boolean = false;
  checkAddGroundValidation: boolean = false;
  checkaddParkValidation: boolean = false;
  selectedCityName: string;
  citiesByLocation = [];
  atLeastOneIsChecked: boolean = true;
  parkCityId: any;
  parkStreet: any;
  parkState: any;
  parkZipcode: any;
  isParkSelected: boolean;
  isAddGroundClicked: boolean;
  slotsAllocated = [];
  // showAllocated :boolean;
  listviewAllotted : boolean;
  gridviewAllotted : boolean = true;
  allotedSlotsPage : number;
  slotKey : string;
  streamUrl : string;
  slotType : string;
  stage: any = null;
  servicesArr = [];
  sportQuals: any[] = [];
  qualRequests = [];
  serviceRequests = [];
  srReq: any;
  readonly : boolean = true;
  selectedService: any;
  selectedSlot: any;
  preferredSRPrice : number;
  fixedPay : boolean = false;
  leagueName: any;
  prodEnv : boolean = environment.production;
  switchAdmin: string = "false";
  isMobile: boolean = false;
  showFilters : boolean = true;
  serviceSubscribed : boolean = true;
  selectedServiceSubscriptionId:any;
  SubscribedServiceId=16
  userSubscribedServices:any[] = [];
  selectedTeams = [];
  multiSelectSettings = {};
  apiKey : string = environment.APIKEY;
  channelId : string = "UCzBiIiI0prF2Um6W58hh5Vw";
  upcomingVideoFlag : string ;
  latestVideoUrl: any[]=[] ; 
  upcomingVideoUrl: any[]=[] ; 
  upcomingVideourlList: SafeResourceUrl[];
  latestVideourlList: SafeResourceUrl[];
  sessionHistory:any[]=[];
  public upcomingLive: any = [];
  enableMembershipDetails:any = {};
  isLivestreaming:boolean =  false;

  windowWidth : number = window.innerWidth;
  @HostListener('window:resize', ['$event'])
  resize(event: any) {
    this.windowWidth = window.innerWidth;
  }



  constructor(
    private sanitizer: DomSanitizer,
    public router: Router,
    public homeService: HomeService,
    private formbuilder: FormBuilder,
    public citiesservice: CitiesService,
    public userService: UserLoginService,
    private dragula: DragulaService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private slotsService : SlotsService,
    private activatedRoute: ActivatedRoute,
    private _sanitizer:DomSanitizer,
    private parksservice: ParksService,
    public dialog: MatDialog,
    public membershipService: MembershipService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.feedback_url = params.activeFeedback;
      this.resv_id = params.resv_id;
      this.report_url = params.activeReport;
    });
    this.route.queryParams.subscribe((params) => {
      if (params.slot_id !== undefined) {
        this.slot_id = params.slot_id;
        this.getSlotDetails();
        this.router.navigate(["."], {
          relativeTo: this.route,
          queryParams: {},
        });
      }
    });
  }

  ngOnInit() {
    this.getSessionHistory();
    this.addExtraRecipient();

    if (this.sessionHistory.length > 2) {
      this.messageService.add({
        severity: "warn",
        summary: "MLC Tickets Raffle",
        detail: "You have been entered into the raffle for MLC tickets!",
      });
    }

    this.multiSelectSettings = { 
      singleSelection: false, 
      text:"Select Teams",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"team_mltselect",
      labelKey: "Team_Name",
      primaryKey: "Team_Id",
    };

    console.log("upcomingLive",this.upcomingLive)
    if (window.screen.width < 576) { // 768px portrait
      this.isMobile = true;
      this.showFilters = false
    }
    console.log( `isMobile = ${this.isMobile}` )

      

    if ( this.isMobile ) {
      this.listview = false;
      this.gridview = true;
    }

    this.safeUrl=this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/videoseries?list=PLLzRfVVZcHAUVTs0SFcZzwZBb-nx6Xu0F')
    this.stage = environment.stage;
    console.log(environment.stage);

    this.minDate = (moment())['_d'];

    this.perPageGrid = 4;
    this.currentPageItem = 1;
    this.teamCurrentPageItem = 1;
    this.perPageTable = 10;

    this.myResvcurrentPage = 1;
    this.myServicevcurrentPage = 1;
    this.pastResvcurrentPage = 1;
    this.teamsCurrentPage = 1;
    this.getSessionHistory();

    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    $("#from_date1").change((e) => {
      this.selectedDate = moment(new Date(e.target.value)).format("YYYY-MM-DD");
    });

    if (this.feedback_url) {
      this.gotoFeedback(this.resv_id);
    }
    if (this.report_url) {
      this.gotoReport(this.resv_id);
    }
    $('[data-toggle="tooltip"]').tooltip();
    this.getSports();
    // this.getServiceFee();
    this.addSlideScript();
    this.placesScript();
    this.placesScript1();
    this.placesScript2();
    this.cityform = this.formbuilder.group({
      city: new FormControl("", Validators.required),
    });
    this.getCities();
    this.user = localStorage.getItem("user");
    this.cityId = sessionStorage.getItem("cityId");
    this.userId = localStorage.getItem("id");
    this.userName = localStorage.getItem("firstName");
    this.cityAdmin = localStorage.getItem("cityAdmin");
    this.superAdmin = localStorage.getItem("superAdmin");
    localStorage.removeItem("searchPlace");
    // this.getCurrentLocation();
    this.getFutureReservations();
    this.getFutureSessionRes();
   // this.getFutureMyServiceRequests();
    // this.getSports();
    this.getlocations();
    if(this.userId)
    this.getPreferredLocations()
    this.getSportsforPreferrence()
    this.enableMembership()
    this.getpastReservations();
    this.getOnboardSlots();
    this.getAllotedSlots();
    this.getTeamsList(this.userId);
    this.getUserSubscriptionDetails();
    this.current_sports_preferences=JSON.parse(localStorage.getItem('sportsPreferences'));
    this.current_location_preferences=JSON.parse(localStorage.getItem('locationPreference'));

    this.customSlotForm = this.formbuilder.group({
      park: new FormControl("", Validators.required),
      ground: new FormControl("", Validators.required),
      sport: new FormControl("", Validators.required),
      date: new FormControl("", Validators.required),
      startTime: new FormControl("", Validators.required),
      endTime: new FormControl("", Validators.required),
    });

    this.fromDateSearch = new Date(Date.now());
    
    this.slotChangeForm = this.formbuilder.group({
      park: ["", Validators.required],
      ground: ["", Validators.required],
      sport: ["", Validators.required],
      date: [this.selectedDate, Validators.required],
      startTime: ["startTime", Validators.required],
      endTime: ["endTime", Validators.required],
    });
    console.log(222, this.slotChangeForm);

    this.inviteForm = this.formbuilder.group({
      email: new FormControl("", Validators.required),
    });

    this.feedbackForm = this.formbuilder.group({
      title: new FormControl("", Validators.required),
      description: new FormControl("", Validators.required),
      service: new FormControl(null, Validators.required),
      regular_user: new FormControl(""),
    });
    $(".input-daterange").datepicker({});

    $("#from_date1").change((e) => {
      $(".datepicker").hide();
    });

    $("#from_date2").change((e)=> {
      this.selectedDate = moment(new Date(e.target.value)).format('YYYY-MM-DD');
      console.log("changed")
    })
    $("#from_date2").change((e) => {
      $(".datepicker").hide();
    });



    setTimeout(() => {
      $("#fltrFromDate").datepicker({
        dateFormat: "yyyy-mm-dd",
        minDate: new Date("2020-08-01"),
      });

      $("#fltrFromDate").change((e) => {
        this.fromDateSearch = moment(new Date(e.target.value)).format(
          "YYYY-MM-DD"
        );
        this.fromDateSearch =
          this.fromDateSearch == "Invalid date"
            ? undefined
            : this.fromDateSearch;
        $(".datepicker").hide();
      });
      $("#fltrToDate").change((e) => {
        this.toDateSearch = moment(new Date(e.target.value)).format(
          "YYYY-MM-DD"
        );
        this.toDateSearch =
          this.toDateSearch == "Invalid date" ? undefined : this.toDateSearch;
        $(".datepicker").hide();
      });
      //switch admin
      this.switchAdmin = localStorage.getItem('switchAdmin');
    }, 3000);

    if (this.cityAdmin == "true") {
      sessionStorage.setItem("cityId", localStorage.getItem("cityId"));
      this.router.navigate(["/admin"]);
    } else if (this.superAdmin == "true") {
      this.router.navigate(["/admin/cityRequests"]);
    }

    this.teamForm = this.formbuilder.group({
      teamName: ["", Validators.required],
      sport: [null, Validators.required],
    });

    this.editTeamForm = this.formbuilder.group({
      // tmPlayers: [''],
      name: ["", Validators.required],
      sport: [""],
      email: [""],
    });

    this.infoSlotReqForm = this.formbuilder.group({
      name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
    });

    this.getServices();
    this.getSlotsCount();
    // zipPattern = '[0-9]{5}';
    this.GroundTyes = [
      { label: "Indoor", value: "1" },
      { label: "Outdoor", value: "2" },
    ];
    this.addSlotForm = this.formbuilder.group({
      zipcode: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{5}"),
      ]),
      parkIdtoAddSlot: new FormControl("", Validators.required),
      groundId: new FormControl("", Validators.required),
      sportId: new FormControl("", Validators.required),
      slotDate: new FormControl("", Validators.required),
      startTime: new FormControl("", Validators.required),
      endTime: new FormControl("", Validators.required),
      onBoardSlotComments: new FormControl(""),
      residentOf: new FormControl(""),  
      RequestliveStreaming: new FormControl(""),
      preferredSRPrice: new FormControl(""),
      fixedPay: new FormControl(""),
      leagueName: new FormControl(""),
      // recipient_emails: new FormControl([])
    });
    this.addGroundForm = this.formbuilder.group({
      parkId: new FormControl("", Validators.required),
      cityId: new FormControl("", Validators.required),
      groundName: new FormControl("", Validators.required),
      groundType: new FormControl("", Validators.required),
      // 'selectedSport': new FormControl('')
    });
    this.parkform = this.formbuilder.group({
      zipcode: new FormControl("", [
        Validators.required,
        Validators.pattern("[0-9]{5}"),
      ]),
      parkName: new FormControl("", Validators.required),
      parkStreet: new FormControl("", Validators.required),
      parkCityId: new FormControl("", Validators.required),
      parkState: new FormControl("", Validators.required),
    });
    this.recipient_form = this.formbuilder.group({
      recipient_emails : new FormControl("", Validators.required)
    })
    this.minimumDate = moment()["_d"];

    this.onChangeCity();
    this.onChangeSlotPark();
    this.onChangeSlotGround();
    // this.loadVideos();
    this.getUpcomingLive();

  }
  
  addExtraRecipient() {
    addExtraRecipientCommon(this.recipient_emails, this.recipient_form)
  }

  removeEmail(index: number) {
    removeEmailCommon(index, this.recipient_emails, this.recipient_form)
  }
  getUpcomingLive(){
      this.homeService.getUpcomingLive().subscribe(response=>{
        this.upcomingLive = response.body;
      })
  }
  setPreferrence(){
    $("#preferenceModal").modal("hide");
    this.slotLocationSearch=this.locationpref
    if(!this.sportPreferrenceSet)
    this.sportPreference()
    if(!this.locationPreferrenceSet)
    this.locationPreference()


  }
  
  sportPreference(){
    this.loadingBar=true;
      let params = {
        'Sports_Preferences': this.sportpref,
        'userId': this.userId,
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'AccessToken': sessionStorage.getItem('accessToken')
      };
      this.homeService.updateSportsPreferences(params)
      .subscribe((res: any) => {
        console.log('update res', res);
        localStorage.setItem('sportsPreferences',JSON.stringify(res.body.Sport_Names));
        this.current_sports_preferences=JSON.parse(localStorage.getItem('sportsPreferences'));
        this.loadingBar=false;
        this.checkValidation=false;
        this.messageService.add({
          severity: 'success',
          summary: 'Update Preferences',
          detail: res.body.msg
        });
      });
  }
  locationPreference(){
    this.loadingBar=true;
      let params = {
        'location_Preferences': this.locationpref,
        'userId': this.userId,
        'authorizationToken': sessionStorage.getItem('authorizationToken'),
        'AccessToken': sessionStorage.getItem('accessToken')
      };
      this.homeService.updatelocationPreferences(params)
      .subscribe((res: any) => {
        this.loadingBar=false;
        this.getPreferredLocations()
        console.log('update res', res);
        localStorage.setItem('locationPreferences',JSON.stringify(res.body.location_Names));
        
        this.checkValidation=false;
        this.messageService.add({
          severity: 'success',
          summary: 'Update Preferences',
          detail: res.body.msg
        });
      });
  }



  getSlotDetails() {
    this.loadingBar = true;
    this.slotsService.slotDetails(this.slot_id).subscribe((slot: any) => {
      // this.informationSlot = slot[0];
      slot[0].BookingUrl = "undefined" ? null : slot[0].BookingUrl;
      this.loadingBar = false;
      this.onViewSlot(slot[0]);
    });
  }

  get teamName() {
    return this.teamForm.controls.teamName.valid;
  }
  get tsport() {
    return this.teamForm.controls.sport.valid;
  }
 

  claimResvReq() {
    $("#infoSlotReqModal").modal("hide");
    this.loadingBar = true;
    let data;

    if (this.user == "false" || this.user == null) {
      data = {
        ...this.infoSlotReqForm.value,
        Slot_Id: this.informationSlot.Slot_Id,
        Last_Name: null,
        User_Id: null,
      };
    } else {
      data = {
        Slot_Id: this.informationSlot.Slot_Id,
        name: localStorage.getItem("firstName"),
        Last_Name: localStorage.getItem("lastName"),
        email: localStorage.getItem("email"),
        phone: localStorage.getItem("phoneNumber"),
        User_Id: this.userId,
        bookingInfo: this.additionalInfo,
      };
    }
    this.homeService.claimResvReq(data).subscribe((res: any) => {
      this.loadingBar = false;
      if (res.status === 200) {
        if (res.body.msg) {
          this.messageService.add({
            severity: "success",
            summary: "Information Slot",
            detail: "Request claimed successfully.",
          });
          $("#infoSlotReqModal").modal("hide");
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Information Slot",
            detail: "Slot Already Requested.",
          });
          $("#infoSlotReqModal").modal("hide");
        }
      }
    });
  }
  getSportsforPreferrence() {
    this.homeService.getSports().subscribe((data) => {
      console.log('get sports data', data);
        this.sportsListPreferred = [];
        if (data.length === 0) {
        } else {
            data.forEach((o) => {
                const temp = {};
                temp['Sport_Id'] = o.Sport_Id;
                temp['Sport_Name'] = o.Sport_Name;
                temp['image'] = 'https://content.fieldsmanager.com/' + environment.logoStage + '/sports-images/' + o.Sport_Id + '.png';
                this.sportsListPreferred.push(temp);
            });
            setTimeout(function () {
              $('.selectpicker').selectpicker('refresh');
            }.bind(this),10);
        }
    });
  }
  getlocations() {
    this.homeService.getlocations().subscribe((data) => {
      console.log('get location data', data);
        this.locationList = [];
        if (data.length === 0) {
        } else {
            data.forEach((o) => {
                const temp = {};
                temp['Location_Id'] = o.Location_Id;
                temp['Location'] = o.Location;
                this.locationList.push(temp);
            });
            setTimeout(function () {
              $('.selectpicker').selectpicker('refresh');
            }.bind(this),10);
        }
    });
  }

  sendInfoSlotReq() {
    this.infoReqFormSubmit = true;
    if (this.user == "false" || this.user == null) {
      if (this.infoSlotReqForm.valid) {
        this.claimResvReq();
      }
    } else {
      this.claimResvReq();
    }
  }

  infoSlotRequest() {
    $("#slotDetailsModal").modal("hide");
    $("#infoSlotReqModal").modal("show");
    this.additionalInfo = "";
  }

  onViewSlot(reservation) {
    console.log(reservation);
    this.informationSlot = reservation;
    if (this.user == "false" || this.user == null) {
      console.log("user not logged in");
      this.slotKey = reservation.hash_key;
      this.slotType = "non-reservable";
      $("#signupmodal").modal("show");
    } else {
      $("#slotDetailsModal").modal("show");
    }
  }

  cnfrmDeltTeam() {
    this.loadingBar = true;
    this.homeService
      .deleteTeam(this.selectedTeam.Team_Id)
      .subscribe((res: any) => {
        if (res) {
          this.selectedTeam = undefined;
          this.getTeamsList(this.userId);
          this.loadingBar = false;
          this.messageService.add({
            severity: "success",
            summary: "Teams",
            detail: "Team deleted successfully",
          });
        }
      });
  }

  viewTeam() {
    this.loadingBar = true;
    this.homeService
      .getTeamMembers(this.selectedTeam.Team_Id)
      .subscribe((res: any) => {
        console.log("team members res", res);
        this.loadingBar = false;
        let team_members = res;
        if (team_members.length > 0) {
          this.teamMembers = team_members;
          $("#viewTeamModal").modal("show");
        }
      });
  }

  onClickDeleteMember(member) {
    this.removed_members = [];
    this.teamMembers = this.teamMembers.filter((item) => {
      if (item.TM_Id !== member.TM_Id) {
        return item;
      } else {
        this.removed_members.push(item.TM_Id);
        console.log("removed Members::", this.removed_members);
      }
    });
  }

  updateTeam() {
    this.teamFormSubmitted = true;
    if (
      (this.editTeamForm.controls["name"].status !== "INVALID" &&
        this.editTeamForm.controls["sport"].status !== "INVALID" &&
        !this.invite_option) ||
      (this.invite_option && this.editTeamForm.valid)
    ) {
      this.loadingBar = true;
      $("#editTeamModal").modal("hide");
      var obj = {
        team_id: this.selectedTeam.Team_Id,
        team_name: this.editTeamForm.controls.name.value,
        members: this.removed_members,
        email: this.editTeamForm.controls.email.value,
        user_id: localStorage.getItem("id"),
        user_name: localStorage.getItem("firstName"),
      };
      this.homeService.updateTeam(obj).subscribe((res: any) => {
        this.loadingBar = false;
        this.teamFormSubmitted = false;
        this.selectedTeam = undefined;
        if (res.body.msg.includes("exist")) {
          this.messageService.add({
            severity: "error",
            summary: "Teams",
            detail: res.body.msg,
          });
        } else {
          this.messageService.add({
            severity: "success",
            summary: "Teams",
            detail: res.body.msg,
          });
        }
        this.getTeamsList(this.userId);
      });
      this.editTeamForm.reset();
    }
  }

  editTeam() {
    this.invite_option = false;
    this.loadingBar = true;
    this.removed_members = [];
    // this.editedTmId = team.Team_Id;
    this.editTeamForm.setValue({
      name: this.selectedTeam.Team_Name,
      sport: this.selectedTeam.Sport_Name,
      email: "",
    });
    this.homeService
      .getTeamMembers(this.selectedTeam.Team_Id)
      .subscribe((res: any) => {
        console.log("team members res", res);
        this.loadingBar = false;
        let team_members = res;
        if (team_members.length > 0) {
          this.teamMembers = team_members;
          $("#editTeamModal").modal("show");
        }
      });
  }
  
  editTeamcard(team) {
    this.selectedTeam = team
    this.invite_option = false;
    this.loadingBar = true;
    this.removed_members = [];
    // this.editedTmId = team.Team_Id;
    this.editTeamForm.setValue({
      name: team.Team_Name,
      sport: team.Sport_Name,
      email: "",
    });
    this.homeService
      .getTeamMembers(team.Team_Id)
      .subscribe((res: any) => {
        console.log("team members res", res);
        this.loadingBar = false;
        let team_members = res;
        if (team_members.length > 0) {
          this.teamMembers = team_members;
          $("#editTeamModal").modal("show");
        }
      });
      this.selectedTeam = []
  }

  inviteTeam() {
    this.teamFormSubmitted = true;
    this.inviteErrRes = false;
    if (this.inviteForm.valid) {
      this.loadingBar = true;
      console.log("invited team", this.selectedTeam);
      var obj = {
        team_id: this.selectedTeam.Team_Id,
        team_name: this.selectedTeam.Team_Name,
        user_id: this.userId,
        user_name: this.userName,
        email: this.inviteForm.controls.email.value,
      };
      this.homeService.inviteTeam(obj).subscribe((res: any) => {
        this.loadingBar = false;
        this.teamFormSubmitted = false;
        this.inviteErrRes = true;
        this.inviteForm.reset();
        $("#inviteTeamModal").modal("hide");
        if(res.body.msg === "Invitation sent successfully!"){
          this.messageService.add({
            severity: "success",
            summary: "Team Invitation",
            detail: "Invitation sent successfully",
          });
        }else{
          this.messageService.add({
            severity: "info",
            summary: "Team Invitation",
            detail: "This user has been invited previously!",
          });
        }
        
      });
    }
  }

  openInviteModal() {
    this.teamFormSubmitted = false;
    $("#inviteTeamModal").modal("show");
  }
  openInviteModalcard(team) {
    this.selectedTeam = team
    this.teamFormSubmitted = false;
    $("#inviteTeamModal").modal("show");
  
  }
  deleteModalcard(team){
    this.selectedTeam = team
  }

  onCreateTeam() {
    this.teamFormSubmitted = true;
    if (this.teamForm.valid) {
      this.loadingBar = true;
      this.teamFormSubmitted = false;
      $("#createTeamModal").modal("hide");
     // $("#addslotmodal").modal('show');
      var sport_details = this.sportsList.filter(
        (res) => res.value == this.teamForm.controls.sport.value
      )[0];
      var sport_max_memebers = 0;
      if (sport_details.label === "Cricket") {
        sport_max_memebers = 11;
      } else if (sport_details.label === "Soccer") {
        sport_max_memebers = 15;
      } else if (sport_details.label === "Baseball") {
        sport_max_memebers = 16;
      }

      var obj = {
        Team_Name: this.teamForm.controls.teamName.value,
        Sport_Id: sport_details.value,
        Sport_Name: sport_details.label,
        Team_Max_Size: sport_max_memebers,
        Team_Size: 1,
        Team_Created_By: this.userId,
      };

      this.homeService.createTeam(obj).subscribe((res: any) => {
        this.selectedTeam = undefined;
        if (res.body.message.includes("exist")) {
          this.messageService.add({
            severity: 'error', 
            summary: 'Teams', 
            detail: "Team name already exists"
          });
        } else {
          this.messageService.add({
            severity: 'success', 
            summary: 'Teams', 
            detail: 'Team created successfully'
          });
        }
        console.log("create team res", res);
        this.loadingBar = false;
        this.getTeamsList(this.userId);
      });

      this.teamForm.reset();
    }
  }

  getTeamsList(user_id) {
    this.homeService.getTeamsList(user_id).subscribe((res: any) => {
      let teams_list = res;
      console.log("teams_list", teams_list);
      teams_list.forEach(obj=>{
        obj['TeamMemb']=[]
        obj['viewDetails']=false})
      this.teams = teams_list;
    });
  }
viewTeamDetails(team){
  for (let i = 0;i<this.teams.length;i++){
    if (this.teams[i].Team_Id== team.Team_Id){
      this.teams[i].viewDetails= true
    }
  }
  this.getTeamMembers(team.Team_Id)
  console.log(team.viewDetails)
}

  enableMembership() {
    this.membershipService.getEnableSignupMembershipStatus(this.userId).subscribe(resp=>{
      this.enableMembershipDetails = JSON.parse(JSON.stringify(resp));
    });
  }

  openMembershipSignup() {
    const config = new MatDialogConfig();
    if(this.windowWidth > 1200){
      config.width = '25%';
      config.height = '85%';
    }
    if(this.windowWidth <= 1200 && this.windowWidth >= 768){
      config.width = '60%';
      config.height = '85%';
    }
    if(this.windowWidth > 500 && this.windowWidth < 768){
      config.width = '50%';
      config.height = '80%';
    }
    if(this.windowWidth <= 500){
      config.width = '80%';
      config.height = '60%';
    }
    const dialogRef = this.dialog.open(AddNewMembershipDialogComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.enableMembershipDetails.enable_signup = 'false';
        console.log('closed');
      }
    });
  }
  openPremiumSignup(){
    if (this.user == "false" || this.user == null) { this.router.navigate(["/home/signup", 'premium']) }
    else {
      const config = new MatDialogConfig();
      if(this.windowWidth > 1200){
        config.width = '50%';
        config.height = '40%';
      }
      if(this.windowWidth <= 1200 && this.windowWidth >= 768){
        config.width = '50%';
        config.height = '40%';
      }
      if(this.windowWidth > 500 && this.windowWidth < 768){
        config.width = '80%';
        config.height = '40%';
      }
      if(this.windowWidth <= 500){
        config.width = '80%';
        config.height = '60%';
      }
      const dialogRef = this.dialog.open(UpgrageToPremiumDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.enableMembershipDetails.enable_signup = 'false';
          console.log('closed');
        }
      });
    }
    
  }

onBack(team){
  for (let i = 0;i<this.teams.length;i++){
    if (this.teams[i].Team_Id== team.Team_Id){
      this.teams[i].viewDetails= false
    }
  }
  console.log(team.viewDetails)
}
getTeamMembers(Team_Id){
    this.loadingBar = true;
    // this.editedTmId = team.Team_Id;
    this.homeService
      .getTeamMembers(Team_Id)
      .subscribe((res: any) => {
        console.log("team members res", res);
        this.loadingBar = false;
        let team_members = res;
        if (team_members.length > 0) {
          this.teamcardsmembers = team_members;
          for (let i = 0;i<this.teams.length;i++){
            if (this.teams[i].Team_Id== Team_Id){
              this.teams[i].TeamMemb= team_members
            }
          }
          console.log(team_members)
        }
      });

}

  getSlotsCount() {
    this.homeService.getSlotsCount(this.userId).subscribe((res: any) => {
      console.log("all parks::", res);
      this.availableSlots = res.available;
      this.bookedSlots = res.booked;
      this.informationSlots = res.information;
      this.publishedSlots = res.published;
    });
  }



  filterReservations() {
    if (
      (this.fromDateSearch == "" || this.fromDateSearch == null) &&
      (this.toDateSearch == "" || this.toDateSearch == null) &&
      this.slotcitySearch == "" &&this.slotLocationSearch.length== 0&&
      this.slotparkSearch == "" &&
      this.slotgroundSearch == "" &&
      this.slotsportSearch == "" &&
      this.slotStatus == ""
    ) {
      alert("Please select at least one filter option");
    } else {
      this.loadingBar = true;
      if (this.fromDateSearch === null) {
        this.fromDateSearch = "";
      }
      if (this.toDateSearch === null) {
        this.toDateSearch = "";
      }
      let fromDate = moment(new Date(this.fromDateSearch)).format("YYYY-MM-DD");
      fromDate = fromDate == "Invalid date" ? undefined : fromDate;
      let toDate;
      if (this.toDateSearch) {
        toDate = moment(new Date(this.toDateSearch)).format("YYYY-MM-DD");
      } else {
        toDate = undefined;
      }
      toDate = toDate == "Invalid date" ? undefined : toDate;

      let data = {
        from_date: fromDate != "" && fromDate != undefined ? fromDate : "all",
        to_date: toDate != "" && toDate != undefined ? toDate : "all",
        city: this.slotcitySearch != "" ? this.slotcitySearch : "all",
        location_preferences:this.slotLocationSearch.length!= 0 ? this.slotLocationSearch : "all",
        park: this.slotparkSearch != "" ? this.slotparkSearch : "all",
        ground: this.slotgroundSearch != "" ? this.slotgroundSearch : "all",
        sport: this.slotsportSearch != "" ? this.slotsportSearch : "all",
        status: this.slotStatus != "" ? this.slotStatus : "all",
        user_id: this.userId,
        sortBy: this.slotdatelocation,
      };

      this.homeService.filterReservations(data).subscribe(
        (res: any) => {
          this.loadingBar = false;
          console.log("res 121212", res);
          if (res.length) {
            this.futureReservations = res;
            if (this.fromDateSearch === null) {
              this.fromDateSearch = "";
            }
          } else {
            this.futureReservations = [];
            this.no_slots_msg = res.message;
            this.date_range = res.dates;
            this.next_slots = res.slots;
            if (this.fromDateSearch === null) {
              this.fromDateSearch = "";
            }
          }
        },
        (err: any) => {
          this.loadingBar = false;
          this.messageService.add({
            severity: "error",
            summary: "API Error",
            detail: err.error.message,
          });
        }
      );
    }
  }

  pageChanged(e) {
    this.currentPageItem = e;
  }

  myResvPageChanged(e) {
    this.myResvcurrentPage = e;
  }
  myServicePageChanged(e) {
    this.myServicevcurrentPage = e;
  }

  teamPageChanged(e) {
    this.teamCurrentPageItem = e;
  }

  pastResvpageChanged(e) {
    this.pastResvcurrentPage = e;
  }

  onboardsPageChanged(e) {
    this.onboardcurrentpage = e;
  }

  allotedSlotsPagehanged(e) {
    this.allotedSlotsPage = e;
  }

  onFromDateClick() {
    $("#fltrFromDate").datepicker("show");
  }
  onToDateClick() {
    $("#fltrToDate").datepicker("show");
  }

  sessUserSlot(user_ids) {
    if (user_ids.length > 0) {
      return user_ids.indexOf(parseInt(this.userId)) > -1 ? true : false;
    } else {
      return false;
    }
  }

  listView() {
    this.listview = true;
    this.gridview = false;
  }

  gridView() {
    this.gridview = true;
    this.listview = false;
    setTimeout(
      function () {
        var table = $("#sort_table").dataTable({
          destroy: true,
          order: [[3, "desc"]],
        });
      }.bind(this),
      0
    );
  }



  // onStatusChange() {
  //   this.futureReservations = [];
  //   if (this.slotStatus === "all" || this.slotStatus==="") {
  //     this.futureReservations.push(...this.resv_slots);
  //     this.futureReservations.push(...this.unresv_slots);

  //     this.futureReservations.sort((a,b) => {
  //       var dateA = new Date(a.Date).getTime();
  //       var dateB = new Date(b.Date).getTime();
  //       return dateA > dateB ? 1 : -1;
  //     });
  //   } else if (this.slotStatus === "Reserved") {
  //     this.futureReservations.push(...this.resv_slots);
  //   } else if (this.slotStatus === "Pending") {
  //     this.futureReservations.push(...this.unresv_slots);
  //   }
  //   console.log('futureReservations', this.futureReservations);
  // }

  customSlotSubmit() {
    this.custSlotFormsbmt = true;
    this.loadingBar = true;
    if (this.slotChangeForm.valid) {
      let start_time = this.slotChangeForm.value.startTime;
      let end_time = this.slotChangeForm.value.endTime;
      let slot =
        moment(start_time["hour"] + ":" + start_time["minute"], [
          "HH:mm",
        ]).format("hh:mm A") +
        " - " +
        moment(end_time["hour"] + ":" + end_time["minute"], ["HH:mm"]).format(
          "hh:mm A"
        );
      let Custom_Price = this.calSlotDuration(
        slot,
        this.slotChangeForm.value.sport.Custom_Price
      );
      let data = {
        Park_Id: this.slotChangeForm.value.park,
        Ground_Id: this.slotChangeForm.value.ground,
        Sport_Id: this.slotChangeForm.value.sport.Sport_Id,
        Date: this.slotChangeForm.value.date,
        Slot: slot,
        User_Id: this.userId,
        Custom_Price: Custom_Price,
      };
      console.log("params::", data);
      this.homeService.reqCustomSlot(data).subscribe((res: any) => {
        this.loadingBar = false;
        this.custSlotFormsbmt = false;
        console.log("req custom slot", res);
        $("#customSlotModal").modal("hide");
        this.slotChangeForm.reset();
        this.messageService.add({
          severity: "success",
          summary: "Custom Slot Request",
          detail: "Slot request successful!",
        });
      });
    }
  }

  calSlotDuration(slot, price) {
    let cusprice =
      price != null
        ? price.includes("$")
          ? price.split("$")[1]
          : price
        : price;
    console.log(cusprice);
    let from_time_arr = slot.split("-")[0].split(" ")[0].split(":");
    let to_time_arr = slot.split("-")[1].split(" ")[1].split(":");
    let from_time =
      parseInt(from_time_arr[0]) * 60 + parseInt(from_time_arr[1]);
    let to_time = parseInt(to_time_arr[0]) * 60 + parseInt(to_time_arr[1]);
    let slot_hours = Math.round((to_time - from_time) / 60);
    this.slot_duration = slot_hours;
    this.toal_price = this.slot_duration * cusprice;
    return this.toal_price;
  }

  onChangePark() {
    this.loadingBar = true;
    let park_id = this.slotChangeForm.value.park;
    this.homeService.groundsList(park_id).subscribe((res: any) => {
      this.groundsList = res;
      this.loadingBar = false;
    });
  }

  onChangeGround() {
    this.loadingBar = true;
    let park_id = this.slotChangeForm.value.park;
    let ground_id = this.slotChangeForm.value.ground;
    this.homeService.sportsList(park_id, ground_id).subscribe((res: any) => {
      this.sportsListDD = res;
      this.loadingBar = false;
    });
  }

  backToLanding(type) {
    this.feedback = false;
    this.sports_details = false;
    this.show_allSports = false;
    this.park_slotsDetails = false;
    this.reportIssue = false;
    this.support_requests = false;
    this.getpastReservations();
  }

  cancelSlot(resv) {
    this.cancelSlotId = resv.Slot_Id;
    this.reason = "";
    $("#deleteSlotModal").modal("show");
  }

  goToPricing(val) {
    this.router.navigate(["home/pricing", val]);
  }

  cnfDelSlot() {
    this.isDelSlot = true;
    if (this.reason !== "") {
      let data = {
        slot_id: this.cancelSlotId,
        reason: this.reason,
        user_id: this.userId,
      };
      $("#deleteSlotModal").modal("hide");
      console.log("cancel slot data ======", data);
      this.loadingBar = true;
      this.homeService.cancelSlot(data).subscribe((res: any) => {
        console.log("cancel slot res", res);
        this.messageService.add({
          severity: "success",
          summary: "Cancel Slot",
          detail: res.body.msg,
        });
        this.getFutureSessionRes();
       // this.getFutureMyServiceRequests();
        this.getOnboardSlots();
      });
    }
  }

  getServices() {
    this.homeService.getServices()
      .subscribe((res: any)=> {
        console.log('services res', res);new FormControl('', Validators.required)
        this.servicesArr = res.filter((e) => e['Service_Status'] == 'Active');
        this.servicesArr.forEach(service => this.addSlotForm.addControl(service.Service_Name.split(' ').join(''), new FormControl('')));
        console.log('this.addSlotForm', this.addSlotForm)
        this.getRefereeStatus();
    });
  }

  getRefereeStatus() {
    this.refereedSports = [];
    let ref_service_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['Service_Id'];
    this.homeService.getRefereeStatus(this.userId, ref_service_id).subscribe((res: any) => {
      console.log("ref res", res);
      this.service_provider_status = res.length;
      this.ref_id = res.ref_id;
      if (this.service_provider_status === 1) {
        this.getRefereeRequests(this.userId, this.ref_id);
        res.data.forEach((element) => {
          this.refereedSports.push({
            Sport_Id: element.Sport_Id,
            Sport_Name: element.Sport_Name,
          });
        });
      }
    });
  }

  onPickSlot(resv) {
    console.log("resv", resv);
    this.homeService
      .pickSlot(
        this.userId,
        resv.Reservation_Id,
        resv.Ref_Slot_Req_Id,
        this.ref_id,
        resv.City_Id
      )
      .subscribe((res: any) => {
        console.log("pick slot res", res);
        this.messageService.add({
          severity: "success",
          summary: "Umpiring",
          detail: res.body.msg,
        });
        this.getRefereeRequests(this.userId, this.ref_id);
      });
  }

  openJoinModal() {
    $("#joinModal").modal("show");
  }

  getFutureSessionRes() {
    this.homeService
      .getFutureSessionRes(this.userId, "future")
      .subscribe((res: any) => {
        // this.futureSessionRes = res.reservations.slice(0,4);
        this.futureSessionRes = res.reservations;
        console.log("THE FUTURE SESSION DATA IS...")
        console.log(this.futureSessionRes)
      });
  }

  refereeRequest(slot, service) {
    // let ref_service_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['Service_Id'];
    let data = {
      slot_id: slot.Slot_Id,
      service_id: service.Service_Id,
      sport_id: slot.Sport_Id,
      user_id: this.userId,
      Requested_Quals: this.qualRequests,
      preferred_price: this.preferredSRPrice,
      fixedPay: this.fixedPay
    }
    this.loadingBar = true;
    this.homeService.requestReferee_v2(data).subscribe((res: any) => {
      this.getFutureSessionRes();
    //  this.getFutureMyServiceRequests()
      this.getFutureReservations()
      console.log("referee reqs res", res);
      this.loadingBar = false;
      $("#addslotmodal").modal("hide");
      this.qualRequests = [];
      let body = JSON.parse(res.body);
      if(body.httpStatus && body.httpStatus == 400) {
        this.messageService.add({
          severity: "error",
          summary: service.Service_Name + " Request",
          detail: 'You have already requested for ' + service.Service_Name + ' for this slot',
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: service.Service_Name + " Request",
          detail: service.Service_Name + ' Request successful',
        });
      }
    });
  }

  getRefereeRequests(user_id, ref_id) {
    this.homeService.getMyRefereereqs(user_id, ref_id).subscribe((res: any) => {
      console.log("referee reqs res", res);
      if (res.msg === undefined) {
        this.refereeRequests = res.slice(0, 4);
      }
    });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var latlng = position.coords.latitude + "," + position.coords.longitude;
        $.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            latlng +
            "&sensor=false&key="+process.env.googleApiKey,
          (locations) => {
            console.log("locations", locations);
            if (locations.results.length > 0) {
              locations.results.forEach((item) => {
                console.log(item.types);
                if (item.types.indexOf("postal_code") > -1) {
                  console.log(item);
                  this.current_location = item.formatted_address;
                  this.searchPlace = item.formatted_address;
                  console.log("current location", this.current_location);
                  localStorage.setItem("searchPlace", this.current_location);
                  this.getFutureReservations();
                  // this.getParks(this.current_location);
                }
              });
            }
          }
        );
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  onInviteSlot() {
    console.log("Invite Slot::", this.inviteForm.value);
    this.inviteFormSubmitted = true;
    this.inviteForm.reset();
    $("#inviteSlotModal").modal("hide");
  }

  onFeedback() {
    this.fbckFormSubmitted = true;
    if (this.feedbackForm.valid) {
      this.fbckFormSubmitted = false;
      $("#feedbackModal").modal("hide");
      if (this.feedbackForm.value.regular_user === true) {
        this.feedbackForm.value.userId = this.userId;
      } else {
        this.feedbackForm.value.regular_user = false;
      }
      console.log(this.feedbackForm.value);
    }
  }
  public get fbckTitle(): string {
    return this.feedbackForm.controls.title.value;
  }
  public get fbckDesc(): string {
    return this.feedbackForm.controls.description.value;
  }
  public get fbckService(): string {
    return this.feedbackForm.controls.service.value;
  }

  get park() {
    return this.slotChangeForm.controls["park"];
  }
  get ground() {
    return this.slotChangeForm.controls["ground"];
  }
  get csport() {
    return this.slotChangeForm.controls["sport"];
  }
  get date() {
    return this.slotChangeForm.controls["date"];
  }
  get startTime() {
    return this.slotChangeForm.controls["startTime"];
  }
  get endTime() {
    return this.slotChangeForm.controls["endTime"];
  }

  onActions(slot) {
    console.log("old slot", slot);
    this.old_slot = slot;
    (this.parkName = slot.Park_Name),
      (this.groundName = slot.Ground_Name),
      (this.sportName = slot.Sport_Name),
      (this.selectedDate = moment(new Date(slot.Reservation_Date)).format(
        "YYYY-MM-DD"
      ));

    this.time1 = {
      hour: slot.Slot.split("-")[0].split(".")[0].split(":")[0],
      minute: slot.Slot.split("-")[0].split(".")[0].split(":")[1].split(" ")[0],
      second: 0,
    };
    this.time2 = {
      hour: slot.Slot.split("-")[0].split(".")[0].split(":")[0],
      minute: slot.Slot.split("-")[0].split(".")[0].split(":")[1].split(" ")[0],
      second: 0,
    };
    $("#actionEditModal").modal("show");
  }

  customSlotReq(park_id = null) {
    if (this.user == "true") {
      if (park_id && park_id != "all") {
        this.router.navigate(["/home/custom-slots"], {
          queryParams: { parkId: park_id },
        });
      } else {
        this.router.navigate(["/home/custom-slots"]);
      }
    } else {
      let url = "/home/custom-slots";
      this.router.navigate(["/home/signin"], {
        queryParams: { customSlot: true },
      });
    }
  }

  onUpdateSlot() {
    $("#actionEditModal").modal("hide");
    this.loadingBar = true;
    let new_slot = this.slotChangeForm.value;
    let slot = moment(this.time1['hour'] + ':' + this.time1['minute'], ['HH:mm']).format("hh:mm A") + ' - ' + moment(this.time2['hour'] + ':' + this.time2['minute'], ['HH:mm']).format("hh:mm A");

    let data = {
      reservation_id: this.old_slot.Reservation_Id,
      user_id: this.userId,
      slot_id: this.old_slot.Slot_Id,
      city_id: this.old_slot.City_Id,
      req_date: new_slot.date,
      req_slot: slot,
    };

    this.homeService.updateSlot(data).subscribe((res: any) => {
      this.loadingBar = false;
      console.log("update slot res", res);
      this.messageService.add({
        severity: "success",
        summary: "Edit Slot Request",
        detail: res.body.msg,
      });
    });
  }

  public get inviteEmail(): string {
    return this.inviteForm.controls.email.value;
  }

  getCities() {
    this.citiesservice.getCity().subscribe(
      (response) => {
        console.log("The response is", response.body);
        if (response.status === 200) {
          this.loadingBar = false;
          let cityArray = response.body;
          for (let i = 0; i < cityArray.length; i++) {
            this.citiesInfo.push({
              label: cityArray[i].City_Name,
              address:
                cityArray[i].Street +
                ", " +
                cityArray[i].City +
                ", \n" +
                cityArray[i].State +
                ", \n" +
                cityArray[i].Zip_Code,
            });
          }
        }
      },
      (err) => {
        console.log("this is the error", err);
        this.loadingBar = false;
        alert(
          "Some unexpected error occured, Please reload the page.\nIf the problem persists, Try again after sometime or contact support."
        );
      }
    );
  }

  userDashboard() {
    localStorage.setItem("upgradeView", "true");
    this.router.navigate(["/home/dashboard"]);
  }

  userServices() {
    localStorage.setItem("upgradeView", "true");
    this.router.navigate(["/home/dashboard"], {
      queryParams: { services: "myServices" },
    });
  }

  csrWithDefvals() {
    if (this.user == "true") {
      this.router.navigate(["/home/custom-slots"], {
        queryParams: { sportId: "85", groundId: "233", parkId:"203", noAction: "true" },
      });
    } else {
      this.router.navigate(["/home/signin"]);
    }
  }

  adminDashboard() {
    this.router.navigate(["/admin"]);
  }

  superAdminDashboard() {
    this.router.navigate(["/admin/cityRequests"]);
  }

  // single sign on auth
  loginWithAuthProvider(authType) {
    var URL,
      domainName =
        location.protocol +
        "//" +
        location.hostname +
        (location.port ? ":" + location.port : "");
    if (authType === "facebook") {
      URL =
        environment.facebookAuthURL +
        "&redirect_uri=" +
        domainName +
        "&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Facebook";
    } else if (authType === "google") {
      URL =
        environment.googleAuthURL +
        "&redirect_uri=" +
        domainName +
        "&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google";
    } else if (authType === "amazon") {
      URL =
        environment.amazonAuthURL +
        "&redirect_uri=" +
        domainName +
        "&state=STATE&&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=LoginWithAmazon";
    }
    popupTools.popup(URL, "My Popup", { width: 400, height: 450 }, (err) => {
      console.log("window closed::");
      // $(".gr_form_bg").css("background-image","url('https://content.fieldsmanager.com/Icons/home_pg_bg.jpg')");
      if (localStorage.authToken) {
        this.cognitoCallback(null, localStorage.authToken);
      }
    });
  }
  // single sign on auth

  cognitoCallback(message: string, result: any) {
    if (message != null) {
      // error
      this.loadingBar = false;
      this.errorMessage = message;
      console.log("result: " + this.errorMessage);
      if (this.errorMessage === "User is not confirmed.") {
        console.log("redirecting");
        this.router.navigate(["/home/accountActivation", this.email]);
      } else if (this.errorMessage === "User needs to set password.") {
        console.log("redirecting to set new password");
        this.router.navigate(["/home/newPassword"]);
      }
    } else {
      // success
      let tokenInfo;
      if (result.idToken == undefined) {
        tokenInfo = this.getDecodedAccessToken(result);
        console.log("this is the id token ###################", tokenInfo);
        sessionStorage.setItem("authorizationToken", result);
      } else {
        tokenInfo = this.getDecodedAccessToken(
          result.getIdToken().getJwtToken()
        );
      }

      let params = sessionStorage.getItem('authorizationToken');
      console.log('This is the token info', tokenInfo);
      if ((tokenInfo['cognito:username'] === 'systemadmin') || (tokenInfo['cognito:username'] === 'rohit') || (tokenInfo['cognito:groups'] && tokenInfo['cognito:groups'].length > 0 && tokenInfo['cognito:groups'][0] == 'SystemAdmin')) {
        console.log('this is the console for system admin');
        localStorage.setItem('superAdmin', 'true');
        this.superAdmin = localStorage.superAdmin;
        // this.loadingBar = false;
        //this.router.navigate(['/admin/cityRequests']);
        this.router.navigate(["/home"]);
      } else if (tokenInfo["cognito:username"].includes("Facebook")) {
        console.log("facebook Data::", tokenInfo);
        localStorage.setItem("user", "true");
        this.user = localStorage.user;
        this.userService.getUserDetails(params).subscribe((data) => {
          console.log("this is the output from get user details:", data);
          localStorage.setItem("firstName", data[0]["First_Name"]);
          localStorage.setItem("lastName", data[0]["Last_Name"]);
          localStorage.setItem(
            "residentOf",
            data[0]["Resident_Of"] === null ? "" : data[0]["Resident_Of"]
          );
          localStorage.setItem(
            "teamName",
            data[0]["Team_Name"] === null ? "" : data[0]["Team_Name"]
          );
          localStorage.setItem(
            "phoneNumber",
            data[0]["Phone_Number"] === null ? "" : data[0]["Phone_Number"]
          );
          localStorage.setItem("email", data[0]["Email"]);
          localStorage.setItem("id", data[0]["User_Id"]);
          localStorage.setItem("street", data[0]["Street"]);
          localStorage.setItem("city", data[0]["City"]);
          localStorage.setItem("state", data[0]["State"]);
          localStorage.setItem("zipcode", data[0]["Zip_Code"]);
          localStorage.setItem(
            "sportsPreferences",
            JSON.stringify(data[0]["Sport_Names"])
          );
        });
        if (this.urlType === "custom") {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/home"]);
        }
      } else if (tokenInfo["cognito:username"].includes("Google")) {
        console.log("google Data::", tokenInfo);
        localStorage.setItem("user", "true");
        this.user = localStorage.user;
        this.userService.getUserDetails(params).subscribe((data) => {
          console.log("this is the output from get user details:", data);
          localStorage.setItem("firstName", data[0]["First_Name"]);
          localStorage.setItem("lastName", data[0]["Last_Name"]);
          localStorage.setItem(
            "residentOf",
            data[0]["Resident_Of"] === null ? "" : data[0]["Resident_Of"]
          );
          localStorage.setItem(
            "teamName",
            data[0]["Team_Name"] === null ? "" : data[0]["Team_Name"]
          );
          localStorage.setItem(
            "phoneNumber",
            data[0]["Phone_Number"] === null ? "" : data[0]["Phone_Number"]
          );
          localStorage.setItem("email", data[0]["Email"]);
          localStorage.setItem("id", data[0]["User_Id"]);
          localStorage.setItem("street", data[0]["Street"]);
          localStorage.setItem("city", data[0]["City"]);
          localStorage.setItem("state", data[0]["State"]);
          localStorage.setItem("zipcode", data[0]["Zip_Code"]);
          localStorage.setItem(
            "sportsPreferences",
            JSON.stringify(data[0]["Sport_Names"])
          );
        });
        if (this.urlType === "custom") {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/home"]);
        }
      } else if (tokenInfo["cognito:username"].includes("LoginWithAmazon")) {
        console.log("amazon Data::", tokenInfo);
        console.log("amazon Data::", tokenInfo["name"]);
        localStorage.setItem("user", "true");
        this.user = localStorage.user;
        this.userService.getUserDetails(params).subscribe((data) => {
          console.log("this is the output from get user details:", data);
          localStorage.setItem("firstName", data[0]["First_Name"]);
          localStorage.setItem("lastName", data[0]["Last_Name"]);
          localStorage.setItem(
            "residentOf",
            data[0]["Resident_Of"] === null ? "" : data[0]["Resident_Of"]
          );
          localStorage.setItem(
            "teamName",
            data[0]["Team_Name"] === null ? "" : data[0]["Team_Name"]
          );
          localStorage.setItem(
            "phoneNumber",
            data[0]["Phone_Number"] === null ? "" : data[0]["Phone_Number"]
          );
          localStorage.setItem("email", data[0]["Email"]);
          localStorage.setItem("id", data[0]["User_Id"]);
          localStorage.setItem("street", data[0]["Street"]);
          localStorage.setItem("city", data[0]["City"]);
          localStorage.setItem("state", data[0]["State"]);
          localStorage.setItem("zipcode", data[0]["Zip_Code"]);
          localStorage.setItem(
            "sportsPreferences",
            JSON.stringify(data[0]["Sport_Names"])
          );
        });
        if (this.urlType === "custom") {
          this.router.navigate(["/home"]);
        } else {
          this.router.navigate(["/home"]);
        }
      } else if (tokenInfo["cognito:groups"][0].includes("User")) {
        console.log("************ User is logged in ***********");
        localStorage.setItem("user", "true");
        this.user = localStorage.user;
        this.userService.getUserDetails(params).subscribe((data) => {
          console.log("this is the output from get user details:", data);
          localStorage.setItem("firstName", data[0]["First_Name"]);
          localStorage.setItem("lastName", data[0]["Last_Name"]);
          localStorage.setItem(
            "residentOf",
            data[0]["Resident_Of"] === null ? "" : data[0]["Resident_Of"]
          );
          localStorage.setItem(
            "teamName",
            data[0]["Team_Name"] === null ? "" : data[0]["Team_Name"]
          );
          localStorage.setItem(
            "phoneNumber",
            data[0]["Phone_Number"] === null ? "" : data[0]["Phone_Number"]
          );
          localStorage.setItem("email", data[0]["Email"]);
          localStorage.setItem("id", data[0]["User_Id"]);
          sessionStorage.setItem("cityId", data[0]["City_Id"]);
          localStorage.setItem("street", data[0]["Street"]);
          localStorage.setItem("city", data[0]["City"]);
          localStorage.setItem("state", data[0]["State"]);
          localStorage.setItem("zipcode", data[0]["Zip_Code"]);
          localStorage.setItem(
            "sportsPreferences",
            JSON.stringify(data[0]["Sport_Names"])
          );
        });
        if (this.urlType === "custom") {
          this.router.navigate(["home/"]);
        } else {
          this.router.navigate(["/home"]);
        }
      } else {
        console.log(
          "cognito:group",
          tokenInfo["cognito:groups"][0].split("_")[1]
        );
        localStorage.setItem(
          "cognitoGroup",
          tokenInfo["cognito:groups"][0].split("_")[1]
        );
        localStorage.setItem("cityAdmin", "true");
        this.cityAdmin = localStorage.cityAdmin;
        sessionStorage.setItem(
          "cityId",
          tokenInfo["cognito:groups"][0].split("_")[0]
        );
        this.userService.getAdminDetails(params).subscribe((data) => {
          console.log("this is the output from get admin details:", data);
          localStorage.setItem("repId", data[0]["Rep_Id"]);
          localStorage.setItem(
            "repName",
            data[0]["First_Name"] + " " + data[0]["Last_Name"]
          );
          localStorage.setItem(
            "repInitials",
            data[0]["First_Name"].charAt(0) + data[0]["Last_Name"].charAt(0)
          );
          if (
            data[0]["Default_Park_Id"] === null ||
            data[0]["Default_Park_Id"] === undefined
          ) {
            // sessionStorage.setItem('repId', data[0]['Rep_Id']);
            // this.loadingBar = false;
            this.router.navigate(["/parkDefaults"]);
          } else {
            localStorage.setItem("defaultParkId", data[0]["Default_Park_Id"]);
            localStorage.setItem("defaultParkName", data[0]["Park_Name"]);
            // sessionStorage.setItem('repId', data[0]['Rep_Id']);
            this.router.navigate(["/home"]);
          }
        });
      }
    }
  }

  getDecodedAccessToken(token: string): any {
    console.log("inside the function");
    try {
      console.log("token info in function: ", jwt_decode(token));
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  placesScript() {
    var options = {
      types: ["(cities)"],
      componentRestrictions: {
        country: "us",
      },
    };
    var input = document.getElementById("searchTextField");
    let __this = this;
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addDomListener(window, "load");
    google.maps.event.addListener(
      autocomplete,
      "place_changed",
      async function () {
        var place = autocomplete.getPlace();
        console.log("place", place);
        let fetch_url =
          "https://api.zippopotam.us/us/" +
          place.formatted_address.split(",")[1].replace(/[0-9]/g, "").trim() +
          "/" +
          place.formatted_address.split(",")[0].replace(/[0-9]/g, "").trim();
        console.log("fetch_url");
        console.log(fetch_url);
        const response = await fetch(fetch_url);
        const details = await response.json();
        let post_code = details.places[0]["post code"];
        console.log(post_code);
      
        let tot_addr = place.formatted_address + " " + post_code;
        console.log("tot_addr");
        console.log(tot_addr);
        localStorage.setItem("searchPlace", tot_addr);
        __this.searchPlace = tot_addr;
        __this.current_location = tot_addr;
        localStorage.setItem("searchPlace", post_code);
        __this.getFutureReservations();
      }
    );
  }

  placesScript1() {
    var options = {
      types: ["(cities)"],
      componentRestrictions: {
        country: "us",
      },
    };
    var input = document.getElementById("searchTextField1");
    setTimeout(
      function () {
        $(".pac-container:nth-last-child(2n+1)").prependTo(".ggl_cnt");
      }.bind(this),
      5000
    );
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addDomListener(window, "load");
    google.maps.event.addListener(autocomplete, "place_changed", function () {
      var place = autocomplete.getPlace();
      console.log("place::", place);
      localStorage.setItem("searchPlace", place.formatted_address);
    });
  }

  logout() {
    console.log(23232323);
    this.user = "false";
    this.cityAdmin = "false";
    this.superAdmin = "false";
    this.userId = null;
    this.getFutureReservations();
    this.userService.logout();
    console.log("UserLoginService: Logging out");
    localStorage.removeItem("fb_token");
    localStorage.removeItem("authToken");
    console.log("UserLoginService: Logging out");
    localStorage.removeItem("user");
    localStorage.removeItem("defaultParkName");
    localStorage.removeItem("defaultParkId");
    sessionStorage.removeItem("authorizationToken");
    localStorage.removeItem("repId");
    localStorage.removeItem("repInitials");
    localStorage.removeItem("repName");
    localStorage.removeItem("superAdmin");
    localStorage.removeItem("id");
    localStorage.clear();
    this.feedback = false;
    this.support_requests = false;
    this.sports_details = false;
    this.show_allSports = false;
    this.park_slotsDetails = false;
    this.reportIssue = false;
    this.router.navigate(["/home"]);
    this.getSlotsCount();
  }

  allSports() {
    this.show_allSports = true;
    this.sports_details = false;
    this.park_slotsDetails = false;
    this.feedback = false;
    this.reportIssue = false;
    this.support_requests = false;
  }

  slotParks() {
    this.selectedPark = undefined;
    this.park_slotsDetails = true;
    this.show_allSports = false;
    this.sports_details = false;
    this.feedback = false;
    this.reportIssue = false;
    this.support_requests = false;
  }

  getsportsDetail(sport) {
    // if (sport.label == "Cricket") {
    //   this.sports_details=true;
    //   this.show_allSports=false;
    //   this.park_slotsDetails=false;
    //   this.feedback=false;
    //   this.reportIssue = false;
    //   this.support_requests = false;
    //   this.sport=sport;
    // }
  }

  gotoFeedback(resv_id) {
    this.resv_id = resv_id;
    this.feedback = true;
    this.sports_details = false;
    this.show_allSports = false;
    this.park_slotsDetails = false;
    this.reportIssue = false;
    this.support_requests = false;
    this.router.navigate(["/home/feedback/" + resv_id]);
  }

  gotoReport(resv_id) {
    this.resv_id = resv_id;
    this.reportIssue = true;
    this.feedback = false;
    this.sports_details = false;
    this.show_allSports = false;
    this.park_slotsDetails = false;
    this.support_requests = false;
    this.router.navigate(["/home/report-issue/" + resv_id]);
  }

  gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details = false;
    this.show_allSports = false;
    this.park_slotsDetails = false;
    this.reportIssue = false;
    this.router.navigate(["/home/support-requests"]);
  }

  setnearPlace(place) {this.parksList
    localStorage.searchPlace = place;
    this.searchPlace = place;
  }

  nearestParks() {
    var origin = localStorage.searchPlace;
    this.distanceArray = [];
    this.parksList.forEach((o) => {
      return new google.maps.DistanceMatrixService().getDistanceMatrix(
        { origins: [origin], destinations: [o.address], travelMode: "DRIVING" },
        (data: any) => {
          if (data.rows[0].elements[0].status == "OK") {
            this.distanceArray.push({
              distance: data.rows[0].elements[0].distance.value,
              label: o.label,
              address: o.address,
            });
          } else {
            this.distanceArray.push({
              distance: 0,
              label: o.label,
              address: o.address,
            });
          }
          this.parksList = this.distanceArray.sort(function (a, b) {
            return a.distance - b.distance;
          });
          setTimeout(function () {}.bind(this), 0);
        }
      );
    });
  }

  goTo() {
    this.searchPlace1 = localStorage.searchPlace;
    this.updateLocation = this.searchPlace1;
    this.loadParks = true;
    //this.nearestParks();
    this.slotParks();
    this.getParks(this.updateLocation);
    //  this.searchPlace = null;
  }

  findParks() {
    var address = localStorage.searchPlace;
    localStorage.setItem("searchPlace", address);
    this.searchPlace1 = address;
    this.updateLocation = this.searchPlace1;
    this.loadParks = true;
    //  this.nearestParks();
    this.slotParks();
  }

  addSlideScript() {
    $("#myCarousel").carousel({
      interval: false,
    });

    $(".carousel .item").each(function () {
      let next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next.children(":first-child").clone().appendTo($(this));

      for (var i = 0; i < 4; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next.children(":first-child").clone().appendTo($(this));
      }
    });
  }

  getSports() {
    this.loading_spinner = true;
    this.sportsList = [];
    this.homeService.getSports().subscribe((data) => {
      this.sportsList = [];
      if (data.length === 0) {
        this.loading_spinner = false;
      } else {
        data.forEach((o) => {
          let obj = new Sport();
          const temp = {};
          temp["value"] = o.Sport_Id;
          temp["label"] = o.Sport_Name;
          temp["image"] =
            "https://content.fieldsmanager.com/" +
            environment.logoStage +
            "/sports-images/" +
            o.Sport_Id +
            ".png";
          this.sportsList.push(temp);
        });
        this.loading_spinner = false;
      }
    });
  }

  getParks(location) {
    // this.parksList = [];
    this.resParksList = [];
    let res_cities = [];
    let city_list = this.futureReservations.map((e) => e.City);
    var ordering = {};
    let city_sort = city_list.sort(function (a, b) {
      return String(a).toLowerCase() >= String(b).toLowerCase() ? 1 : -1;
    });
    let f_city_sort = city_sort.filter((v, i) => city_sort.indexOf(v) === i);
    this.homeService.getParks(location).subscribe((data) => {
      // this.parksList = [];
      if (data.length === 0) {
      } else {
        let ind = 0;
        data.forEach((o, i) => {
          ind++;
          let obj = new Sport();
          const temp = {};
          const cityTemp = {};
          cityTemp["City"] = o.City;
          temp["value"] = o.Park_Id;
          temp["label"] = o.Park_Name;
          temp["address"] = o.Park_Address;
          // this.parksList.push(temp);
          if (!this.futureInitCities.some((e) => e.City === o.City)) {
            if (f_city_sort.indexOf(cityTemp["City"]) < 0) {
              this.futureInitCities.push(cityTemp);
            } else {
              if (!this.resParksList.some((e) => e.City === o.City)) {
                this.resParksList.push(cityTemp);
              }
            }
            this.futureInitCities.sort(function (i, j) {
              return String(i.City).toLowerCase() >=
                String(j.City).toLowerCase()
                ? 1
                : -1;
            });
            this.resParksList.sort(function (i, j) {
              return String(i.City).toLowerCase() >=
                String(j.City).toLowerCase()
                ? 1
                : -1;
            });
          }
          if (ind == data.length) {
            this.futureInitCities = [
              ...this.futureInitCities.slice(0, 0),
              ...this.resParksList,
              ...this.futureInitCities.slice(0),
            ];
            // @ts-ignore
            let jsonObject = this.futureInitCities.map(JSON.stringify);
            let uniqueSet = new Set(jsonObject);
            // @ts-ignore
            this.futureInitCities = Array.from(uniqueSet).map(JSON.parse);
          }
        });
      }
    });
  }

  goToSignUp(type) {
    $("#joinModal").modal("hide");
    this.router.navigate(["/home/signup", type]);
  }

  viewMore(type) {
    this.citiesservice.sendViewData(type);
  }

  getsportsDetailWidget(data) {
    this.sport = {
      label: data.Sport,
      value: data.Sport_Id,
      image:
        "https://content.fieldsmanager.com/" +
        environment.logoStage +
        "/sports-images/" +
        data.Sport_Id +
        ".png",
    };
    this.getsportsDetail(this.sport);
  }

  getFutureReservations() {
    // this.showAllocated = false;
    let dateNow = moment(Date.now()).format("YYYYMMDD");
    let location = localStorage.getItem("searchPlace");
    this.loadingBar = true;
    this.homeService
      .getFutureReservations(dateNow, location, this.userId)
      .subscribe(
        (res: any) => {
          console.log("GETTING FUTURE RESERVATIONS...")
          console.log(res)
          this.loadingBar = false;
          if (res.length) {
            this.futureReservations = res;
            this.futureInitReservations = res;
            // let datetemp=new Date(Date.now());
            // console.log("datetemp",datetemp)
            // console.log("this.fromDateSearch",this.fromDateSearch)
            // this.toDateSearch = new Date(
            //   this.futureReservations.slice(-1)[0]["Date"]
            // );
          } else {
            this.futureReservations = [];
            this.no_slots_msg = res.message;
            this.date_range = res.dates;
            this.next_slots = res.slots;
          }
          this.getParks(localStorage.getItem("searchPlace"));
        },
        (err: any) => {
          this.loadingBar = false;
          this.messageService.add({
            severity: "error",
            summary: "API Error",
            detail: err.error.message,
          });
        }
      );
    // if(res.msg===undefined){
    //   this.futureReservations = res;
    // this.resv_slots = [], this.unresv_slots = [], this.futureReservations = [];
    // if (res.length > 0) {
    //   res.sort((a,b) => {
    //     var dateA = new Date(a.Date).getTime();
    //     var dateB = new Date(b.Date).getTime();
    //     return dateA > dateB ? 1 : -1;
    //   });
    //   this.futureReservations = res;

    //   res.forEach(elm => {
    //     if (elm.Slot_Status === "Reserved") {
    //       this.resv_slots.push(elm);
    //     } else {
    //       this.unresv_slots.push(elm);
    //     }
    //   });
    // }

    // if (this.resv_slots.length > 0) {
    //   this.resv_slots.sort((a,b) => {
    //     var dateA = new Date(a.Date).getTime();
    //     var dateB = new Date(b.Date).getTime();
    //     return dateA > dateB ? 1 : -1;
    //   });
    // }

    // if (this.unresv_slots.length > 0) {
    //   this.unresv_slots.sort((a,b) => {
    //     var dateA = new Date(a.Date).getTime();
    //     var dateB = new Date(b.Date).getTime();
    //     return dateA > dateB ? 1 : -1;
    //   });
    // }
    // }
    // if(res.msg==='Location not found! Please allow location!'){
    //  console.log('location not found');
    // }
    //});
  }

  getpastReservations() {
    this.homeService
      .getpastReservations(this.userId, "past")
      .subscribe((res: any) => {
        // this.pastReservations = res.reservations.slice(0, 4);
        this.pastReservations = res.reservations;
        console.log("THE PAST RESERVATION DATA IS...")
        console.log(this.pastReservations)
      });
  }

  sortRes(sort_key, pastRes = false) {
    console.log(sort_key, pastRes);
    this.pastResvcurrentPage = 1;
    let procRes;
    if (pastRes) {
      procRes = this.processRes(sort_key, this.pastReservations, this.asc);
      this.pastReservations = procRes;
    } else {
      procRes = this.processRes(sort_key, this.futureSessionRes, this.asc);
      this.futureSessionRes = procRes;
    }
    this.asc = !this.asc;
  }

  processRes(sort_key, reservations, asc) {
    let __this = this;
    if (sort_key == "Date") {
      reservations.sort(function (a: any, b: any) {
        if (asc) {
          return moment(a[sort_key]).isSameOrBefore(moment(b[sort_key]))
            ? -1
            : 1;
        } else {
          return moment(a[sort_key]).isSameOrBefore(moment(b[sort_key]))
            ? 1
            : -1;
        }
      });
    } else if (sort_key == "Slot") {
      reservations.sort(function (a: any, b: any) {
        if (asc) {
          return moment(a[sort_key], "hh:mm A - hh:mm A").diff(
            moment(b[sort_key], "hh:mm A - hh:mm A"),
            "minutes"
          ) > 0
            ? -1
            : 1;
        } else {
          return moment(a[sort_key], "hh:mm A - hh:mm A").diff(
            moment(b[sort_key], "hh:mm A - hh:mm A"),
            "minutes"
          ) > 0
            ? 1
            : -1;
        }
      });
    } else {
      reservations.sort(function (a: any, b: any) {
        if (asc) {
          return a[sort_key] < b[sort_key] ? -1 : 1;
        } else {
          return a[sort_key] > b[sort_key] ? -1 : 1;
        }
      });
    }
    return reservations;
  }

  onNext(slot, isAssigned) {
    if (this.user == "false" || this.user == null) {
      console.log("user not logged in");
      this.slotKey = slot.hash_key;
      this.slotType = "reservable";
      $("#signupmodal").modal("show");
    } else {
      this.router.navigate(["/home/slot-detail/" + slot.hash_key], {
        queryParams: { assignedSlotType: slot.Status ? slot.Status : null },
      });
    }
  }

  onHdrParkFilter(park) {
    this.slotParks();
    this.selectedPark = park;
  }

  gotoMyAccount(sDoc: string) {
    localStorage.setItem("upgradeView", "true");
    this.citiesservice.sendData("default message");
    this.router.navigate(["/home/dashboard"], {
      queryParams: { account: "myAccount" },
    });
  }
  gotoSignUpUser(user) {
    $("#signupmodal").modal("hide");
    this.router.navigate(["/home/signup/user"]);
  }
  gotoLogin() {
    $("#signupmodal").modal("hide");
    if(this.streamUrl){
      this.router.navigate(["/home/signin"], {
        queryParams: {
          stream: "true",
          streamUrl: this.streamUrl,
        },
      });

    }
    else{
      this.router.navigate(["/home/signin"], {
        queryParams: {
          fromLanding: "true",
          slotKey: this.slotKey,
          slotType: this.slotType,
        },
      });

    }
   
  }

  listViewSlots() {
    this.listviewSlots = true;
    this.gridviewSlots = false;
  }

  gridViewSlots() {
    this.listviewSlots = false;
    this.gridviewSlots = true;
  }
  signInCheck(reservation){
    if (this.user == "false" || this.user == null) {
      console.log("user not logged in");
      this.slotType = "non-reservable";
      this.slotKey = reservation.hash_key;
      $("#signupmodal").modal("show");
    }
  }

  onAddSlot(slot, service) {
    console.log("onAddSlot");
    console.log(slot, service);
    this.selectedSlot = slot;
    this.selectedService = service;
    if(slot === null && service === null) {
      this.readonly = false;
      $("#addslotmodal").modal("show");
      this.zipcode = "";
      this.allGrounds = [];
      this.sportQuals = [];
      this.allParks = [];
      this.allSport = [];
      this.isGroundsAvailable = false;
      this.isSportsAvailable = false;
      this.checkValidation = false;
      this.addSlotForm.reset();
      this.isZipcodeNotEntered = false;
      this.isAddGroundClicked = false;
      // setTimeout(() => {
      //   $('#zip').focus();
      // }, 500);
      this.getAllParks();
    } else {
      if(this.userSubscribedServices&&service.Service_Id)
      this.selectedServiceSubscriptionId=this.userSubscribedServices.find(item=>(item.Service_Id==service.Service_Id)&&(item.Subscription_Status=='Active'))
      this.readonly = true;
      let temp = {};
      temp['value'] = slot.Park_Id
      temp['label'] = slot.Park_Name
      this.allParks.push(temp);
      this.parkIdtoAddSlot = slot.Park_Id;
      temp = {};
      temp["value"] = slot.Ground_Id;
      temp["label"] = slot.Ground_Name;
      this.allGrounds= [];
      this.allGrounds.push(temp);
      this.groundId = slot.Ground_Id;
      temp = {};
      temp["value"] = slot.Sport_Id;
      temp["label"] = slot.Sport_Name;
      this.allSport= [];
      this.allSport.push(temp);
      this.isSportsAvailable = true;
      this.sportId = slot.Sport_Id;
      // this.slotdate = moment("2022-05-30T18:30:00.000Z").utcOffset( -12, true )["_d"];
      // this.slotdate = moment("2022-05-30T18:30:00.000Z").subtract(12, 'hours')["_d"];
      this.slotdate = moment(slot.Date).add(12, 'hours')["_d"];
      this.starttime = slot.Slot.split(' - ')[0]
      this.endtime = slot.Slot.split(' - ')[1]
      this.loadingBar = true;
      this.homeService.getSportQuals(slot.Sport_Id, service.Service_Id).subscribe((response) => {
        this.loadingBar = false;
        this.sportQuals = response.body;
        $("#addslotmodal").modal("show");
        // if(response.body.length > 0) {
        //   this.sportQuals = response.body;
        //   $("#addslotmodal").modal("show");
        // } else {
        //   this.refereeRequest(slot, service);
        // }
      })
  }
  }
  getAllParks() {
    let user_id = localStorage.getItem("id");
    this.loadingBar = true;
    this.homeService.getTotalParks(user_id).subscribe((response) => {
      this.loadingBar = false;
      if (response.status === 200) {
        if (response.body.length > 0) {
          this.groundsData = response.body;
          response.body.forEach((park) => {
            const parksTemp = {};
            parksTemp["value"] = park.Park_Id;
            parksTemp["label"] = park.Park_Name;
            this.allParks.push(parksTemp);
          });
        }
      }
    });
  }

  getParksBasedOnLocation() {
    let zipcode = this.zipcode;
    if (this.addSlotForm.controls["zipcode"].status != "INVALID") {
      this.isZipcodeNotEntered = false;
      this.allParks = [];
      this.groundsData = [];
    } else {
      this.isZipcodeNotEntered = true;
    }
    if (
      !this.isZipcodeNotEntered &&
      this.addSlotForm.controls["zipcode"].status != "INVALID"
    ) {
      this.loadingBar = true;
      this.homeService
        .getParksBasedOnLocation(zipcode)
        .subscribe((response) => {
          console.log(response);
          this.loadingBar = false;
          if (response.status === 200) {
            if (response.body.includes("No City exists with the zipcode")) {
              +this.messageService.add({
                severity: "error",
                summary: "Zipcode error",
                detail: "No City exists with zipcode " + this.zipcode,
              });
            } else {
              if (response.body.length > 0) {
                this.groundsData = response.body;
                response.body.forEach((park) => {
                  const parksTemp = {};
                  parksTemp["value"] = park.Park_Id;
                  parksTemp["label"] = park.Park_Name;
                  this.allParks.push(parksTemp);
                });
              } else {
                this.messageService.add({
                  severity: "error",
                  summary: "Report Parks Update",
                  detail: "No Parks exists under the zipcode",
                });
              }
            }
          }
        });
    }
  }

  getGrounds() {
    let zipcode = this.zipcode;
    if (
      zipcode != undefined ||
      zipcode != null ||
      this.addSlotForm.controls["zipcode"].status != "INVALID"
    ) {
      this.isZipcodeNotEntered = false;
    } else {
      this.isZipcodeNotEntered = true;
    }
    this.allGrounds = [];
    this.allParks = [];
    this.allSport = [];
    this.groundId = undefined;
    this.sportId = undefined;
    if (
      !this.isZipcodeNotEntered &&
      this.addSlotForm.controls["zipcode"].status != "INVALID"
    ) {
      this.loadingBar = true;
      this.homeService
        .getGroundsBasedonZipCode(zipcode, this.userId)
        .subscribe((response) => {
          console.log(response);
          this.loadingBar = false;
          if (response.status === 200) {
            if (response.body.length === 0) {
              this.allGrounds = [];
              this.allParks = [];
              this.allSport = [];
              this.isGroundsAvailable = false;
              this.isSportsAvailable = false;
              // this.loadingBar = false;
              this.messageService.add({
                severity: "error",
                summary: "Report Grounds Update",
                detail: "No Grounds exist",
              });
            } else {
              this.isGroundsAvailable = true;
              this.groundsData = response.body;
            }
            const allParksData = response.body.filter(
              (ground, i, arr) =>
                arr.findIndex((t) => t.Park_Id === ground.Park_Id) === i
            );
            allParksData.forEach((park) => {
              console.log(park);
              const parksTemp = {};
              parksTemp["value"] = park.Park_Id;
              parksTemp["label"] = park.Park_Name;
              this.allParks.push(parksTemp);
            });
          }
        });
    }
  }

  onAddGround() {
    this.isAddGroundClicked = true;
    console.log(this.parkIdtoAddSlot);
    if (this.parkIdtoAddSlot == null || this.parkIdtoAddSlot == undefined) {
      this.isParkSelected = false;
      return;
    }
    this.addGroundForm.reset();
    this.checkAddGroundValidation = false;
    this.parkId = this.parkIdtoAddSlot;
    this.addGroundForm.controls["parkId"].setValue(this.parkIdtoAddSlot);
    $("#addGroundPopup").modal("show");
    $(".sprs_chck_box input:checkbox").removeAttr("checked");
    this.atLeastOneIsChecked = true;
  }
  onAddPark() {
    $("#createParkModal").modal("show");
    this.parkform.controls["parkName"].setValue("");
    this.parkform.controls["parkStreet"].setValue("");
    this.parkform.controls["parkCityId"].setValue("");
    this.parkform.controls["parkState"].setValue("");
    this.checkaddParkValidation = false;
    // console.log(this.zipcode);
    // if(this.zipcode!=null||this.zipcode != undefined){
    //   this.citiesByLocation = [];
    //   this.loadingBar = true
    //   this.homeService.getCitiesByLocation(this.zipcode).subscribe((response) => {
    //     this.loadingBar = false;
    //     console.log(response)
    //     if(response.status === 200){
    //       if(response.body.length > 0){
    //         response.body.forEach(city => {
    //           const temp = {};
    //              temp['value'] = city.City_Id;
    //              temp['label'] = city.City;
    //              this.citiesByLocation.push(temp);
    //         });
    //       }
    //     }
    //   })
    // }
  }
  cancelAddPark() {
    let element = document.getElementById("add-park");
    element.classList.remove("park_modal_bg");

    this.addParkDialog = false;
    // this.parkform.reset();
  }

  getGroundsByPark(parkid) {
    this.allGrounds = [];
    let dataByPark = this.groundsData.filter((data) => {
      return data.Park_Id === parkid;
    });
    let data = {
      User_Id: this.userId,
      Park_Id: parkid,
    };
    this.loadingBar = true;
    this.homeService.getGroundsByPark(data).subscribe((response) => {
      console.log(response);
      this.loadingBar = false;
      if (response.status == 200) {
        if (response.body.length > 0) {
          response.body.forEach((ground) => {
            const temp = {};
            temp["value"] = ground.Ground_Id;
            temp["label"] = ground.Ground_Name;
            this.allGrounds.push(temp);
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary:
              "No Grounds exists under the " + dataByPark[0].Park_Name + "",
            detail: "you can add ground by clicking add ground icon",
          });
        }
      }
    });
    console.log(dataByPark);
    this.selectedGroundCityId = dataByPark[0].City_Id;
    this.selectedGroundCity = dataByPark[0].City;
    this.selectedGroundParkId = dataByPark[0].Park_Id;
    this.selectedGroundPark = dataByPark[0].Park_Name;
    //   if(dataByPark.length > 0){
    //   dataByPark.forEach((data) => {
    //     const temp = {};
    //     temp['value'] = data.Ground_Id;
    //     temp['label'] = data.Ground_Name;
    //     this.allGrounds.push(temp);
    //   })
    //  }
    //  else{
    //    this.allGrounds = [];
    //  }
  }
  getSportsByGround(groundId) {
    console.log(groundId);
    this.sportId = undefined;
    this.allSport = [];
    let ground = this.allGrounds.find((ground) => {
      return ground.value === groundId;
    });
    console.log(ground);
    // this.selectedGroundPark = ground.Park_Name;
    // this.selectedGroundParkId = ground.Park_Id;
    // this.selectedGroundCity = ground.City;
    // this.selectedGroundName = ground.value;
    // this.selectedGroundCityId = ground.City_Id;
    // this.selectedGroundSport = ground.Sport_Name;
    // let park_id = ground.Park_Id;
    // let ground_id = ground.Ground_Id;
    this.loadingBar = true;
    this.homeService
      .sportsList(this.parkIdtoAddSlot, groundId)
      .subscribe((res: any) => {
        this.loadingBar = false;
        console.log(res);
        if (res.length > 0) {
          this.isSportsAvailable = true;
          res.forEach((sport) => {
            const temp = {};
            temp["value"] = sport.Sport_Id;
            temp["label"] = sport.Sport_Name;
            this.allSport.push(temp);
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Report Sports Update",
            detail: "No Sports exist",
          });
          // this.allGrounds = [];
          // this.allParks = [];
          this.allSport = [];
          // this.isGroundsAvailable = false;
          this.isSportsAvailable = false;
        }
      });
  }

  onSportSelect(sportId) {
    let sport = this.allSport.find((sport) => {
      return sport.value === sportId;
    });
    console.log(sport);
    this.selectedGroundSport = sport.label;
    this.selectedGroundSportId = sport.value;
    let ref_sport_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['sports'].filter((e) => e['Sport_Name'] == 'Cricket')[0]['Sport_Id'];
    if(ref_sport_id !== sport.value){
      this.qualRequests = [];
      this.sportQuals = [];
    }
  }

  onChangeSport(event, sport) {
    console.log(sport);
    if (event.target.checked) {
      let selectedSport = {
        Sport_Id: sport.value,
        Sport_Name: sport.label,
      };
      this.selectedSportArray.push(sport);
    } else {
      let index = this.selectedSportArray.indexOf(sport);
      this.selectedSportArray.splice(index, 1);
    }
    console.log(this.selectedSportArray);
    if (this.selectedSportArray.length > 0) {
      this.atLeastOneIsChecked = true;
    } else {
      this.atLeastOneIsChecked = false;
    }
  }

  onSelectStartTime(event) {
    console.log(event);
    if (
      this.addSlotForm.get("endTime").value != null &&
      this.addSlotForm.get("endTime").value <
        this.customSlotForm.get("startTime").value
    ) {
      this.timeError = true;
    } else {
      this.timeError = false;
    }
  }

  onSelectEndTime(event) {
    if (
      this.addSlotForm.get("startTime").value != null &&
      this.addSlotForm.get("endTime").value <
        this.addSlotForm.get("startTime").value
    ) {
      this.timeError = true;
    } else {
      this.timeError = false;
    }
  }

  onCreateSlot() {
    let extraReceipients = '';
    this.recipient_emails.forEach(receipients=>{
      extraReceipients += (extraReceipients.length === 0) ? receipients.value : ', ' + receipients.value
    })
    console.log(extraReceipients);
    this.validateAddSlot();
    if (!this.checkValidation && !this.timeError) {
      let startTime = moment(this.addSlotForm.value.startTime).format(
        "hh:mm A"
      );
      let endTime = moment(this.addSlotForm.value.endTime).format("hh:mm A");
      let slottoadd = startTime + " - " + endTime;
      let sDate = moment(this.slotdate).format("YYYY-MM-DD");
      let lst = this.addSlotForm.value.LiveStreaming;
      let obsc = this.addSlotForm.value.onBoardSlotComments;
      let residentOf = this.addSlotForm.value.residentOf;
      let team_names
      if (this.selectedTeams.length !== 0){
       team_names = this.selectedTeams.map((item) => {
        return item.Team_Name;})
      }
      let recipient_mail = this.recipient_emails.filter((control) => control.value != "").map(control=>(control.value));
      console.log(JSON.stringify(recipient_mail))
      let data = {
        City_Id: this.selectedGroundCityId,
        Park_Id: this.selectedGroundParkId,
        Ground_Id: this.groundId,
        Sport_Id: this.selectedGroundSportId,
        Slot: slottoadd,
        Date: sDate,
        User_Id: this.userId,
        onBoardSlotComments: obsc,
        RequestliveStreaming: lst,
        Requested_Quals: this.qualRequests,
        Service_Ids: this.serviceRequests,
        preferred_price: this.preferredSRPrice,
        fixedPay: this.fixedPay,
        leagueName: this.leagueName,
        selectedTeams:team_names,
        Extra_Recipient: extraReceipients,
        residentOf:residentOf
      };
      // console.log("data --",recipient_mail)
      console.log(data)
      this.loadingBar = true;
      this.homeService.addOnboardSlot(data).subscribe((response) => {
        this.qualRequests = [];
        this.selectedTeams=[]
        console.log(response);
        if(response.body.message == "overlap"){
          this.loadingBar = false;
          this.messageService.add({
            severity: "error",
            summary: "Report Sports Update",
            detail: "Another Slot Exist On Same Time",
          });
        }
        else if (response.status == 200) {
          this.loadingBar = false;
          this.messageService.add({
            severity: "success",
            summary: "Report Sports Update",
            detail: "Slot Created Successfully",
          });
          // this.getOnboardSlots();
          console.log("AFTER NEW SLOT CREATION...")
          this.getFutureSessionRes();
    //      this.getFutureMyServiceRequests()
        }
        else{
        }
      });
      $("#addslotmodal").modal("hide");
    } else {
      console.log("error");
    }
  }

  getOnboardSlots() {
    let userid = this.userId;
    this.loadingBar = true;
    this.homeService.getOnboardSlots(userid).subscribe((response) => {
      console.log(response);
      this.loadingBar = false;
      if (response.status == 200) {
        this.onBoardSlots = response.body;
      }
    });
  }
  validateAddSlot() {
    if (
      this.addSlotForm.controls["parkIdtoAddSlot"].status == "INVALID" ||
      this.addSlotForm.controls["groundId"].status == "INVALID" ||
      this.addSlotForm.controls["sportId"].status == "INVALID" ||
      this.addSlotForm.controls["slotDate"].status == "INVALID" ||
      this.addSlotForm.controls["startTime"].status == "INVALID" ||
      this.addSlotForm.controls["endTime"].status == "INVALID"
    ) {
      this.checkValidation = true;
    } else {
      this.checkValidation = false;
    }
  }
  onParkSelect(id) {
    let ground = this.groundsData.find((ground) => {
      if (ground.Park_Id == this.parkId) {
        return true;
      }
    });
    console.log(ground);
    this.parkIdtoAddGround = ground.Park_Id;
    this.cityIdtoAddGround = ground.City_Id;
  }
  addGround() {
    if (
      this.addGroundForm.controls["parkId"].status === "INVALID" ||
      this.addGroundForm.controls["groundName"].status === "INVALID" ||
      this.addGroundForm.controls["groundType"].status === "INVALID"
    ) {
      this.checkAddGroundValidation = true;
    } else {
      this.checkAddGroundValidation = false;
    }
    this.atLeastOneIsChecked = $('input[name="check"]:checked').length > 0;
    console.log(this.atLeastOneIsChecked);
    if (!this.checkAddGroundValidation && this.atLeastOneIsChecked) {
      let sports = [];
      this.selectedSportArray.forEach((sport) => {
        let selectedSport = {
          Sport_Id: sport.value,
          Sport_Name: sport.label,
        };
        sports.push(selectedSport);
      });
      let ground = this.groundsData.find((ground) => {
        if (ground.Park_Id == this.parkId) {
          return true;
        }
      });
      console.log(ground);
      this.parkIdtoAddGround = ground.Park_Id;
      this.cityIdtoAddGround = ground.City_Id;
      let data = {
        Park_Id: this.parkId,
        City_Id: this.cityIdtoAddGround,
        Ground_Name: this.groundName,
        Ground_Type: this.groundType == 1 ? "Indoor" : "Outdoor",
        User_Id: this.userId,
        sport: JSON.stringify(sports),
      };
      this.loadingBar = true;
      this.homeService.addGroundToPark(data).subscribe((response) => {
        console.log(response);
        this.loadingBar = false;
        if (response.status == 200) {
          $("#addGroundPopup").modal("hide");
          this.messageService.add({
            severity: "success",
            summary: "Report grounds update",
            detail: "Ground added Successfully",
          });
          $(".sprs_chck_box input:checkbox").removeAttr("checked");
          // this.getGrounds();
          this.getGroundsByPark(this.parkIdtoAddSlot);
        }
      });
    }
  }

  placesScript2() {
    var options = {
      types: ["(cities)"],
      componentRestrictions: {
        country: "us",
      },
    };
    var input = document.getElementById("searchTextField1");
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addDomListener(window, "load");
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      var place = autocomplete.getPlace();
      console.log("place", place);
      for (let i = 0; i < place.address_components.length; i++) {
        if (
          place.address_components[i].types.indexOf(
            "administrative_area_level_1"
          ) > -1
        ) {
          console.log("long name", place.address_components[i].long_name);
          this.parkform.patchValue({
            parkState: place.address_components[i].long_name,
          });
        }
      }
      localStorage.setItem("searchPlace", place.formatted_address);
    });
  }
  onCitySelect(cityid) {
    let selectedCity = this.citiesByLocation.filter((city) => {
      return city.value === cityid;
    });
    console.log(selectedCity);
    this.selectedCityName = selectedCity[0].label;
  }

  addPark() {
    console.log(this.parkform);
    if (
      this.parkform.controls["parkName"].status === "INVALID" ||
      this.parkform.controls["parkStreet"].status === "INVALID" ||
      this.parkform.controls["parkState"].status === "INVALID" ||
      this.parkform.controls["zipcode"].status === "INVALID"
    ) {
      this.checkaddParkValidation = true;
    } else {
      this.checkaddParkValidation = false;
      let data = {
        Park_Name: this.parkform.controls["parkName"].value,
        Street: this.parkform.controls["parkStreet"].value,
        // "City" : this.selectedCityName,
        Zip_Code: this.parkform.controls["zipcode"].value,
        State: this.parkform.controls["parkState"].value,
        City_Id: 166,
        User_Id: this.userId,
      };
      this.loadingBar = true;
      this.homeService.addParkRequest(data).subscribe((response) => {
        console.log(response);
        this.loadingBar = false;
        if (response.status == 200) {
          if (response.body.length === 0) {
            this.messageService.add({
              severity: "error",
              summary: "Zipcode Error",
              detail: "No city exists with zipcode " + this.parkZipcode,
            });
            document.getElementById("parkzip").focus();
          } else {
            this.messageService.add({
              severity: "success",
              summary: "Report parks update",
              detail: "Park added Successfully",
            });
            this.getAllParks();
            $("#createParkModal").modal("hide");
          }
        }
      });
    }
  }
  getAllotedSlots() {
    // this.showAllocated = true;
    let user_id = this.userId;
    this.loadingBar = true;
    this.slotsService.getUserAllotedSlots(user_id).subscribe((response) => {
      this.loadingBar = false;
      console.log(response);
      if (response.status === 200) {
        if (response.body.length > 0) {
          this.slotsAllocated = response.body;
        }
      }
    });
  }
  listViewOfAllottedSlots() {
    this.listviewAllotted = true;
    this.gridviewAllotted = false;
  }

  gridViewOfAllottedSlots() {
    this.listviewAllotted = false;
    this.gridviewAllotted = true;
  }

  getClass(item, c_type): void {
    if (c_type == "park") {
      // @ts-ignore
      return this.futureReservations.filter((i) => i.Park_Id == item).length > 0
        ? "active"
        : "inactive";
    } else if (c_type == "city") {
      // @ts-ignore
      return this.futureReservations.filter((i) => i.City == item).length > 0
        ? "active"
        : "inactive";
    }
  }
  onChangeCity() {
    if (this.slotcitySearch) {
      this.loadingBar = true;
      this.parksList = [];
      if (window.location.href.includes('crickworld')) {
        this.slotparkSearch = "215";
      }
      else{
        this.slotparkSearch = "all";
      }
      
      this.slotgroundSearch = "all";
      this.slotsportSearch = "all";
      this.homeService
        .getParksByCity(this.slotcitySearch)
        .subscribe((res: any) => {
          let data = res.body;
          if (data.length > 0) {
            data.map((o) => {
              const temp = {};
              temp["value"] = o.Park_Id;
              temp["label"] = o.Park_Name;
              temp["address"] = o.Park_Address;
              this.parksList.push(temp);
            });
          }
          this.filterReservations();

          this.onChangeSlotPark();
          this.onChangeSlotGround();
          this.onChangeSlotSport();

          if (this.parksList.length == 1) {
            this.slotparkSearch = this.parksList[0].value;
            this.onChangeSlotPark();
          }
          this.loadingBar = false;
        });
    }
  }

  getPreferredLocations(){
    this.loadingBar=true;
    this.homeService.getPreferredLocations(this.userId).subscribe((data) => {
      this.loadingBar=false;
      console.log('get location data', data);
      let temp = [];
        if (data.Location_Preferences.length === 0) {
          this.locationPreferrenceSet=false
          if(this.current_sports_preferences.length>0){
             this.sportPreferrenceSet=true
          }
          else{
            this.sportPreferrenceSet=false
          }
          $("#preferenceModal").modal("show");
        } else {
          this.locationPreferrenceSet=true
          if(this.current_sports_preferences.length>0){
            this.sportPreferrenceSet=true
           
          }
          else{
            $("#preferenceModal").modal("show");
            this.sportPreferrenceSet=false
          }
         
            data.Location_Preferences.forEach((o) => {
                
               
                temp.push(o.Location_Id);
                

            });
             temp = temp.map(num => String(num));
            this.slotLocationSearch=temp;
            setTimeout(function () {
              $('.selectpicker').selectpicker('refresh');
            }.bind(this),10);
            console.log('preferred locations',this.slotLocationSearch)
        }
    });

  }

  onChangeLocation() {
    if (this.slotLocationSearch) {
      this.loadingBar = true;
      this.parksList = [];
      this.slotparkSearch = "all";
      this.slotgroundSearch = "all";
      this.slotsportSearch = "all";
      this.homeService
        .getParksByLocation(this.slotLocationSearch.join(","))
        .subscribe((res: any) => {
          let data = res.body;
          if (data.length > 0) {
            data.map((o) => {
              const temp = {};
              temp["value"] = o.Park_Id;
              temp["label"] = o.Park_Name;
              temp["address"] = o.Park_Address;
              this.parksList.push(temp);
            });
          }
          this.filterReservations();

          this.onChangeSlotPark();
          this.onChangeSlotGround();
          this.onChangeSlotSport();

          if (this.parksList.length == 1) {
            this.slotparkSearch = this.parksList[0].value;
            this.onChangeSlotPark();
          }
          this.loadingBar = false;
        });
      console.log(this.slotLocationSearch.join(","))
    }
  }

  onChangeSlotPark() {
    if (this.slotparkSearch) {
      this.loadingBar = true;
      this.futureInitGrounds = [];
      this.slotgroundSearch = "all";
      this.slotsportSearch = "all";
      this.homeService
        .groundsList(this.slotparkSearch)
        .subscribe((res: any) => {
          this.futureInitGrounds = res;
          this.filterReservations();
          if (this.futureInitGrounds.length == 1) {
            this.slotgroundSearch = this.futureInitGrounds[0].Ground_Id;
            this.onChangeSlotGround();
          }
          this.loadingBar = false;
        });
    }
  }

  onChangeSlotGround() {
    this.loadingBar = true;
    this.slotsportSearch = "all";
    let park_id = this.slotparkSearch;
    let ground_id = this.slotgroundSearch;
    console.log("ground And Park Id", park_id, ground_id);
    this.homeService.sportsList(park_id, ground_id).subscribe((res: any) => {
      this.futureInitSports = res;
      this.filterReservations();
      if (this.futureInitSports.length == 1) {
        this.slotsportSearch = this.futureInitSports[0].Sport_Id;
        this.onChangeSlotSport();
      }
      this.loadingBar = false;
    });
  }

  onChangeSlotSport() {
    this.loadingBar = true;
    this.filterReservations();
  }

  onChangeSlotStatus() {
    this.filterReservations();
  }

  onStdtChange() {
    var frm_dt = new Date(this.fromDateSearch);
    var to_dt = new Date(this.toDateSearch);
    if (frm_dt.getTime() >= to_dt.getTime()) {
      frm_dt.setDate(frm_dt.getDate() + 10);
      this.toDateSearch = new Date(frm_dt);
    }
    this.filterReservations();
  }
  onChangeSlotSortby() {
    let loc =
      this.current_location.match(/\d+/).length > 0
        ? this.current_location.match(/\d+/)[0]
        : null;
    this.loadingBar = true;
    this.fromDateSearch = undefined;
    this.toDateSearch = undefined;
    this.slotcitySearch = "all";
    this.slotLocationSearch=[]
    this.slotparkSearch = "all";
    this.slotgroundSearch = "all";
    this.slotsportSearch = "all";
    this.slotStatus = "all";
    let data = {
      from_date: this.fromDateSearch,
      to_date: this.toDateSearch,
      city: this.slotcitySearch,
      park: this.slotparkSearch,
      ground: this.slotgroundSearch,
      sport: this.slotsportSearch,
      status: this.slotStatus,
      user_id: this.userId,
      sortBy: this.slotdatelocation,
      location: loc,
    };
    this.homeService.filterReservations(data).subscribe((res: any) => {
      this.loadingBar = false;
      if (res.length) {
        this.futureReservations = res;
        if (this.fromDateSearch === null) {
          this.fromDateSearch = "";
        }
      } else {
        this.futureReservations = [];
        this.no_slots_msg = res.message;
        this.date_range = res.dates;
        this.next_slots = res.slots;
        if (this.fromDateSearch === null) {
          this.fromDateSearch = "";
        }
      }
    });
  }
  onServiceChange(event, service) {
    this.isLivestreaming = service.Service_Name === "Live Streaming" ? true : false;
    this.sportQuals = [];
    this.qualRequests = [];
    this.serviceRequests=[]
   
    if(event.target.checked){
      console.log("inside check "+service.Service_Id)
      this.serviceRequests.push(service.Service_Id);
       this.selectedServiceSubscriptionId=this.userSubscribedServices.find(item=>(item.Service_Id==service.Service_Id)&&(item.Subscription_Status=='Active'))    
    } else {
      console.log("inside else")
      const index = this.serviceRequests.indexOf(service.Service_Id);
      this.serviceRequests.splice(index, 1);
      this.selectedServiceSubscriptionId=null
    }
    let ref_service_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['Service_Id'];
    let ref_sport_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['sports'].filter((e) => e['Sport_Name'] == 'Cricket')[0]['Sport_Id'];
    if(this.selectedGroundSportId === ref_sport_id && service.Service_Id === ref_service_id) {
      if(event.target.checked) {
        this.loadingBar = true;
        this.homeService.getSportQuals(this.selectedGroundSportId, service.Service_Id).subscribe((response) => {
          console.log('testing quals______', response.body)
          this.loadingBar = false;
          this.sportQuals = response.body;
        })
      } else {
        this.sportQuals = [];
        this.qualRequests = [];
      }
    }
  }

  onQualChange(event, qual) {
    console.log(event)
    console.log(qual)
    if(event.target.checked){
        // Add a new control in the arrayForm
        this.qualRequests.push(event.target.value);
    } else {
        // find the unselected element
        const index = this.qualRequests.indexOf(event.target.value);
        this.qualRequests.splice(index, 1);
    }
  }

  getRequestType(service, status) {
    let sr_name = this.servicesArr.filter((e) => e['Service_Id'] == service.Service_Id)[0]['Service_Name'];
    if(service.SR_Bid_Id) {
      if(service.Slot_Assigned_Time == null) {
        return sr_name + ' Bid Request awaiting';
      } else {
        return sr_name + ' Request ' + status;
      }
    } else {
      return sr_name + ' Request ' + status;
    }
  }

  getRequestImg(service, status) {
    let sr_name = this.servicesArr.filter((e) => e['Service_Id'] == service.Service_Id)[0]['Service_Name'];
    return sr_name.split(' ').join('_')
  }
  srNavigation(service, status) {
    console.log(service);
    if(service.Slot_Assigned_to_ProviderId){
      this.router.navigate(["/home/serviceRequests/" + service.Service_Request_Id + "/success"]);
    } else {
      this.router.navigate(["/home/serviceRequests/" + service.Service_Request_Id]);
    }
  }
  srNavigationBookSlot(service, status) {

    if (this.user == "false" || this.user == null) {
      this.streamUrl = service.Channel_Stream_Url;
      $("#signupmodal").modal("show");
    } else {
      if(service.Slot_Assigned_to_ProviderId&&service.Channel_Stream_Url!=null){
        window.location.href = service.Channel_Stream_Url
        }
    } 
  }


  onSwitchAdmin() {
    this.loadingBar = true;
    let params = sessionStorage.getItem('authorizationToken');
    
      this.userService.getAdminDetails(params).subscribe(data => {

        console.log('admin details', data);

        localStorage.setItem('cityAdmin', 'true');
        sessionStorage.setItem('cityId', data[0]['City_Id']);
        localStorage.setItem('cityId', data[0]['City_Id']);
        localStorage.setItem('repId', data[0]['Rep_Id']);
        localStorage.setItem('repName', data[0]['First_Name'] + ' ' + data[0]['Last_Name']);
        localStorage.setItem('repInitials', data[0]['First_Name'].charAt(0) + data[0]['Last_Name'].charAt(0));
        localStorage.setItem('paymentStatus',  data[0]['Payment_Status'])
        localStorage.setItem('planId',  data[0]['Plan_Id'])
        localStorage.setItem('planName',  data[0]['Plan_Name'])
        localStorage.setItem('subscriptionId',  data[0]['Subscription_Id'])
        localStorage.setItem('planPrice',  data[0]['Price'])
        localStorage.setItem('planUpdatedAt',  data[0]['Plan_Updated_At'])
        localStorage.setItem('sixMthPlan',  data[0]['active_six_months_subscription'])
        localStorage.setItem('User_Enabled',  data[0]['User_Enabled'])

        const params1 = {
          'cityId': sessionStorage.getItem('cityId'),
          'authorizationToken': sessionStorage.getItem('authorizationToken')
        };

        this.parksservice.getParks(params1).subscribe(response => {
        this.loadingBar = false;
        var noofParks;
        //for no parks admin
        if(localStorage.getItem('repName')=='madhu m'){
            noofParks=0;
        }
        else{
            noofParks=response.body.length;
        }
        //for no parks admin
        
        if (data[0]['Default_Park_Id'] === null || data[0]['Default_Park_Id'] === undefined) {
            this.router.navigate(['/parkDefaults']);
        }
        else if(noofParks==0){
            localStorage.setItem('defaultParkId', 'temp');
            this.router.navigate(['/admin/parks']);
        }
        else {
            localStorage.setItem('defaultParkId', data[0]['Default_Park_Id']);
            localStorage.setItem('defaultParkName', data[0]['Park_Name']);
            this.router.navigate(['/admin']);
        }
    })
    });
  }
  
  getUserSubscriptionDetails() {
    this.loadingBar = true;
    this.homeService.getUserSubscriptionDetails(this.userId).subscribe((res: any)=> {
      this.loadingBar = false;
      this.userSubscribedServices = res.body;

    })
  }
  onClickTeam(){
    
     $("#createTeamModal1").modal('show');
     //$("#addslotmodal").modal('hide');

     
   
 }

 loadVideos() {
  this.homeService
    .getVideoList(this.apiKey,this.channelId)
    .subscribe((res: any) => {
      console.log("test loadvideos");
      if (res) {
        console.log(res)
        let items=res.items;
        items.map((o) => {
        if(o.snippet.liveBroadcastContent=="upcoming" && this.upcomingVideoUrl.length<3 && o.id.videoId){
          console.log("enetered");
          this.upcomingVideoUrl.push(`https://www.youtube.com/embed/${o.id.videoId}`);
        }
        else if(o.snippet.liveBroadcastContent!="upcoming" && this.latestVideoUrl.length <3 && o.id.videoId){
          this.latestVideoUrl.push(`https://www.youtube.com/embed/${o.id.videoId}`);
        }
        });
        if(!this.upcomingVideoUrl.length){
          this.upcomingVideoFlag="0";
        }
        this.upcomingVideourlList= this.upcomingVideoUrl.map(video =>  this.sanitizer.bypassSecurityTrustResourceUrl(video));
        this.latestVideourlList= this.latestVideoUrl.map(video =>  this.sanitizer.bypassSecurityTrustResourceUrl(video));
      }
    });
 }


 getFutureMyServiceRequests() {
  this.homeService
    .getFutureMyServiceRequests(this.userId, "future")
    .subscribe((res: any) => {
      // this.futureSessionRes = res.reservations.slice(0,4);
      this.myServiceReqRes = res;
      console.log("THE FUTURE SESSION DATA my service IS...")
      console.log(this.futureSessionRes)
    });
}

getSessionHistory(){
  this.userService.getsessionHistory(this.userId).subscribe(data => {
    if(data.history.length==0){
      this.sessionHistory=[];
    }
    else{
      this.sessionHistory=data.history;
    }
  },err => {
    console.log('this is the error', err);
    this.sessionHistory=[];
  })
}

}

import { ConfirmationService } from 'primeng/api';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import { SlotsService } from '../../../service/slots.service';
import { Slot } from '../../../models/slot';
import { Ground } from '../../../models/ground';
import { Sport } from '../../../models/sport';
import { Reservation } from '../../../models/reservation';
import { CalendarModule } from 'primeng/primeng';
import * as moment from 'moment';
import 'moment-timezone';
import { Router, RouterModule } from '@angular/router';
import { CognitoUtil } from '../../../service/cognito.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
declare let $;

@Component({
    selector: 'app-slots-calendar',
    templateUrl: './slots-calendar.component.html',
    styleUrls: ['./slots-calendar.component.css']
})
export class SlotsCalendarComponent implements OnInit, OnChanges {
    @Output() slotsCountChange = new EventEmitter<any>();
    @Output() slotsCount = new EventEmitter<any>();
    @Output() errorOut = new EventEmitter<any>();
    @Output() unauthorizedOut = new EventEmitter<any>();
    @Input() selectedGround;
    @Input() selectedSport;
    @Input() selectedGroundName;
    @Output() ondateclick = new EventEmitter<any>();

    selectedSlot = new Slot();
    showDtls: boolean = false;
    cancellationReason = '';
    selectedReservation = new Reservation();
    slotsCategoryCount = [];
    selectedSlotReservationDetails = {};
    calendarOptions: Options;
    customApprove: boolean;
    public allReservations = [];
    public allGrounds = [];
    public allSports = [];
    unauthorized = false;
    error = false;
    meridian = true;
    events: any;
    showSlotCustomDates: any;
    showScheduleCustomDates: any;
    displayEvent: any;
    selectedPark: any;
    deleteDialog: boolean;
    next_month: string;
    ngStartTime = {};
    ngEndTime = {};
    @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;
    isChangedToSlot: boolean = false;
    loadingBar = false;
    isChangedToSchedule: boolean = false;
    reservationSelect = [];
    scheduleDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    openWeekDays = [{ label: 'Monday', value: 'Monday' }, { label: 'Tuesday', value: 'Tuesday' }, { label: 'Wednesday', value: 'Wednesday' }, { label: 'Thursday', value: 'Thursday' }, { label: 'Friday', value: 'Friday' }, { label: 'Saturday', value: 'Saturday' }, { label: 'Sunday', value: 'Sunday' }];
    repeatDays = {};
    first_day = moment().startOf('month').format('YYYY-MM-DD');
    last_day = moment().endOf('month').format('YYYY-MM-DD');
    editItems = [];
    payreq_name;
    payreq_email;
    payreq_mobilenumber;
    payreq_id;
    disabled_option: boolean = false;
    paypal: any;
    showScheduleWeekDays: any;
    editSlotForm: FormGroup;
    editSlotcheduleForm: FormGroup;
    resvInfoSlotForm: FormGroup;
    checkValidation: boolean = false;
    checkscheValidation: boolean = false;
    atLeastOneIsChecked: any;
    link_email: string;
    slot_link: string;
    copy_btn_txt: string = "Copy Link";
    email_msg: string;
    emailErr: boolean = false;
    resvInfoSlot: boolean;
    resvInfoFormSubmit: boolean = false;
    lastDayOfMonth: string;
    atleastOneChecked: boolean = false;
    timeError: boolean = false;

    constructor(
        public slotsService: SlotsService,
        public cognitoUtil: CognitoUtil,
        public confirmationservice: ConfirmationService,
        public router: Router,
        private messageService: MessageService,
        private formbuilder: FormBuilder
    ) {
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        this.editSlotForm = this.formbuilder.group({
            'groundId': new FormControl('', Validators.required),
            'sportId': new FormControl('', Validators.required),
            'date': new FormControl('', Validators.required),
            'startTime': new FormControl('', Validators.required),
            'endTime': new FormControl('', Validators.required),
            'slotCategory': new FormControl('', Validators.required),
            'openDate': new FormControl('', Validators.required),
            'closeDate': new FormControl('', Validators.required),
            'autoFIFO': new FormControl('', Validators.required),
            'paidSlot': new FormControl('', Validators.required),
            'slotPrice': new FormControl('', Validators.required),
            'isPrivateSlot': new FormControl('', Validators.required),
            'payByCash': new FormControl(''),
            'password': new FormControl('', Validators.required),
            'cnf_pass': new FormControl('', Validators.required),
            'team': new FormControl('', Validators.required),
            'resident': new FormControl('', Validators.required),
            'informationSlot': new FormControl('', Validators.required),
            'comments': new FormControl('', Validators.required),
            'bookingURL': new FormControl('', [Validators.required, Validators.pattern(reg)]),
            'nonReserveSlot': new FormControl('', Validators.required),
            'fcfsSlot' : new FormControl('',Validators.required),
            'serviceFee':new FormControl(''),
            'leagueName':new FormControl('')
        });

        this.editSlotcheduleForm = this.formbuilder.group({
            'groundId': new FormControl('', Validators.required),
            'sportId': new FormControl('', Validators.required),
            'date': new FormControl('', Validators.required),
            'startDate': new FormControl('', Validators.required),
            'endDate': new FormControl('', Validators.required),
            'startTime': new FormControl('', Validators.required),
            'endTime': new FormControl('', Validators.required),
            'scheduleCategory': new FormControl('', Validators.required),
            'autoFIFO': new FormControl('', Validators.required),
            'paidSlot': new FormControl('', Validators.required),
            'slotPrice': new FormControl('', Validators.required),
            'openBefore': new FormControl('', Validators.required),
            'closeBefore': new FormControl('', Validators.required),
            'day': new FormControl('', Validators.required),
            'isPrivateSlot': new FormControl('', Validators.required),
            'password': new FormControl('', Validators.required),
            'cnf_pass': new FormControl('', Validators.required),
            'payByCash': new FormControl(''),
            'team': new FormControl('', Validators.required),
            'resident': new FormControl('', Validators.required),
            'informationSlot': new FormControl('', Validators.required),
            'comments': new FormControl('', Validators.required),
            'bookingURL': new FormControl('', [Validators.required, Validators.pattern(reg)]),
            'nonReserveSchedule': new FormControl('', Validators.required),
            'fcfsSlot' : new FormControl('',Validators.required),
            'serviceFee':new FormControl(''),
            'leagueName':new FormControl('')
        });

        this.resvInfoSlotForm = this.formbuilder.group({
            'name': new FormControl('', Validators.required),
            'email': new FormControl('', [Validators.required, Validators.email]),
            'phone': new FormControl('', Validators.required)
        })
    }

    ngOnInit() {
        this.selectedPark = localStorage.getItem('defaultParkName');
        this.paypal = localStorage.paypal;
        this.resvInfoSlot = false;
        // $('.cnfrm_dlt').attr('disabled', 'disabled');

        if (localStorage.getItem('planName') == 'Basic') {
            setTimeout(function () {
                $(".switch__toggle").prop('disabled', true);
                $(".info_slot").prop('disabled', false);
                $(".info_schedle").prop('disabled', false);
            }.bind(this), 1000);
        }

    }


    reserveInfoSlot() {
        this.resvInfoFormSubmit = true;
        if (this.resvInfoSlotForm.valid) {
            $("#availableSlot").modal("hide");
            this.loadingBar = true;
            let data = {
                ...this.resvInfoSlotForm.value,
                repId: localStorage.getItem("repId"),
                cityId: localStorage.getItem("cityId"),
                slotId: this.selectedSlot.slotId
            }

            this.slotsService.assignInfoSlot(data)
                .subscribe((res: any) => {
                    this.loadingBar = false;
                    this.messageService.add({ severity: 'success', summary: 'Information Slot', detail: 'Slot Assigned Successfully' });
                    console.log('assign info slot', res);
                    this.reloadSlots();
                });
        }
    }

    onInformationSlot(slot) {
        console.log("@@@@@@@@@@@", slot)
        if (slot == false) {
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['autoFIFO'] = false;
            this.selectedSlot['paidSlot'] = false;
            this.selectedSlot['nonReserveSlot'] = false;
            this.selectedSlot['fcfsSlot'] = false;
            // $(".switch__toggle").prop('disabled', true);
            // $(".info_slot").prop('disabled', false);
        }
        else if (slot == true) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            $(".switch__toggle").prop('disabled', false);
            $(".info_slot").prop('disabled', false);
            if (localStorage.getItem('planName') == 'Basic') {
                this.selectedSlot['autoFIFO'] = true;
                this.selectedSlot['team'] = false;
                this.selectedSlot['resident'] = false;
                // $(".switch__toggle").prop('disabled', true);
                // $(".info_slot").prop('disabled', false);
            }
        }
    }

    onfcfsSlot(slot) {
        if (slot) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            // this.selectedSlot['team'] = false;
            // this.selectedSlot['resident'] = false;
            // this.selectedSlot['informationSlot'] = false;
            // this.selectedSlot['nonReserveSlot'] = false;
        }
        else if (!slot) {
            // this.selectedSlot['team'] = true;
            // this.selectedSlot['resident'] = true;
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['informationSlot'] = false;
            this.selectedSlot['nonReserveSlot'] = false;
        }

    }

    onNonReserveSlot(slot) {
        console.log("Non Resv", slot)
        if (slot) {
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['autoFIFO'] = true;
            this.selectedSlot['informationSlot'] = false;
            this.selectedSlot['fcfsSlot'] = false;
            // $(".switch__toggle").prop('disabled', true);
            // $(".nonresv_slot").prop('disabled', false);
            // $(".auto_fifo").prop('disabled', false);
        }
        else if (!slot) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            this.selectedSlot['autoFIFO'] = false;
            // $(".switch__toggle").prop('disabled', false);
        }
    }

    onNonReserveSchedule(slot) {
        console.log("Non Resv", slot)
        if (slot) {
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['autoFIFO'] = true;
            this.selectedSlot['informationSlot'] = false;
            this.selectedSlot['fcfsSlot'] = false;
            // $(".switch__toggle").prop('disabled', true);
            // $(".nonresv_schedule").prop('disabled', false);
            // $(".auto_fifo").prop('disabled', false);
        }
        else if (!slot) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            this.selectedSlot['autoFIFO'] = false;
            // $(".switch__toggle").prop('disabled', false);
        }
    }

    onInformationSchedule(schedule) {
        console.log("!!!!!!!!!", schedule)
        if (schedule == false) {
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['autoFIFO'] = false;
            this.selectedSlot['paidSlot'] = false;
            this.selectedSlot['nonReserveSlot'] = false;
            this.selectedSlot['fcfsSlot'] = false;
            // $(".switch__toggle").prop('disabled', true);
            // $(".info_schedle").prop('disabled', false);
        }
        else if (schedule == true) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            // $(".switch__toggle").prop('disabled', false);
            // $(".info_schedle").prop('disabled', false);
            if (localStorage.getItem('planName') == 'Basic') {
                this.selectedSlot['autoFIFO'] = true;
                this.selectedSlot['team'] = false;
                this.selectedSlot['resident'] = false;
                // $(".switch__toggle").prop('disabled', true);
                // $(".info_slot").prop('disabled', false);
            }
        }
    }

    onfcfsSchedule(schedule) {
        if (schedule == false) {
            this.selectedSlot['team'] = false;
            this.selectedSlot['resident'] = false;
            this.selectedSlot['autoFIFO'] = false;
            this.selectedSlot['paidSlot'] = false;
            this.selectedSlot['nonReserveSlot'] = false;
            this.selectedSlot['informationSlot'] = false;
            // $(".switch__toggle").prop('disabled', true);
            // $(".info_schedle").prop('disabled', false);
        }
        else if (schedule == true) {
            this.selectedSlot['team'] = true;
            this.selectedSlot['resident'] = true;
            // $(".switch__toggle").prop('disabled', false);
            // $(".info_schedle").prop('disabled', false);
            if (localStorage.getItem('planName') == 'Basic') {
                this.selectedSlot['autoFIFO'] = true;
                this.selectedSlot['team'] = false;
                this.selectedSlot['resident'] = false;
                // $(".switch__toggle").prop('disabled', true);
                // $(".info_slot").prop('disabled', false);
            }
        }
    }


    ngOnChanges() {
        console.log('Inside on changes : Ground change, Ground Id is', this.selectedGround);
        console.log('Inside on changes : Sport change, Sport Id is', this.selectedSport);
        const params = {
            'first_date': this.first_day,
            'last_date': this.last_day
        };
        if (this.selectedGround !== undefined) {
            this.getSlots(params);
        }
    }

    onDateClick(event) {
        this.ondateclick.emit(event.date.format('L'));
    }

    clickButton(model: any) {
        this.displayEvent = model;
        let self = this;
        if (this.displayEvent.buttonType === 'next') {
            let date1 = $('.fc-center h2');
            let arrFromList = Array.prototype.slice.call(date1);
            let next_month = arrFromList[0].innerHTML;
            self.datesOfMonth(next_month);
        } else if (this.displayEvent.buttonType === 'prev') {
            let date1 = $('.fc-center h2');
            let arrFromList = Array.prototype.slice.call(date1);
            let prev_month = arrFromList[0].innerHTML;
            self.datesOfMonth(prev_month)
        } else if (this.displayEvent.buttonType === 'month') {
            console.log('month button clicked')
            let date = $('.fc-center h2')
            let month1 = Array.prototype.slice.call(date);
            let month = month1[0].innerHTML;
            self.datesOfMonth(month);
        } else if (this.displayEvent.buttonType === 'agendaWeek') {
            console.log('week button clicked')
            let date = $('.fc-center h2')
            let weekList = Array.prototype.slice.call(date);
            let week = weekList[0].innerHTML;
            self.datesOfMonth(week);
        } else if (this.displayEvent.buttonType === 'agendaDay') {
            console.log('day button clicked')
            let date = $('.fc-center h2')
            let day1 = Array.prototype.slice.call(date);
            let day = day1[0].innerHTML;
            console.log('in day ', day)
            self.datesOfMonth(day);
        } else if (this.displayEvent.buttonType === 'listMonth') {
        }
    }

    datesOfMonth(month) {
        this.messageService.clear();
        let a = month.substr(0, month.indexOf(' '));
        let b = month.substr(month.indexOf(' ') + 1);
        let con_mon = moment().month(a).format('MM');
        let con_month = b + '-' + con_mon;
        console.log('month is', moment(con_month + '-01').add(-15, 'days').format('YYYY-MM-DD'));
        console.log('data::', con_month);
        this.first_day = moment(con_month + '-01').format('YYYY-MM-DD');
        this.last_day = moment(con_month + '-31').format('YYYY-MM-DD');
        if (this.last_day === 'Invalid date') {
            this.last_day = moment(con_month + '-30').format('YYYY-MM-DD');
            if (this.last_day === 'Invalid date') {
                this.last_day = moment(con_month + '-28').format('YYYY-MM-DD');
                console.log('start date::', this.first_day);
                console.log('end date::', this.last_day);
                const params = {
                    'first_date': moment(con_month + '-01').format('YYYY-MM-DD'),
                    'last_date': this.last_day
                };
                this.getSlots(params);
            } else {
                console.log('start date::', this.first_day);
                console.log('end date::', this.last_day);
                const params = {
                    'first_date': moment(con_month + '-01').format('YYYY-MM-DD'),
                    'last_date': this.last_day
                };
                this.getSlots(params);
            }
        } else {
            console.log('start date::', this.first_day);
            console.log('end date::', this.last_day);
            const params = {
                'first_date': moment(con_month + '-01').format('YYYY-MM-DD'),
                'last_date': this.last_day
            };
            this.getSlots(params);
        }
    }

    eventClick(model: any) {
        if (model.event.Date > this.lastDayOfMonth) {
            return;
        }
        model = {
            event: {
                id: model.event.id,
                start: model.event.start,
                end: model.event.end,
                title: model.event.title,
                allDay: model.event.allDay,
                available: model.event.available,
                reserved: model.event.reserved,
                reservation: model.event.reservation,
                requested: model.event.requested,
                Sport_Id: model.event.Sport_Id,
                Ground_Id: model.event.Ground_Id,
                Date: model.event.Date,
                Slot: model.event.Slot,
                Slot_Status: model.event.Slot_Status,
                Schedule_Id: model.event.Schedule_Id,
                Rep_Id: model.event.Rep_Id,
                Rep_Name: model.event.Rep_Name,
                Park_Id: model.event.Park_Id,
                Auto_FIFO: model.event.Auto_FIFO,
                Price: model.event.Price,
                Slot_Type: model.event.Slot_Type,
                Open_Date: model.event.Open_Date,
                Close_Date: model.event.Close_Date,
                Slot_Category: model.event.Slot_Category,
                isPrivate: model.event.isPrivate,
                password: model.event.password,
                hash_key: model.event.hash_key,
                Pay_By_Cash: model.event.Pay_By_Cash,
                Ask_Team_Name: model.event.Ask_Team_Name,
                Ask_Resident_Of: model.event.Ask_Resident_Of,
                Comments: model.event.Comments,
                BookingUrl: model.event.BookingUrl,
                Service_Fee: model.event.Service_Fee,
                League_Name: model.event.League_Name,
            },
            duration: {}
        };
        console.log('selected calender event: ', model);
        if (model.event.reserved === true && (this.router.url === '/admin' || this.router.url === '/admin/slots')) {
            // this.loadingBar = true;
            this.cognitoUtil.refresh();
            const params = {
                'slotId': model.event.id,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken')
            };
            this.loadingBar = true;
            this.slotsService.getReservationDetails(params).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    if (response.body.length === 0) {
                        this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'No reservations exist' });
                        this.loadingBar = false;
                    } else {
                        console.log('this is the data', response.body[0]);
                        this.selectedSlotReservationDetails = {
                            'email': response.body[0].Email,
                            'phoneNumber': response.body[0].Phone_Number,
                            'name': response.body[0].First_Name + ' ' + response.body[0].Last_Name
                        };
                        this.loadingBar = false;
                    }
                }
            },
                err => { this.errorHandle(err) });
        } else if (model.event.reservation === true && (this.router.url === '/admin' || this.router.url === '/admin/slots')) {
            this.loadingBar = true;
            this.cognitoUtil.refresh();
            const params = {
                'slotId': model.event.id,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken')
            };
            this.slotsService.getReservationRequests(params).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    if (response.body.length === 0) {
                        this.loadingBar = false;
                        this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'No reservations exist' });
                    } else {
                        this.allReservations = response.body;
                        this.loadingBar = false;
                    }
                }
            },
                err => { this.errorHandle(err) });
        } else if (model.event.requested === true && (this.router.url === '/admin' || this.router.url === '/admin/slots')) {
            this.loadingBar = true;
            this.cognitoUtil.refresh();
            const params = {
                'slotId': model.event.id,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken')
            };
            this.slotsService.getReservationRequests(params).subscribe(response => {
                this.selectedSlotReservationDetails['name'] = '';
                this.selectedSlotReservationDetails['email'] = '';
                this.selectedSlotReservationDetails['phoneNumber'] = '';
                this.selectedSlotReservationDetails['reservationId'] = '';
                console.log('The response is', response);
                if (response.status === 200) {
                    if (response.body.length === 0) {
                        this.loadingBar = false;
                        this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'No reservations exist' });
                    } else {
                        this.selectedSlotReservationDetails['name'] = response.body[0]['First_Name'] + ' ' + response.body[0]['Last_Name'];
                        this.selectedSlotReservationDetails['email'] = response.body[0]['Email'];
                        this.selectedSlotReservationDetails['phoneNumber'] = response.body[0]['Phone_Number'];
                        this.selectedSlotReservationDetails['reservationId'] = response.body[0]['Reservation_Id'];
                        this.loadingBar = false;
                    }
                }
            },
                err => { this.errorHandle(err) });
        }
        this.setSlotOpenDates(model.event.Slot_Category);
        // this.selectedSlot['openDate'] = new Date((moment(model.event.Open_Date).add(1, 'days')).format('llll'));
        // this.selectedSlot['closeDate'] = new Date((moment(model.event.Close_Date).add(1, 'days')).format('llll'));
        this.selectedSlot['openDate'] = new Date((moment(model.event.Open_Date)).format('llll'));
        this.selectedSlot['closeDate'] = new Date((moment(model.event.Close_Date)).format('llll'));
        this.selectedSlot['slotCategory'] = model.event.Slot_Category;
        this.selectedSlot['slotId'] = model.event.id;
        this.selectedSlot['sportId'] = model.event.Sport_Id;
        this.selectedSlot['oldSportId'] = model.event.Sport_Id;
        this.selectedSlot['sport'] = model.event.title;
        this.selectedSlot['groundId'] = model.event.Ground_Id;
        this.selectedSlot['date'] = model.event.Date;
        this.selectedSlot['oldDate'] = model.event.Date;
        this.selectedSlot['startDate'] = new Date(new Date(model.event.start.toISOString()).setHours(0));
        this.selectedSlot['endDate'] = new Date(model.event.end);
        this.selectedSlot['slot'] = model.event.Slot;
        this.selectedSlot['oldSlot'] = model.event.Slot;
        this.selectedSlot['slotStatus'] = model.event.Slot_Status;
        this.selectedSlot['scheduleId'] = model.event.Schedule_Id;
        this.selectedSlot['repId'] = model.event.Rep_Id;
        this.selectedSlot['parkId'] = model.event.Park_Id;
        this.selectedSlot['repName'] = model.event.Rep_Name;
        this.selectedSlot['startTime'] = (this.selectedSlot['slot'].split(' - '))[0];
        this.selectedSlot['autoFIFO'] = JSON.parse(model.event.Auto_FIFO);
        this.selectedSlot['paidSlot'] = model.event.Slot_Type;
        this.selectedSlot['slotPrice'] = model.event.Price;
        this.selectedSlot['hash_key'] = model.event.hash_key;
        this.selectedSlot['endTime'] = (this.selectedSlot['slot'].split(' - '))[1];
        this.selectedSlot['isPrivate'] = model.event.isPrivate == 'true' ? true : false;
        this.selectedSlot['password'] = model.event.password == 'null' ? '' : model.event.password;
        this.selectedSlot['cnf_pass'] = "";
        this.selectedSlot['payByCash'] = model.event.Pay_By_Cash === 'true' ? true : false;
        this.selectedSlot['team'] = JSON.parse(model.event.Ask_Team_Name);
        this.selectedSlot['resident'] = JSON.parse(model.event.Ask_Resident_Of);
        this.selectedSlot['comments'] = model.event.Comments;
        this.selectedSlot['bookingURL'] = model.event.BookingUrl;
        this.selectedSlot['serviceFee'] = model.event.Service_Fee;
        this.selectedSlot['leagueName'] = model.event.League_Name;




        let startHour, endHour;
        console.log('this is the 24 hour format time:', this.selectedSlot['startTime']);
        startHour = (moment(this.selectedSlot['startTime'], ["h:mm A"]).format('HH:mm')).split(':')[0];
        endHour = (moment(this.selectedSlot['endTime'], ["h:mm A"]).format('HH:mm')).split(':')[0]
        this.ngStartTime = {
            hour: startHour,
            minute: ((this.selectedSlot['startTime']).split(':')[1]).split(" ")[0]
        };

        this.ngEndTime = {
            hour: endHour,
            minute: ((this.selectedSlot['endTime']).split(':')[1]).split(" ")[0]
        };
        this.repeatDays['MON'] = false;
        this.repeatDays['TUE'] = false;
        this.repeatDays['WED'] = false;
        this.repeatDays['THU'] = false;
        this.repeatDays['FRI'] = false;
        this.repeatDays['SAT'] = false;
        this.repeatDays['SUN'] = false;
        console.log('this is the selected selectedSlot', this.selectedSlot);

        if (model.event.available) {
            document.getElementById('available-slot').click();
        } else if (model.event.reserved) {
            document.getElementById('reserved-slot').click();
        } else if (model.event.reservation) {
            document.getElementById('reservation-slot').click();
        } else if (model.event.requested) {
            document.getElementById('requested-slot').click();
        } else {
            document.getElementById('cancel-slot').click();
        }

        // calendar actions
        let Today = moment(new Date()).utc().format('YYYY-MM-DD');
        console.log('today', Today, this.selectedSlot['date']);
        if (this.selectedSlot['date'] < Today) {
            $('.share_btn').prop('disabled', true);
            $('.cncel_btn').prop('disabled', false);
            $('.reset_btn').prop('disabled', true);
            $('.delete_btn').prop('disabled', false);
            $('.edit_btn').prop('disabled', true);

            $('.reenter_btn').prop('disabled', true);
            $('.save_btn').prop('disabled', false);
        }
        else {
            $('.share_btn').prop('disabled', false);
            $('.cncel_btn').prop('disabled', false);
            $('.reset_btn').prop('disabled', false);
            $('.delete_btn').prop('disabled', false);
            $('.edit_btn').prop('disabled', false);

            $('.reenter_btn').prop('disabled', false);
            $('.save_btn').prop('disabled', false);
        }
        // calendar actions
    }
    customButton() {
        this.customApprove = true;
    }
    setSlotOpenDates(params) {
        if (params !== 'custom') {
            this.showSlotCustomDates = false;
        } else {
            this.showSlotCustomDates = true;
        }
    }
    setScheduleOpenDates(params) {
        if (params !== 'custom') {
            if (params === 'default') {
                this.showScheduleWeekDays = true;
                this.showScheduleCustomDates = false;
            } else if (params === 'always') {
                this.showScheduleCustomDates = false;
                this.showScheduleWeekDays = false;
            }
        } else {
            this.showScheduleCustomDates = true;
            this.showScheduleWeekDays = false;
        }
        // this.createSchedule.scheduleCategory = params;
        // console.log('This is the slot open category', this.createSchedule.scheduleCategory);
    }
    getSlots(dataIn) {
        // $('#loadingModal').modal('show');
        this.loadingBar = true;
        // this.cognitoUtil.refresh();
        console.log('Selected ground is: ', this.selectedGroundName);
        console.log('slot dates:', dataIn);
        if (this.selectedGroundName !== undefined) {
            if (this.selectedSport === '' || this.selectedSport === undefined) {
                const params = {
                    'startDate': moment(dataIn.first_date).format('YYYY-MM-DD'),
                    'endDate': moment(dataIn.last_date).add(15, 'days').format('YYYY-MM-DD'),
                    'authorizationToken': sessionStorage.getItem('authorizationToken'),
                    'parkId': localStorage.getItem('defaultParkId'),
                    'cityId': sessionStorage.getItem('cityId'),
                    'groundId': this.selectedGround
                };
                console.log('Input to get slots are', params);
                const slotsSummary = {};
                this.slotsService.getSlots(params).subscribe(response => {
                    console.log('The response is', response);
                    if (response.status === 200) {
                        if (response.body.length === 0) {
                            this.events = [];
                            this.loadingBar = false;
                            console.log('at first');
                            // $('#loadingModal').modal('hide');
                            this.calendarOptions = {
                                editable: true,
                                eventLimit: false,
                                header: {
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'month,agendaWeek,agendaDay,listMonth'
                                },
                                events: response.body,
                            };
                            // alert('No slots are available');
                        } else {
                            // this.calendarOptions = {};
                            this.calendarOptions = {
                                editable: true,
                                eventLimit: false,
                                header: {
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'month,agendaWeek,agendaDay,listMonth'
                                },
                                events: response.body,
                            };
                            this.events = response.body;
                            let availableCount = 0;
                            let reservedCount = 0;
                            let pendingCount = 0;
                            let requestedCount = 0;
                            let canceledCount = 0;
                            let infoSlotCount = 0;
                            for (let i = 0; i < response.body.length; ++i) {
                                console.log(1212, response.body[i]);
                                if (this.router.url !== '/admin' && this.router.url !== '/admin/slots') {
                                    response.body[i]['color'] = '#9e9e9e';
                                }
                                if (response.body[i]['available'] === true && response.body[i]['Slot_Type'] != "Information") {
                                    availableCount++;
                                } else if (response.body[i]['reservation'] === true) {
                                    pendingCount++;
                                } else if (response.body[i]['reserved'] === true) {
                                    reservedCount++;
                                } else if (response.body[i]['requested'] === true) {
                                    requestedCount++;
                                } else if (response.body[i]['information'] === true) {
                                    infoSlotCount++;
                                } else {
                                    canceledCount++;
                                }

                            }
                            slotsSummary['availableCount'] = availableCount;
                            slotsSummary['reservedCount'] = reservedCount;
                            slotsSummary['pendingCount'] = pendingCount;
                            slotsSummary['requestedCount'] = requestedCount;
                            slotsSummary['canceledCount'] = canceledCount;
                            slotsSummary['infoSlotCount'] = infoSlotCount;
                            this.slotsCountChange.emit(slotsSummary);
                        }
                        this.loadingBar = false;
                        this.slotsCount.emit(this.events.length);
                    }
                },
                    err => { this.errorHandle(err) });
                console.log('calender input data: ', this.calendarOptions);
            } else {
                this.loadingBar = true;
                this.cognitoUtil.refresh();
                const params = {
                    'startDate': moment(dataIn.first_date).format('YYYY-MM-DD'),
                    'endDate': moment(dataIn.last_date).add(15, 'days').format('YYYY-MM-DD'),
                    'authorizationToken': sessionStorage.getItem('authorizationToken'),
                    'parkId': localStorage.getItem('defaultParkId'),
                    'cityId': sessionStorage.getItem('cityId'),
                    'groundId': this.selectedGround + '',
                    'sportId': this.selectedSport
                };
                console.log('Input to get slots are', params);
                const slotsSummary = {};
                this.slotsService.getSlots(params).subscribe(response => {
                    console.log('The response is', response);
                    if (response.status === 200) {
                        if (response.body.length === 0) {
                            this.events = [];
                            this.loadingBar = false;
                            console.log('at second');
                            this.messageService.add({ severity: 'info', summary: 'Slots Update', detail: 'No slots to display' });
                        } else {
                            this.events = response.body;
                            let availableCount = 0;
                            let reservedCount = 0;
                            let pendingCount = 0;
                            let requestedCount = 0;
                            let canceledCount = 0;
                            for (let i = 0; i < response.body.length; ++i) {
                                if (this.router.url !== '/admin' && this.router.url !== '/admin/slots') {
                                    response.body[i]['color'] = '#9e9e9e';
                                }
                                if (response.body[i]['available'] === true) {
                                    availableCount++;
                                } else if (response.body[i]['reservation'] === true) {
                                    pendingCount++;
                                } else if (response.body[i]['reserved'] === true) {
                                    reservedCount++;
                                } else if (response.body[i]['requested'] === true) {
                                    requestedCount++;
                                } else {
                                    canceledCount++;
                                }
                            }
                            slotsSummary['availableCount'] = availableCount;
                            slotsSummary['reservedCount'] = reservedCount;
                            slotsSummary['requestedCount'] = requestedCount;
                            slotsSummary['pendingCount'] = pendingCount;
                            slotsSummary['canceledCount'] = canceledCount;
                            this.slotsCountChange.emit(slotsSummary);
                        }
                        this.loadingBar = false;
                    }
                },
                    err => { this.errorHandle(err) });
                console.log('reservations are: ', this.calendarOptions);
            }
        }
    }
    errorHandle(err) {
        if (err.status === 401) {
            this.loadingBar = false;
            this.unauthorized = true;
            this.unauthorizedOut.emit(this.unauthorized)
            // this.router.navigate(['/admin/unauthorized']);
        } else if (err.status === 400 || err.status === 404 || err.status === 500) {
            this.loadingBar = false;
            this.error = true;
            this.errorOut.emit(this.error)
            // this.router.navigate(['/admin/error']);
        } else {
            this.loadingBar = false;
            this.error = true;
            this.errorOut.emit(this.error)
            // this.router.navigate(['/admin/error']);
        }
    }
    closeEdit() {
        this.selectedSlot = new Slot();
        $("#radio1").prop("checked", false);
        $("#radio2").prop('checked', false);
    }
    getSportsByGround() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        this.allSports = [];
        console.log('ground id to get sports', this.selectedGround);
        // console.log('get sports for', inputParams);
        this.selectedSport = '';
        const params = {
            'groundId': this.selectedGround,
            'cityId': sessionStorage.getItem('cityId'),
            'authorizationToken': sessionStorage.getItem('authorizationToken')
        };
        this.slotsService.getSports(params).subscribe(response => {
            console.log('The response is', response);
            // this.allSports = [];
            if (response.status === 200) {
                this.allSports = [];
                if (response.body.length === 0) {
                    this.messageService.add({ severity: 'warn', summary: 'Grounds Update', detail: 'No sports in selected ground' });
                    this.loadingBar = false;
                } else {
                    response.body.forEach((o) => {
                        console.log(o);
                        // let obj = new Sport();
                        const temp = {};
                        temp['value'] = o.Sport_Id;
                        temp['label'] = o.Sport_Name;
                        this.allSports.push(temp);
                    });
                    this.loadingBar = false;
                    console.log('sportList data is: ', this.allSports);
                }
            }
        },
            err => { this.errorHandle(err) });
    }
    editSlot(dataIn) {
        this.timeError = false;
        // $('.asgn_prsn_slct label').removeClass('background_none');
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
        document.getElementById('close-button').click();
        console.log('Edit slot', dataIn);
        // data._scheduleId = '';
        this.getSportsByGround();
        if (this.selectedSlot['paidSlot'] == 'Information') {
            this.selectedSlot['informationSlot'] = true;
            $(".switch__toggle").prop('disabled', true);
            $(".info_slot").prop('disabled', false);
            $(".info_schedle").prop('disabled', false);
        } else {
            this.selectedSlot['informationSlot'] = false;
            $(".switch__toggle").prop('disabled', false);
            $(".info_slot").prop('disabled', false);
            $(".info_schedle").prop('disabled', false);
        }
        if (this.selectedSlot['paidSlot'] == 'First Come First Serve') {
            this.selectedSlot['fcfsSlot'] = true;
            // $(".switch__toggle").prop('disabled', true);
            $(".fcfs_slot").prop('disabled', false);
            // $(".info_schedle").prop('disabled', false);
        }
        else {
            this.selectedSlot['fcfsSlot'] = false;
            // $(".switch__toggle").prop('disabled', false);
            $(".fcfs_slot").prop('disabled', false);
            // $(".info_schedle").prop('disabled', false);
        }
        if (this.selectedSlot['paidSlot'] === 'Paid') {
            this.selectedSlot['paidSlot'] = true;
            console.log('this is the selected slot price', this.selectedSlot['slotPrice']);
        } else {
            this.selectedSlot['paidSlot'] = false;
            this.selectedSlot['slotPrice'] = 0
        }
        if (dataIn._scheduleId === '' || dataIn._scheduleId === undefined || dataIn._scheduleId === null) {
            // this.slotsService.getSlot(params).subscribe( data => {
            //     console.log('Slot service call data: ', data);
            // });
            document.getElementById('edit-slot').click();
        } else {
            document.getElementById('edit-slot-conformation').click();
            this.isChangedToSchedule = false;
            this.isChangedToSlot = false;
        }
    }
    onSelectionSlot() {
        this.isChangedToSlot = true;
        this.isChangedToSchedule = false;
        // this.selectedSlot['startDate'] = new Date((this.selectedSlot['startDate']).split('T')[0]);
        // this.selectedSlot['startDate'].setDate(this.selectedSlot['startDate'].getDate() + 1);
    }
    onSelectionSchedule(dataIn, type) {
        this.isChangedToSlot = false;
        this.isChangedToSchedule = true;
        if (type === 'edit') {
            this.loadingBar = true;
            const params = {
                'scheduleId': dataIn.scheduleId,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken')
            };
            this.slotsService.getScheduleDetails(params).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    this.setScheduleOpenDates(response.body[0].Schedule_Category);
                    this.loadingBar = true;
                    if (response.body[0].Schedule_Type === 'Paid') {
                        this.selectedSlot['scheduleType'] = true;
                        this.selectedSlot['schedulePrice'] = response.body[0].Price;
                    } else {
                        this.selectedSlot['scheduleType'] = false;
                        this.selectedSlot['schedulePrice'] = '0';
                    }
                    this.selectedSlot['startDate'] = new Date(new Date(response.body[0].Start_Date).toISOString());
                    // this.selectedSlot['startDate'] = new Date((response.body[0].Start_Date.split('T'))[0]);
                    // this.selectedSlot['startDate'].setDate(this.selectedSlot['startDate'].getDate() + 1);
                    // this.selectedSlot['startDate'] = moment(data[0].Start_Date).format('YYYY-MM-DD');
                    let d = new Date(this.selectedSlot['startDate']);
                    console.log('this is the selected start before format:', this.selectedSlot['startDate']);
                    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
                    this.selectedSlot['startDate'] = d;
                    console.log('this is the selected start after format:', this.selectedSlot['startDate']);
                    this.selectedSlot['endDate'] = new Date(new Date(response.body[0].End_Date).toISOString());
                    let e = new Date(this.selectedSlot['endDate']);
                    console.log('this is the selected start before format:', this.selectedSlot['endDate']);
                    e.setMinutes(e.getMinutes() + e.getTimezoneOffset());
                    this.selectedSlot['endDate'] = e;
                    console.log('this is the selected start after format:', this.selectedSlot['endDate']);
                    // this.selectedSlot['endDate'] = new Date( (response.body[0].End_Date.split('T'))[0] );
                    // this.selectedSlot['endDate'].setDate(this.selectedSlot['endDate'].getDate() + 1);
                    // let startDate = (data[0].Start_Date).toString();
                    // let currentDateString = (new Date()).toString();
                    // this.selectedSlot['startDate'] = new Date(startDate.substr(0, startDate.indexOf('T'))
                    // + currentDateString.substr(currentDateString.lastIndexOf('T')));
                    this.selectedSlot['scheduleCategory'] = response.body[0].Schedule_Category;
                    this.selectedSlot['openBefore'] = response.body[0].Open_Before;
                    this.selectedSlot['closeBefore'] = response.body[0].Close_Before;
                    this.selectedSlot['slot'] = response.body[0].Slot;
                    this.selectedSlot['startTime'] = (this.selectedSlot['slot'].split(' - '))[0];
                    this.selectedSlot['endTime'] = (this.selectedSlot['slot'].split(' - '))[1];

                    // open by week
                    this.selectedSlot['weekStartDay'] = response.body[0].Week_StartDay;
                    this.selectedSlot['openWeekBefore'] = response.body[0].Open_Week_Before;
                    // open by week

                    let startHour, endHour;
                    // if (this.selectedSlot['startTime'].split(':')[0] === '12' && this.selectedSlot['startTime'].split(' ')[1] === 'AM') {
                    //     startHour = '00';
                    // } else {
                    console.log('this is the 24 hour format time:', this.selectedSlot['startTime']);
                    startHour = (moment(this.selectedSlot['startTime'], ["h:mm A"]).format('HH:mm')).split(':')[0];
                    // }

                    // if (this.selectedSlot['endTime'].split(':')[0] === '12' && this.selectedSlot['endTime'].split(' ')[1] === 'AM') {
                    //     endHour = '00';
                    // } else {
                    endHour = (moment(this.selectedSlot['endTime'], ["h:mm A"]).format('HH:mm')).split(':')[0]
                    // }
                    // console.log('sttime si: ', stTime, (stTime.split(':')[0] === 12 && stTime.split(' ')[1] === 'AM') ? '00' : stTime );
                    this.ngStartTime = {
                        hour: startHour,
                        // hour: moment(model.event.start).format('hh:mm A').split(':')[0],
                        minute: ((this.selectedSlot['startTime']).split(':')[1]).split(" ")[0]
                    };

                    this.ngEndTime = {
                        hour: endHour,
                        minute: ((this.selectedSlot['endTime']).split(':')[1]).split(" ")[0]
                    };
                    let days = JSON.parse(response.body[0].Days);
                    this.repeatDays['MON'] = days.Mon;
                    this.repeatDays['TUE'] = days.Tue;
                    this.repeatDays['WED'] = days.Wed;
                    this.repeatDays['THU'] = days.Thu;
                    this.repeatDays['FRI'] = days.Fri;
                    this.repeatDays['SAT'] = days.Sat;
                    this.repeatDays['SUN'] = days.Sun;
                    this.loadingBar = false;
                    this.isChangedToSchedule = true;
                    this.isChangedToSlot = false;
                    // console.log("These are the selected schedule values" +
                    // JSON.stringify(this.selectedSlot) + "repeat Days" ,this.repeatDays);
                    // this.loadingBar = false;
                    // this.messageService.add({severity: 'warn', summary:
                    // 'Schedules Update', detail: 'Schedule updated successfully'});
                }
            },
                err => { this.errorHandle(err) });
        }
    }
    confirmEdit(dataIn) {
        this.cognitoUtil.refresh();
        if (this.isChangedToSchedule === true) {
            console.log('scheduleId is', this.selectedSlot);
            // service call for Slot Schedule data
            // this.slotsService.editSchedule(params).subscribe(data => {
            //     console.log('SlotBySchedule service call data: ', data);
            // });
            document.getElementById('edit-schedule').click();
        } else if (this.isChangedToSlot === true) {
            console.log('slot details', dataIn)
            const params = { 'slotId': dataIn.slotId, };
            // service call for Slot data
            // this.slotsService.editSlot(params).subscribe(data => {
            //     console.log('Slot service call data: ', data);
            // });
            document.getElementById('edit-slot').click();
        }
        $("#radio1").prop("checked", false);
        $("#radio2").prop('checked', false);
    }

    // editSlotModel(){
    //     if ($("#radio1").prop("checked")) {
    //         alert("hellooo 1")
    //     }else if ($("#radio2").prop("checked")) {
    //         alert("hellooo 2")
    //     }

    //     var radio1 = document.getElementById('radio1').checked();
    //     alert("r1 : ",radio1)
    // }

    deleteSlotOptions(params) {
        document.getElementById('close-button').click();
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
        document.getElementById('close-button1').click();
        // console.log('Delete this slot', params);
        this.isChangedToSchedule = false;
        this.isChangedToSlot = false;
        $("#radio3").prop("checked", false);
        $("#radio4").prop('checked', false);
        if (params.scheduleId === '' || params.scheduleId === undefined || params.scheduleId === null) {
            console.log('Delete Object - No Schedule', params);
            // this.slotsService.deleteSlot(params._slotId).subscribe(data => {
            // });
            document.getElementById('delete-slot').click();
        } else {
            console.log('Delete Object', params);
            // this.slotsService.deleteSlotbySchedule(params._scheduleId).subscribe(data => {
            // });
            document.getElementById('delete-slot-conformation').click();
        }
    }

    confirmDelete(params) {
        if (this.isChangedToSlot == true || this.isChangedToSchedule == true) {
            this.atleastOneChecked = true;
            // $('.cnfrm_dlt').removeAttr('disabled')
        }
        if (this.isChangedToSchedule === true) {
            console.log('schedule')
            this.closeConfirmDelete();
            document.getElementById('delete-schedule').click();
        } else if (this.isChangedToSlot === true) {
            console.log('slot')
            this.closeConfirmDelete();
            document.getElementById('delete-slot').click();
        }
    }

    closeConfirmDelete() {
        $('#DeleteSlotConformation').modal('hide')
    }
    deleteSlot(params) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        document.getElementById('close-button').click();
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
        document.getElementById('close-button1').click();
        // this.slotsService.deleteSlot(params._slotId).subscribe(data => {
        console.log('Delete this slot', params);
        const paramsOut = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': params.slotId,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
        };
        this.slotsService.deleteSlot(paramsOut).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                if (response.body.msg === 'Slot deleted successfully. No associated reservation requests!') {
                    this.loadingBar = false;
                    this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Deleted successfully' });
                    this.reloadSlots();
                } else if (response.body.msg === 'Slot deleted successfully. Sent notification to all reservation requesters!') {
                    this.loadingBar = false;
                    this.messageService.add({
                        severity: 'success', summary: 'Slots Update',
                        detail: 'Deleted successfully, sent notifications'
                    });
                    this.reloadSlots();
                }
            } else if (response.status === 201) {
                if (response.body.msg === 'Slot deleted successfully. Unable to send notification to reservation requesters!') {
                    this.messageService.add({
                        severity: 'warn', summary: 'Slots Update',
                        detail: 'Deleted successfully, error sending notifications'
                    });
                    this.loadingBar = false;
                    this.reloadSlots();
                }
            }
        },
            err => { this.errorHandle(err) });
    }
    reloadSlots() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        const slotParams = {
            'first_date': this.first_day,
            'last_date': this.last_day,
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'parkId': localStorage.getItem('defaultParkId'),
            'cityId': sessionStorage.getItem('cityId'),
            'groundId': this.selectedGround,
        };
        console.log("These are the slot params:", slotParams);
        this.loadingBar = false;
        this.getSlots(slotParams);
    }
    deleteSchedule(params) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        document.getElementById('close-button').click();
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
        document.getElementById('close-button1').click();
        console.log('Delete this schedule', params);
        const paramsOut = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'scheduleId': params.scheduleId,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
        };
        this.slotsService.deleteSlotSchedule(paramsOut).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.loadingBar = false;
                this.reloadSlots();
                this.messageService.add({ severity: 'warn', summary: 'Schedules Update', detail: 'Deleted all free slots, Reserved slots must be managed manually' });
                // console.log("this is the this.dates::::", this.first_day);
                // console.log("this is the this.dates::::", this.last_day);
            } else if (response.status === 201) {
                this.loadingBar = false;
                this.reloadSlots();
                this.messageService.add({ severity: 'warn', summary: 'Schedules Update', detail: 'Deleted successfully! Unable to delete associated slots.' });
            }
        },
            err => { this.errorHandle(err) });
    }
    declineRequest(paramsIn) {
        this.confirmationservice.confirm({
            message: 'Are you sure, you want to decline this request?',
            header: 'Decline Request',
            accept: () => {
                this.loadingBar = true;
                console.log('decline this slot', paramsIn);
                const paramsOut = {
                    'authorizationToken': sessionStorage.getItem('authorizationToken'),
                    'slotId': paramsIn.slotId,
                    'cityId': sessionStorage.getItem('cityId'),
                    'repId': localStorage.getItem('repId'),
                };
                this.slotsService.deleteSlot(paramsOut).subscribe(response => {
                    console.log('The response is', response);
                    if (response.status === 200) {
                        if (response.body.msg === 'Slot deleted successfully. No associated reservation requests!') {
                            this.loadingBar = false;
                            this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Deleted successfully' });
                            this.reloadSlots();
                        } else if (response.body.msg === 'Slot deleted successfully. Sent notification to all reservation requesters!') {
                            this.loadingBar = false;
                            this.messageService.add({
                                severity: 'success', summary: 'Slots Update',
                                detail: 'Deleted successfully, sent notifications'
                            });
                            this.reloadSlots();
                        }
                    } else if (response.status === 201) {
                        if (response.body.msg === 'Slot deleted successfully. Unable to send notification to reservation requesters!') {
                            this.messageService.add({
                                severity: 'warn', summary: 'Slots Update',
                                detail: 'Deleted successfully, error sending notifications'
                            });
                            this.loadingBar = false;
                            this.reloadSlots();
                        }
                    }
                },
                    err => { this.errorHandle(err) });
            },
            reject: () => { }
        })
    }
    approveRequest(paramsIn) {
        this.confirmationservice.confirm({
            message: 'Are you sure, you want to approve this request?',
            header: 'Approve Request',
            accept: () => {
                console.log('approve this slot', paramsIn);
                this.loadingBar = true;
                this.cognitoUtil.refresh();
                console.log('selected Confirm reservation', this.reservationSelect[0]);
                const params = {
                    'authorizationToken': sessionStorage.getItem('authorizationToken'),
                    'slotId': paramsIn.slotId,
                    'reservationId': this.selectedSlotReservationDetails['reservationId'],
                    'cityId': sessionStorage.getItem('cityId'),
                    'repId': localStorage.getItem('repId'),
                    'repName': localStorage.getItem('repName')
                };
                console.log('these are the params', params);
                this.slotsService.assignCustomSlot(params).subscribe(response => {
                    console.log('The response is', response);
                    if (response.status === 200) {
                        this.loadingBar = false;
                        this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservations are processed successfully' });
                        this.reloadSlots();
                    } else if (response.status === 201) {
                        this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                        this.loadingBar = false;
                        this.reloadSlots();
                    }
                },
                    err => { this.errorHandle(err) });
            },
            reject: () => { }
        });
    }
    cancelSlot(paramsIn) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        document.getElementById('close-button').click();
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
        console.log('Cancel this slot', paramsIn);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': paramsIn.slotId,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
            'cancellationReason': this.cancellationReason
        };
        this.slotsService.cancelSlot(params).subscribe(response => {
            this.cancellationReason = '';
            console.log('The response is', response);
            if (response.status === 200) {
                // this.router.navigate(['/admin']);
                // this.router.navigate(['/admin/slots']);
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Cancelled successfully' });
                this.reloadSlots();
                // console.log("this is the this.dates::::", this.first_day);
                // console.log("this is the this.dates::::", this.last_day);
            } else if (response.status === 201) {
                this.loadingBar = false;
                this.messageService.add({
                    severity: 'success',
                    summary: 'Slots Update', detail: 'Cancelled successfully. Unable to send notification to reservation requesters!'
                });
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }
    reactivateSlot(paramsIn) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        document.getElementById('close-button1').click();
        console.log('Reactivate this slot', paramsIn);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': paramsIn.slotId,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
        };
        this.slotsService.reactivateSlot(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Reactivated Slot, Notifications sent!' });
                this.reloadSlots();
            } else if (response.status === 201) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Reactivated Slot, Unable to send notifications!' });
                this.reloadSlots();
            } else if (response.status === 202) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Reactivated Slot!' });
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }
    resetReservations(paramsIn) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        document.getElementById('close-button4').click();
        console.log('Reset reservations for this slot', paramsIn);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': paramsIn.slotId,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
            'repName': localStorage.getItem('repName')
        };
        this.slotsService.resetReservations(params).subscribe(response => {
            console.log('The response is', response);
            if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Reservations successfully reset!' });
                this.reloadSlots();
            } else if (response.status === 201) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Reservations successfully reset, Unable to send notifications!' });
                this.reloadSlots();
            } else if (response.status === 202) {
                this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Slot edited but unable to send notifications' });
                this.loadingBar = false;
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }

    onclickEditSlot() {
        document.getElementById('edit-slot').click();
    }
    updateEvent(model: any) {
        model = {
            event: {
                id: model.event.id,
                start: model.event.start,
                end: model.event.end,
                title: model.event.title
                // other params
            },
            duration: {
                _data: model.duration._data
            }
        }
        this.displayEvent = model;
    }
    // slot update
    updateSlot(dataIn, startTime, endTime) {
        this.cognitoUtil.refresh();
        let st = moment(startTime.hour + ':' + startTime.minute, 'HH:mm');
        let et = moment(endTime.hour + ':' + endTime.minute, 'HH:mm')
        let timeCheck = et.isSameOrBefore(st);
        this.timeError = timeCheck;
        if ((dataIn.sportId === undefined || this.editSlotForm.controls['sportId'].status === 'INVALID') || (dataIn.date === undefined || this.editSlotForm.controls['date'].status === 'INVALID') || (dataIn.startTime === undefined || this.editSlotForm.controls['startTime'].status === 'INVALID') || (dataIn.endTime === undefined || this.editSlotForm.controls['endTime'].status === 'INVALID') || (dataIn.slotCategory == 'custom' && (dataIn.openDate === undefined || this.editSlotForm.controls['openDate'].status === 'INVALID' || dataIn.closeDate === undefined || this.editSlotForm.controls['closeDate'].status === 'INVALID')) || (dataIn.slotCategory === undefined || this.editSlotForm.controls['slotCategory'].status === 'INVALID') || (dataIn.paidSlot == true && (dataIn.slotPrice == undefined || this.editSlotForm.controls['slotPrice'].status === 'INVALID')) || (dataIn.isPrivate == true && ((dataIn.password == undefined || this.editSlotForm.controls['password'].status === 'INVALID') && dataIn.isPrivate == true) || (this.editSlotForm.controls['password'].value !== this.editSlotForm.controls['cnf_pass'].value) && dataIn.isPrivate == true) || this.timeError == true) {
            this.checkValidation = true;
        }
        else {
            this.loadingBar = true;
            this.checkValidation = false;
            document.getElementById('close-button2').click();

            let slot = moment(startTime['hour'] + ':' + startTime['minute'], ['HH:mm']).format("hh:mm A") + ' - ' + moment(endTime['hour'] + ':' + endTime['minute'], ['HH:mm']).format("hh:mm A");
            let sDate = moment(dataIn.startDate).format('YYYY-MM-DD');
            let oDate = moment(dataIn.oldDate).format('YYYY-MM-DD');
            let openDate = moment(dataIn.openDate).format('YYYY-MM-DD');
            let closeDate = moment(dataIn.closeDate).format('YYYY-MM-DD');


            if (dataIn.informationSlot == true) {
                dataIn.paidSlot = 'Information';
            }
            else if(dataIn.fcfsSlot == true){
                dataIn.paidSlot = 'First Come First Serve'
            }
            // if ( dataIn.slotCategory === 'always') {
            //     dataIn.openDate = moment().format('YYYY-MM-DD');
            //     dataIn.closeDate = moment(sDate).add(-1, 'days')
            // }
            // else if(dataIn.slotCategory === 'anyTime') {
            //     dataIn.openDate = moment().format('YYYY-MM-DD');
            //     dataIn.closeDate = moment(sDate);
            // }
            else {
                if (dataIn.paidSlot === false || dataIn.paidSlot === undefined || dataIn.paidSlot === null ||
                    dataIn.slotPrice === false || dataIn.slotPrice === undefined || dataIn.slotPrice === null) {
                    dataIn.paidSlot = 'Free';
                    dataIn.slotPrice = '0';
                } else {
                    dataIn.paidSlot = 'Paid';
                }
            }

            const params = {
                'slotId': dataIn.slotId,
                'newSportId': dataIn._sportId,
                'oldSportId': dataIn.oldSportId,
                'groundId': dataIn._groundId,
                'repId': dataIn.repId,
                'newTime': slot,
                'oldTime': dataIn.oldSlot,
                'newDate': sDate,
                'oldDate': oDate,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'slotCategory': dataIn.slotCategory,
                'openDate': openDate,
                'closeDate': closeDate,
                'autoFIFO': dataIn.autoFIFO,
                'slotPrice': dataIn.slotPrice,
                'paidSlot': dataIn.paidSlot,
                'isPrivate': dataIn.isPrivate == false ? "" : dataIn.isPrivate,
                'password': dataIn.isPrivate ? dataIn.password : "",
                'payByCash': dataIn.payByCash === null ? false : dataIn.payByCash,
                'Ask_Team_Name': dataIn.team,
                'Ask_Resident_Of': dataIn.resident,
                'Comments': dataIn.comments,
                'BookingUrl': dataIn.bookingURL,
                'Service_Fee':dataIn.serviceFee,
                'nonResvSlot': dataIn.nonReserveSlot,
                'leagueName':dataIn.leagueName,
            };
            console.log('update slot data', params);
            this.slotsService.editSlot(params).subscribe(response => {
                console.log('The response is', response);
                if (response.status === 200) {
                    this.loadingBar = false;
                    this.messageService.add({ severity: 'success', summary: 'Slots Update', detail: 'Slot updated successfully' });
                    this.reloadSlots();
                } else if (response.status === 201) {
                    this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Unable to update slot, overlap with slot: ' + response.body.overlapSlot[0].Slot });
                    this.loadingBar = false;
                    this.reloadSlots();
                } else if (response.status === 202) {
                    this.messageService.add({ severity: 'warn', summary: 'Slots Update', detail: 'Unable to send notification' });
                    this.loadingBar = false;
                    this.reloadSlots();
                }
            },
                err => { this.errorHandle(err) });
        }
    }
    // schedule update
    cancelEditSchedule() {
        this.loadingBar = false;
    }

    getWeekStartDay(day) {
        console.log(day)
    }

    updateSchedule(data, startTime, endTime) {
        console.log(this.editSlotcheduleForm.controls['day'].status)
        var atLeastOneIsChecked = $('input[name="day"]:checked').length > 0;
        this.atLeastOneIsChecked = atLeastOneIsChecked
        console.log(atLeastOneIsChecked)
        this.cognitoUtil.refresh();
        let st = moment(startTime.hour + ':' + startTime.minute, 'HH:mm');
        let et = moment(endTime.hour + ':' + endTime.minute, 'HH:mm')
        let timeCheck = et.isSameOrBefore(st);
        this.timeError = timeCheck;
        if ((data.sportId === undefined || this.editSlotcheduleForm.controls['sportId'].status === 'INVALID') || (data.startDate === undefined || this.editSlotcheduleForm.controls['startDate'].status === 'INVALID') || (data.endDate === undefined || this.editSlotcheduleForm.controls['endDate'].status === 'INVALID') || (data.startTime === undefined || this.editSlotcheduleForm.controls['startTime'].status === 'INVALID') || (data.endTime === undefined || this.editSlotcheduleForm.controls['endTime'].status === 'INVALID') || atLeastOneIsChecked == false || (data.scheduleCategory == 'custom' && (data.openBefore == undefined || data.closeBefore == undefined || this.editSlotcheduleForm.controls['openBefore'].status === 'INVALID' || this.editSlotcheduleForm.controls['closeBefore'].status === 'INVALID')) || (data.paidSlot == true && ((data.schedulePrice == undefined) || this.editSlotcheduleForm.controls['slotPrice'].status === 'INVALID')) || (data.isPrivate == true && ((data.password == undefined || this.editSlotcheduleForm.controls['password'].status === 'INVALID') && data.isPrivate == true) || (this.editSlotcheduleForm.controls['password'].value !== this.editSlotcheduleForm.controls['cnf_pass'].value) && data.isPrivate == true) || this.timeError == true) {
            this.checkscheValidation = true;
        }
        else {
            this.checkscheValidation = false;
            this.loadingBar = true;
            document.getElementById('close-button9').click();
            let slot = moment(startTime['hour'] + ':' + startTime['minute'], ['HH:mm']).format("hh:mm A") + ' - ' + moment(endTime['hour'] + ':' + endTime['minute'], ['HH:mm']).format("hh:mm A");
            let sDate = moment.utc(data.startDate).format('YYYY-MM-DD');
            let eDate = moment(data.endDate).format('YYYY-MM-DD');
            let daysSelected = { 'Mon': this.repeatDays['MON'], 'Tue': this.repeatDays['TUE'], 'Wed': this.repeatDays['WED'], 'Thu': this.repeatDays['THU'], 'Fri': this.repeatDays['FRI'], 'Sat': this.repeatDays['SAT'], 'Sun': this.repeatDays['SUN'] };

            if (data.informationSlot == true) {
                data.scheduleType = 'Information';
            }
            else {
                if (data.scheduleType === true) {
                    data.scheduleType = 'Paid';
                } else {
                    data.scheduleType = 'Free';
                    data.schedulePrice = '0';
                }
            }
            const params = {
                'scheduleCategory': data.scheduleCategory,
                'openBefore': data.openBefore,
                'closeBefore': data.closeBefore,
                'autoFIFO': data.autoFIFO,
                'scheduleId': data.scheduleId,
                'sportId': data._sportId,
                'groundId': data._groundId,
                'repId': data.repId,
                'slot': slot,
                'startDate': sDate,
                'endDate': eDate,
                'days': JSON.stringify(daysSelected),
                'parkId': data.parkId,
                'cityId': sessionStorage.getItem('cityId'),
                'authorizationToken': sessionStorage.getItem('authorizationToken'),
                'schedulePrice': data.schedulePrice,
                'scheduleType': data.scheduleType,
                'isPrivate': data.isPrivate,
                'password': data.isPrivate ? data.password : "",
                'payByCash': data.payByCash === null ? false : data.payByCash,
                'Ask_Team_Name': data.team,
                'Ask_Resident_Of': data.resident,
                'Comments': data.comments,
                'BookingUrl': data.bookingURL,
                'Service_Fee':data.serviceFee,
                'nonResvSchedule': data.nonReserveSlot,
                'leagueName':data.leagueName,
            };
            console.log('update schedule data', params)
            this.slotsService.scheduleUpdate(params).subscribe(response => {
                console.log('The response is', response);
                if (response.body.httpStatus === 200) {
                    this.loadingBar = false;
                    this.messageService.add({ severity: 'success', summary: 'Schedule Update', detail: 'Schedule updated successfully' });
                    this.reloadSlots();
                } else if (response.body.httpStatus === 201) {
                    this.messageService.add({ severity: 'warn', summary: 'Schedule Update', detail: 'Unable to update schedule, overlap with slot on: ' + (response.body.message[0].Date).split('T')[0] });
                    this.loadingBar = false;
                    this.reloadSlots();
                } else if (response.body.httpStatus === 202) {
                    this.messageService.add({ severity: 'warn', summary: 'Schedule Update', detail: 'Selected days not present in selected date range.' });
                    this.loadingBar = false;
                    this.reloadSlots();
                } else if (response.body.httpStatus === 203) {
                    this.messageService.add({ severity: 'warn', summary: 'Schedule Update Failed', detail: 'Some slots in this schedule are already reserved, Please process them manually in order to edit schedules.' });
                    this.loadingBar = false;
                    this.reloadSlots();
                }

            },
                err => { this.errorHandle(err) });
        }
    }
    // assign popup call
    gotoAssign() {
        document.getElementById('close-button3').click();
        document.getElementById('assign-slots').click();
        this.disabled_option = false;
        this.customApprove = false;
    }
    assignFifo() {
        document.getElementById('assign-slot-popup').click();
        document.getElementById('assign-fifo').click();
    }
    selectedFIFOReservation(paramsIn) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        console.log('this is the selected reservation fifo', paramsIn);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': paramsIn[0].Slot_Id,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
            'repName': localStorage.getItem('repName')
        };
        this.slotsService.assignSlotFifo(params).subscribe(response => {
            console.log('The response is', response);
            if (response.body.httpStatus == 400) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Please contact administrator' });
                this.reloadSlots();
            }
            else if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservations are processed successfully' });
                this.reloadSlots();
            } else if (response.status === 202) {
                // this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.payreq_id = response.body.reservationId;
                this.payreq_name = response.body.firstName + ' ' + response.body.lastName;
                this.payreq_email = response.body.email;
                this.payreq_mobilenumber = response.body.phoneNumber;
                setTimeout(function () {
                    document.getElementById('payment_assign-fifo').click();
                }.bind(this), 0);
                this.loadingBar = false;
                this.reloadSlots();
            } else if (response.status === 201) {
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.loadingBar = false;
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }

    removeReservation(resId, paramsIn) {
        setTimeout(function () {
            document.getElementById('assign-slots').click();
        }.bind(this), 0);
        this.disabled_option = true;
        //  console.log('reservation id:', resId);
        //  console.log('slot id:', paramsIn[0]['Slot_Id']);
        //  const params = {
        //     'authorizationToken': sessionStorage.getItem('authorizationToken'),
        //     'slotId': paramsIn[0]['Slot_Id'],
        //     'cityId': sessionStorage.getItem('cityId'),
        //     'reservationId': resId
        // };
        // this.slotsService.deleteReservation(params).subscribe(response => {
        //     console.log('The response is', response);
        //     if (response.status === 200) {
        //         this.loadingBar = false;
        //         this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservation Deleted, Process the slot again!' });
        //         this.reloadSlots();
        //     } else if (response.status === 201) {
        //         this.loadingBar = false;
        //         this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservation Deleted, No more reservations exist!' });
        //         this.reloadSlots();
        //     }
        // },
        //     err => { this.errorHandle(err) });
    }

    assignRandom() {
        document.getElementById('assign-slot-popup').click();
        document.getElementById('assign-random').click();
    }
    selectedRandomReservation(paramsIn) {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        console.log('this is the selected reservation random', paramsIn);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': paramsIn[0].Slot_Id,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
            'repName': localStorage.getItem('repName')
        };
        this.slotsService.assignSlotRandom(params).subscribe(response => {
            console.log('The response is', response);
            if (response.body.httpStatus == 400) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Please contact administrator' });
                this.reloadSlots();
            }
            else if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservations are processed successfully' });
                this.reloadSlots();
            } else if (response.status === 202) {
                // this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.payreq_id = response.body.reservationId;
                this.payreq_name = response.body.firstName + ' ' + response.body.lastName;
                this.payreq_email = response.body.email;
                this.payreq_mobilenumber = response.body.phoneNumber;
                setTimeout(function () {
                    document.getElementById('payment_assign-fifo').click();
                }.bind(this), 0);
                this.loadingBar = false;
                this.reloadSlots();
            }
            else if (response.status === 201) {
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.loadingBar = false;
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }
    onAssignSelect(reservation) {
        this.reservationSelect = [];
        if (this.reservationSelect.length === 0) {
            this.reservationSelect.push(reservation)
        }
        console.log('reservation data', this.reservationSelect)
    }
    assignSelected() {
        document.getElementById('assign-slot-popup').click();
        document.getElementById('assign-confirm').click();
        console.log('confirm reservation data')
    }
    reservationSelected() {
        this.loadingBar = true;
        this.cognitoUtil.refresh();
        console.log('selected Confirm reservation', this.reservationSelect[0]);
        const params = {
            'authorizationToken': sessionStorage.getItem('authorizationToken'),
            'slotId': this.reservationSelect[0].Slot_Id,
            'reservationId': this.reservationSelect[0].Reservation_Id,
            'cityId': sessionStorage.getItem('cityId'),
            'repId': localStorage.getItem('repId'),
            'repName': localStorage.getItem('repName')
        };
        this.slotsService.assignCustomSlot(params).subscribe(response => {
            console.log('The response is', response);
            if (response.body.httpStatus == 400) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Please contact administrator' });
                this.reloadSlots();
            }
            else if (response.status === 200) {
                this.loadingBar = false;
                this.messageService.add({ severity: 'success', summary: 'Reservations Update', detail: 'Reservations are processed successfully' });
                this.reloadSlots();
            }
            else if (response.status === 202) {
                // this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.payreq_id = response.body.reservationId;
                this.payreq_name = response.body.firstName + ' ' + response.body.lastName;
                this.payreq_email = response.body.email;
                this.payreq_mobilenumber = response.body.phoneNumber;
                setTimeout(function () {
                    document.getElementById('payment_assign-fifo').click();
                }.bind(this), 0);
                this.loadingBar = false;
                this.reloadSlots();
            }
            else if (response.status === 201) {
                this.messageService.add({ severity: 'warn', summary: 'Reservations Update', detail: 'Reservations are processed successfully, error sending notification!' });
                this.loadingBar = false;
                this.reloadSlots();
            }
        },
            err => { this.errorHandle(err) });
    }

    onDaySelection(day) {
        if (this.repeatDays[day] === false) {
            this.repeatDays[day] = true;
        } else {
            this.repeatDays[day] = false;
        }
    }

    calcelSlot() {
        document.getElementById('close-button').click();
        document.getElementById('close-button4').click();
        document.getElementById('close-button3').click();
    }

    reactivateGroup() {
        document.getElementById('close-button1').click();
    }

    openShareSlotModal() {
        $('#availableSlot').modal('hide');
        $('#reservedSlot').modal('hide');
        $('#shareSlotModal').modal('show');
        this.slot_link = location.origin + "/home/slot-detail/" + this.selectedSlot['hash_key'];
    }
    copyLink() {
        let copyText = document.getElementById("copy_link") as HTMLInputElement;
        copyText.select();
        document.execCommand("copy");
        $('#copy_link_btn').html('<i style="color: #fff;padding-right: 2px;" class="fa fa-check" aria-hidden="true"></i>Copied');
        $('#copy_link_btn').removeClass('btn-default');
        $('#copy_link_btn').addClass('btn-success');
    }

    changeBtnTxt() {
        $('#copy_link_btn').html('Copy Link');
        $('#copy_link_btn').addClass('btn-default');
        $('#copy_link_btn').removeClass('btn-success');
    }

    sendEmailLink() {
        if (this.link_email !== "" && this.link_email !== undefined) {
            let data = {
                email: this.link_email,
                link: "home/slot-detail/" + this.selectedSlot['hash_key'],
                slot_id: this.selectedSlot['slotId'],
                city_id: localStorage.getItem('cityId')
            };
            this.slotsService.sendCopyLinkToEmail(data).subscribe(response => {
                console.log("email send::", data);
                $('#shareSlotModal').modal('hide');
                this.messageService.add({ severity: 'success', summary: 'Message', detail: 'message sent successfully' });
            })
        } else {
            this.emailErr = true;
            this.email_msg = "Email required";
        }
    }

    onChangeEmail() {
        if (this.link_email !== "" && this.link_email !== undefined) {
            this.emailErr = false;
        } else {
            this.emailErr = true;
        }
    }
    more_dtls(){
        if(this.showDtls==false){
            this.showDtls=true;
        }
        else{
            this.showDtls=false
        }
      }
    

    eventRender(event: any): any {
        console.log("event data is :" + event.event.Date);
        console.log("last day is :" + moment(event.view.intervalEnd._d).format('YYYY-MM-DD'))
        this.lastDayOfMonth = moment(event.view.intervalEnd._d).add(0, 'days').format('YYYY-MM-DD');
        if (event.view.type == "month") {
            if (event.event.Date > moment(event.view.intervalEnd._d).add(0, 'days').format('YYYY-MM-DD')) {
                event.element.css("opacity", "0.6");
                event.element.css("cursor", "no-drop")
            }
        }
    }

    timeChange(event: any, startTime: any, endTime: any, checkType: any): any {
        let st = moment(startTime.hour + ':' + startTime.minute, 'HH:mm');
        let et = moment(endTime.hour + ':' + endTime.minute, 'HH:mm')
        this.timeError = et.isSameOrBefore(st);
        if(checkType == 'slot'){
            this.checkValidation = et.isSameOrBefore(st);
        } else {
            this.checkscheValidation = et.isSameOrBefore(st);
        }
    }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlotsService } from '../../service/slots.service';
import { CitiesService } from '../../service/cities.service';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import { UserLoginService } from '../../service/user-login.service';
declare let $;
import {MessageService} from 'primeng/components/common/messageservice';
import { environment } from '../../../environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Reservation } from '../../models/reservation';
import {Location} from '@angular/common';
import { BidForServiceDialogComponent } from './bid-for-service-dialog/bid-for-service-dialog';
import { MatDialog, MatDialogConfig } from "@angular/material";

@Component({
  selector: 'app-slot-details',
  templateUrl: './slot-details.component.html',
  styleUrls: ['./slot-details.component.css']
})
export class SlotDetailsComponent implements OnInit {

  slot_id: any;
  currentSlot: any;
  password: string;
  formSubmitted: boolean = false;
  slotPwdErr: boolean;
  err_msg: string = "";
  user = 'false';
  moment: any = moment;
  cityAdmin='false';
  superAdmin='false';
  loadingBar = false;
  environment:any=environment;
  feedback_url:any;
  support_requests: boolean = false;
  sports_details=false;
  show_allSports=false;
  park_slotsDetails=false;
  reportIssue = false;
  feedback=false;
  cancelSlotId: any;
  isDelSlot: boolean = false;
  reason: string = "";
  userId:any;
  teams: any[] = [];
  teamsOnSearch:any[] = [];
  residentOf: string = "";
  selectedTeam: string = "";
  chosenTeam: any;
  chosenTeams=[];
  editTeamForm: FormGroup;
  teamMembers: any[] = [];
  removed_members: any[] = [];
  teamFormSubmitted: boolean = false;
  invite_option: boolean = false;
  sportsList: any[] = [];
  selectedSport: string = "";
  teamForm: FormGroup;
  selectedTeams = [];
  multiSelectSettings = {};
  selectedResult: any = null;
  reservationData = new Reservation();
  bookingFlag: boolean = false;
  isSlotAssigned : string = '';
  query = '';
  servicesArr: any[] = [];
  refereedSports:any=[];
  service_provider_status:any;
  refereeRequests: any;

  


  constructor(
    private route: ActivatedRoute,
    private slotService: SlotsService,
    private citiesservice: CitiesService,
    private homeService: HomeService,
    private router: Router,
    public userService: UserLoginService,
    private messageService: MessageService,
    private formbuilder: FormBuilder,
    private _location: Location,
    public dialog: MatDialog,
  ) { 
    this.route.params.subscribe((params)=> {
      if (params.slot_id !== undefined) {
        this.slot_id = params.slot_id;
      }
    })
    this.route.queryParams.subscribe((response) => {
       this.isSlotAssigned =response.assignedSlotType;
       console.log(this.isSlotAssigned)
    })
  }

  ngOnInit() {
    this.user = localStorage.getItem('user');
    this.userId = localStorage.getItem('id');
    this.getSlotDetails();
    this.getTeamsList(this.userId);
    this.getSports();

    this.teamForm = this.formbuilder.group({
      teamName: ['', Validators.required],
      sport: [null, Validators.required]
    });

    this.multiSelectSettings = { 
      singleSelection: false, 
      text:"My Teams",
      selectAllText:'Select All',
      unSelectAllText:'UnSelect All',
      enableSearchFilter: true,
      classes:"team_mltselect",
      labelKey: "Team_Name",
      primaryKey: "Team_Id",
    };

    this.reservationData.firstName=localStorage.getItem('firstName');
    this.reservationData.lastName=localStorage.getItem('lastName');
    this.reservationData.residentOf=localStorage.getItem('residentOf');
    this.reservationData.teamName=localStorage.getItem('teamName');
    this.reservationData.email=localStorage.getItem('email');
    this.reservationData.phoneNumber=localStorage.getItem('phoneNumber'); 

    this.editTeamForm = this.formbuilder.group({
      // tmPlayers: [''],
      name: ["", Validators.required],
      sport: [""],
      email: [""],
    });
   
    if(this.user == "true"){
      this.getServices();
    }
    
  }

  get teamName() {
    return this.teamForm.controls.teamName.valid;
  }
  get sport() {
    return this.teamForm.controls.sport.valid;
  }

  onBookingUrl(value: boolean) {
    $("#bookingUrlModal").modal("hide");
    this.bookingFlag = value;
    if (this.currentSlot.User_Id.indexOf(parseInt(this.userId)) == -1) {
      console.log('saving booking data');
      this.saveBookingUrlData();
    }
  }

  saveBookingUrlData() {
    if (this.reservationData.teamName === '' || this.reservationData.teamName === undefined) {
      this.reservationData.teamName = null;
    }
    this.reservationData.selectedDate = moment(this.reservationData.selectedDate).format('YYYY-MM-DD');
    console.log('sport is: ', this.reservationData.sportId);
    const phone = '+1'.concat(this.reservationData.phoneNumber);
    const createdAt = moment().tz('America/Chicago').format('YYYY-MM-DD hh:mm:ss');
    if (this.reservationData.residentOf === '' || this.reservationData.residentOf === undefined || this.reservationData.residentOf === null ){
        this.reservationData.residentOf = null;
    }
    if (localStorage.getItem('id') === null || localStorage.getItem('id') === undefined || localStorage.getItem('id') === '') {
        this.reservationData.userId = null;
    } else {
        this.reservationData.userId = localStorage.getItem('id');
    }

    const params = {
      'phoneNumber': phone,
      'slotId': this.currentSlot.Slot_Id,
      'slot': this.currentSlot.Slot,
      'slotStatus': this.currentSlot.Slot_Status,
      'slotFIFO': this.currentSlot.Auto_FIFO,
      'repId': this.currentSlot.Rep_Id,
      'reservationDate': this.currentSlot.Date,
      'cityId': this.currentSlot.City_Id,
      'userId': this.reservationData.userId,
      'groundId': this.currentSlot.Ground_Id,
      'sportId': this.currentSlot.Sport_Id,
      'createdAt': createdAt,
      'firstName': this.reservationData.firstName,
      'lastName': this.reservationData.lastName,
      'email': this.reservationData.email,
      'teamName': this.reservationData.teamName,
      'residentOf': this.reservationData.residentOf,
      'stage': environment.logoStage,
      'authorizationId': null,
      'paypalAmount': null,
      'orderId': null,
      'paymentMode':null,
      'External_Booking_Flag': this.bookingFlag
    };

    this.homeService.saveBookingFlag(params)
      .subscribe((res: any) => {
        console.log('booking flag', res);
      });
    
  }

  getTeamsList(user_id) {
    this.homeService.getTeamsList(user_id)
    .subscribe((res: any)=> {
      let teams_list = res;
      console.log('teams_list', teams_list);
      this.teams = teams_list;
    });
  }
  getTeamsListonSearch(user_id,query) {
    this.homeService.getTeamsListonSearch(user_id,query)
    .subscribe((res: any)=> {
      let teams_list_search = res;
      console.log('teams_list', teams_list_search);
      this.teamsOnSearch = teams_list_search;
    });
  }

  getSports() {
    this.homeService.getSports().subscribe((data) => {
      console.log('get sports data', data);
        this.sportsList = [];
        if (data.length === 0) {
        } else {
            data.forEach((o) => {
                const temp = {};
                temp['Sport_Id'] = o.Sport_Id;
                temp['Sport_Name'] = o.Sport_Name;
                temp['image'] = 'https://content.fieldsmanager.com/' + environment.logoStage + '/sports-images/' + o.Sport_Id + '.png';
                this.sportsList.push(temp);
            });
        }
    });
  }

  getServices() {
    this.homeService.getServices()
      .subscribe((res: any)=> {
        console.log('services res', res);
        this.servicesArr = res.filter((service) => service['Service_Status'] == 'Active');
        this.getServiceProviderStatus();
    });
  }

  getServiceProviderStatus(){
    let ref_service_id = this.servicesArr.filter((e) => e['Service_Name'] == 'Umpiring')[0]['Service_Id'];
    this.refereedSports=[];
    let service_id = ref_service_id;

    this.loadingBar = true;
    this.homeService.getRefereeStatus(this.userId, service_id)
    .subscribe((res: any)=> {
      this.service_provider_status = res.length;
      if(this.service_provider_status !==0) {
        this.getRefereeDetails(this.userId, service_id);
      }
    })
  }
  getRefereeDetails(userId,service_id){
    this.loadingBar = true;
      this.homeService.getMyRefereereqs(userId,service_id)
        .subscribe((res: any)=> {
          this.loadingBar = false;
          if(res.msg==undefined){
            this.refereeRequests = res;
          }
        });
  }

  openServiceBidDialog(slotId){
    const config = new MatDialogConfig();
    config.panelClass = 'bidForServiceCustom';
    config.data = this.refereeRequests.find(item=> item.Slot_Id == slotId);
    const serviceRequest = config.data;

    if (serviceRequest.Slot_Assigned_Time != null && serviceRequest.Slot_Assigned_to_ProviderId == this.userId && serviceRequest.Cancel_Flag != 1) {
      this.router.navigate(['/home/serviceRequests/' + serviceRequest.Service_Request_Id + '/success']);
    } else if (serviceRequest.Slot_Assigned_to_ProviderId != this.userId && serviceRequest.Slot_Assigned_to_ProviderId != null && serviceRequest.Cancel_Flag != 1) {
    } else {
      const dialogRef = this.dialog.open(BidForServiceDialogComponent, config);
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          // this.enableMembershipDetails.enable_signup = 'false';
          console.log('closed');
        }
      });
    }
  }

  onClickTeam(){
     if(this.user=='true'){
      $("#createTeamModal").modal('show');
     }
     else{
      this.router.navigate(['/home/signin',{param:'slot-detail/'+this.currentSlot.hash_key}]);
     }
  }
  onClickJoin(){
    if(this.user=='true'){
     $("#joinTeamModal").modal('show');
    }
    else{
     this.router.navigate(['/home/signin',{param:'slot-detail/'+this.currentSlot.hash_key}]);
    }
 }
 onClickManage(){
  if(this.user=='true'){
    $("#manageTeamModal").modal('show');
   }
   else{
    this.router.navigate(['/home/signin',{param:'slot-detail/'+this.currentSlot.hash_key}]);
   }
 }
 editTeam() {
  // this.invite_option = false;
  this.loadingBar = true;
  $("#manageTeamModal").modal('hide');
  this.removed_members = [];
  this.editTeamForm.setValue({
    name: this.chosenTeam.Team_Name,
    sport: this.chosenTeam.Sport_Name,
    email: "",
  });
  this.homeService
    .getTeamMembers(this.chosenTeam.Team_Id)
    .subscribe((res: any) => {
      console.log("team members res", res);
      this.loadingBar = false;
      let team_members = res;
      if (team_members.length > 0) {
        this.teamMembers = team_members;
        $("#editTeamModal").modal("show");
      }
    });
}
onClickDeleteMember(member) {
  this.teamMembers = this.teamMembers.filter((item) => {
    if (item.TM_Id !== member.TM_Id) {
      return item;
    } else {
      this.removed_members.push(item.TM_Id);
      console.log("removed Members::", this.removed_members);
    }
  });
}
updateTeam() {
  this.teamFormSubmitted = true;
  if (
    (this.editTeamForm.controls["name"].status !== "INVALID" &&
      this.editTeamForm.controls["sport"].status !== "INVALID" &&
      !this.invite_option) ||
    (this.invite_option && this.editTeamForm.valid)
  ) {
    this.loadingBar = true;
    $("#editTeamModal").modal("hide");
    var obj = {
      team_id: this.chosenTeam.Team_Id,
      team_name: this.editTeamForm.controls.name.value,
      members: this.removed_members,
      email: this.editTeamForm.controls.email.value,
      user_id: localStorage.getItem("id"),
      user_name: localStorage.getItem("firstName"),
    };
    this.homeService.updateTeam(obj).subscribe((res: any) => {
      this.loadingBar = false;
      this.teamFormSubmitted = false;
      this.chosenTeam = undefined;
      if (res.body.msg.includes("exist")) {
        this.messageService.add({
          severity: "error",
          summary: "Teams",
          detail: res.body.msg,
        });
      } else {
        this.messageService.add({
          severity: "success",
          summary: "Teams",
          detail: res.body.msg,
        });
      }
      this.getTeamsList(this.userId);
    });
    this.editTeamForm.reset();
  }
}


  onCreateTeam() {
    this.formSubmitted = true;
    if(this.teamForm.valid){
      this.formSubmitted =false;
      $("#createTeamModal").modal('hide');
      var sport_details = this.sportsList.filter(res => res.Sport_Id == this.teamForm.controls.sport.value)[0];
      var sport_max_memebers = 0;
      if (sport_details.Sport_Name === "Cricket") {
        sport_max_memebers = 11;
      } else if (sport_details.Sport_Name === "Soccer") {
        sport_max_memebers = 15;
      } else if (sport_details.Sport_Name === "Baseball") {
        sport_max_memebers = 16;
      }

      var obj = {
        Team_Name: this.teamForm.controls.teamName.value, 
        Sport_Id: sport_details.Sport_Id, 
        Sport_Name: sport_details.Sport_Name, 
        Team_Max_Size: sport_max_memebers, 
        Team_Size: 1,
        Team_Created_By: this.userId 
      }

      this.homeService.createTeam(obj)
        .subscribe((res: any)=> {
          console.log('create team res', res);
          if (res.body.message != "exist") {
            this.messageService.add({ severity: 'success', summary: 'Teams', detail: 'Team created successfully' });
          } else {
            this.messageService.add({ severity: 'error', summary: 'Teams', detail: "Team name already exists" });
          }
          this.getTeamsList(this.userId);
          // $("#createTeamModal").modal('hide');
        });
      
      this.teamForm.reset();
    }
  }

  onJoinTeam(){
    this.loadingBar=true;
    $("#joinTeamModal").modal('hide');
    this.homeService.onJoinTeam(this.selectedResult)
    .subscribe((res: any)=> {
      console.log('create team res', res);
      if (res.body) {
        this.loadingBar=false;
        this.onAcceptTeam(res.body.invitation_id)
        // this.messageService.add({ severity: 'success', summary: 'Teams', detail: 'Team created successfully' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Teams', detail: "something went wrong" });
      }
      this.getTeamsList(this.userId);
      // $("#createTeamModal").modal('hide');
    });
    this.teamsOnSearch = [];
    this.selectedResult = '';
  }
  onAcceptTeam(invitation_id){
    this.loadingBar=true;
    this.homeService.onAcceptTeam(invitation_id,this.userId)
    .subscribe((res: any)=> {
      this.loadingBar=false;
      console.log('create team res', res);
      if (res.body.msg == "Joined team successfully!") {
        this.messageService.add({ severity: 'success', summary: 'Teams', detail: 'Joined team successfully!' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Teams', detail: "something went wrong" });
      }
      this.getTeamsList(this.userId);
      // $("#createTeamModal").modal('hide');
    });


  }

  sessUserSlot(user_ids) {
    if (this.userId && user_ids !== null) {
      if (user_ids.length > 0) {
        return user_ids.indexOf(parseInt(this.userId)) > -1 ? true : false;
      } else {
        return false;
      }
    }
  }

  getSlotDetails() {
    this.loadingBar=true;
    this.slotService.slotDetails(this.slot_id)
      .subscribe((slot: any)=> {
        slot[0].BookingUrl = "undefined" ? null : slot[0].BookingUrl;
                this.currentSlot = slot[0];
        this.loadingBar=false;
        this.getServiceRequests();
      });
  }

  getServiceRequests() {
    this.loadingBar=true;
    this.slotService.serviceRequests(this.currentSlot.Slot_Id, this.userId).subscribe((res: any)=> {
        console.log('res', res);
        console.log('res.body', res.body);
        this.currentSlot['service_requests'] = res.body;
        this.loadingBar=false;
        setTimeout(
          function () {
            $('[data-toggle="tooltip"]').tooltip();
          }.bind(this),
          1000
        );
      });
  }

  gotoSupportReqs() {
    this.feedback = false;
    this.support_requests = true;
    this.sports_details=false;
    this.show_allSports=false;
    this.park_slotsDetails=false;
    this.reportIssue = false;
    this.router.navigate(['/home/support-requests']);
  }


  gotoMyAccount() {
    localStorage.setItem('upgradeView','true');
    this.router.navigate(['/home/dashboard'], {queryParams: {account: 'myAccount'}});
  }

  customSlotReq(){
    if(this.user=='true'){
      this.router.navigate(['/home/custom-slots'], {queryParams: {
        parkId: this.currentSlot.Park_Id,
        sportId: this.currentSlot.Sport_Id,
        groundId: this.currentSlot.Ground_Id
      }});
    }
    else{
      this.router.navigate(['/home/signin'], {queryParams: {
        parkId: this.currentSlot.Park_Id,
        sportId: this.currentSlot.Sport_Id,
        groundId: this.currentSlot.Ground_Id,
        customSlot: true
      }});
    }
  }

  bookSlot() {
    let sport = {
      "label": this.currentSlot.Sport_Name,
      "value": this.currentSlot.Sport_Id,
      "image": "https://content.fieldsmanager.com/"+environment.logoStage+"/sports-images/"+this.currentSlot.Sport_Id+".png"
    }

    let team_names = this.selectedTeams.map((item) => {
      return item.Team_Name;
    });

    localStorage.setItem("teamName", team_names.toString());
    localStorage.setItem("residentOf", this.residentOf);

    this.router.navigate(['/home/bookings', { dn: this.currentSlot.Opt_Donation }]);
    this.citiesservice.sendBookingData(sport);
    this.homeService.sendBookingData(this.currentSlot);
  }

  requestPassword(currentSlot){
    if(this.user=='true'){
      console.log(localStorage.getItem('id'));
      this.slotService.passwordRequest(localStorage.getItem('id'),currentSlot.Slot_Id,currentSlot.City_Id).subscribe((res)=>{
        console.log(res);
        this.messageService.add({ severity: 'success', summary: 'Request', detail: res.body.msg});
        $("#checkPwdModal").modal('hide');
      });
    }
    else{
      $("#checkPwdModal").modal('hide');
      this.router.navigate(['/home/signin',{param:'slot-detail/'+currentSlot.hash_key}]);
    }
  }

  onVerifyPwd() {
    this.formSubmitted = true;
    if (this.password !== "" && this.password !== undefined) {
      this.slotService.verifySlotPwd(this.currentSlot.Slot_Id, this.password)
      .subscribe((res)=> {
        if (res.body.msg !== undefined) {
          if (res.body.msg == "invalid") {
            this.slotPwdErr = true;
            this.err_msg = "Password not matched";
          } else {
            this.slotPwdErr = false;
            $("#checkPwdModal").modal('hide');
            this.bookSlot();
          }
        }
      });
    } else {
      this.err_msg = "Password should not be empty";
      this.slotPwdErr = true;
    }
  }

  bookSlotNow() {
    let current_date = new Date();
    let open_date = new Date(this.currentSlot.Open_Date);
    if (open_date.getTime() <= current_date.getTime()) {
      if (this.currentSlot.isPrivate === "true") {
        $("#checkPwdModal").modal('show');
      } else {
        if (this.currentSlot.Slot_Type == 'non-reservable') {
          $("#disclamierModal").modal('show');
        } else {
          this.formSubmitted = true;
  
          if ((this.selectedTeams.length !== 0 && this.currentSlot.Ask_Team_Name == 'true' && this.residentOf !== "" && this.currentSlot.Ask_Resident_Of == 'true') || (this.selectedTeams.length == 0 && this.currentSlot.Ask_Team_Name == 'false' && this.residentOf == "" && this.currentSlot.Ask_Resident_Of == 'false') || (this.selectedTeams.length == 0 && this.currentSlot.Ask_Team_Name == 'false' && this.residentOf !== "" && this.currentSlot.Ask_Resident_Of == 'true') || (this.selectedTeams.length !== 0 && this.currentSlot.Ask_Team_Name == 'true' && this.residentOf == "" && this.currentSlot.Ask_Resident_Of == 'false')) {
            this.bookSlot();
          }
        }

      }
    } else {
      $("#bookingAlertModal").modal("show");
    }
  }

  onDisclamier() {
    this.formSubmitted = true;
    if ((this.selectedTeams.length !== 0 && this.currentSlot.Ask_Team_Name == 'true' && this.residentOf !== "" && this.currentSlot.Ask_Resident_Of == 'true') || (this.selectedTeams.length == 0 && this.currentSlot.Ask_Team_Name == 'false' && this.residentOf == "" && this.currentSlot.Ask_Resident_Of == 'false') || (this.selectedTeams.length == 0 && this.currentSlot.Ask_Team_Name == 'false' && this.residentOf !== "" && this.currentSlot.Ask_Resident_Of == 'true') || (this.selectedTeams.length !== 0 && this.currentSlot.Ask_Team_Name == 'true' && this.residentOf == "" && this.currentSlot.Ask_Resident_Of == 'false')) {
      this.bookSlot();
    }
  }

  cancelSlot(resv) {
    this.cancelSlotId = resv.Slot_Id;
    if(this.user !='true' ){
      this.router.navigate(['/home/signin',{param:'slot-detail/'+this.currentSlot.hash_key}]);
    }
    else{
      $("#deleteSlotModal").modal('show');
    }
  }

  cnfDelSlot(){
    this.isDelSlot = true;
    if (this.reason !== '') {
      let data = {
        slot_id: this.cancelSlotId,
        reason: this.reason,
        user_id:this.user
      }
      $("#deleteSlotModal").modal('hide');
      console.log('cancel slot data ======', data);
      this.homeService.cancelSlot(data)
      .subscribe((res: any)=> {
        console.log('cancel slot res', res);
        this.messageService.add({ severity: 'success', summary: 'Cancel Slot', detail: res.body.msg });
      });
    }
  }

  declineSlot() {
    let slot_id = this.currentSlot.Slot_Id;
    this.loadingBar = true;
    this.slotService.declineAssignedSlot(slot_id).subscribe((response) =>{
      console.log(response)
      this.loadingBar = false;
      if(response.status === 200){
        this.router.navigate(['/home']);
        this.messageService.add({ severity: 'success', summary: 'Decline Slot', detail: 'slot declined successfully!' })
      }
    })

  }

  logout () {
    this.user = 'false';
    this.cityAdmin='false';
    this.superAdmin='false';
    this.userService.logout();
    console.log('UserLoginService: Logging out');
    localStorage.removeItem('fb_token')
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    localStorage.removeItem('defaultParkName');
    localStorage.removeItem('defaultParkId');
    sessionStorage.removeItem('authorizationToken');
    localStorage.removeItem('repId');
    localStorage.removeItem('repInitials');
    localStorage.removeItem('repName');
    localStorage.removeItem('superAdmin');
    localStorage.removeItem('id');
    localStorage.clear();
    this.router.navigate(['/home']);
  }

  getRequestType(service, status) {
    if(service.SR_Bid_Id) {
      if(service.Slot_Assigned_Time == null) {
        return service.Service_Name + ' Bid Request awaiting';
      } else {
        return service.Service_Name + ' Request ' + status;
      }
    } else {
      return service.Service_Name + ' Request ' + status;
    }
  }

  getRequestImg(service, status) {
    return service.Service_Name.split(' ').join('_')
  }

  goBack() {
    this._location.back();
  }
  onSearch(query: string) {
    if (query.length > 2) {
     console.log(query)
     this.getTeamsListonSearch(this.userId,query)
    }
    else{
      this.teamsOnSearch = [];
    } 
  }
  onSelect(result: string) {
    this.selectedResult = result;
    this.teamsOnSearch = [];
  }
}

import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
import * as moment from 'moment';
import { MessageService } from 'primeng/components/common/messageservice';
declare let $;
import {Validators, FormControl, FormGroup, FormBuilder, AsyncValidator, FormArray } from '@angular/forms';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  services: any[] = [];
  currentService: any;
  sports: any[] = [];
  serviceSports: any[] = [];
  serviceTypes: any[] = [];
  moment: any = moment;
  serviceName: string = "";
  serviceType: string = "";
  isFormSubmitted: boolean = false;
  userId: string;
  cityId: string;
  editService: boolean;
  editServiceId: any;
  loadingBar = false;
  error:any;
  unauthorized:any;
  msgs:any;
  qualService:any;
  qualSport:any;
  serviceSportsQual: any[] = [];
  qualsForm: FormGroup;
  qualsLimit:any = 5;
  commentService:any;
  serviceComment: any[] = [];
  commentForm: FormGroup;
  commentLimit:any = 5;

  


  constructor(
    private homeService: HomeService,
    private messageService: MessageService,
    private fb: FormBuilder,
    private cm: FormBuilder,

  ) {

  }

  get serviceSportsQuals() {
    return this.qualsForm.controls["serviceSportsQuals"] as FormArray;
  }

  set serviceSportsQuals(val) {
    this.qualsForm.controls["serviceSportsQuals"] = val;
  }

  get serviceComments() {
    return this.commentForm.controls["serviceComments"] as FormArray;
  }

  set serviceComments(val) {
    this.commentForm.controls["serviceComments"] = val;
  }

  ngOnInit() {
    this.qualsForm = this.fb.group({
        serviceSportsQuals: this.fb.array([])
    });
    this.serviceSportsQuals = this.qualsForm.get('serviceSportsQuals') as FormArray;

    this.commentForm = this.cm.group({
      serviceComments: this.cm.array([])
  });
  this.serviceComments = this.commentForm.get('serviceComments') as FormArray;


    this.serviceTypes = ['', 'Facility', 'Provider']
    this.getSports();
    this.getServices();
    this.userId = localStorage.getItem('CognitoIdentityServiceProvider.3run3nquj30pkpaf1djg6acu8n.rohit.clockDrift');
    this.cityId = sessionStorage.getItem('cityId');
  }

  onServiceAction() {
    if (this.editService) {
      this.onUpdateService();
    } else {
      this.onCreateService();
    }
  }

  onCreateService() {
    this.isFormSubmitted = true;
    let typeSport = false;
    if(this.serviceType == '2'){
      typeSport = this.serviceSports.length > 0;
    } else {
      typeSport = this.serviceType != '';
    }
    if (this.serviceName !== "" && typeSport) {
      $("#createServiceModal").modal('hide');
      this.loadingBar=true;
      let data = {
        service_name: this.serviceName,
        serviceType: this.serviceType,
        serviceSports: this.serviceSports,
        created_by: this.userId,
        city_id: this.cityId
      }
      this.homeService.createService(data)
        .subscribe((res: any)=> {
          let body = JSON.parse(res.body);
          if(body.httpStatus == 400) {
            $("#createServiceModal").modal('show');
            this.loadingBar=false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error Creating Service',
              detail: body.message
            });
          } else {
            this.loadingBar=false;
            this.messageService.add({
              severity: 'success',
              summary: 'Create Service',
              detail: body.message
            });
            this.getServices();
            $("#createServiceModal").modal('hide');
            this.isFormSubmitted = false;
          }
        });
    }
  }

  getServices() {
    this.loadingBar=true;
    this.homeService.getServices()
    .subscribe((res: any)=> {

      if(res.msg=='No services found!'){
        this.services = [];
      }
      else{
        this.services = res;
      }
      this.loadingBar=false;
    });
  }

  openCreateSrvModal() {
    this.isFormSubmitted = false;
    this.currentService = null;
    $("#createServiceModal").modal('show');
    this.editService = false;
    this.serviceName = "";
    this.serviceType = "";
    this.serviceSports = [];
  }

  onEditService(service) {
    this.isFormSubmitted = false;
    this.currentService = service;
    this.editService = true;
    this.editServiceId = service.Service_Id
    this.serviceName = service.Service_Name;
    this.serviceType = service.Service_Type;
    this.serviceSports = service.sports.map((e) => e.Sport_Id);
    setTimeout(function(){
      $(".selectpicker").selectpicker();
      $('.selectpicker').selectpicker('refresh')
    }.bind(this), 100);
    $("#createServiceModal").modal('show');
  }

  onUpdateService() {
    this.isFormSubmitted = true;
    let typeSport = false;
    if(this.serviceType == '2'){
      typeSport = this.serviceSports.length > 0;
    } else {
      typeSport = this.serviceType != '';
    }
    if (this.serviceName !== "" && typeSport) {
      this.loadingBar=true;
      $("#createServiceModal").modal('hide');
      this.isFormSubmitted = false;
      if(this.serviceType == '1') {
        this.serviceSports = [];
      }
      let data = {
        service_id: this.editServiceId,
        service_name: this.serviceName,
        service_type: this.serviceType,
        service_sports: this.serviceSports,
      }
      this.homeService.updateService(data)
      .subscribe((res: any)=> {
          let body = JSON.parse(res.body);
          if(body.httpStatus == 400) {
            $("#createServiceModal").modal('show');
            this.loadingBar=false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error Creating Service',
              detail: body.message
            });
          } else {
            this.loadingBar=false;
            this.messageService.add({
              severity: 'success',
              summary: 'Create Service',
              detail: body.message
            });
            this.getServices();
            $("#createServiceModal").modal('hide');
            this.isFormSubmitted = false;
          }
      });
    }
  }

  onChangeStatus(type, service_id) {
    this.loadingBar=true;
      let data = {
        service_id: service_id,
        type: type === "Activate" ? "Active": "InActive"
      }
      this.homeService.changeServiceStatus(data)
      .subscribe((res: any)=> {
          let body = JSON.parse(res.body);
          if(body.httpStatus == 400) {
            this.loadingBar=false;
            this.messageService.add({
              severity: 'error',
              summary: 'Error Updating status',
              detail: body.message
            });
          } else {
            this.loadingBar=false;
            this.messageService.add({
              severity: 'success',
              summary: 'Update Service',
              detail: body.message
            });
            this.getServices();
            this.isFormSubmitted = false;
          }
      });
  }

  onTypeChange(val) {
    setTimeout(function(){
      if(this.serviceType == '2'){
        if(this.currentService) {
          this.serviceSports = this.currentService.sports.map((e) => e.Sport_Id);
        }
      } else {
        this.serviceSports = [];
      }
      setTimeout(function(){
        $(".selectpicker").selectpicker();
        $('.selectpicker').selectpicker('refresh')
      }.bind(this), 10);
    }.bind(this), 10);
  }

  getSports() {
    this.homeService.getSports().subscribe((data) => {
        this.sports = [];
        if (data.length === 0) {
        } else {
            data.forEach((o) => {
                const temp = {};
                temp['Sport_Id'] = o.Sport_Id;
                temp['Sport_Name'] = o.Sport_Name;
                this.sports.push(temp);
            });
        }
    });
  }

  addQualsforSerivceSports(service, sport) {
    this.addnewQualfieldforSerivceSports(service.Service_Id, sport.Sport_Id);
    this.qualService = service
    this.qualSport = sport
    this.serviceSportsQual = []
  }

  addnewQualfieldforSerivceSports(serviceId, sportId) {
    this.loadingBar = true;
    this.homeService.getServiceSportQuals(serviceId, sportId).subscribe((res: any)=> {
      this.loadingBar = false;
      this.serviceSportsQuals = this.fb.array([]);
      for(let obj of res.body){
        this.serviceSportsQuals.push(this.createItem(obj));
      }
      $("#addQualsModal").modal('show');
    });
  }

  addNewQual(){
      const serviceQualsForm = this.fb.group({
        Qual_Tag: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(6), Validators.pattern('^[A-Z0-9]{1,10}$')]],
        Qual_Name: [null, [Validators.required, Validators.minLength(3)]]
      });
      this.serviceSportsQuals.push(serviceQualsForm);
      this.serviceSportsQuals.controls
  }

  removeSportServiceQual(i){
    this.serviceSportsQuals.removeAt(i);
  }

  onQualsSubmit() {
    let data = {
      serviceId: this.qualService.Service_Id,
      sportId: this.qualSport.Sport_Id,
      quals: this.serviceSportsQuals.value
    }
    $("#addQualsModal").modal('hide');
    this.loadingBar=true;
    this.homeService.addServiceSportQuals(data).subscribe((res: any)=> {
      this.loadingBar=false;
      this.messageService.add({
        severity: 'success',
        summary: 'Update Serivce Sport Qualifications',
        detail: 'Serivce Sport Qualifications updated successfully!'
      });
    })
  }

  createItem(obj): FormGroup {
    return this.fb.group({
      Qual_Tag: [obj.Qual_Tag, [Validators.required, Validators.minLength(3), Validators.maxLength(6), Validators.pattern('^[A-Z0-9]{1,10}$')]],
      Qual_Name: [obj.Qual_Name, [Validators.required, Validators.minLength(3)]]
    })
  }

  addCommentsforSerivce(service) {
    this.commentService = service
    this.serviceComment = []
    this.addnewCommentfieldforSerivce(service.Service_Id);
    
  }

  addnewCommentfieldforSerivce(serviceId,) {
    this.loadingBar = true;
   this.homeService.getServiceComments(serviceId,).subscribe((res: any)=> {
      this.loadingBar = false;
      this.serviceComments = this.cm.array([]);
      for(let obj of res.body){
        this.serviceComments.push(this.createCommentItem(obj));
      }
      $("#addCommentsModal").modal('show');
    });
  }

  createCommentItem(obj): FormGroup {
    return this.cm.group({
      Comments: [obj.Comments, [Validators.required, Validators.minLength(3)]]
    })
  }

  removeComment(i){
    this.serviceComments.removeAt(i);
  }

  addNewComment(){
    
    const serviceCommentForm = this.cm.group({
      Comments: [null, [Validators.required, Validators.minLength(3)]]
    });
    this.serviceComments.push(serviceCommentForm);
    this.serviceComments.controls
}

onCommentSubmit() {
  let data = {
    serviceId: this.commentService.Service_Id,
    comments: this.serviceComments.value
  }
  $("#addCommentsModal").modal('hide');
  this.loadingBar=true;
  this.homeService.addServiceComments(data).subscribe((res: any)=> {
    this.loadingBar=false;
    this.messageService.add({
      severity: 'success',
      summary: 'Update comment',
      detail: 'Serivce comment updated successfully!'
    });
  })
}
}

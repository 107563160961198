export class Schedule {
  get endTime(): string {
    return this._endTime;
  }

  set endTime(value: string) {
    this._endTime = value;
  }
  get startTime(): string {
    return this._startTime;
  }

  set startTime(value: string) {
    this._startTime = value;
  }
  get paidSlot(): any {
    return this._paidSlot;
  }

  set paidSlot(value: any) {
    this._paidSlot = value;
  }
  get slotPrice(): any {
    return this._slotPrice;
  }

  set slotPrice(value: any) {
    this._slotPrice = value;
  }

  get isPrivateSlot(): any {
    return this._isPrivateSlot;
  }

  set isPrivateSlot(value: any) {
    this._isPrivateSlot = value;
  }

  get password(): any {
    return this._password;
  }

  set password(value: any) {
    this._password = value;
  }

  get payByCash(): any {
    return this._payByCash;
  }

  set payByCash(value: any) {
    this._payByCash = value;
  }


  private _slotPrice: any;
  private _paidSlot: any;
  private _scheduleId: number;
  private _sportId: number;
  private _groundId: number;
  private _startDate: string;
  private _endDate: string;
  private _slot: string;
  private _scheduleStatus: string;
  private _days: string;
  private _repId: number;
  private _parkId: number;
  private _startTime: string;
  private _endTime: string;
  private _openBefore: string;
  private _closeBefore: string;
  private _scheduleCategory: string;
  private _autoFIFO: any;
  private _weekStartDay: any;
  private _openWeekBefore: string;
  private _isPrivateSlot: string;
  private _password: string;
  private _payByCash: boolean;
  private _team: boolean;
  private _resident: boolean;
  private _informationSlot:boolean;
  private _comments:any;
  private _bookingURL:any;
  private _nonReserveSlot: boolean;
  private _publishInFb: boolean;
  private _fcfsSlot : boolean;
  private _serviceFee:any;
  private _leagueName:any;

  
  get openWeekBefore(): any {
    return this._openWeekBefore;
  }

  set openWeekBefore(value: any) {
    this._openWeekBefore = value;
  }

  get weekStartDay(): any {
    return this._weekStartDay;
  }

  set weekStartDay(value: any) {
    this._weekStartDay = value;
  }

  get autoFIFO(): any {
    return this._autoFIFO;
  }

  set autoFIFO(value: any) {
    this._autoFIFO = value;
  }
  get scheduleCategory(): string {
    return this._scheduleCategory;
  }

  set scheduleCategory(value: string) {
    this._scheduleCategory = value;
  }

  get openBefore(): string {
    return this._openBefore;
  }

  set openBefore(value: string) {
    this._openBefore = value;
  }

  get closeBefore(): string {
    return this._closeBefore;
  }

  set closeBefore(value: string) {
    this._closeBefore = value;
  }

  get scheduleId(): number {
    return this._scheduleId;
  }

  set scheduleId(value: number) {
    this._scheduleId = value;
  }

  get sportId(): number {
    return this._sportId;
  }

  set sportId(value: number) {
    this._sportId = value;
  }

  get groundId(): number {
    return this._groundId;
  }

  set groundId(value: number) {
    this._groundId = value;
  }

  get startDate(): string {
    return this._startDate;
  }

  set startDate(value: string) {
    this._startDate = value;
  }

  get endDate(): string {
    return this._endDate;
  }

  set endDate(value: string) {
    this._endDate = value;
  }

  get slot(): string {
    return this._slot;
  }

  set slot(value: string) {
    this._slot = value;
  }

  get scheduleStatus(): string {
    return this._scheduleStatus;
  }

  set scheduleStatus(value: string) {
    this._scheduleStatus = value;
  }

  get days(): string {
    return this._days;
  }

  set days(value: string) {
    this._days = value;
  }

  get repId(): number {
    return this._repId;
  }

  set repId(value: number) {
    this._repId = value;
  }

  get parkId(): number {
    return this._parkId;
  }

  set parkId(value: number) {
    this._parkId = value;
  }
  get team(): boolean {
    return this._team;
  }

  set team(value: boolean) {
    this._team = value;
  }

  get resident(): boolean {
    return this._resident;
  }

  set resident(value: boolean) {
    this._resident = value;
  }
  get informationSlot(): boolean {
    return this._informationSlot;
  }

  set informationSlot(value: boolean) {
    this._informationSlot = value;
  }
  get comments(): boolean {
    return this._comments;
  }

  set comments(value: boolean) {
    this._comments = value;
  }

  get bookingURL(): boolean {
    return this._bookingURL;
  }

  set bookingURL(value: boolean) {
    this._bookingURL = value;
  }
  get nonReserveSlot(): boolean {
    return this._nonReserveSlot;
  }

  set nonReserveSlot(value: boolean) {
    this._nonReserveSlot = value;
  }

  get publishInFb(): boolean {
    return this._publishInFb;
  }

  set publishInFb(value: boolean) {
    this._publishInFb = value;
  }

  get fcfsSlot(): boolean {
    return this._fcfsSlot;
  }

  set fcfsSlot(value: boolean){
    this._fcfsSlot = value;
  }

  get serviceFee(): boolean {
    return this._serviceFee;
  }

  set serviceFee(value: boolean){
    this._serviceFee = value;
  }

  get leagueName(): boolean {
    return this._leagueName;
  }

  set leagueName(value: boolean) {
    this._leagueName = value;
  }
}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()
export class UsersService {

  constructor(private http: HttpClient) { }

  public getReservations(userId, cityId, category, filter): Observable<HttpResponse<any>> {
    console.log('this is the data from get Reservations History by user Id', userId);
    let params = new HttpParams();
    let headers = new HttpHeaders().set('authorizationtoken', sessionStorage.getItem('authorizationToken'));
    params = params.append('cityId', cityId);
    params = params.append('userId', userId);
    params = params.append('filter', filter);
    params = params.append('category', category);
    const options = { observe: 'response', params: params};
    console.log('headers:', headers);
    console.log('params:', params);
    const url = environment.apiUrl + '/users/reservations';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers});
  }

  public getAllReservations(userId, cityId, category): Observable<HttpResponse<any>> {
    console.log('this is the data from get all Reservations History by user Id', userId);
    let params = new HttpParams();
    let headers = new HttpHeaders().set('authorizationtoken', sessionStorage.getItem('authorizationToken'));
    params = params.append('cityId', cityId);
    params = params.append('userId', userId);
    // params = params.append('filter', filter);
    params = params.append('category', category);
    const options = { observe: 'response', params: params};
    console.log('headers:', headers);
    console.log('params:', params);
    const url = environment.apiUrl + '/users/reservations';
    return this.http.get<any>(url, { observe: 'response', params: params, headers: headers});
  }
  public getActiveUsers() {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    // let params = new HttpParams()
    const url = environment.usersessionUrl+"/facility/users"
    return this.http.get<any>(url,{observe: 'response', headers: headers})
  }

  public getActivityHistory(userId) {
    // let headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append('user_id',userId);
    const url = environment.usersessionUrl+"/profile/user/activity/history"
    return this.http.get<any>(url,{observe: 'response',params : params})
  }

  public getUsersOnEmail(char) {
    let params = new HttpParams();
    params = params.append('char',char);
    const url = environment.newapiUrl+"/user/userfind"
    return this.http.get<any>(url,{observe: 'response',params : params})
  }
}

export class ParkModel {
  get parkId(): number {
    return this._parkId;
  }

  set parkId(value: number) {
    this._parkId = value;
  }

  get parkName(): string {
    return this._parkName;
  }

  set parkName(value: string) {
    this._parkName = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get street(): string {
    return this._street;
  }

  set street(value: string) {
    this._street = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get location(): number {
    return this._location;
  }

  set location(value: number) {
    this._location = value;
  }

  get state(): string {
    return this._state;
  }

  set state(value: string) {
    this._state = value;
  }

  get zipCode(): string {
    return this._zipCode;
  }

  set zipCode(value: string) {
    this._zipCode = value;
  }

  get website(): string {
    return this._website;
  }

  set website(value: string) {
    this._website = value;
  }

  get cityId(): number {
    return this._cityId;
  }

  set cityId(value: number) {
    this._cityId = value;
  }

  get parkStatus(): string {
    return this._parkStatus;
  }

  set parkStatus(value: string) {
    this._parkStatus = value;
  }
  
  get isCancel(): boolean {
    return this._isCancel;
  }

  set isCancel(value: boolean) {
    this._isCancel= value;
  }

  private _parkId: number;
  private _parkName: string;
  private _address: string;
  private _street: string;
  private _city: string;
  private _state: string;
  private _zipCode: string;
  private _website: string;
  private _cityId: number;
  private _parkStatus: string;
  private _isCancel:boolean;
  private _location:number;
}

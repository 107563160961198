import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../service/home.service';
declare let $;
@Component({
  selector: 'app-location-preference',
  templateUrl: './location-preference.component.html',
  styleUrls: ['./location-preference.component.css']
})
export class LocationPreferenceComponent implements OnInit {
  services: any[] = [];

  adminLocations: any[] = [];
  locationName: string = "";
  loadingBar = false;
  error:any;
  unauthorized:any;
  msgs:any;
  checkValidation;
  constructor( private homeService: HomeService,) { }

  ngOnInit() {
    // this.getServices()
    this.getAdminRequestLocation()
  }

  // getServices() {
   
  //   this.homeService.getlocations()
  //   .subscribe((res: any)=> {

  //     if(res.msg=='No services found!'){
  //       this.services = [];
  //     }
  //     else{
  //       this.services = res;
  //     }
      
  //   });
  // }
  getAdminRequestLocation() {
    this.loadingBar=true;
    this.homeService.getAdminRequestLocation()
    .subscribe((res: any)=> {
       
      if(res.msg=='No services found!'){
        this.adminLocations = [];
      }
      else{
        this.adminLocations = res;
      }
      this.loadingBar=false;
    });
  }
  acceptLocationRequest(location) {
   
    this.homeService.acceptLocationRequest(location.Location_Id)
    .subscribe((res: any)=> {
      this.getAdminRequestLocation()
    });
  }
  deleteLocationRequest(location) {
   
    this.homeService.deleteLocationRequest(location.Location_Id)
    .subscribe((res: any)=> {
      this.getAdminRequestLocation()
    });
  }

  openCreateSrvModal() {
    // this.isFormSubmitted = false;
    // this.currentService = null;
    this.checkValidation=false;
    $("#createServiceModal").modal('show');
    this.locationName = '';
    // this.serviceName = "";
    // this.serviceType = "";
    // this.serviceSports = [];
  }

  onCreateLocation() {
    if(this.locationName.length===0){
      this.checkValidation=true;
    }
    else{
      $("#createServiceModal").modal('hide');
       this.loadingBar=true;
      let data = {
        location_name: this.locationName,
       
      }
      this.homeService.createLocation(data)
        .subscribe((res: any)=> {
         
            this.loadingBar=false;
            this.getAdminRequestLocation()
          // this.getServices()
        });
    }
  }

  onDeleteLocation(location){
    this.loadingBar=true;
    let data = {
      locationId: location.Location_Id,
     
    }
    this.homeService.deleteLocation(data)
      .subscribe((res: any)=> {
       
        // this.getServices()
        this.loadingBar=false;
      });

  }



}

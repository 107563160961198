import { ReservationHistoryComponent } from './secure/reservation-history/reservation-history.component';
import { CheckStatusComponent } from './public/check-status/check-status.component';
import {RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {HomeComponent, HomeLandingComponent} from './public/home.component';
import {AdminComponent} from './admin.component';
import {GroundsComponent} from './secure/grounds/grounds.component';
import {SlotsComponent} from './secure/slots/slots.component';
import {ParksComponent} from './secure/parks/parks.component';
import {ParkDefaultsComponent} from './secure/parkDefaults/parkDefaults.component';
import {AdminsComponent} from './secure/admins/admins.component';
import {LoginComponent} from './public/auth/login/login.component';
import {RegisterComponent} from './public/auth/register/registration.component';
import {ForgotPassword2Component, ForgotPasswordStep1Component} from './public/auth/forgot/forgotPassword.component';
import {LogoutComponent, ConfirmRegistrationComponent} from './public/auth/confirm/confirmRegistration.component';
import {ResendCodeComponent} from './public/auth/resend/resendCode.component';
import {NewPasswordComponent} from './public/auth/newpassword/newpassword.component';
import {UnauthorizedComponent} from './secure/unauthorized/unauthorized.component';
import {ErrorComponent} from './secure/error/error.component';
import {AddCityComponent} from './public/add-city/add-city.component';
import {CityRequestsComponent} from './secure/city-requests/city-requests.component';
import {LandingComponent} from './public/landing/landing.component';
import {PricingComponent} from './public/pricing/pricing.component';
import {CustomSlotComponent} from './public/custom-slot/custom-slot.component';
import {DashboardComponent} from './public/dashboard/dashboard.component';
import {AccountPreferencesComponent} from './secure/account-preferences/account-preferences.component';
import {SportsComponent} from './public/sports/sports.component';
import {SportsDetailComponent} from './public/sports-detail/sports-detail.component';
import {SigninComponent} from './public/main-auth/signin/signin.component';
import {ForgotPasswordComponent,ForgotPasswordStep2Component} from './public/main-auth/forgot-password/forgot-password.component';
import {ReSendComponent} from './public/main-auth/re-send/re-send.component';
import {SignupComponent} from './public/main-auth/signup/signup.component';
import {HomeLogoutComponent,ConfirmSignupComponent} from './public/main-auth/confirm-signup/confirm-signup.component';
import {BookingComponent} from './public/booking/booking.component';
import {FeedbackComponent} from './public/feedback/feedback.component';
import {ReportIssueComponent} from './public/report-issue/report-issue.component';
import {RefereeBookingComponent} from './public/referee-booking/referee-booking.component';
import {ServicesComponent} from './secure/services/services.component';
import {ServiceSignupsComponent} from './secure/service-signups/service-signups.component';
import {SportRequestsComponent} from './secure/sport-requests/sport-requests.component';
import {RefereeRequestsComponent} from './secure/referee-requests/referee-requests.component';
import { PartnersComponent } from './secure/partners/partners.component';
import { SlotDetailsComponent } from './public/slot-details/slot-details.component';
import { ServiceDetailsComponent } from './public/service-details/service-details.component';
import { SlotPasswordRequestsComponent } from './secure/slot-password-requests/slot-password-requests.component';
import { EditSlotRequestsComponent } from './secure/edit-slot-requests/edit-slot-requests.component';
import { BillingComponent } from './secure/billing/billing.component';
import { SubscriptionsComponent } from './secure/subscriptions/subscriptions.component';
import {CustomSlotRequestsComponent} from './secure/custom-slot-requests/custom-slot-requests.component';
import { CustomSlotsComponent } from './public/custom-slots/custom-slots.component';
import {CityAdminsComponent} from './secure/city-admins/city-admins.component'
import {SupportRequestsComponent} from './public/support-requests/support-requests.component';
import {SummaryComponent} from './secure/summary/summary.component';
import { AboutComponent } from './public/about/about.component';
import { WhyComponent } from './public/why/why.component';
import { InfoslotClaimReqsComponent } from './secure/infoslot-claim-reqs/infoslot-claim-reqs.component';
import { NotificationsComponent } from './secure/notifications/notifications.component';
import { OnboardSlotsComponent } from './secure/onboard-slots/onboard-slots.component';
import { AdminProfileComponent } from './secure/admin-profile/admin-profile.component';
import { WelcomeComponent } from './public/welcome/welcome.component';
import { SlotReservationSuccessComponent } from './public/slot-reservation-success/slot-reservation-success.component'
import { ServiceRequestComponent } from './public/service-request/service-request.component';
import { ServiceRequestSuccessComponent } from './public/service-request-success/service-request-success.component';
import { ServiceSubscriptionsComponent } from './secure/service-subscriptions/service-subscriptions.component';
import { SurveyComponent } from './public/survey/survey.component';
import { LocationPreferenceComponent } from './secure/location-preference/location-preference.component';
import { LocationRequestComponent } from './secure/location-request/location-request.component';
import { MembershipRequestsComponent } from './secure/membership-requests/membership-requests.component';

const homeRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'home', component: HomeComponent, children: [
            { path: 'crickworld', component: LandingComponent },
            { path: 'login', component: LoginComponent },
            {path: 'register', component: RegisterComponent},
            {path: 'accountActivation/:username', component: ConfirmRegistrationComponent},
            {path: 'resendCode', component: ResendCodeComponent},
            {path: 'forgotPassword/:email', component: ForgotPassword2Component},
            {path: 'forgotPassword', component: ForgotPasswordStep1Component},
            {path: 'newPassword', component: NewPasswordComponent},
            {path: 'signUp', component: AddCityComponent},
            {path: 'landing', component: LandingComponent},
            {path: 'land', component: HomeLandingComponent},
            // {path: 'pricing', component: PricingComponent},
            {path: 'pricing/:type', component: PricingComponent},
            {path: 'checkStatus', component: CheckStatusComponent},
            {path: 'customSlot', component: CustomSlotComponent},
            {path: 'dashboard', component: DashboardComponent},
            {path: '', component: LandingComponent},
            {path: 'sports', component: SportsComponent},
            {path: 'sports-detail', component: SportsDetailComponent},
            {path: 'signin', component: SigninComponent},
            {path: 'forgot-password', component: ForgotPasswordComponent},
            {path: 'forgot-password/:email', component: ForgotPasswordStep2Component},
            {path: 'resend', component: ReSendComponent},
            {path: 'signup/:type', component: SignupComponent},
            {path: 'newaccountActivation/:username/:userId', component: ConfirmSignupComponent},
            {path: 'bookings', component: BookingComponent},
            {path: 'feedback/:reservation_id', component: FeedbackComponent},
            {path: 'report-issue/:reservation_id', component: ReportIssueComponent},
            {path: 'referee-bookings', component: RefereeBookingComponent},
            {path: 'slot-detail/:slot_id', component: SlotDetailsComponent },
            {path: 'service-detail/:service_id', component: ServiceDetailsComponent },
            {path: 'custom-slots', component: CustomSlotsComponent },
            {path: 'support-requests', component: SupportRequestsComponent },
            {path: 'about', component: AboutComponent },
            {path: 'why', component: WhyComponent },
            {path: 'welcome', component: WelcomeComponent },
            {path: 'success', component : SlotReservationSuccessComponent},
            {path: 'serviceRequests/:serviceRequestId', component : ServiceRequestComponent},
            {path: 'serviceRequests/:serviceRequestId/success', component : ServiceRequestSuccessComponent},
            {path: 'survey/:serviceRequestId/:reviewerName/:serviceName/:userName/:reviewerId/:slotDate/:slotTime/:sport', component: SurveyComponent },

        ]
    },
];

const adminRoutes: Routes = [
    {

        path: '',
        redirectTo: '/admin',
        pathMatch: 'full'
    },
    {
        path: 'admin', component: AdminComponent, children: [
            { path: 'logout', component: LogoutComponent },
            // {path: 'parkDefaults', component: ParkDefaultsComponent},
            { path: 'slots', component: SlotsComponent },
            { path: 'grounds', component: GroundsComponent },
            { path: 'reservationHistory', component: ReservationHistoryComponent },
            { path: 'parks', component: ParksComponent },
            {path: 'locationpreference', component: LocationPreferenceComponent},
            {path: 'locationrequest', component: LocationRequestComponent},
            { path: 'admins', component: AdminsComponent },
            { path: 'unauthorized', component: UnauthorizedComponent },
            { path: 'error', component: ErrorComponent },
            { path: 'cityRequests', component: CityRequestsComponent },
            { path: '', component: SummaryComponent },
            { path: 'preferences', component: AccountPreferencesComponent },
            { path: 'logOut', component: HomeLogoutComponent },
            { path: 'services', component: ServicesComponent },
            { path: 'service-signups', component: ServiceSignupsComponent },
            { path: 'sportRequests', component: SportRequestsComponent },
            { path: 'refereeRequests', component: RefereeRequestsComponent },
            { path: 'membershipRequests', component: MembershipRequestsComponent },
            { path: 'refereeRequests', component: RefereeRequestsComponent },
            { path: 'partners', component: PartnersComponent },
            { path: 'slotPasswordRequests', component: SlotPasswordRequestsComponent },
            {path: 'edit-slot-requests', component: EditSlotRequestsComponent },
            {path: 'billing/:type', component: BillingComponent },
            {path: 'subscriptions', component: SubscriptionsComponent },
            {path: 'custom-slot-requests', component: CustomSlotRequestsComponent },
            {path: 'city-admins',component:CityAdminsComponent},
            {path: 'infoslot-claim-reqs',component: InfoslotClaimReqsComponent },
            {path: 'notifications',component:NotificationsComponent},
            { path: 'onboard-slot-requests',component:OnboardSlotsComponent },
            { path: 'profile', component: AdminProfileComponent },
            {path: 'service-subscriptions', component: ServiceSubscriptionsComponent },

        ],
    },
    {
        path: 'parkDefaults', component: ParkDefaultsComponent
    }
];

const routes: Routes = [
    {
        path: '',
        children: [
            ...homeRoutes,
            ...adminRoutes,
            {
                path: '',
                component: HomeComponent
            }
        ]
    },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatSnackBar } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { MembershipService } from '../../../service/membership.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-membership-preferences',
  templateUrl: './membership-preferences.component.html',
  styleUrls: ['./membership-preferences.component.css']
})
export class MembershipPreferencesComponent implements OnInit {

  membership_edit = false;
  membershipSetupConfig;

  constructor(
    public dialog: MatDialog,
    public membershipService: MembershipService,
    public snackBar: MatSnackBar
  ) { }

  littleElmCityId = environment.littleElmCityId;

  ngOnInit() {
    
    this.getMembershipPreferences(this.littleElmCityId);
    this.membershipSetupConfig = {
      membership_setup: false,
      first_name: true,
      last_name: true,
      street: true,
      city: true,
      state: true,
      zip_code: true,
      cricclub_id: true,
      email: true,
      phone_number: true
    }
  }

  getMembershipPreferences(city_id) {
    this.membershipService.getMembershipPreferences(city_id).subscribe(resp => {
      this.membershipSetupConfig = JSON.parse(JSON.stringify(resp));
    })
  }

  saveMembershipSetupConfig() {
    const setupConfig = JSON.stringify(this.membershipSetupConfig);
    let resultString = setupConfig.replace(new RegExp('\\b' + true + '\\b', 'gi'), '"' + true + '"');
    let finalString = resultString.replace(new RegExp('\\b' + false + '\\b', 'gi'), '"' + false + '"');
    this.membershipService.saveMembershipPreferences(this.littleElmCityId, JSON.parse(finalString)).subscribe(resp => {
      this.snackBar.open('Membership preferences updated successfully', 'Okay', {
        duration: 4000
      })
    })
  }

  confirmationPopUp(event) {
    const config = new MatDialogConfig();
    config.width = '35%';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, config);
    if (!this.membershipSetupConfig.membership_setup) {
      dialogRef.componentInstance.msg = `Are you sure you want to enable the League membership for users?`;
    } else {
      dialogRef.componentInstance.msg = `Are you sure you want to disable the League membership for users?`;
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result && !this.membershipSetupConfig.membership_setup) {
        this.membershipSetupConfig.membership_setup = true;
        this.saveMembershipSetupConfig();
      } else if (result && this.membershipSetupConfig.membership_setup) {
        this.membershipSetupConfig.membership_setup = false;
        this.saveMembershipSetupConfig();
      }
      if (!result) {
        event.source.checked = this.membershipSetupConfig.membership_setup;
      }
    });
  }

}
